import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment/moment';

//  styles
import useStyles from "./index.styles"

//  utils
import useRouter from '../../utils/useRouter'
import isEmpty from '../../utils/isEmpty';

// services
import formServices from "../../services/form";
import companiesServices from '../../services/companies';
import reportsServices from '../../services/reports';

//  components
import MasteroTable from '../../components/mastero-table';
import CreateNewForm from '../../components/create-form';
import LoadingDialog from '../../components/progress-dialog';
import Title from '../../components/title';
import SuccessDialog from '../../components/success-dialog';
import FailureDialog from '../../components/failure-dialog';
import DeleteDialog from '../../components/delete-dialog';

// mui
import { Autocomplete, TextField } from '@mui/material';

//global-states
import { GlobalStates } from '../../App';

let defaultPagination = {
    limit: 0,
    pageCount: 0,
    startIndex: -1,
    totalCount: 0
}

const NewForms = () => {
    const router = useRouter()
    const styles = useStyles();

    const [companyId, setCompanyId] = useState('');
    const [tableData, setTableData] = useState([]);
    const [globalStates] = useContext(GlobalStates);
    const [userType, setUserType] = useState(localStorage.getItem("user-type"))
    const [progressDialogOpen, setProgressDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [failureDialogOpen, setFailureDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState('');
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const [deleteFormId, setDeleteFormId] = useState('');



    //  SUPER ADMIN
    const [companies, setCompanies] = useState([]);


    const [startIndex, setStartIndex] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pagination, setPagination] = useState(defaultPagination);
    const [totalCount, setTotalCount] = useState(0);

    const TITLE = "CREATE NEW FORM"
    const PLACEHOLDER = "Form Name"
    const LABELS = {
        formName: "Form Name",
        description: "Form Description"
    }
    const SAVE_BTN_NXT = "Save Form"
    const TABLE_TITLE = "All Forms"
    const HEADINGS = [
        { title: "Name", key: "name", link: false },
        { title: "Description", key: "description", link: false },
        { title: "Report", key: "report", link: true },
        { title: "Assessment", key: "assessment", link: false },
        { title: "Launched", key: "launched", link: false },
        { title: "Created On", key: "createdon", link: false },
        { title: "Last Updated", key: "updatedon", link: false },
        { title: 'Quick Actions', key: 'quickActions', link: false }
    ];

    const fetchAllCompanies = async () => {
        let res = await companiesServices.fetchCompanies();
        if (res.status === 200 || res.status === 201) {
            setCompanies(res.data.data);
        }
    }

    const handleDownloadAssessment = async (element) => {
        console.log('handleDownloadAssessment element = ', element);
        if (isEmpty(companyId)) return;
        setDialogText('Downloading...')
        setProgressDialogOpen(true);
        let payload = {
            formId: element.formId,
            companyId: companyId
        }
        let options = {};
        const response = await reportsServices.downloadAssessmentReport(payload, options);
        console.log('downloadAssessmentReport response = ', response);
        if (response.status === 200) {
            let fileUrl = response.data.message.file.Location || response.data.data.file.Location;
            console.log('downloadAsExcel fileUrl = ', fileUrl);
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Assessment reports';
                link.click();
                setProgressDialogOpen(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setProgressDialogOpen(false);
        }
    }

    const fetchForms = async (companyId) => {
        let res = await formServices.fetchForm(companyId, startIndex);
        if (res.status === 200 || res.status === 201) {
            let tempData = []
            let data = res.data.data;
            data.forEach(ele => {
                let jsonData = {
                    formId: ele.id,
                    companyId: ele.companyId,
                    name: ele.name,
                    description: ele.description,
                    report: ele.launched ? "Yes" : "No",
                    assessment: ele.assessment ? "Yes" : "No",
                    launched: ele.launched ? "Yes" : "No",
                    createdon: moment(ele.createdOn).format("DD-MM-YYYY hh:mm A"),
                    updatedon: moment(ele.updatedOn).format("DD-MM-YYYY hh:mm A")
                }
                tempData.push(jsonData);
            })
            let pagination = res.data.pagination;
            setPagination(pagination);
            setPageCount(pagination.pageCount)
            setTotalCount(pagination.totalCount);
            setTableData(tempData)
        }
    }

    const handleEdit = (element, key) => {
        if (element.launched === 'No') {
            router.history.push(`/forms?c_id=${element.companyId}&formId=${element.formId}`);
        }
        else {
            setDialogText('Launched Form cannot be edited');
            setFailureDialogOpen(true);
        }
    }

    useEffect(() => {
        if (companyId !== '') {
            fetchForms(companyId, startIndex)
        }
    }, [startIndex, companyId]);

    useEffect(() => {
        if (userType === "superAdmin") {
            fetchAllCompanies();
        }
        else {
            setCompanyId(localStorage.getItem("companyId"))
        }
    }, []);

    const handleCompanySelection = (e, values) => {
        if (values != null && values != '') {
            setCompanyId(values.id);
        }
    }

    const handleForwardClick = () => {
        if (startIndex + 10 < totalCount) {
            setStartIndex(startIndex + 10);
            setPageNo(pageNo + 1)
        }
    }

    const handleBackClick = () => {
        if (startIndex >= 10) {
            setStartIndex(startIndex - 10)
            setPageNo(pageNo - 1)
        }
    }

    const handleCopy = async (element, key) => {
        setDialogText('Copying Form, Please Wait...')
        setProgressDialogOpen(true);
        const payload = {
            oldFormId: element.formId,
            companyId: element.companyId
        }
        const respData = await formServices.copyForm(payload);
        if (respData.status === 200 || respData.status === 201) {
            const data = respData.data;
            setProgressDialogOpen(false);
            setDialogText('Form Copied Successfully');
            setSuccessDialogOpen(true);
            fetchForms(companyId, startIndex)
        }
        else {
            setProgressDialogOpen(false);
            setDialogText('Error in Copying Form');
            setFailureDialogOpen(true);
        }
    }

    const handleDelete = (key) => {
        setDeleteFormId(tableData[key].formId);
        setDialogText('Form');
        setConfirmDeleteDialog(true);
    }

    const handleDeleteForm = async (formId) => {
        setConfirmDeleteDialog(false);
        setDialogText('Deleting Form, Please Wait...')
        setProgressDialogOpen(true);
        const respData = await formServices.deleteForm({ id: formId });
        if (respData.status === 200 || respData.status === 201) {
            const data = respData.data;
            setTimeout(() => {
                setProgressDialogOpen(false);
                setDialogText('Form Deleted Successfully');
                setSuccessDialogOpen(true);
                fetchForms(companyId, startIndex);
            }, [1500])
        }
        else {
            setProgressDialogOpen(false);
            setDialogText('Error in Deleting Form');
            setFailureDialogOpen(true);
        }
    }

    const handleSuccessClose = () => {
        setSuccessDialogOpen(false);
    }

    const handleFailureClose = () => {
        setFailureDialogOpen(false)
    }

    return (
        <div className={styles.container}>
            <div className={styles.row1}>
                {userType === "superAdmin" ? (
                    <div className={styles.CompanyContainer}>
                        <Title titleText={"SELECT COMPANY"} infoIcon={true} />
                        <Autocomplete
                            sx={{
                                '& .MuiInputBase-root': {
                                    width: "25%",
                                    padding: "0rem 1rem",
                                }
                            }}

                            options={companies}
                            onChange={handleCompanySelection}
                            getOptionLabel={(option) => { return option.name }}
                            renderInput={(params) => <TextField{...params} />}
                            noOptionsText={"No Company Found"}
                        />
                    </div>
                ) : null}
                <CreateNewForm
                    title={TITLE}
                    lables={LABELS}
                    placeholder={PLACEHOLDER}
                    btnText={SAVE_BTN_NXT}
                    companyId={companyId}
                />
            </div>

            {companyId !== '' && tableData.length > 0 ? (
                <div className={styles.row2}>
                    <MasteroTable
                        tableTitle={TABLE_TITLE}
                        tableHeadings={HEADINGS}
                        tableData={tableData}
                        quickActions={true}
                        deleteKey={true}
                        copyKey={true}
                        pageNo={pageNo}
                        pageCount={pageCount}
                        pagination={pagination}
                        handleForwardClick={handleForwardClick}
                        handleBackClick={handleBackClick}
                        handleEdit={handleEdit}
                        handleDuplicate={handleCopy}
                        handleDelete={handleDelete}
                        handleDownloadAssessment={handleDownloadAssessment}
                        thPadding={"0 3rem"}
                    />
                </div>
            ) : null}

            {
                <LoadingDialog
                    progressDialogOpen={progressDialogOpen}
                    dialogText={dialogText}
                />
            }

            {
                <SuccessDialog
                    successDialogOpen={successDialogOpen}
                    setSuccessDialogOpen={setSuccessDialogOpen}
                    dialogText={dialogText}
                    handleClick={handleSuccessClose}
                />
            }

            {
                <FailureDialog
                    errorDialogOpen={failureDialogOpen}
                    setErrorDialogOpen={setFailureDialogOpen}
                    dialogText={dialogText}
                    handleClick={handleFailureClose}
                />
            }

            {
                <DeleteDialog
                    id={deleteFormId}
                    confirmDeleteDialog={confirmDeleteDialog}
                    setConfirmDeleteDialog={setConfirmDeleteDialog}
                    dialogText={dialogText}
                    handleDelete={handleDeleteForm}
                />
            }
        </div>

    )
}

export default NewForms