import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import useStyles from './index.styles'

//  components
import Title from '../title';

//  mui
import { FormGroup } from 'react-bootstrap';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from "@mui/material"

//  services
import formServices from '../../services/form';

//  utils
import useRouter from '../../utils/useRouter';
import FailureDialog from '../failure-dialog';
import CustomInput from '../form-question/components/custom-input';

const CreateNewForm = ({
    title,
    lables,
    placeholder = '',
    btnText,
    companyId
}) => {
    const styles = useStyles();
    const router = useRouter();
    const formNameCharLimit = 60;
    const formDescCharLimit = 250;

    const [isAssessment, setAssessment] = useState(false);
    const [isTime, setTime] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [timeValue, setTimeValue] = useState(60);
    const [formId, setFormId] = useState('');

    const [formName, setFormName] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [formNameValidation, setFormNameValidation] = useState({ exceeded: false, limitText: `${formNameCharLimit} Characters`, isEmpty: true });
    const [formDescValidation, setFormDescValidation] = useState({ exceeded: false, limitText: `${formDescCharLimit} Characters`, isEmpty: true });

    const [failure, setFailure] = useState(false);

    // refs
    const nameErrorRef = useRef();
    const descErrorRef = useRef();

    useEffect(() => {
        if (formId !== '') {
            router.history.push(`/forms?c_id=${companyId}&formId=${formId}`);
        }
    }, [formId])

    const handleAssessment = () => {
        setAssessment(!isAssessment);
    }

    const handleAddTime = () => {
        setTime(!isTime);
    }

    const handleShowScore = () => {
        setShowScore(!showScore);
    }

    const handleTimeValue = (e) => {
        setTimeValue(e.target.value)
    }

    const createForm = async (data) => {
        const resp = await formServices.createForm(data);
        if (resp.status === 200 || resp.status === 201) {
            let respData = resp.data.data;
            setFormId(respData.id);
        }
    }

    const handleChangeFormName = (e) => {
        let value = e.target.value;
        setFormName(e.target.value);
        
        // reset error message
        nameErrorRef.current.style.display = 'none';
        nameErrorRef.current.innerText = ``

        if (value.length > formNameCharLimit) {
            setFormNameValidation({ exceeded: true, limitText: `${value.length - formNameCharLimit} Characters Exceeded`, isEmpty: false });
        }
        else {
            setFormNameValidation({ exceeded: false, limitText: `${formNameCharLimit - value.length} Characters`, isEmpty: false });
        }

    }

    const handleChangeFormDescription = (e) => {
        let value = e.target.value;
        setFormDescription(e.target.value);

        // reset error message
        descErrorRef.current.style.display = 'none';
        descErrorRef.current.innerText = ``

        if (value.length > formDescCharLimit) {
            setFormDescValidation({ exceeded: true, limitText: `${value.length - formDescCharLimit} Characters Exceeded`, isEmpty: false });
        }
        else {
            setFormDescValidation({ exceeded: false, limitText: `${formDescCharLimit - value.length} Characters`, isEmpty: false });
        }
    }

    const submitData = (e) => {
        e.preventDefault();
        if (companyId === '') {
            setFailure(true);
            return;
        }

        let target = e.target.elements;

        if (target.FormName.value === '') {
            target.FormName.focus();
            // target.FormName.style.outlineColor = `#ff0000`
            nameErrorRef.current.style.display = 'block';
            nameErrorRef.current.innerText = `Form name cannot be blank.`
            return;
        }
        if (target.FormName.value.length > formNameCharLimit) {
            target.FormName.focus();
            // target.FormName.style.outlineColor = `#ff0000`;
            nameErrorRef.current.style.display = 'block';
            nameErrorRef.current.innerText = `Form name cannot be more then 60 characters.`
            return;
        }

        if (formDescription === '') {
            target.FormDescription.focus();
            // target.FormDescription.style.outlineColor = `#ff0000`;
            descErrorRef.current.style.display = 'block';
            descErrorRef.current.innerText = `Form description cannot be blank.`
            return;
        }
        if (formDescription.length > formDescCharLimit) {
            target.FormDescription.focus();
            // target.FormDescription.style.outlineColor = `#ff0000`;
            descErrorRef.current.style.display = 'block';
            descErrorRef.current.innerText = `Form description cannot be more then 250 characters.`
            return;
        }

        let data = {}
        data["companyId"] = companyId;
        data["name"] = target.FormName.value;

        if (formDescription !== '') {
            data["description"] = formDescription;
        }

        if (isAssessment) {
            data["assessment"] = isAssessment;
            if (isTime) {
                data["timeLimit"] = parseInt(timeValue);
            } else {
                data["timeLimit"] = parseInt(60);
            }
        }


        if (showScore) {
            data["showScore"] = showScore
        }

        data["questions"] = [];
        createForm(data);
    }


    return (
        <div className={styles.CreateCompany_Container}>
            <div className={styles.Left_div}>
                <div className={styles.Inside_Left_div}>
                    <Title titleText={title} infoIcon={true} />

                    <form onSubmit={submitData}>
                        <div style={{position: 'relative', paddingBottom: '1rem'}} >
                            <label htmlFor='FormName' className={styles.Label}>
                                Form Name
                            </label>
                            {/* <input
                                className={styles.Input_Box}
                                id="FormName"
                                name="FormName"
                                type="text"
                                minLength="2"
                                // maxLength="60"
                                placeholder={placeholder}
                                onChange={event => {
                                    event.target.style.outlineColor = `#000`;
                                    nameErrorRef.current.style.display = 'none';
                                    nameErrorRef.current.innerText = ``
                                }}
                            // required
                            /> */}
                            <CustomInput
                                id="FormName"
                                defaultValue={formName}
                                handleChange={(e) => handleChangeFormName(e)}
                                exceeded={formNameValidation.exceeded}
                                // isEmpty={formNameValidation.isEmpty}
                                limitText={formNameValidation.limitText}
                                wrapperStyling={{ width: "60%" }}
                                maxLength={formNameCharLimit * 100}
                            />
                            <h6 className={styles.errorText} ref={nameErrorRef}></h6>
                        </div>

                        <div style={{ position: 'relative', paddingBottom: '1rem' }}>
                            <label htmlFor='FormDescription' className={styles.Label}>
                                Form Description
                            </label>
                            <textarea
                                className={`${styles.Input_Box} ${styles.TextArea}`}
                                name="FormDescription"
                                id="FormDescription"
                                // style={{outlineColor: formDescValidation.exceeded ? 'red' : ''}}
                                // maxLength={200}
                                onChange={handleChangeFormDescription}

                            />
                            <span className={styles.descLimitText}>{formDescValidation.limitText}</span>
                            <h6 className={styles.errorText} ref={descErrorRef}></h6>
                        </div>

                        <div className={styles.CheckBoxContainer}>
                            <FormGroup>
                                <FormControlLabel
                                    className={styles.CheckBox}
                                    control={
                                        <Checkbox
                                            style={{ color: "#F4511E" }}
                                            onChange={handleAssessment}
                                            checked={isAssessment}
                                        />
                                    }
                                    label="Assessment"
                                />
                            </FormGroup>
                        </div>

                        {isAssessment && (
                            <div style={{ display: "flex", flexDirection: "column", margin: "0.8rem 0rem", justifyContent: "flex-start" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        className={styles.CheckBox}
                                        control={
                                            <Checkbox
                                                style={{ color: "#F4511E" }}
                                                onChange={handleShowScore}
                                                checked={showScore}
                                            />
                                        }
                                        label="Show Score to Learner"
                                    />

                                    <div style={{ display: "flex" }}>
                                        <FormControlLabel
                                            className={styles.CheckBox}
                                            control={
                                                <Checkbox
                                                    style={{ color: "#F4511E" }}
                                                    onChange={handleAddTime}
                                                    checked={isTime}
                                                />
                                            }
                                            label="Time Limit(Seconds)"
                                        />
                                        <input
                                            className={styles.Input_Box}
                                            type="number"
                                            defaultValue="60"
                                            disabled={!isTime}
                                            onChange={handleTimeValue}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        )}
                        <button className={styles.Save_Btn} type="Submit">
                            Create Form
                        </button>
                    </form>

                    {companyId === '' && (
                        <FailureDialog
                            setErrorDialogOpen={setFailure}
                            errorDialogOpen={failure}
                            dialogText='Please Select Company'
                            handleClick={() => setFailure(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CreateNewForm