import React, { useState, useEffect, useRef } from 'react';
import TopBarWrapper from '../../components/tms-components/TopBarWrapper';
import MOTopBar from '../../components/tms-components/MOTopBar';
import TopBarHeading from '../../components/tms-components/TopBarHeading';
import FormControl from '../../components/tms-components/FormControl';
import Label from '../../components/tms-components/Label';
import TextInput from '../../components/tms-components/TextInput';
import SimpleFormSelect from '../../components/tms-components/SimpleFormSelect';
import CoursePathwayElement from '../../components/tms-components/CoursePathwayElement';
import FlatPrimaryButton from '../../components/tms-components/FlatPrimaryButton';
import FlatClearButton from '../../components/tms-components/FlatClearButton';
// import { Dialog } from '@material-ui/core';
import AddCourseEvent from './AddCourseEvent';
import styled from 'styled-components';
import ClearButton from '../../components/tms-components/ClearButton';
import PlusIcon from '../../components/tms-components/MDLIcon/icons/Plus';
import Loader from '../Loader';
import Button2 from '../../components/tms-components/Button2';

//new
import Dialog from '@mui/material/Dialog';

//utils
import useQuery from '../../utils/useQuery';

//services
import courseServices from '../../services/course';
import { GlobalStates } from '../../App';
import { useContext } from 'react';

let courseElementBGConstants = ['#1a56bd', '#f63b34', '#56dab8', '#ffdd00'];

const FormWrapper = styled.div`
  padding: 30px;
  text-align: left;
`;

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

function EditCourse(props) {
  console.log('::EditCourse:: props', props);
  const query = useQuery();
  const companyId = query.get('c_id');
  // const course_types = [
  //   {
  //     id: 'clgkuscuk000lqn5wngi1jkoz',
  //     display_text: 'Standalone Journey',
  //   },
  //   {
  //     id: 'clgf5d296000hqnlv0ro33trw',
  //     display_text: 'Blended Learning Journey',
  //   },
  //   {
  //     id: 'clgkut06c000nqn5w0svdecrp',
  //     display_text: 'Webinar',
  //   },
  //   {
  //     id: 'clgkutj25000pqn5wacr0ygbv',
  //     display_text: 'Internal',
  //   },
  //   {
  //     id: 'clgkuu57c000rqn5wtck1tv2s',
  //     display_text: 'In-house',
  //   },
  //   {
  //     id: 'clgkuulsn000tqn5wkbi6v072',
  //     display_text: 'External',
  //   }
  // ];

  let newEventModuleOptions = [];

  if (
    props.hasOwnProperty('companyInfo') &&
    props.companyInfo.hasOwnProperty('modules')
  ) {
    for (let microskill of props.companyInfo.modules) {
      newEventModuleOptions.push({
        id: microskill.id,
        display_text: microskill.name,
      });
    }
  }
  let trainingHoursOptions = [];

  for (let i = 0; i <= 24; i++) {
    trainingHoursOptions.push({
      id: i,
      display_text: i.toString()
    });
  }

  const [loading, setLoading] = useState(false);
  const [globalStates] = useContext(GlobalStates);
  const [progressText, setProgressText] = useState('Please wait...');
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState(
    'There was an unknown error, please try again?',
  );

  const [courseId, setCourseId] = React.useState(0);
  const [courseName, setCourseName] = useState('');
  const courseNameRef = useRef(null);

  const [courseNameErrorText, setCourseNameErrorText] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(0);

  const [addCourseEventDialogOpen, setAddCourseEventDialogOpen] = useState(false);
  const [newEventName, setNewEventName] = useState(null);
  const [newEventSelectedModules, setNewEventSelectedModules] = useState([]);
  const [newEventTrainingHours, setNewEventTrainingHours] = useState(8);

  const [editCourseEventDialogOpen, setEditCourseEventDialogOpen] = useState(false);
  const [editCourseEventIndex, setEditCourseEventIndex] = useState(0);
  const [editEventId, setEditEventId] = useState(null);
  const [editEventName, setEditEventName] = useState(null);
  const [editEventSelectedModules, setEditEventSelectedModules] = useState([]);
  const [editEventTrainingHours, setEditEventTrainingHours] = useState(8);

  // Teams, Google Meet links
  const [eventInviteLink, setEventInviteLink] = useState(null);
  const [eventInviteLinkPassword, setEventInviteLinkPassword] = useState(null);

  console.log('editEventTrainingHours = ', editEventTrainingHours);

  const newEventModuleOptionsObject = {};
  const editEventModuleOptionsObject = {};

  const [coursePathwayElements, setCoursePathwayElements] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category_object, setCategoryObject] = useState({});
  const [course_types, setCourseTypes] = useState([
    {
      id: 0,
      display_text: '',
    },
  ]);

  useEffect(() => {
    // fetchCourseCategories(companyId);
    localStorage.removeItem('isSaving');
    fetchCourseTypes();
  }, [])

  // const fetchCourseCategories = async (companyId) => {
  //   const res = await courseServices.fetchCourseCategories(companyId);
  //   console.log('fetchCourseCategories res = ', res);
  //   if (res.status === 200 || res.status === 201) {
  //     let data = res.data.data;
  //     let tempCategoryOptions = [];
  //     let tempCategoryObject = {};
  //     data.forEach(element => {
  //       tempCategoryOptions.push(
  //         {
  //           id: element.id,
  //           display_text: element.name,
  //         },
  //       );
  //       tempCategoryObject[element.id] = { name:element.name };
  //     })
  //     console.log('tempCategoryOptions = ', tempCategoryOptions);
  //     console.log('tempCategoryObject = ', tempCategoryObject);
  //     setCategoryOptions(tempCategoryOptions);
  //     setCategoryObject(tempCategoryObject);
  //   }
  // }

  const fetchCourseTypes = async () => {
    const res = await courseServices.fetchCourseTypes();
    console.log('fetchCourseTypes res = ', res);
    if (res.status === 200 || res.status === 201) {
      let data = res.data.data;
      let tempCourseTypes = [];
      data.forEach(element => {
        tempCourseTypes.push(
          {
            id: element.id,
            display_text: element.name,
          },
        );
      })
      console.log('tempCourseTypes = ', tempCourseTypes);
      setCourseTypes(tempCourseTypes);
    }
  }

  for (let m of newEventModuleOptions) {
    editEventModuleOptionsObject[m.id] = m;
  }

  for (let m of newEventModuleOptions) {
    newEventModuleOptionsObject[m.id] = m;
  }

  let newEventModulesSelectedText = '';

  for (let selected of newEventSelectedModules) {
    if (newEventModuleOptionsObject.hasOwnProperty(selected)) {
      newEventModulesSelectedText +=
        newEventModuleOptionsObject[selected].display_text + ', ';
    }
  }

  let editEventModulesSelectedText = '';

  for (let selected of editEventSelectedModules) {
    if (editEventModuleOptionsObject.hasOwnProperty(selected)) {
      editEventModulesSelectedText +=
        editEventModuleOptionsObject[selected].display_text + ', ';
    }
  }

  function runFormValidation() {
    if (courseName === '' || courseName === null) {
      setCourseNameErrorText('Course Name is required');
      courseNameRef.current.focus();
      return false;
    } else {
      setCourseNameErrorText(null);
    }

    return true;
  }

  React.useEffect(() => {
    let course_id = props.match.params.id;
    console.log('edit course course_id = ', course_id);
    let [course] = props.courses.filter((row) => row.id === course_id);
    console.log('edit course = ', course);
    if (typeof course === 'undefined') return;
    let { courseTypeId, name, description } = course;
    setCourseId(course_id);
    setCourseName(name);
    setSelectedCourse(courseTypeId);
    // if (course_type === 'clgf5d296000hqnlv0ro33trw') setSelectedCourse('blended');
    // if (course_type === 1) setSelectedCourse('standalone');
    // if (course_type === 2) setSelectedCourse('webinar');
    // if (course_type === 3) setSelectedCourse('internal');
    // if (course_type === 4) setSelectedCourse('inhouse');
    // if (course_type === 5) setSelectedCourse('external');
    // setLoading(false);
  }, [props.match.params.id, props.courses]);

  React.useEffect(() => {
    let course_id = props.match.params.id;
    fetchCourseEvents(course_id);

    // let filteredCourseEvents = props.courseEvents.filter(
    //   (el) => el.course_id === courseId,
    // );

    // let mappedEvents = filteredCourseEvents.map((e) => {
    //   let o = {
    //     event_name: e.name,
    //     ...e,
    //   };

    //   if (!Array.isArray(o.modules)) {
    //     o.modules = [];
    //   }
    //   return o;
    // });
    // setCoursePathwayElements(mappedEvents);
  }, [courseId]);


  const fetchCourseEvents = async (courseId) => {
    const res = await courseServices.fetchCourseEvents(courseId);
    console.log('fetchCourseEvents res = ', res);
    if (res.status === 200 || res.status === 201) {
      let activeEvents = res.data.data.filter(ele => ele.active === true);
      activeEvents.sort((a, b) => {
        if (!a.sequence && !b.sequence) return 0
        if (a.sequence > b.sequence) {
          return 1
        } else {
          return -1
        }
      })
      let mappedEvents = activeEvents.map((e) => {
        let o = {
          event_name: e.name,
          training_hours: e.trainingHours,
          ...e,
        };

        // o.modules = [];
        // if (!Array.isArray(o.modules)) {
        // }
        return o;
      });

      // mappedEvents[0].modules = ["clf6czzkw002eqndnrqjafpdp", "clfuudap100mxqnf42ywsnfra"]
      console.log('mappedEvents = ', mappedEvents);
      setCoursePathwayElements(mappedEvents);
      fetchEventMicroskills(mappedEvents);
    }
  }

  const fetchEventMicroskills = async (events) => {
    let eventsWithMicroskills = [];
    for (let i = 0; i < events.length; i++) {
      const eventMsResponse = await courseServices.fetchEventMicroskills(events[i].id, companyId);
      if (eventMsResponse.status === 200 || eventMsResponse.status === 201) {

        let tempMicroskillIds = [];
        eventMsResponse.data.data.forEach(ele => {
          tempMicroskillIds.push(ele.microskillId);
        })
        let tempObj = {
          ...events[i],
          modules: tempMicroskillIds
        }
        eventsWithMicroskills.push(tempObj);
      }
    }
    console.log('eventsWithMicroskills = ', eventsWithMicroskills);
    setCoursePathwayElements(eventsWithMicroskills);
  }

  const handleSave = async () => {
    let isFormValid = runFormValidation();

    if (isFormValid !== true) {
      return;
    }

    // setProgressDialogOpen(true);

    let networkRequestBodyObject = {
      id: courseId,
      name: courseName,
      courseTypeId: selectedCourse,
      courseEvents: coursePathwayElements,
    };
    console.log('handleSave = ', networkRequestBodyObject);
    // return
    const res = await courseServices.updateCourse(networkRequestBodyObject);
    console.log('handleSave res = ', res);
    if (res.status === 200 || res.status === 201) {
      setProgressDialogOpen(false);
      setSuccessDialogOpen(true);
      props.onSave();
    } else if (res.status === 400 && res.data.errors[0].message) {
      setProgressDialogOpen(false);
      setErrorDialogText(res.data.errors[0].message)
      setErrorDialogOpen(true);
    } else {
      setProgressDialogOpen(false);
      setErrorDialogText("Internal Server Error.")
      setErrorDialogOpen(true);
    }

  }

  const addCourseEvent = async () => {
    let isSaving = localStorage.getItem('isSaving');
    console.log('isSaving = ', isSaving);
    if (isSaving) {
      return
    }
    localStorage.setItem('isSaving', true);
    if (newEventName === '' || newEventName === null) return;
    globalStates.handleToast(true, 'Please wait....', 'info');

    let sequence = coursePathwayElements.length + 1;
    if (coursePathwayElements.length) {
      if (coursePathwayElements.at(-1).sequence !== null) {
        sequence = coursePathwayElements.at(-1).sequence + 1
      }
    }
    let addEventPayload = {
      companyId: companyId,
      name: newEventName,
      courseId: courseId,
      trainingHours: newEventTrainingHours,
      sequence: sequence,
      meetingURL: eventInviteLink,
      meetingURLPassword: eventInviteLinkPassword
    }
    console.log('addEventPayload = ', addEventPayload);
    let res = await courseServices.createCourseEvent(addEventPayload);
    console.log('addCourseEvent res = ', res);
    if (res.status === 200 || res.status === 201) {
      let newEventId = res.data.data.id;
      let o = {
        id: newEventId,
        event_name: newEventName,
        modules: newEventSelectedModules,
        training_hours: newEventTrainingHours,
        meetingURL: eventInviteLink,
        meetingURLPassword: eventInviteLinkPassword
      };
      if (newEventSelectedModules.length) {
        let addMsToEventPayload = {
          companyId: companyId,
          microskillIds: newEventSelectedModules,
          courseEventId: newEventId
        }
        console.log('addMsToEventPayload = ', addMsToEventPayload);
        const addMsToEventResponse = await courseServices.addEventMicroskills(addMsToEventPayload);
        console.log(' addMsToEventResponse = ', addMsToEventResponse);
        if (addMsToEventResponse.status === 200 || addMsToEventResponse.status === 201) {
          console.log(o);
          let copy = [...coursePathwayElements];
          copy.push(o);
          setCoursePathwayElements(copy);
          setNewEventName('');
          setNewEventSelectedModules([]);
          setAddCourseEventDialogOpen(false);
          setEventInviteLink(null);
          setEventInviteLinkPassword(null);

        } else {
          globalStates.handleToast(true, 'Failed to add microskills to this event.', 'error');
          localStorage.removeItem('isSaving');
          return
        }

      } else {
        let copy = [...coursePathwayElements];
        copy.push(o);
        setCoursePathwayElements(copy);
        setNewEventName('');
        setNewEventSelectedModules([]);
        setAddCourseEventDialogOpen(false);
      }
      globalStates.handleToast(true, 'Event Added Successfully!', 'success');
      localStorage.removeItem('isSaving');
      return


    } else if (res.status === 400) {
      let message = res.data.errors[0].message;
      globalStates.handleToast(true, message, 'error');
      localStorage.removeItem('isSaving');

    } else {
      globalStates.handleToast(true, 'Internal Server Error', 'error');
      localStorage.removeItem('isSaving');
    }

  }

  const updateCourseEvent = async () => {
    let isSaving = localStorage.getItem('isSaving');
    console.log('isSaving = ', isSaving);
    if (isSaving) return
    localStorage.setItem('isSaving', true);
    if (editEventName === null || editEventName === '') return;
    let sequence = 0;
    for (let i = 0; i < coursePathwayElements.length; i++) {
      if (coursePathwayElements[i].id === editEventId) {
        sequence = i + 1;
        break;
      }
    }
    globalStates.handleToast(true, 'Please wait....', 'info');

    let payload = {
      id: editEventId,
      name: editEventName,
      trainingHours: editEventTrainingHours,
      sequence: sequence,
      meetingURL: eventInviteLink,
      meetingURLPassword: eventInviteLinkPassword
    }
    console.log('updateCourseEvent payload = ', payload);

    let res = await courseServices.updateCourseEvent(payload);
    console.log('updateCourseEvent res = ', res);
    if (res.status === 200 || res.status === 201) {
      let o = {
        id: editEventId,
        event_name: editEventName,
        modules: editEventSelectedModules,
        training_hours: editEventTrainingHours,
        meetingURL: eventInviteLink,
        meetingURLPassword: eventInviteLinkPassword
      };

      console.log(o);

      let copy = [...coursePathwayElements];
      copy[editCourseEventIndex] = o;
      setCoursePathwayElements(copy);
      setEditEventId(null);
      setEditEventName('');
      setEditEventSelectedModules([]);
      setEditCourseEventDialogOpen(false);
      setEventInviteLink(null);
      setEventInviteLinkPassword(null);
      globalStates.handleToast(true, 'Event Updated Successfully!', 'success');
      localStorage.removeItem('isSaving');
      return
    } else if (res.status === 400) {
      let message = res.data.errors[0].message;
      globalStates.handleToast(true, message, 'error');
      localStorage.removeItem('isSaving');
      return

    } else {
      globalStates.handleToast(true, 'Internal Server Error', 'error');
      localStorage.removeItem('isSaving');
      return
    }
  }

  const deleteCourseEvent = async (k) => {
    if (coursePathwayElements.length === 1) {
      globalStates.handleToast(true, 'Minimun 1 event is required', 'error')
      return
    }
    let id = coursePathwayElements[k].id;
    console.log('coursePathwayElements[k] = ', coursePathwayElements[k]);
    let res = await courseServices.deleteCourseEvent({ id })
    console.log('deleteCourseEvent res = ', res);
    if (res.status === 200 || res.status === 201) {
      let copy = [...coursePathwayElements];
      copy.splice(k, 1);
      setCoursePathwayElements(copy);
      globalStates.handleToast(true, 'Event Deleted Successfully!', 'success');
      return
    } else {
      globalStates.handleToast(true, 'Internal Server Error', 'error');
      return
    }

  }

  const editEventMicroskills = async (id) => {
    let copy = [...editEventSelectedModules];
    let payload = {
      companyId: companyId,
      microskillIds: [id],
      courseEventId: editEventId
    }
    console.log('editEventMicroskills payload = ', payload);
    if (copy.indexOf(id) === -1) {
      let addMicroskillResponse = await courseServices.addEventMicroskills(payload);
      console.log('addMicroskillResponse = ', addMicroskillResponse);
      if (addMicroskillResponse.status === 200 || addMicroskillResponse.status === 201) {
        copy.push(id);
        setEditEventSelectedModules(copy);
      }
    } else {
      let removeMicroskillResponse = await courseServices.removeEventMicroskills(payload);
      console.log('removeMicroskillResponse = ', removeMicroskillResponse);
      if (removeMicroskillResponse.status === 200 || removeMicroskillResponse.status === 201) {
        copy = copy.filter((e) => e !== id);
        setEditEventSelectedModules(copy);
      }
    }
  }


  if (loading === true) {
    return (
      <div
        style={{
          display: 'flex',
          left: 0,
          width: '100%',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 3.4rem)',
        }}>
        <Loader fill="#4f0060" />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: '#fff', height: 'calc(100vh - 3.4rem)' }}>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Edit Training Course</TopBarHeading>
        </MOTopBar>
        <div>
          <FormWrapper>
            <FormControl>
              <Label>
                Course Name <span style={{ color: 'red' }}>*</span>
              </Label>
              <TextInput
                ref={courseNameRef}
                value={courseName}
                onChangeText={(text) => {
                  setCourseName(text);
                }}
                errorText={courseNameErrorText}
              />
            </FormControl>

            <FormControl>
              <Label>Course Type</Label>

              <SimpleFormSelect
                options={course_types}
                text={
                  course_types.filter((v) => v.id === selectedCourse)[0]?.display_text
                }
                autoClose
                onSelect={setSelectedCourse}
              />
            </FormControl>

            <FormControl>
              <Label>Course Pathway</Label>
              {coursePathwayElements.map((e, k) => {
                console.log("Modules:", coursePathwayElements[k].modules);
                return (
                  <CoursePathwayElement
                    key={k}
                    disableClone={true}
                    backgroundColor={courseElementBGConstants[k % 4]}
                    text={e.event_name}
                    onEdit={() => {
                      console.log('coursePathwayElements[k].meetingURL = ', coursePathwayElements[k].meetingURL);
                      // console.log('editt clicked ');
                      // return
                      setEditCourseEventIndex(k);
                      setEditEventId(coursePathwayElements[k]?.id || k);
                      setEditEventName(coursePathwayElements[k].event_name);
                      setEditEventTrainingHours(coursePathwayElements[k]?.trainingHours || coursePathwayElements[k].training_hours);
                      setEditEventSelectedModules(coursePathwayElements[k].modules);
                      setEditCourseEventDialogOpen(true);
                      setEventInviteLink(coursePathwayElements[k].meetingURL)
                      setEventInviteLinkPassword(coursePathwayElements[k].meetingURLPassword);
                    }}
                    onCopy={() => {
                      let copy = [...coursePathwayElements];
                      copy.push(coursePathwayElements[k]);
                      setCoursePathwayElements(copy);
                    }}
                    onDelete={() => deleteCourseEvent(k)}
                  />
                );
              })}

              <StyledGenericButton
                onClick={() => {
                  setAddCourseEventDialogOpen(true);
                }}>
                <div style={{ marginRight: 5, display: 'flex' }}>
                  <StyledPlusIcon />
                </div>
                Add Course Event
              </StyledGenericButton>
            </FormControl>

            <FormControl>
              <FlatPrimaryButton
                onClick={handleSave}>
                Save
              </FlatPrimaryButton>
            </FormControl>

          </FormWrapper>
        </div>

        {/* Add Course Event Dialog */}
        <div id="create-course-event-dialog">
          <Dialog
            open={addCourseEventDialogOpen}
            onClose={() => {
              setAddCourseEventDialogOpen(false);
            }}>
            <AddCourseEvent
              eventName={newEventName}
              onChangeEventName={(text) => {
                setNewEventName(text);
              }}
              eventInviteLink={eventInviteLink}
              onChangeEventInviteLink={(text) => {
                setEventInviteLink(text);
              }}
              eventInviteLinkPassword={eventInviteLinkPassword}
              onChangeEventInviteLinkPassword={(text) => {
                setEventInviteLinkPassword(text);
              }}
              moduleOptions={newEventModuleOptions}
              onModuleSelect={(id) => {
                let copy = [...newEventSelectedModules];
                if (copy.indexOf(id) === -1) {
                  copy.push(id);
                  setNewEventSelectedModules(copy);
                } else {
                  copy = copy.filter((e) => e !== id);
                  setNewEventSelectedModules(copy);
                }
              }}
              modulesSelected={newEventSelectedModules}
              selectedModulesText={newEventModulesSelectedText}
              trainingHoursOptions={trainingHoursOptions}
              trainingHoursSelected={newEventTrainingHours}
              onTrainingHoursSelect={(id) => {
                setNewEventTrainingHours(id);
              }}
              trainingHoursText={trainingHoursOptions.filter((i) => i.id == newEventTrainingHours)[0].display_text}
              onAddEvent={addCourseEvent}
              onDelete={() => {
                setAddCourseEventDialogOpen(false);
              }}
            />
          </Dialog>
        </div>

        {/* Edit Course Event Dialog */}
        <div id="edit-course-event-dialog">
          <Dialog
            open={editCourseEventDialogOpen}
            onClose={() => {
              setEditCourseEventDialogOpen(false);
            }}>
            <AddCourseEvent
              eventName={editEventName}
              onChangeEventName={(text) => {
                setEditEventName(text);
              }}
              eventInviteLink={eventInviteLink}
              onChangeEventInviteLink={(text) => {
                setEventInviteLink(text);
              }}
              eventInviteLinkPassword={eventInviteLinkPassword}
              onChangeEventInviteLinkPassword={(text) => {
                setEventInviteLinkPassword(text);
              }}
              moduleOptions={newEventModuleOptions}
              onModuleSelect={editEventMicroskills}
              modulesSelected={editEventSelectedModules}
              selectedModulesText={editEventModulesSelectedText}
              trainingHoursOptions={trainingHoursOptions}
              trainingHoursSelected={editEventTrainingHours}
              onTrainingHoursSelect={(id) => {
                setEditEventTrainingHours(id);
              }}
              trainingHoursText={trainingHoursOptions.filter((i) => i.id == editEventTrainingHours)[0]?.display_text}
              onAddEvent={updateCourseEvent}
              onDelete={() => {
                setEditCourseEventDialogOpen(false);
              }}
            />
          </Dialog>
        </div>

        {/* Progress Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={progressDialogOpen}
          onClose={() => {
            setProgressDialogOpen(false);
          }}>
          <ProgressContainer>
            {loading === true ? <Loader fill="#f4511e" /> : null}

            <ProgressText>{progressText}</ProgressText>
          </ProgressContainer>
        </Dialog>

        {/* Success Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={successDialogOpen}
          onClose={() => {
            setSuccessDialogOpen(false);
          }}>
          <ProgressContainer>
            <img
              src={require('../../assets/tick.png')}
              style={{ width: '50px' }}
            />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>Course Updated Successfully!</ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  props.history.push(`/drive/courses-and-training/course-log?c_id=${companyId}`);
                }}>
                Go to Course Log
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>

        {/* Failure Dialog */}
        <Dialog
          open={errorDialogOpen}
          onClose={() => {
            setErrorDialogOpen(false);
          }}>
          <ProgressContainer>
            <img
              src={require('../../assets/cross.png')}
              style={{ width: '50px' }}
            />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>{errorDialogText}</ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  setErrorDialogOpen(false);
                }}>
                Close
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>
      </TopBarWrapper>
    </div>
  );
}


export default EditCourse;
