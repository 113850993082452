import React, { useState, useEffect, useContext, useRef } from 'react'

//mui
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';

//components
import DefaultCropCard from '../crop-card';
import ImageCrop from '../crop-image/index'
import TagsInput from '../tags-input';
import DialogAlert from '../dialog-alert';
import Title from '../title';
import CreateChallengeCard from '../ms-create-card';
import SelectChallenge from '../select-challenge';
import ChallengeCard from '../challenge-card';
import LoadingContainer from '../loading-container';
import SuccessDialog from '../success-dialog';
import FailureDialog from '../failure-dialog';
import Disclaimer from '../disclaimer';
import GroupDialog from './components/group-dialog';

//services
import categoriesServices from '../../services/categories';
import microskillServices from '../../services/microskill';
import challengesServices from '../../services/challenges';
import groupServices from '../../services/group';

// utils
import useRouter from "../../utils/useRouter";
import isEmpty from '../../utils/isEmpty';
import useQuery from '../../utils/useQuery';
import commonUtil from '../../utils/commonUtil';

//constants
import constants from '../../constants';

//styles
import useStyles from './index.styles'

//context
import { GlobalStates } from '../../App';
import Label from '../custom-styled/label';
import CustomButton from '../custom-styled/button';
import AzureOpenAi from './components/azure-open-ai';

const defaultFilterGroupData = [
    {
        id: "language",
        name: 'Language Groups',
        subMenu: [],
        selected: [],
        total: 0,
        singleSelect: true,
    },
    {
        id: "other",
        name: 'Other Groups',
        subMenu: [],
        selected: [],
        total: 0,
        singleSelect: false,
    }
]

const CreateMicroskill = () => {
    let roles = localStorage.getItem('roles') || [];
    const showLanguageOption = true || roles.includes("MULTILINGUAL");
    const classes = useStyles();
    const router = useRouter();
    let query = useQuery();
    const isEdit = query.get('edit');
    const companyId = query.get("c_id");
    const projectId = query.get("p_id")
    const microskillId = query.get("ms_id");
    const [globalStates] = useContext(GlobalStates)
    const [loading, setLoading] = useState(false);
    const [challengesLength, setChallengesLength] = useState(0)
    const [finalImage, setFinalImage] = useState("/images/add-microskill/default_img_large.png")
    const [src, setSrc] = useState("/images/add-microskill/default_img_large.png")
    const [croppedImageURL, setCroppedImageURL] = useState("/images/add-microskill/default_img_large_cropped.png")
    const [brandIcon, setBrandIcon] = useState('images/add-microskill/Master-O Logo.png')
    const [microskillTitle, setMicroskillTitle] = useState("Microskill Name")
    const [logoFilePath, setLogoFilepath] = useState('No file chosen')
    const [coverFilePath, setCoverFilepath] = useState('No file chosen')
    const [likes, setLikes] = useState(0)
    const [isCertificate, setIsCertificate] = useState(true)
    const [views, setViews] = useState(0)
    const [toggleEditBtn, setToggleEditBtn] = useState(true)
    const cropDetails = { unit: '%', width: 80, height: 100, aspect: 9 / 6, x: 10, y: 0 }
    const [crop, setCrop] = useState(cropDetails)
    const [categories, setCategories] = useState([]);
    const categoriesObject = commonUtil.objectify(categories, "id");
    // console.log('categoriesObject = ', categoriesObject);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [isSelectChallengeOpen, setIsSelectChallengeOpen] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const [challenges, setChallenges] = useState([null, null, null, null]);
    const [imageFile, setImageFile] = useState(null);
    const [brandImageFile, setBrandImageFile] = useState(null);
    const [microskillChallenges, setMicroskillChallenges] = useState(false) // TOGGLE ADD CHALLENGE
    const [sequence, setSequence] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [showSequenceCta, setShowSequenceCta] = useState(false);
    const [isSavingSequence, setIsSavingSequence] = useState(false);
    const [sequenceChangedFor, setSequenceChangedFor] = useState([]);
    const [selectedChallengeForCopy, setSelectedChallengeForCopy] = useState(null);
    const [isCopyingChallenge, setIsCopyingChallenge] = useState(false);
    const [microskills, setMicroskills] = useState([]);
    const [selectedMsForCopy, setSelectedMsForCopy] = useState([]);
    const [showAnimation, setShowAnimation] = useState(null);
    const [microskillTags, setMicroskillTags] = useState([]);
    const [showFeedback, setShowFeedback] = useState(true);
    const [language, setLanguage] = useState(null);
    const [challengeMap, setChallengeMap] = useState(new Map())
    const languagesObject = commonUtil.objectify(constants.LANGUAGES, "id")
    const [defaultOption, setDefaultOption] = useState({ id: null, name: 'defaultOption', type: 'default' });
    const _filterOption = createFilterOptions();
    // console.log('_filterOption = ', _filterOption);
    const [selectedCategory, setSelectedCategory] = useState({ id: null, name: '', type: 'default' });
    // console.log('selectedCategory = ', selectedCategory);
    const timer = useRef();
    const [allGroups, setAllGroups] = useState([]);
    const groupsObject = commonUtil.objectify(allGroups, "id")
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [groupMicroskills, setGroupMicroskills] = useState([]);
    const [loadingGroups, setLoadingGroups] = useState(true);
    const [manageGroupDialogOpen, setManageGroupDialogOpen] = useState(false);
    const [msGroupsData, setMsGroupsData] = useState([]);
    // const [isSavingGroups, setIsSavingGroups] = useState(false);
    const [microskillName, setMicroskillName] = useState('');
    const [selectGroupFromDialog, setSelectGroupFromDialog] = useState([]);
    const [filterGroupData, setFilterGroupData] = useState(defaultFilterGroupData);
    const [chips, setChips] = useState([]);
    const [isDefaultDataSet, setIsDefaultDataSet] = useState(true);

    let isChallengeCreated = localStorage.getItem('isChallengeCreated');
    let isChallengeUpdated = localStorage.getItem('isChallengeUpdated');

    useEffect(() => {
        if (isEdit) {
            fetchMicroskill(companyId, microskillId, { withLoader: true });
            fetchAllMicroskills(companyId);

        } else {
            const selectedCategory = JSON.parse(localStorage.getItem('category-id'));
            if (selectedCategory) setSelectedCategory(selectedCategory);
            setLoading(false);
        }
        fetchCategories(companyId);
        fetchCompanyGroups(companyId);
    }, [])

    const handleFourChallengeToast = () => {
        const filtered = []
        challenges.forEach(ele => {
            if (ele && ele.active && ele.status === "COMPLETED") filtered.push(ele)
        })

        if (filtered.length === 4) globalStates.handleToast(true, 'Congratulations! You have successfully created all 4 challenges!', 'success')

    }



    useEffect(() => {
        if (!loading) {
            if (isChallengeCreated) {
                setDialogContent("Challenge created successfully!");
                setSuccessDialogOpen(true);
                setMicroskillChallenges(true);
                return
            }
            if (isChallengeUpdated) {
                setDialogContent("Challenge updated successfully!");
                setSuccessDialogOpen(true);
                setMicroskillChallenges(true);
                localStorage.removeItem('isChallengeUpdated');
                return
            }
        }
    }, [loading])

    useEffect(() => {
        fetchCategories(companyId);
    }, [])


    useEffect(() => {
        if (isEdit && allGroups.length) fetchMicroskillGroups(microskillId);
    }, [allGroups])


    useEffect(() => {
        if (allGroups.length) {
            updateToDefaultFilterGroupData();
        }
    }, [allGroups])

    useEffect(() => {
        if (msGroupsData.length && isDefaultDataSet) {
            updateToDefaultFilterGroupData();
            setIsDefaultDataSet(false);
        }
    }, [msGroupsData]);

    useEffect(() => {
        if (filterGroupData.length > 0) {
            updateChips(filterGroupData);
        }
    }, [filterGroupData]);

    useEffect(() => {
        if (isEdit && microskillId) {
            fetchGroupMircoSkills(microskillId);
        }
    }, [isEdit, microskillId])

    const fetchGroupMircoSkills = async (ms_id) => {
        const response = await microskillServices.fetchMicroskillGroups(ms_id);
        if (response.status === 200) {
            let msGroups = [];
            response.data.data.forEach(ele => {
                msGroups.push(ele.Group);
            })
            setChips(msGroups);
            setMsGroupsData(msGroups);
        }
    }

    const updateChips = (filterGroupData) => {
        const allGroupsObject = commonUtil.objectify(allGroups, "id");
        let newChips = [];
        filterGroupData[0].selected.forEach(id => newChips.push({ id: id, name: allGroupsObject[id]?.name, microskillId }));//language
        filterGroupData[1].selected.forEach(id => newChips.push({ id: id, name: allGroupsObject[id]?.name, microskillId }));//other
        setChips(newChips);
    }

    const updateToDefaultFilterGroupData = () => {
        let languageGroups = [];
        let selectedLanguageGroups = [];
        let otherGroups = [];
        let selectedOtherGroups = [];
        allGroups.forEach(element => {
            if (element.type === 'LANGUAGE') languageGroups.push(element);
            else otherGroups.push(element);
        });
        msGroupsData.forEach(element => {
            if (element?.type === 'LANGUAGE') selectedLanguageGroups.push(element?.id);
            else selectedOtherGroups.push(element?.id);
        });
        let tempData = [
            {
                id: "language",
                name: 'Language Groups',
                subMenu: languageGroups,
                selected: selectedLanguageGroups,
                total: languageGroups.length,
                singleSelect: true,
            },
            {
                id: "other",
                name: 'Other Groups',
                subMenu: otherGroups,
                selected: selectedOtherGroups,
                total: otherGroups.length,
                singleSelect: false,
            }
        ];
        setFilterGroupData(tempData)
    }

    const fetchMicroskillGroups = async (microskill_Id) => {
        const response = await microskillServices.fetchMicroskillGroups(microskillId ? microskillId : microskill_Id);
        if (response.status === 200) {
            let msGroups = [];
            response.data.data.forEach(ele => {
                msGroups.push(groupsObject[ele?.Group.id]);
            })
            setSelectedGroups(msGroups);
            setGroupMicroskills(msGroups)
        }
    }

    const fetchCompanyGroups = async (companyId, options = { startIndex: 0, limit: 1000 }) => {
        const response = await groupServices.fetchGroups(companyId, options);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            const activeGroups = data.filter(ele => ele.active === true);
            activeGroups.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                else return 0;
            })
            setAllGroups(activeGroups);
            setLoadingGroups(false);
        }
    }

    const fetchAllMicroskills = async (companyId) => {
        const response = await microskillServices.fetchMicroskills(companyId);
        // console.log('fetchAllMicroskills = ', response);
        if (response.status === 200 || response.status === 201) {
            let activeMicroskills = response.data.data.filter(ele => ele.active === true && ele.Challenges?.length < 4);
            // console.log('fetchAllMicroskills activeMicroskills = ', activeMicroskills);
            setMicroskills(activeMicroskills);
        }
    }

    const fetchMicroskill = async (companyId, microskillId, { withLoader = true }) => {
        if (withLoader) setLoading(true);
        const res = await microskillServices.fetchMicroskills(companyId, microskillId);
        // console.log('while edit fetchMicroskills = ', res);
        if (res.status === 200 || res.status === 201) {
            let activeMicroskills = res.data.data.filter(element => element.active === true);
            // console.log('fetchMicroskills activeMicroskills = ', activeMicroskills);
            let myMicroskill = activeMicroskills.filter(element => element.id === microskillId)
            const coverImg = myMicroskill[0]?.coverImage || constants.MS_DEFAULT_COVER_IMAGE;
            const brandImg = myMicroskill[0]?.brandImage || constants.MS_DEFAULT_BRAND_ICON;
            setMicroskillTitle(myMicroskill[0].name);
            setMicroskillName(myMicroskill[0].name);
            setSrc(coverImg);
            setFinalImage(coverImg);
            setBrandIcon(brandImg);
            let logoUrl = myMicroskill[0].brandImage;
            if (!isEmpty(logoUrl)) {
                let logoFilename = commonUtil.remoteUrlToFilename(logoUrl);
                setLogoFilepath(logoFilename);
            }
            let coverUrl = myMicroskill[0]?.coverImage;
            if (!isEmpty(coverUrl)) {
                let coverFilename = commonUtil.remoteUrlToFilename(coverUrl);
                setCoverFilepath(coverFilename);
            }
            setMicroskillChallenges(true);
            setLikes(myMicroskill[0].likes);
            setViews(myMicroskill[0].views);
            setIsCertificate(myMicroskill[0].certificate);
            setShowFeedback(myMicroskill[0].showFeedback);
            if (!myMicroskill[0].categoryId) {
                //here categoryId is null
                setSelectedCategory({ id: null, name: '', type: 'default' });
            } else {
                setSelectedCategory({ id: myMicroskill[0].categoryId, name: '', type: 'default' });
            }
            if (!isEmpty(myMicroskill[0].language) && myMicroskill[0].language !== "Default") {
                let myLanguage = constants.LANGUAGES.find(ele => ele.value === myMicroskill[0].language);
                setLanguage(myLanguage);
            }
            fetchChallenges(microskillId);
            return
        }
        if (res.status === 400 || res.status === 401) {
            return
        }
    }


    const fetchChallenges = async (microskillId, updateSequence = false) => {
        const res = await challengesServices.fetchChallenges(microskillId);
        if (res.status === 200) {
            setChallengesLength(res.data.data.length)//USED TO CREATE CHALLENGE NAME
            let activeChallenges = res.data.data.filter(element => element.active === true);
            let blankChallengeData = new Array(4).fill(null);

            const data = res.data.data;
            const newMap = new Map(challengeMap)  // Used to edit form challenge
            data.forEach((element) => {
                if (element.formId !== null) {
                    newMap.set(element.id, element.formId)
                }
            })
            setChallengeMap(newMap);


            if (!activeChallenges.length) {
                //HERE CURRENT MICROSKILL HAS NO CHALLENGE PRESENT IN IT.
                setChallenges(blankChallengeData)
                setLoading(false);
                return
            } else {
                activeChallenges.sort((a, b) => {
                    if (a.sequence < b.sequence) {
                        return -1
                    } else if (a.sequence > b.sequence) return 1
                });
                activeChallenges.forEach((element, i) => {
                    // console.log('challengeNumber = ', i+1);
                    if (i < 4) {
                        let elementCopy = { ...element }
                        constants.CHALLENGE_DATA.forEach(ele => {
                            if (element.type === ele.challengeName) {
                                elementCopy.imgUrl = ele.imgUrl;
                                // elementCopy.challengeNumber = i+1;
                                blankChallengeData[elementCopy.sequence - 1] = elementCopy;
                            }
                        })
                    }

                });
                setChallenges(blankChallengeData);
                if (updateSequence) _updateFinalSequence(blankChallengeData);
                setLoading(false);

            }

        }
    }

    const fetchCategories = async (companyId) => {
        // console.log('fetchCategories companyId = ', companyId);
        const res = await categoriesServices.fetchCategories(companyId)
        // console.log('fetchCategories res = ', res);
        if (res.status === 200 || res.status === 201) {
            let activeCategories = res.data.data.categories.filter(element => element.active === true);
            activeCategories.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            })
            setCategories(activeCategories);
            // console.log('activeCategories = ', activeCategories);
        }
    }

    const createCategories = async (element) => {
        const companyId = query.get('c_id');
        const res = await categoriesServices.createCategories(companyId, element.name);
        // console.log('createCategories = ', res);
        if (res.status === 200 || res.status === 201) {
            fetchCategories(companyId);
            setSelectedCategory(res.data.data);
        }
    }

    useEffect(() => {
        if (selectedCategory.type === "new") {
            createCategories(selectedCategory);
        }
    }, [selectedCategory])

    const handleCategoryChange = (e, values) => {
        // console.log('handleCategoryChange = ', values);
        localStorage.removeItem('category-id');
        if (!values) {
            setSelectedCategory({ id: null, name: '', type: 'default' });
            return;
        }
        setSelectedCategory(values);

    }

    const handleLanguageChange = (e, values) => {
        if (!values) {
            setLanguage(null);
            return;
        }
        setLanguage(values);

    }

    const handleGroupChange = (e, values) => {
        if (!values?.length) {
            setSelectedGroups([]);
            return;
        }
        setSelectedGroups(values);

    }

    const handleFilter = (option, value) => {
        let results = _filterOption(option, value);
        if (results.length == 0) {
            results.push(defaultOption)
        }
        // console.log('handleFilter result = ', results);
        return results;
    }

    const handleSetTitle = (e) => {
        setMicroskillTitle(e.target.value);
        if (e.target.value === "") {
            setMicroskillTitle('Microskill Name')
        }
    }

    const fileTobase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = (err) => {
                reject(err);
            }

        })
    }

    //FOR READING COVER IMAGE ONLY  
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrc(reader.result)
                setFinalImage(reader.result)
            }

            );
            reader.readAsDataURL(e.target.files[0]);
        }
        setCoverFilepath(e.target.files[0].name)
    };

    const handleChangeFile = async (e) => {
        // console.log(e.target.name);
        const base64Path = await fileTobase64(e.target.files[0])
        if (e.target.name === "coverPhoto") {
            // console.log('coverPhoto = ', e.target.files[0]);
            setImageFile(e.target.files[0]);
            onSelectFile(e);
            setCoverFilepath(e.target.files[0].name);
            return
        }
        if (e.target.name === "brandingIcon") {
            // console.log('brandingIcon = ', e.target.files[0]);
            setBrandIcon(base64Path);
            setBrandImageFile(e.target.files[0]);//SETTING RAW IMAGE OBJECT FOR MULTIPART FORM DATA
            setLogoFilepath(e.target.files[0].name);
            return
        }

    }

    const handleDoneCancel = (e) => {
        if (e.target.name === "Done") {
            setFinalImage(croppedImageURL)
            setToggleEditBtn(true)
            return
        }
        if (e.target.name === "Cancel") {
            setToggleEditBtn(true)
            return
        }
    }

    const challengeNames = {
        LA: 'Learning Aid',
        GBA: 'Game Based Assessment',
        SL: 'Spotlight',
        SCORM: 'Scorm',
        QR_CODE: 'QR code for feedback',
        FORM: 'FORM'
    }

    const getChallengeName = (value) => challengeNames[value];

    const createChallenge = async (element) => {
        setLoading(true)
        const newChallengeName = `${getChallengeName(element.challengeName)} - ${challengesLength + 1}`

        if (element.challengeName === "LA") {
            //GENERATING RANDOM CHALLENGE NAME FOR LA 
            let payload = {
                companyId: companyId,
                microskillId: microskillId,
                name: newChallengeName,
                type: element.challengeName,
                gameType: "ILA",
                designType: "LA_ILA",
                sequence: sequence,
                maxScore: 0
            }
            // console.log('LA create chall payload = ', payload);
            let res = await challengesServices.createChallenge(payload);
            // console.log('create LA challenge = ', res);
            if (res.status === 200 || res.status === 201) {
                let newChallengeId = res.data.data.id;
                setIsSelectChallengeOpen(false);
                setLoading(false);
                router.history.push(`${element.url}?microskillName=${microskillTitle}&&challengeName=${newChallengeName}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${newChallengeId}`)//
                return
            }
            return
        } else if (element.challengeName === "GBA") {
            //FOR GBA WE WILL BE CREATING CHALLENGE AFTER SELECTING GAME AND DESIGN TYPE IN GBA PAGE.
            setLoading(false);
            if (!microskillTags.length) {
                globalStates.handleToast(true, "Please add skill tags to this Microskill before continuing.", "error");
                return
            }
            router.history.push(`${element.url}?microskillName=${microskillTitle}&&challengeName=${newChallengeName}&&seq=${sequence}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`)
        }


        else if (element.challengeName === "SCORM") {
            setIsSelectChallengeOpen(() => false);
            setLoading(() => false);
            router.history.push(`${element.url}?microskillName=${microskillTitle}&&challengeName=${newChallengeName}&&c_id=${companyId}&&ms_id=${microskillId}&&seq=${sequence}&&challenge_id=${"xx"}`);
            return
        }

        else if (element.challengeName === "FORM") {
            setIsSelectChallengeOpen(() => false);
            setLoading(() => false);
            router.history.push(`${element.url}?microskillName=${microskillTitle}&&c_id=${companyId}&&ms_id=${microskillId}&&seq=${sequence}`);
            return
        }



        else if (element.challengeName === "QR_CODE") {
            setIsSelectChallengeOpen(false);
            setLoading(false);
            router.history.push(`${element.url}?microskillName=${microskillTitle}&&challengeName=${newChallengeName}&&c_id=${companyId}&&ms_id=${microskillId}&&seq=${sequence}&&challenge_id=${"xx"}`);
            return
        } else if (element.challengeName === 'SL') {
            //payload might change 
            let payload = {
                companyId: companyId,
                microskillId: microskillId,
                name: newChallengeName,
                type: "SL",
                gameType: "MAN",
                designType: "SL_MAN",
                sequence: sequence,
                maxScore: 120
            }
            // console.log('SL create chall payload = ', payload);
            let res = await challengesServices.createChallenge(payload)
            console.log('create SL challenge response = ', res);
            if (res.status === 200 || res.status === 201) {
                let newChallengeId = res.data.data.id;
                setIsSelectChallengeOpen(false);
                setLoading(false);
                router.history.push(`${element.url}?microskillName=${microskillTitle}&&challengeName=${newChallengeName}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${newChallengeId}`);
                return
            }
            return
        }

    }

    const handleSelectChallenge = (key, element) => {
        // console.log('SelectChallenge key = ', key);
        if (typeof key === 'number') {//IF TRUE -> CHALLENGE IS SELECTED, ELSE -> MODAL is closed without selecting any challenge.
            //CHALLENGE IS SELECTED
            console.log(element);
            //here first we have to show loader
            createChallenge(element);
        }
        setIsSelectChallengeOpen(false);
        return
    }

    const handleSaveMs = async () => {
        if (isEmpty(microskillTitle) || microskillTitle === "Microskill Name") {
            globalStates.handleToast(true, "Please enter the microskill name", "error");
            return
        }
        if (isEmpty(chips)) {
            globalStates.handleToast(true, "Please select the group", "error");
            return
        }
        globalStates.handleProgressDialog(true, "Please wait...");
        let json = {
            companyId: companyId,
            name: microskillTitle,
            type: 'Major',
            brandImage: brandImageFile || constants.MS_DEFAULT_BRAND_ICON,
            OrgCoverImage: imageFile || constants.MS_DEFAULT_COVER_IMAGE,
            coverImage: imageFile || constants.MS_DEFAULT_COVER_IMAGE,
            categoryId: selectedCategory.id,
            certificate: isCertificate ? 1 : 0,
            showFeedback: showFeedback ? 1 : 0,
            language: "Default",
            groupIds: []
        }
        if (!isEmpty(language)) json.language = language.value;
        if (selectedCategory.id === "null") json.categoryId = null;
        if (selectedGroups.length) json.groupIds = selectedGroups.map(ele => ({ id: ele.id, name: ele.name, active: true }));
        if (isEdit) {
            json.id = microskillId;
            delete json.type
            if (!json.brandImage) delete json.brandImage;
            if (!json.OrgCoverImage) delete json.OrgCoverImage;
            if (!json.coverImage) delete json.coverImage;
            if (groupMicroskills.length) {
                let selectedGroupIds = selectedGroups.map(ele => ele.id);
                let unLinkedGroups = [];
                groupMicroskills.forEach(ele => {
                    if (!selectedGroupIds.includes(ele.id)) unLinkedGroups.push({ id: ele.id, name: ele.name, active: false });
                })
                json = { ...json, groupIds: JSON.stringify([...json.groupIds, ...unLinkedGroups]) }
            }
            // console.log('update ms json = ', json);
            let payload = await commonUtil.jsonToFormData(json);
            let res = await microskillServices.updateMicroskill(payload);
            globalStates.handleProgressDialog(false);
            if (res.status === 200 || res.status === 201) {
                setDialogContent("Microskill updated successfully!");
                setSuccessDialogOpen(true);
                setMicroskillChallenges(true);
                await _updateFinalSequence(challenges);
                await handleSaveGroupAfterMicroskill(microskillId, true);
                globalStates.handleProgressDialog(false);
                return
            } else if (res.status === 500) {
                globalStates.handleToast(true, 'Internal server error', 'error');
            } else {
                globalStates.handleToast(true, 'Something went wrong, please try again!', 'error');
            }
            return;
        }
        if (json.groupIds.length) json = { ...json, groupIds: JSON.stringify([...json.groupIds]) };

        let payload = await commonUtil.jsonToFormData(json);
        let resData = await microskillServices.createMicroskill(payload);
        globalStates.handleProgressDialog(false);
        if (!resData) {
            setDialogContent("Something went wrong, please try again!");
            setErrorDialogOpen(true);
            return
        }
        if (resData.status === 201 || resData.status === 200) {
            setDialogContent("Microskill created successfully!");
            setSuccessDialogOpen(true);
            setMicroskillChallenges(true);
            await handleSaveGroupAfterMicroskill(resData.data.data.id, false)
            router.history.replace(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${resData.data.data.id}`);
            return
        }
        if (resData.status === 400) {
            if (resData?.data?.errors) {
                setDialogContent(resData.data.errors[0].message);
                setErrorDialogOpen(true);
            }

        }
        if (resData.status === 500) {
            console.log('resData?.data?.errors = ', resData?.data?.errors);
            let message = 'Internal server error'
            if (resData?.data?.errors[0]?.message === "File too large") message = "File too large";
            setDialogContent(message);
            setErrorDialogOpen(true);
        }

    }

    const handleClearChanges = () => {
        router.history.push(`/manage-microskills/${companyId}/${projectId}`);
    }

    const checkBlankChallengeSequence = (idx) => {
        let sequence = idx;
        for (let i = 0; i < challenges.length; i++) {
            if (!challenges[i]) {
                sequence = i;
                break
            }
        }
        return sequence + 1
    }

    const handleCreateChallenge = (index) => {
        if (showSequenceCta) {
            globalStates.handleToast(true, 'Please save the sequence first', 'error')
            return
        }
        const blankIdx = checkBlankChallengeSequence(index);
        setSequence(blankIdx);
        setIsSelectChallengeOpen(true);
    }

    const handleEditChallenge = (element) => {
        const challengeId = element.id;
        const name = element.name;
        if (element.type === 'LA') {
            router.history.push(`/microskill-studio/learning-aid?edit=true&&microskillName=${microskillTitle}&&challengeName=${name}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${challengeId}`);
        }
        if (element.type === 'GBA') {
            if (element?.setting) localStorage.setItem('gba-setting', JSON.stringify(element.setting));
            router.history.push(`/microskill-studio/game-based-assessment?edit=true&&microskillName=${microskillTitle}&&challengeName=${name}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${challengeId}`);
        }
        if (element.type === 'SL') {
            router.history.push(`/microskill-studio/spotlight?edit=true&&microskillName=${microskillTitle}&&challengeName=${name}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${challengeId}`);
        }
        if (element.type === 'SCORM') {
            router.history.push(`/microskill-studio/scorm?edit=true&&microskillName=${microskillTitle}&&challengeName=${name}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${challengeId}`);
        }
        if (element.type === 'FORM') {
            [...challengeMap].map(([id, formId]) => {
                if (id === challengeId)
                    router.history.push(`microskill-studio/form-based-challenge?edit=true&microskillName=${microskillTitle}&&challengeName=${name}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${challengeId}&&formId=${formId}`);
            })
        }
    }

    const handleDeleteChallenge = async (e, index) => {
        let id = challenges[index].id
        let confirm = window.confirm("Do you really want to delete this challenge?");
        if (confirm) {
            globalStates.handleProgressDialog(true, "Please wait...");
            const res = await challengesServices.deleteChallenge({ id });
            globalStates.handleProgressDialog(false);
            if (res.status === 200 || res.status === 201) {
                setDialogContent(`${challenges[index].name} deleted successfully!`)
                setSuccessDialogOpen(true)
                const updateSequence = true;
                await fetchChallenges(microskillId, updateSequence);
            } else {
                globalStates.handleToast(true, 'Internal server error', 'error');
            }
        }
        return
    }

    const handleChangeSequence = (element, moveTowards, index) => {
        clearTimeout(timer.current)
        setShowSequenceCta(true);
        let tempChallenges = [...challenges];
        let updateSequenceFor = []
        if (moveTowards === 'left') {
            let currentChallenge = { ...element, sequence: element.sequence - 1 };
            updateSequenceFor.push(currentChallenge.id);
            let challengeOnLeft = null;
            if (tempChallenges[index - 1] !== null) {
                challengeOnLeft = { ...tempChallenges[index - 1], sequence: element.sequence };
                updateSequenceFor.push(challengeOnLeft.id);
                tempChallenges[index] = challengeOnLeft;
            } else {
                tempChallenges[index] = null;
            }
            tempChallenges[index - 1] = currentChallenge;
            setChallenges(tempChallenges);
        } else {
            if (tempChallenges[index + 1] === null) {
                globalStates.handleToast(true, 'Not allowed', 'error')
                setShowSequenceCta(false);
                return
            }
            let currentChallenge = { ...element, sequence: element.sequence + 1 };
            updateSequenceFor.push(currentChallenge.id);
            let challengeOnRight = null;
            if (tempChallenges[index + 1] !== null) {
                challengeOnRight = { ...tempChallenges[index + 1], sequence: element.sequence };
                updateSequenceFor.push(challengeOnRight.id);
                tempChallenges[index] = challengeOnRight;
            } else {
                // challengeOnRight = { ...tempChallenges[index + 1], sequence: element.sequence };
                tempChallenges[index] = null;
            }
            tempChallenges[index + 1] = currentChallenge;
            setChallenges(tempChallenges);
        }
        setSequenceChangedFor(updateSequenceFor);
        timer.current = setTimeout(() => {
            setSequenceChangedFor([]);
        }, 3000)
    }

    const _updateFinalSequence = async (challenges) => {
        const filtered = challenges.filter(ele => ele !== null);
        for (let i = 0; i < filtered.length; i++) {
            let payload = {
                companyId: companyId,
                microskillId: microskillId,
                challengeId: filtered[i].id,
                sequence: i + 1
            }
            const res = await challengesServices.updateChallenge(payload);
            if (res.status === 200) {
                if (i === filtered.length - 1) {
                    await fetchMicroskill(companyId, microskillId, { withLoader: false });
                    setIsSavingSequence(false);
                    setShowSequenceCta(false);
                    return res
                }
            }
        }
    }

    const handleUpdateSequence = async () => {
        setIsSavingSequence(true);
        const response = await _updateFinalSequence(challenges);
        if (response?.status === 200) {
            globalStates.handleToast(true, 'Sequence updated successfully!', 'success');
            setIsSavingSequence(false);
            setShowSequenceCta(false);
        } else {
            globalStates.handleToast(true, 'Failed to update sequence, please try again!', 'error');
        }
    }

    const _undoSequenceChanges = () => {
        fetchChallenges(microskillId);
        setShowSequenceCta(false);
    }

    const _handleCopyClick = async (item) => {
        setSelectedChallengeForCopy(item.id);
    }

    const handleCopyChallenge = async () => {
        if (!selectedMsForCopy.length) {
            globalStates.handleToast(true, 'Please select the microskill where you want to copy this challenge', 'warning');
            return
        }
        setIsCopyingChallenge(true);
        for (let i = 0; i < selectedMsForCopy.length; i++) {
            let payload = {
                newMicroskillId: selectedMsForCopy[i],
                oldChallengeId: selectedChallengeForCopy,
                newCompanyId: companyId,
                flag: "0"
            }
            let response = await challengesServices.copyChallenge(payload);
            if (response.status === 200 || response.status === 201) {
                if (selectedMsForCopy[i] === microskillId) {
                    fetchChallenges(microskillId);
                }
                if (i === selectedMsForCopy.length - 1) {
                    setShowAnimation(selectedChallengeForCopy);
                    setTimeout(() => {
                        setShowAnimation(null);
                        setSelectedChallengeForCopy(null);
                        setSelectedMsForCopy([]);
                        setIsCopyingChallenge(false);
                    }, 5000)
                }
            } else {
                setIsCopyingChallenge(false);
                globalStates.handleToast(true, 'Internal server error', 'error');
                break;
            }
        }
        return
    }

    const handleCancelCopyChallenge = () => {
        setIsCopyingChallenge(false);
        setSelectedChallengeForCopy(null);
        setSelectedMsForCopy([]);
    }

    const getMSgroupDataIds = () => {
        const groupIds = [];
        msGroupsData.forEach(ele => {
            groupIds.push(ele.id)
        })
        return groupIds
    }

    const getGroupId = () => {
        const groupIds = []
        const msGroupIDs = getMSgroupDataIds();
        chips.forEach(ele => {
            if (!msGroupIDs.includes(ele.id)) {
                groupIds.push(ele.id)
            }
        }
        )
        return groupIds
    }

    const handleSaveGroupAfterMicroskill = async (msId, isEdit) => {
        const groupIds = getGroupId();
        let payload = {
            companyId: companyId,
            microskillId: msId,
            groupIds: groupIds,
        }
        if (groupIds.length === 0) return
        let response = await microskillServices.addMsToGroups(payload);
        isEdit && fetchGroupMircoSkills(msId)
    }

    const handleRemoveMsFromGroup = async (ele) => {
        let exists = msGroupsData.find(item => item.id === ele.id)
        setFilterGroupData((prev) => {
            const updatedData = prev.map((group) => {
                if (group.selected.includes(ele.id)) {
                    return {
                        ...group,
                        selected: group.selected.filter((id) => id !== ele.id),
                    };
                }
                return group;
            });
            return updatedData;
        });
        if (!exists) {
            // let filtered = msGroupsData.filter(element => element.id !== ele.id)
            // setMsGroupsData(filtered);
            globalStates.handleToast(true, `${ele.name} successfully removed `, 'success')
            return
        }

        let payload = {
            companyId: companyId,
            microskillId: microskillId,
            groupId: ele.id
        }
        const res = await microskillServices.removeMsFromGroup(payload);
        if (res.status === 200 || res.status === 201) {
            let filtered = msGroupsData.filter(element => element.id !== ele.id)
            setMsGroupsData(filtered);
            let filteredGroups = selectedGroups.filter(element => element.id !== ele.id);
            setSelectedGroups(filteredGroups);
            globalStates.handleToast(true, `${ele.name} successfully removed`, 'success')
        }
    }

    const handleAddGroup = () => {
        router.history.push(`/groups?c_id=${companyId}`)
    }

    if (loading) {
        return <LoadingContainer />
    } else {
        return (
            <>
                <div className={classes.MainContainer}>
                    <div className={classes.Row1}>
                        <div className={classes.Row_LeftDiv}>
                            <h1 className={classes.Title}>Microskill details:</h1>
                            <form>
                                <div className={classes.LabelInputBox}>
                                    <label htmlFor='microskillName' className={classes.Label}>
                                        Name
                                    </label>
                                    <input
                                        className={classes.Input_Box}
                                        id="microskillName"
                                        name="microskillName"
                                        type="text"
                                        placeholder="Microskill Name"
                                        value={microskillTitle === "Microskill Name" ? "" : microskillTitle}
                                        onChange={handleSetTitle}
                                    />
                                </div>
                                <div className={classes.LabelInputBox}>
                                    <label htmlFor='brandingIcon' className={classes.Label}>
                                        Branding Icon
                                    </label>
                                    <div className={classes.Custom_File_Input}>
                                        <h1 className={classes.FilePath} >{logoFilePath}</h1>
                                        <label className={classes.Btn}><img className={classes.Folder_Icon} src="/images/add-microskill/folder.svg" alt='' />  BROWSE
                                            <input
                                                style={{ display: "none" }}
                                                id="brandingIcon"
                                                name="brandingIcon"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleChangeFile}
                                            />
                                        </label>
                                    </div>
                                </div>


                                <div className={classes.LabelInputBox}>
                                    <label htmlFor='coverPhoto' className={classes.Label}>
                                        Cover Photo
                                    </label>
                                    <div className={classes.Custom_File_Input}>
                                        <h1 className={classes.FilePath} >{coverFilePath}</h1>
                                        <label className={classes.Btn}><img className={classes.Folder_Icon} src="/images/add-microskill/folder.svg" alt='' />  BROWSE
                                            <input
                                                style={{ display: "none" }}
                                                id="coverPhoto"
                                                name="coverPhoto"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleChangeFile}
                                            />
                                        </label>
                                    </div>
                                </div>
                                <div className={classes.LabelInputBox}>
                                    <label htmlFor='categories' className={classes.Label}>
                                        Categories
                                    </label>
                                    <Autocomplete
                                        style={{ width: "50%", minHeight: "2rem", height: "2rem" }}
                                        sx={{
                                            '& .MuiTextField-root': {
                                                height: "2rem",
                                                '& .MuiInputBase-root': {
                                                    height: "2rem",
                                                    '& .MuiAutocomplete-input': {
                                                        padding: '1px 0px 1px 9px',
                                                        fontSize: '0.8rem',
                                                        fontWeight: 500,
                                                        color: '#707070'
                                                    },
                                                    '& .MuiAutocomplete-tag': {
                                                        fontSize: "0.7rem",
                                                        height: "1.5rem",
                                                        margin: "0px 3px",
                                                        top: "-11%"
                                                    },
                                                    '& svg': {
                                                        color: '#f4511e'
                                                    }
                                                }
                                            }
                                        }}
                                        value={selectedCategory}
                                        options={categories}
                                        filterOptions={handleFilter}
                                        getOptionLabel={(option) => {
                                            // console.log('getOptionLabel option = ', option);
                                            if (!option.id) return '';
                                            if (option.name === '') return categoriesObject[option.id]?.name || '';
                                            return option.name
                                        }}
                                        onInputChange={(e, value) => {
                                            setDefaultOption({ name: value, type: "new" })
                                        }}
                                        renderInput={(params) =>
                                            <TextField sx={{
                                                '& .MuiInputBase-root': {
                                                    padding: 0,
                                                    '& > input': {
                                                        fontFamily: 'Open Sans',
                                                        '&::placeholder': {
                                                            color: 'rgb(0,0,0) !important',
                                                            fontFamily: 'Open Sans'
                                                        }

                                                    }
                                                }
                                            }} {...params} placeholder="Select Category" />}
                                        onChange={handleCategoryChange}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>{option.name}</li>
                                            )
                                        }} />
                                </div>

                                {showLanguageOption ? <div className={classes.LabelInputBox}>
                                    <label className={classes.Label}>
                                        Language
                                        {/* <i>New</i> */}
                                    </label>
                                    <Autocomplete
                                        style={{ width: "50%", minHeight: "2rem", height: "2rem" }}
                                        sx={{
                                            '& .MuiTextField-root': {
                                                height: "2rem",
                                                '& .MuiInputBase-root': {
                                                    height: "2rem",
                                                    '& .MuiAutocomplete-input': {
                                                        padding: '1px 0px 1px 9px',
                                                        fontSize: '0.8rem',
                                                        fontWeight: 500,
                                                        color: '#707070'
                                                    },
                                                    '& .MuiAutocomplete-tag': {
                                                        fontSize: "0.7rem",
                                                        height: "1.5rem",
                                                        margin: "0px 3px",
                                                        top: "-11%"
                                                    },
                                                    '& svg': {
                                                        color: '#f4511e'
                                                    }
                                                }
                                            }
                                        }}
                                        value={language}
                                        options={constants.LANGUAGES}
                                        // filterOptions={handleFilter}
                                        getOptionLabel={(option) => {
                                            // console.log('getOptionLabel option = ', option);
                                            if (!option.id) return '';
                                            if (option.value === '') return languagesObject[option.id]?.value;
                                            return option.value
                                        }}
                                        // onInputChange={(e, value) => {
                                        //     setDefaultOption({ value: value, type: "new" })
                                        // }}
                                        renderInput={(params) =>
                                            <TextField sx={{
                                                '& .MuiInputBase-root': {
                                                    padding: 0,
                                                    '& > input': {
                                                        fontFamily: 'Open Sans',
                                                        '&::placeholder': {
                                                            color: 'rgb(0,0,0) !important',
                                                            fontFamily: 'Open Sans'
                                                        }

                                                    }
                                                }
                                            }} {...params} placeholder="Select Language" />}
                                        onChange={handleLanguageChange}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>{option.value}</li>
                                            )
                                        }} />
                                </div> : null}

                                {false ? <div className={classes.LabelInputBox}>
                                    <label className={classes.Label}>
                                        Groups
                                    </label>
                                    <Autocomplete
                                        loading={loadingGroups}
                                        loadingText={"Loading..."}
                                        style={{ width: "50%", minHeight: "2rem", height: "2rem" }}
                                        renderTags={(tags) => {
                                            // console.log('tags = ', tags);
                                            let text = tags.length ? tags[0].name : '';
                                            if (tags.length && tags.length > 1) text += ` & ${tags.length - 1} more`
                                            return <span style={{ paddingLeft: 9, fontSize: '0.8rem', fontWeight: 500, color: '#707070' }}>{text}</span>
                                        }}
                                        getLimitTagsText={(more) => ` & ${more} more`}
                                        sx={{
                                            '& .MuiTextField-root': {
                                                height: "2rem",
                                                '& .MuiInputBase-root': {
                                                    height: "2rem",
                                                    '& .MuiAutocomplete-input': {
                                                        padding: '1px 0px 1px 9px',
                                                        fontSize: '0.8rem',
                                                        fontWeight: 500,
                                                        color: '#707070',
                                                    },
                                                    '& .MuiAutocomplete-tag': {
                                                        fontSize: "0.7rem",
                                                        height: "1.5rem",
                                                        margin: "0px 3px",
                                                        top: "-11%"
                                                    },
                                                    '& svg': {
                                                        color: '#f4511e'
                                                    }
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    padding: 0
                                                }
                                            }
                                        }}
                                        value={selectedGroups}
                                        multiple
                                        limitTags={1}
                                        options={allGroups}
                                        disableCloseOnSelect
                                        // filterOptions={handleFilter}
                                        getOptionLabel={(option) => {
                                            // console.log('getOptionLabel option = ', option);
                                            if (!option.id) return '';
                                            if (option.name === '') return groupsObject[option.id]?.name;
                                            return option.name
                                        }}
                                        renderInput={(params) => {
                                            return <TextField sx={{
                                                '& .MuiInputBase-root': {
                                                    padding: 0,
                                                    '& > input': {
                                                        fontFamily: 'Open Sans',
                                                        '&::placeholder': {
                                                            color: 'rgb(0,0,0) !important',
                                                            fontFamily: 'Open Sans'
                                                        }

                                                    }
                                                }
                                            }} {...params} placeholder={selectedGroups.length ? '' : "Select Group"} />
                                        }

                                        }
                                        onChange={handleGroupChange}
                                        renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>} />
                                </div> : null}
                            </form>
                            {roles.includes('GEN_AI') && isEdit ? <AzureOpenAi /> : null}
                            <ButtonBase
                                id="basic-button"
                                className={classes.chip}
                                onClick={() => setManageGroupDialogOpen(true)}>
                                <h6> {"Add Group"} </h6>
                                <IconButton
                                    aria-label="close"
                                    sx={{
                                        position: 'absolute',
                                        right: '0.7rem',
                                        color: '#ffffff',
                                        padding: 0,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1rem'
                                        }
                                    }}
                                >
                                    <AddIcon />
                                </IconButton>
                            </ButtonBase>
                            {
                                manageGroupDialogOpen &&
                                <GroupDialog
                                    manageGroupDialogOpen={manageGroupDialogOpen}
                                    setManageGroupDialogOpen={setManageGroupDialogOpen}
                                    handleAddGroup={handleAddGroup}
                                    microskillName={microskillName}
                                    msGroupsData={msGroupsData}
                                    handleRemoveMsFromGroup={handleRemoveMsFromGroup}
                                    allGroups={allGroups}
                                    handleSave={() => setManageGroupDialogOpen(false)}
                                    // isSaving={isSavingGroups}
                                    selectGroupFromDialog={selectGroupFromDialog}
                                    setSelectGroupFromDialog={setSelectGroupFromDialog}
                                    filterGroupData={filterGroupData}
                                    setFilterGroupData={setFilterGroupData}
                                    chips={chips}
                                />
                            }
                        </div>
                        <div className={classes.Row_RightDiv}>
                            {toggleEditBtn ?
                                <DefaultCropCard
                                    finalImage={finalImage}
                                    microskillTitle={microskillTitle}
                                    brandIcon={brandIcon}
                                    setToggleEditBtn={setToggleEditBtn}
                                    likes={likes}
                                    views={views}
                                />
                                : <div className={classes.Inside_RightDiv}>
                                    <div>
                                        <ImageCrop
                                            microskillName={microskillTitle}
                                            brandIcon={brandIcon}
                                            src={src}
                                            setCroppedImageURL={setCroppedImageURL}
                                            croppedImageURL={croppedImageURL}
                                            crop={crop}
                                            setCrop={setCrop}
                                            setFinalImage={setFinalImage}
                                        />
                                    </div>
                                    <div className={classes.Preview_Icons_div}>
                                        <h4 className={classes.Preview_txt}>PREVIEW: </h4>
                                        <span className={classes.Icons} >
                                            <img name="Cancel" title="Cancel" onClick={handleDoneCancel} className={classes.Done_Cancel_Icons} src="/images/add-microskill/circle-xmark-regular.svg" alt='' />
                                            <img name="Done" title="Done" onClick={handleDoneCancel} className={classes.Done_Cancel_Icons} src="/images/add-microskill/circle-check-solid.svg" alt='' />
                                        </span>
                                    </div>
                                    <h2 className={classes.Note}>
                                        <span className={classes.Note_text}>NOTE :</span> Default images will be used if you don't upload any assets of your own
                                    </h2>
                                </div>}
                        </div>
                    </div>

                    <div className={classes.Row2}>
                        <div className={classes.Row2_Left_Div}>
                            <h1 className={classes.Title}>Selected Skill Tags:</h1>
                            <TagsInput
                                microskillTags={microskillTags}
                                setMicroskillTags={setMicroskillTags}
                            />
                        </div>

                        <div className={classes.Row2_Right_Div}>
                            <div className={classes.Inside_Right_div}>
                                <h1 className={classes.Right_div_title}>
                                    <ErrorOutlineIcon className={classes.Error_Icon} /> How to use
                                    Skill Tags:
                                </h1>
                                <p className={classes.Right_div_textbox}>
                                    Skill Tags are the logical requirement for any assessment on Master-O. Each Game based assessment requires a minimum of 3 tags to be fully functional for your learning audience skill gaps.
                                </p>
                            </div>
                        </div>

                    </div>
                    {
                        microskillChallenges ? <div className={classes.Row3}>
                            <div style={{ margin: '0.5rem 0', position: 'relative' }}>
                                <Title titleText={"Challenges:"} infoIcon={true} />
                                {false && showSequenceCta ? <div className={classes.sequenceCtaBtnBox}>
                                    {isSavingSequence ?
                                        <LoadingButton
                                            loading
                                            loadingPosition="center"
                                            startIcon={<SaveIcon />}
                                            variant="outlined"
                                        >
                                        </LoadingButton>
                                        : <CustomButton
                                            variant='filled'
                                            textColor='#fff'
                                            borderColor='#f4511e'
                                            bgcolor='#f4511e'
                                            fs="0.7rem"
                                            fw={900}
                                            ls={1}
                                            btnText={'Save Sequence'}
                                            handleClick={handleUpdateSequence}
                                        />}
                                    <CustomButton
                                        textColor='#f4511e'
                                        borderColor='#f4511e'
                                        bgcolor='#fff'
                                        fs="0.7rem"
                                        fw={900}
                                        ls={1}
                                        btnText={'Cancel'}
                                        handleClick={_undoSequenceChanges}
                                    />
                                </div> : null}
                            </div>
                            <div className={classes.addChallengeCardContainer}>
                                {challenges.map((ele, index) => (
                                    (ele === null) ?
                                        <CreateChallengeCard
                                            key={index}
                                            handleCreate={() => handleCreateChallenge(index)}
                                            createText={"ADD CHALLENGE"}
                                        /> :
                                        <ChallengeCard
                                            key={index}
                                            id={index}
                                            element={ele}
                                            handleEdit={handleEditChallenge}
                                            _copyClick={_handleCopyClick}
                                            handleDelete={handleDeleteChallenge}
                                            challengeTypeImg={ele.imgUrl}
                                            challengeNumber={index + 1}
                                            challengeName={ele.name}
                                            designType={ele.designType}
                                            handleSequence={handleChangeSequence}
                                            sequenceChangedFor={sequenceChangedFor}
                                            selectedChallengeForCopy={selectedChallengeForCopy}
                                            isCopying={isCopyingChallenge}
                                            handleCopyChallenge={handleCopyChallenge}
                                            handleCancelCopy={handleCancelCopyChallenge}
                                            microskills={microskills}
                                            selectedMsForCopy={selectedMsForCopy}
                                            setSelectedMsForCopy={setSelectedMsForCopy}
                                            showAnimation={showAnimation}
                                        />
                                ))}
                            </div>
                        </div> : null
                    }
                    <div className={classes.Row4}>
                        <div className={classes.certificateCheckbox}>
                            <span>
                                <Checkbox
                                    id='certificate'
                                    checked={isCertificate}
                                    onChange={() => setIsCertificate(!isCertificate)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                />
                                <Label labelText={'Enable Certificate'} htmlFor={'certificate'} />
                            </span>
                            <span>
                                <Checkbox
                                    id='feedback'
                                    checked={showFeedback}
                                    onChange={() => setShowFeedback(!showFeedback)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                />
                                <Label labelText={'Enable Feedback'} htmlFor={'feedback'} />
                            </span>
                        </div>
                        <div className={classes.btnBox}>
                            {
                                isSaving ?
                                    <LoadingButton
                                        loading
                                        loadingPosition="start"
                                        startIcon={<SaveIcon />}
                                        variant="outlined"
                                    >
                                        Saving Microskill...
                                    </LoadingButton>
                                    : <button className={classes.saveBtn} onClick={handleSaveMs}>
                                        {isEdit ? "UPDATE MICROSKILL" : "SAVE MICROSKILL"}
                                    </button>
                            }

                            <button className={classes.ClearBtn} onClick={handleClearChanges}>CLEAR CHANGES & EXIT</button>
                        </div>
                    </div>
                    <Disclaimer />
                </div>
                {
                    isSelectChallengeOpen ? (
                        <SelectChallenge
                            isOpen={isSelectChallengeOpen}
                            handleClose={handleSelectChallenge}
                        />
                    ) : (null)
                }
                {
                    successDialogOpen &&
                    <SuccessDialog
                        successDialogOpen={successDialogOpen}
                        setSuccessDialogOpen={setSuccessDialogOpen}
                        dialogText={dialogContent}
                        handleClick={() => {
                            setSuccessDialogOpen(false);
                            let sequenceUpdated = localStorage.getItem('sequenceUpdated');
                            if (sequenceUpdated) {
                                localStorage.removeItem('sequenceUpdated');
                                window.location.reload();
                            }
                            if (isChallengeCreated) {
                                localStorage.removeItem('isChallengeCreated');
                                handleFourChallengeToast()
                            }

                        }}
                        buttonText='Okay'
                    />
                }
                {
                    errorDialogOpen &&
                    <FailureDialog
                        errorDialogOpen={errorDialogOpen}
                        setErrorDialogOpen={setErrorDialogOpen}
                        dialogText={dialogContent}
                        handleClick={() => setErrorDialogOpen(false)}
                    />
                }

            </>
        )
    }


}

export default CreateMicroskill