import React from 'react';
import styled from 'styled-components';
import TextInput from "../../components/tms-components/TextInput";
import FormControl from '../../components/tms-components/FormControl';
import Label from '../../components/tms-components/Label';
import SimpleFormSelect from "../../components/tms-components/SimpleFormSelect";
import Button2 from '../../components/tms-components/Button2';

export default function AddCourseEvent(props) {
  return (
    <ComponentWrapper>
      <FormControl>
        <Label>Event Name:</Label>
        <TextInput
          value={props.eventName}
          onChangeText={props.onChangeEventName}
        />
      </FormControl>

      <FormControl>
        <Label>Select Event Microskills:</Label>
        <SimpleFormSelect
          multiple={true}
          options={props.moduleOptions}
          onSelect={props.onModuleSelect}
          selected={props.modulesSelected}
          text={props.selectedModulesText}
        />
      </FormControl>

      <FormControl>
        <Label>Training Hours:</Label>
        <SimpleFormSelect
          options={props.trainingHoursOptions}
          onSelect={props.onTrainingHoursSelect}
          selected={props.trainingHoursSelected}
          text={props.trainingHoursText}
          autoClose
        />

      </FormControl>

      <FormControl>
        <Label>Invite Link (MS Teams, Google Meet, Zoom, etc)</Label>
        <TextInput
          value={props.eventInviteLink}
          onChangeText={props.onChangeEventInviteLink}
        />
      </FormControl>

      <FormControl>
        <Label>Invite Password (if any)</Label>
        <TextInput
          type={"password"}
          value={props.eventInviteLinkPassword}
          onChangeText={props.onChangeEventInviteLinkPassword}
        />
      </FormControl>

      <FormControl>
        <Actions>
          <Button2 onClick={props.onAddEvent}>Save Course Event</Button2>
          <Button2 onClick={props.onDelete} transparent={true} style={{ marginLeft: "5px" }}>Close</Button2>
        </Actions>
      </FormControl>

    </ComponentWrapper>
  )
};

const ComponentWrapper = styled.div`
  padding: 20px;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;