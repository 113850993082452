import React, { useState, useEffect, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Typography, Checkbox, Button } from '@mui/material';

// import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import EmailReports from '../email-report';
import SetReportScheduleModal from '../report-schedule-modal';
import { GlobalStates } from '../../../../App';
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';
import reportsServices from '../../../../services/reports';
import useStyles from './index.styles';
import isEmpty from '../../../../utils/isEmpty';
import useRouter from "../../../../utils/useRouter";

// const reportData = [
//     { name: 'Regional Completions', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'North Heads Comparison', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'North Dealers KPIs', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'All Dealership KPIs', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'West Completions', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'East Pulsar NS400', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'All North Dealers', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'BAL-MC Master', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'KPI Tracker', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
//     { name: 'Tough questions Q2 2024', createdOn: '14-4-2024', time: '3:20 PM', id: 'string' },
// ];

const ReportTable = ({ setBackdrop, mapping }) => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [emailId, setEmailId] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [globalStates] = useContext(GlobalStates);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [emailArray, setEmailArray] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleTime, setScheduleTime] = useState(null);
    const [frequency, setFrequency] = useState("Daily");
    const [reportPeriod, setReportPeriod] = useState("Current Month");

    const query = useQuery();
    const companyId = query.get("c_id");
    const router = useRouter();
    // const [payload, setPayload] = useState({ companyId: companyId, flag: "filter" });
    let mappingObject = commonUtil.objectify(mapping, "name");


    useEffect(() => {
        (async () => {
            const response = await reportsServices.fetchSavedCustomTemplates(companyId);
            console.log('reponse', response);
            if (response.status === 200 || response.status === 201) {
                const data = response.data.data;
                setData(data);
            }

        })()
    }, [])

    const handleDownloadCustomReport = async (id) => {
        setBackdrop(true);
        const downloadReportPayload = {
            templateId: id,
            flag: "excel",
            companyId: companyId,
        }
        const response = await reportsServices.sendReportActions(downloadReportPayload);
        console.log('downloadAsExcel response = ', response);
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location;
            console.log('downloadAsExcel fileUrl = ', fileUrl);
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Login reports'; // Set the desired filename for the downloaded file
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }

    const handleEmailCustomReport = async (id) => {
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        setSendingEmail(true);
        const emailReportPayload = {
            templateId: id,
            flag: "email",
            email: emailId,
            companyId: companyId,
        }
        const response = await reportsServices.sendReportActions(emailReportPayload);
        console.log('emailReport response = ', response);
        if (response.status === 200) {
            let message = `Login Report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
            setSendingEmail(false);
            setEmailDialog(false);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setSendingEmail(false);
        }
    }

    const handleDeleteTemplate = async (id) => {
        let confirm = window.confirm("Do you really want to delete this template?");
        if (!confirm) return
        globalStates.handleProgressDialog(true, 'Please wait...')
        const response = await reportsServices.deleteSavedCustomTemplate({ id });
        globalStates.handleProgressDialog(false)
        if (isEmpty(response)) {
            globalStates.handleToast(true, "Request timeout", "error");
            return
        }
        if (response.status === 200 || response.status === 201) {
            const filterData = data.filter((ele) => ele.id !== id)
            setData(filterData);
            globalStates.handleToast(true, 'Deleted succesfully!', "success");

        } else {
            globalStates.handleToast(true, "Failed to delete, please try again!", "error");
        }

    }

    const handleCheckboxChange = (id) => {
        setSelectedTemplates((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };

    const isSelected = (id) => selectedTemplates.includes(id);

    const handleSchedule = async () => {
        const schedulePayload = {
            emailArray: emailArray.split(','),
            startDate: startDate,
            endDate: endDate,
            scheduleTime: scheduleTime,
            frequency,
            report_duration: reportPeriod,
            templateIds: selectedTemplates,
            companyId: companyId,
        }
        const response = await reportsServices.customReportSchedule(schedulePayload);
        if (response.status === 200) {
            let message = `Report Scheduled Successfully`
            globalStates.handleToast(true, message, "success");
            setIsReportModalOpen(prev => !prev);
            setSelectedTemplates([]);
            resetModalInput();
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    }

    const resetModalInput = () => {
        setStartDate(null);
        setEndDate(null);
        setEmailArray('');
        setScheduleTime(null);
        setFrequency("Daily");
        setReportPeriod("Current Month");
    }

    // const deleteTemplate = () => {

    // }
    return (
        <div className={classes.container}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 className={classes.heading}>All Report Templates</h1>
                {!isEmpty(selectedTemplates) && <div><Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setIsReportModalOpen(prev => !prev)
                    }}
                    style={{ color: '#fff', backgroundColor: '#ff5722', marginRight: '10px' }}
                    className={classes.btn}
                >
                    Schedule
                </Button>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => deleteTemplate()}
                        style={{ color: '#f4511e', backgroundColor: '#fff' }}
                        className={classes.btn}
                    >
                        Delete
                    </Button> */}
                </div>
                }
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Template Name</TableCell>
                            <TableCell>Created On</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length > 0 &&
                            data.map((row, index) => (
                                <TableRow key={index} className={classes.tableRow}>
                                    <TableCell>
                                        <Checkbox
                                            checked={isSelected(row.id)}
                                            onChange={() => handleCheckboxChange(row.id)}
                                            color="primary"
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: "#ff5722",
                                                },
                                                '& .MuiSvgIcon-root': { fontSize: 20 },
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        className={classes.templateName}
                                        onClick={() =>
                                            router.history.push(
                                                `/analytics?c_id=${companyId}&&tab=20&&template_Id=${row.id}`
                                            )
                                        }
                                    >
                                        {row.title}
                                    </TableCell>
                                    <TableCell>{`${commonUtil.getFormattedDate(row.createdOn, '-')}`}</TableCell>
                                    <TableCell align="center">
                                        <div className={classes.actionIcons}>
                                            <EmailReports
                                                open={emailDialog}
                                                setOpen={setEmailDialog}
                                                loading={sendingEmail}
                                                handleChange={(e) => setEmailId(e.target.value)}
                                                handleClick={() => handleEmailCustomReport(row.id)}
                                            />
                                            <img
                                                src="/images/analytics/download-icon.svg"
                                                alt=""
                                                title="Download Custom Report"
                                                onClick={() => handleDownloadCustomReport(row.id)}
                                                style={{ cursor: "pointer" }}
                                            />
                                            <img
                                                src="/images/analytics/delete.svg"
                                                alt=""
                                                title="Delete Custom Report"
                                                onClick={() => handleDeleteTemplate(row.id)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <SetReportScheduleModal
                open={isReportModalOpen}
                onClose={() => setIsReportModalOpen(prev => !prev)}
                emailArray={emailArray}
                setEmailArray={setEmailArray}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                scheduleTime={scheduleTime}
                setScheduleTime={setScheduleTime}
                frequency={frequency}
                setFrequency={setFrequency}
                handleSchedule={handleSchedule}
                isReportHistory={true}
                reportPeriod={reportPeriod}
                setReportPeriod={setReportPeriod}
            />
        </div>
    );
};

export default ReportTable;