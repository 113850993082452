import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    Heading: {
        display: "block",
        // fontFamily: "Montserrat",
        color: "#484848",
        fontSize: "0.8rem",
        fontWeight: "Bold",
        characterSpacing: "0",
    },
    AnswersContainer: {
        display: 'flex',
        rowGap: '1rem',
        flexDirection: "column",
        width: "100%",
        marginTop: "0.8rem"
    },
    Answers: {
        display: "flex",
        columnGap: "1rem",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    AnswerInputWrapper: {
        display: "flex",
        columnGap: "1rem",
        width: "100%",
        alignItems: 'center',
    },
    AnswerInput: {
        width: "75%"
    },
    AddButtonsContainer: {
        display: "flex",
        columnGap: "2rem",
    },
    AddButtons: {
        color: "#F4511E",
        display: 'flex',
        alignItems: "center",
        border: "2px solid #F4511E",
        padding: "0.3rem 2rem",
        borderRadius: "2rem",
        textTransform: "none",
        fontSize: '0.8rem',
        fontWeight: 600,
    },
    deleteAnswerIcon: {
        cursor: 'pointer',
        marginRight: '1rem',
        color: 'dimgray'
    },
    radioLabel: {
        fontSize: '0.66rem',
        fontWeight: 750,
        color: 'rgba(112, 112, 112, 0.8)'
    }
}))

export default useStyles;