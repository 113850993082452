import React, { useEffect, useRef } from "react";

//mui
import HelpIcon from "@mui/icons-material/Help";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//components
import Loader from "../custom-styled/loader";

// utils
import commonUtil from "../../utils/commonUtil";

//components
import Title from "../title";

//styles
import useStyles from "./index.styles";

//context
// import { GlobalStates } from "../../App";

const func = () => { }

const MasteroTable = ({
  tableTitle = '',
  tableHeadings,
  tableData = [],
  quickActions = true,
  deleteKey = true,
  copyKey = true,
  handleDelete = func,
  handleEdit = func,
  handleNameClick = func,
  handleDuplicate = func,
  handleCreateRepository = func,
  handleShowCourses = func,
  handleManageLearners = func,
  handleTraining = func,
  handleDownloadAssessment = func,
  handleBackClick = func,
  handleForwardClick = func,
  pagination = {
    limit: 0,
    pageCount: 0,
    startIndex: -1,
    totalCount: 0
  },
  loader = false,
  emptyTableText = 'No data found',
  thPadding= ""
}) => {
  const styles = useStyles();
  const loadingDivRef = useRef(null);

  useEffect(() => {
    if (loader) {
      scrollToStart()
    }
  }, [loader])



  const scrollToStart = () => {
    if (loadingDivRef.current) {
      loadingDivRef.current.scrollLeft = 0;
    }
  };

  const getPaginationText = () => {
    if (!tableData.length || loader) return `${0} - ${0} out of ${0} Entries`
    return `${pagination.startIndex + 1} - ${(pagination.startIndex + 1 * pagination.limit) > pagination.totalCount ? pagination.totalCount : pagination.startIndex + pagination.limit} out of ${pagination.totalCount} Entries`

  }


  return (
    <div className={styles.MasteroTable_Container}>
      <Title titleText={tableTitle} infoIcon={true} padding="1rem 0rem 2rem 0rem" />
      <div className={`${styles.tableWrapper} ${loader || !tableData.length ? styles.disableScroll : ''}`} ref={loadingDivRef}>
        <table className={styles.Table}>
          <thead>
            <tr className={styles.Table_Heading}>
              {tableHeadings.map((element, i) => (
                (element.key === "quickActions" && !quickActions) ? null : (
                  <th key={i} style={{padding: thPadding}}>{element.title}</th>
                )
              ))}
            </tr>
          </thead>
          {!loader ? <tbody>
            {tableData.map((element, key) => (
              <tr className={styles.Table_Data} key={key}>

                {
                  tableHeadings.map((data, i) => {
                    switch (data['key']) {
                      case "quickActions":
                        if (quickActions) {
                          return (
                            <td key={i} className={styles.iconBox}>
                              <img
                                className={styles.Icons}
                                onClick={() => handleEdit(element, key)}
                                src="/images/icons/pencil-edit-button.svg"
                                alt="Edit Button"
                                title="Edit"
                              />
                              {copyKey ?
                                <img
                                  className={styles.Icons}
                                  onClick={() => handleDuplicate(element, key)}
                                  src="/images/icons/duplicate.svg"
                                  alt="Duplicate Button"
                                  title="Duplicate"
                                /> : null}
                              {
                                deleteKey ?
                                  <img
                                    className={styles.Icons}
                                    onClick={() => handleDelete(key)}
                                    src="/images/icons/delete-photo.svg"
                                    alt="Delete Button"
                                    title="Delete"
                                  />
                                  : null
                              }
                            </td>
                          )
                        } else {
                          return null
                        }
                      case "name":
                        return (<td key={i} style={{ textDecoration: data['link'] ? 'underline' : 'none', cursor: data['link'] ? 'pointer' : 'default', lineHeight: '2', padding: '1rem', width: '20%' }} onClick={() => handleNameClick(element)}>{element.name}</td>)
                      // break;
                      case "repository":
                        if (element.repository) {
                          return (
                            <td
                              key={i}
                              style={{ textDecoration: data['link'] ? 'underline' : 'none', color: '#f4511e', fontWeight: 700 }}
                              onClick={() => handleCreateRepository(element)}
                            >
                              Create
                            </td>
                          )
                        } else {
                          return (
                            <td
                              key={i}
                              style={{ textDecoration: data['link'] ? 'underline' : 'none', color: '#f4511e', fontWeight: 700, lineHeight: '2', padding: '1rem' }}
                              onClick={() => handleCreateRepository(element)}
                            >
                              Create
                            </td>
                          )
                        }

                      case "report":
                        return (
                          <td
                            key={i}
                            style={{
                              textDecoration: data['link'] && element[data.key] === "Yes" ? 'underline' : 'none',
                              color: element[data.key] === "Yes" ? '#f4511e' : '',
                              fontWeight: element[data.key] === "Yes" ? 700 : '',
                              cursor: element[data.key] === "Yes" ? 'pointer' : 'default'
                            }}
                            onClick={element[data.key] === "Yes" ? () => handleDownloadAssessment(element) : ''}
                          >
                            {element[data.key] === "Yes" ? "Download Report" : element[data.key]}
                          </td>
                        )

                      case "assessment":
                        return (
                          <td key={i}>
                            {element[data.key]}
                          </td>
                        )

                      case "courses":
                        return (
                          <td
                            key={i}
                            style={{ textDecoration: 'underline', color: '#f4511e', fontWeight: 700 }}
                            onClick={() => handleShowCourses(element)}
                          >
                            Course log
                          </td>
                        )

                      case "batches":
                        return (
                          <td
                            key={i}
                            style={{ textDecoration: 'underline', color: '#f4511e', fontWeight: 700 }}
                            onClick={() => handleManageLearners(element)}
                          >
                            Manage
                          </td>
                        )

                      case "training":
                        return (
                          <td
                            key={i}
                            style={{ textDecoration: 'underline', color: '#f4511e', fontWeight: 700 }}
                            onClick={() => handleTraining(element)}
                          >
                            Training log
                          </td>
                        )

                      case "expiresOn":
                        return (<td key={i} style={{
                          cursor: data['link'] ? 'pointer' : 'default'
                        }}>{new Date(element[data.key]).toLocaleString()}</td>)

                      case "updatedOn":
                        return (<td key={i} style={{
                          cursor: data['link'] ? 'pointer' : 'default'
                        }}>{new Date(element[data.key]).toLocaleString()}</td>)

                      case "description":
                        return (<td key={i} style={{
                          cursor: data['link'] ? 'pointer' : 'default', lineHeight: '2', padding: '1rem', width: '30%'
                        }}>
                          {commonUtil.truncateString(element[data.key], 50)}
                        </td>)

                      default:
                        return (
                          <td key={i} style={{
                            cursor: data['link'] ? 'pointer' : 'default', lineHeight: '2', padding: '1rem',
                          }}>
                            {element[data.key]}
                          </td>
                        )
                    }
                  }
                  )
                }
              </tr>
            ))}
          </tbody> : null}
        </table>

        {
          loader ?
            <div className={styles.loaderBox}>
              <Loader />
            </div> : null
        }
        {
          (!loader && !tableData.length && <div className={styles.emptyTable}>
            {emptyTableText}
          </div>)
        }

      </div>
      {!loader ? <div className={styles.paginationBox}>
        <h5>{getPaginationText()}</h5>
        <ChevronLeftIcon className={styles.leftIcon} onClick={handleBackClick} />
        <ChevronRightIcon className={styles.leftIcon} onClick={handleForwardClick} />
      </div> : null}

    </div>

  );
};

export default MasteroTable;
// '2022-1-24T05:34:41.555Z'.split('T')[0] //CHANGE DATE FORMAT
{/* <div className={styles.Inside_MasteroTable_Container}>
</div> */}