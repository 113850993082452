import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    orangeScrollbar: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                background: '#f5411e',
                borderRadius: '10px'
            }
        }
    },
    MainContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '30% 50% 20%',
        height: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
    },
    settingsContainer: {
        padding: '0rem 1rem',
        display: 'flex',
        flexDirection: "column",
        justifyContent: "space-between",
        rowGap: '1rem',
    },
    formQuestionContainer: {
        padding: "0rem 0.5rem 1rem",
    },
    FormQuestionHeading: {
        background: "rgb(221, 224, 226, 0.5)",
        padding: "0.6rem",
        marginBottom: "0.5rem",
        color: "#707070",
        fontWeight: "bold",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "0.8rem",
        cursor: 'pointer',
    },

    dropDownIcon: {
        color: '#f4511e',
        fontSize: '0.8rem',
        marginRight: '1rem'
    },

    validatedQuestionIcon: {
        color: "#15B725 !important",
        fontSize: '0.8rem',
        marginRight: '0.4rem'
    },

    unValidatedQuestionIcon: {
        color: '#f4511e',
        fontSize: '0.8rem',
        marginRight: '0.4rem'
    },

    previewConatiner: {
        height: `100%`,
        // height: `calc(100dvh - ${constants.HEADER_HEIGHT})`,
        width: '100%',
        padding: "1rem 0.5rem",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    AddButtons: {
        width: "25%",
        color: "#FFF",
        background: "#F4511E",
        display: 'flex',
        alignItems: "center",
        border: "1px solid #F4511E",
        padding: "0.8rem 2rem",
        textTransform: "none",
        '&:hover': {
            color: "#FFF",
            background: "#F4511E",
        }
    },
    Input_Box: {
        fontFamily: "Open Sans",
        fontSize: "11px",
        fontWeight: "400",
        display: "block",
        width: "90%",
        padding: "0.5rem",
        // margin: "0.5rem 0",
        border: "none",
        // boxShadow: "0 0 6px #DDE0E2",
        border: "1.4px solid #70707050",
        color: "#707070",
        fontSize: "0.8rem",
    },
    Label: {
        display: "block",
        // fontFamily: "Montserrat",
        color: "#484848",
        fontSize: "0.8rem",
        fontWeight: "Bold",
        letterSpacing: "0",
        // padding: "0.6rem 0"
    },
    formOptionContainer: {
        width: '80%',
        color: '#484848',
        fontWeight: '700',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        // flexWrap: 'wrap',
        // rowGap: '1rem'
    },
    timeLimitContainer: {
        justifyContent: 'flex-start',
        width: '90%'
    },
    timeLimitArrowContainer: {
        color: "#F4511E",
        width: '35%',
        border: '1.4px solid #70707050',
        borderLeft: 'transparent',
        columnGap: '0.5rem'
    },
    timeLimitInput: {
        width: '60%',
        borderRight: 'transparent',
        padding: '0.76rem',
        marginBottom: '0.5rem'
    },
    timeLimitArrows: {
        flexDirection: 'column',
        color: "#F4511E",
        cursor: 'pointer'
    },
    btnBox: {
        display: 'flex',
        // justifyContent: 'center',
        marginBottom: '1rem',
        gap: '1rem',
        width: '90%',
        '& .MuiButtonBase-root': {
            // marginRight: '2rem',
            width: '50%',
            fontFamily: "Montserrat",
            fontWeight: 800,
            lineHeight: 0,
            fontSize: "0.6rem",
            borderRadius: "0.2rem",
        }
    },
    btn: {
        width: '50%',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#F4511E",
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        borderRadius: "0.2rem",
        cursor: "pointer",
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
        }
    },
    saveBtn: {
        backgroundColor: "#F4511E",
        // marginRight: "2rem",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F4511E",
        }
    },
    clearBtn: {
        backgroundColor: "#FFFFFF",
        color: "#F4511E",
        "&:hover": {
            border: "2px solid #F4511E",
            backgroundColor: "#FFFFFF",
        }
    },

    errorContainer: {
        width: '35%',
        columnGap: '0.5rem',
        justifyContent: 'flex-end'
    },

    errorText: {
        minWidth: '50%',
        border: '1px solid red',
        color: '#F4511E',
        textAlign: 'center',
        color: '#F4511E',
        borderRadius: '5px',
        fontWeight: '600',
        padding: '0.2rem'
    },

    // // // // // 
    addQuesBtn: {
        background: "#F4511E",
        color: "#FFF",
        fontSize: "0.8rem",
        fontWeight: 650,
        "&:hover": {
            background: "#F4511E",
            color: "#FFF",
        }
    },
    boldText: {
        color: "#484848 !important",
        // fontFamily: "Montserrat !important",
        fontWeight: "bold !important"
    },

    scoreInput: {
        // paddingLeft: '4px',
        // paddingRight: '4px',
        minWidth: '3rem !important'
    },

    descLimitText: {
        position: 'absolute',
        right: '0%',
        bottom: '1rem',
        transform: 'translate(-1px, -1px)',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.28rem 0.5rem',
        width: '14%',
        minWidth: 'fit-content',
        fontWeight: '500',
        fontSize: '0.6rem',
        // fontFamily: 'Montserrat',
        color: 'red',
        borderRadius: '0 0 3px 0'
    },
    TextArea: {
        minHeight: "10rem",
        width: '100%',
        paddingBottom: '1.5rem',
        outline: '1.5px solid rgba(184, 184, 184, 0.5)',
        borderRadius: '3px',
        maxHeight: '10rem', 
        resize: 'none',
        border: 'none',
        "&:focus": {
            outlineColor: "#000",
        }
    },

    uploadProgressBox: {
        display: 'flex',
        alignItems: 'center',
        '& .fa-solid': {
            width: '6%',
            fontSize: '1.3rem',
            marginRight: '3%',
            color: '#444444',
        },
        '& > div': {
            width: '91%',
            '& > h6': {
                fontSize: '0.6rem',
                color: '#f3c50e',
                fontFamily: 'Montserrat',
                fontWeight: 600,
            }
        }
    },
    csvUploadBoxContainer: {
        border: '2px dashed #CCCCCC',
        borderRadius: '10px',
        margin: '1rem 0',
        height: '8rem'
    },
    csvUploadBox: {
        padding: '1rem 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '8rem',
        '& h4,h5,a': {
            marginBottom: '0.2rem',
            fontSize: '0.6rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: "700",
        },
        '& h5': {
            margin: '0.3rem 0',
            fontWeight: "500",
        },
        '& > a': {
            margin: '0.3rem 0',
            color: '#F4511E',
            fontWeight: "600",
            '&:hover': {
                fontWeight: "700",
            }
        },

    },
    uploadIcon: {
        '& .fa-solid': {
            fontSize: '1.5rem',
            color: '#F4511E',
            cursor: 'pointer',
            marginBottom: '0.3rem',
            transition: '0.5s',
            '&:hover': {
                scale: 1.1
            }
        },

    },
}));

export default useStyles;