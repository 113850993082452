import { authFetch } from "../utils/fetch";

const fetchCourses = async (companyId) => {
    try {
        const resData = await authFetch.get(`/course?startIndex=0&limit=1000&sort=createdOn&sortDirection=desc&companyIds=${companyId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const createCourse = async (payload) => {
    try {
        const resData = await authFetch.post(`/course`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateCourse = async (payload) => {
    try {
        const resData = await authFetch.put(`/course`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const deleteCourse = async (data) => {
    try {
        const resData = await authFetch.delete(`/course`, { data });
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchCourseEvents = async (courseId) => {
    try {
        const resData = await authFetch.get(`/course/event?startIndex=0&limit=20&sort=createdOn&sortDirection=asc&courseIds=${courseId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const createCourseEvent = async (payload) => {
    try {
        const resData = await authFetch.post(`/course/event`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateCourseEvent = async (payload) => {
    try {
        const resData = await authFetch.put(`/course/event`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const deleteCourseEvent = async (data) => {
    try {
        const resData = await authFetch.delete(`/course/event`, { data });
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchEventMicroskills = async (eventId, companyId) => {
    let url = `/course/event/microskills?startIndex=0&limit=100&sort=createdOn&sortDirection=asc&companyIds=${companyId}&eventIds=${eventId}`
    try {
        const resData = await authFetch.get(url);
        return resData;
    } catch (err) {
        return err.response;
    }
}
const addEventMicroskills = async (payload) => {
    try {
        const resData = await authFetch.post(`/course/event/add-microskills`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const removeEventMicroskills = async (payload) => {
    try {
        const resData = await authFetch.post(`/course/event/remove-microskills`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchCourseCategories = async (companyId) => {
    try {
        const resData = await authFetch.get(`/course/category?startIndex=0&limit=50&sort=createdOn&sortDirection=asc&companyIds=${companyId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const createCourseCategory = async (payload) => {
    try {
        const resData = await authFetch.post(`/course/category`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchCourseTypes = async () => {
    try {
        const resData = await authFetch.get(`/course/type?startIndex=0&limit=10&sort=createdOn&sortDirection=asc`);
        return resData;
    } catch (err) {
        return err.response;
    }
}


export default {
    fetchCourses,
    createCourse,
    deleteCourse,
    updateCourse,
    fetchCourseEvents,
    createCourseEvent,
    updateCourseEvent,
    deleteCourseEvent,
    fetchEventMicroskills,
    addEventMicroskills,
    removeEventMicroskills,
    fetchCourseCategories,
    createCourseCategory,
    fetchCourseTypes
}