import React from "react";

import FormControl from '../../components/tms-components/FormControl';
import Button2 from '../../components/tms-components/Button2';
import styled from 'styled-components';
// import { Dialog } from '@material-ui/core';

//new
import Dialog from '@mui/material/Dialog';



const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteDialog = (props) => {
    const { id, dialogText, confirmDeleteDialog, setConfirmDeleteDialog, handleDelete } = props;
    return (
        <Dialog
            open={confirmDeleteDialog}
            onClose={() => {
                setConfirmDeleteDialog(false);
            }}>
            <ProgressContainer>
                <ProgressText>
                    {
                        id.type === 'CLEAR' ? `Are you sure you want to clear all data?` : `Are you sure you want to delete this ${dialogText}?`
                    }
                </ProgressText>
                <ProgressText>
                    <FormControl>
                        <Actions>
                            <Button2
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleDelete(id);
                                }}>
                                Yes Delete
                            </Button2>
                            <Button2
                                onClick={() => setConfirmDeleteDialog(false)}
                                transparent={true}
                                style={{ marginLeft: '5px' }}>
                                Cancel
                            </Button2>
                        </Actions>
                    </FormControl>
                </ProgressText>
            </ProgressContainer>
        </Dialog>
    )
}

export default DeleteDialog;