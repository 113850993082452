import React, { useState } from 'react';
import dayjs from 'dayjs';

// material-ui
import { ButtonBase } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

//components
import SearchBox from '../../../search-box';
import CustomSelect from '../../../custom-styled/custom-select';
import MODateTimePicker from '../../../tms-components/MODateTimePicker';

//utils
import commonUtil from '../../../../utils/commonUtil';
import Label from '../../../custom-styled/label';

//styles
import useStyles from '../../index.styles';

const Step2 = ({
    launchType,
    setLaunchType,
    setStep3,
    scheduledDates,
    setScheduledDates,

}) => {
    const classes = useStyles();
    let currentDate = dayjs(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(currentDate);
    const [startDateError, setStartDateError] = useState('');
    const [startDateDialog, setStartDateDialog] = useState(false);

    let launchTypeData = [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }, { id: 'DISABLE', name: 'DISABLE' }];
    launchTypeData = [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }, { id: 'SCHEDULED', name: 'SCHEDULED' }];
    const launchTypeObject = commonUtil.objectify(launchTypeData, 'id');

    const _selectLaunchType = (item) => {
        console.log('_selectLaunchType item = ', item);
        if (launchType.includes(item.id)) {
            return
        }
        // setLinkedContent([...linkedContent, item.id]); //for selecting multiple content
        setLaunchType([item.id]);
        handleResetDates();
        if (item.id === 'SCHEDULED') {
            setScheduledDates([]);
            setShowDatePicker(true);
            setStep3(false);
            return;
        }
        setStep3(true);
    }

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
    }

    const handleResetDates = () => {
        setStartDate(currentDate);
    }

    const handleSaveDateChanges = () => {
        let tempScheduledDates = [...scheduledDates];
        tempScheduledDates.push({
            order: tempScheduledDates.length + 1,
            launchesOn: dayjs(startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
        });
        setScheduledDates(tempScheduledDates)
        handleResetDates()
        setShowDatePicker(false);
        setStep3(true);
    }
    const handleCancelDateChanges = () => {
        setShowDatePicker(false);
        handleResetDates()
    }
    const handleAddSchedule = () => {
        setShowDatePicker(true);
    }
    const handleDeleteSchedule = (item, idx) => {
        let tempScheduledDates = scheduledDates.filter((element, i) => i !== idx);
        setScheduledDates(tempScheduledDates);
        if (!tempScheduledDates.length) setStep3(false);
    }



    return (
        <div style={{
            marginBottom: '3rem'
        }}>
            <h2 style={{
                fontSize: '1.2rem',
                fontFamily: "Montserrat",
                color: "#707070",
                padding: '1rem 0rem',
                paddingTop: '1.5rem',
                borderTop: "1px solid rgb(112, 112, 112, 0.3)",
                marginTop: '1.5rem'
            }}>Step 2: Launch Schedule</h2>

            <div className={classes.row}>
                <CustomSelect
                    defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Launch Type', selected: launchType, dataObject: launchTypeObject })}
                    autoClose={true}
                    withSearch={false}
                    withSelectAllOption={false}
                    listData={launchTypeData}
                    selected={launchType}
                    setSelected={setLaunchType}
                    handleSelect={_selectLaunchType}
                />
                {launchType.includes("SCHEDULED") ?
                    <>
                        {scheduledDates.map((item, i) => (
                            <div className={classes.scheduleListItem} key={i}>
                                <h5 className={classes.scheduleLilabel} >
                                    <Label labelText={`Schedule ${i + 1}:`} fw={800} />
                                    <img src='/images/icons/delete-photo.svg' alt='' title='Delete Schedule' onClick={(e) => handleDeleteSchedule(item, i)} />
                                </h5>
                                <div>
                                    <h4>Launch: {dayjs(item.launchesOn).format('DD-MM-YYYY HH:mm [Hrs]')}</h4>
                                </div>
                            </div>
                        ))}

                        {showDatePicker ?
                            <div className={classes.scheduledWrapper}>
                                <div className={classes.schedule}>
                                    <Label labelText={"Launch"} fw={800} />
                                    <MODateTimePicker
                                        onClick={() => {
                                            setStartDateDialog(true);
                                        }}
                                        open={startDateDialog}
                                        value={startDate}
                                        minDate={currentDate}
                                        errorText={startDateError}
                                        onClose={() => setStartDateDialog(false)}
                                        text={startDate.format('DD-MM-YYYY HH:mm [Hrs]')}
                                        handleDateChange={handleStartDateChange}
                                    />
                                </div>
                                <div className={classes.iconWrapper}>
                                    <i onClick={handleSaveDateChanges} className="fa-solid fa-check" title='Save'></i>
                                    <i onClick={handleCancelDateChanges} className="fa-solid fa-xmark" title='Cancel'></i>
                                </div>
                            </div> :
                            <ButtonBase className={classes.addScheduleBtn} onClick={handleAddSchedule}><AddIcon />Add Schedule</ButtonBase>
                        }
                    </>
                    : null}
                {/* <div className={classes.selectWrapper}>
                    <div
                        className={`${classes.select} ${active === 'launch-type' ? classes.active : ''}`}
                        onClick={() => handleSetActive('launch-type')}
                    >
                        {
                            launchType.length ?
                                launchTypeObject[launchType[0]]?.name
                                : 'Select Launch Type'
                        }
                        <i className={`fa-solid ${active === 'launch-type' ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                    </div>
                    {
                        active === 'launch-type' &&
                        <div className={classes.selectBox}>
                            <SearchBox
                                withSearch={false}
                                withSelectAllOption={false}
                                listData={launchTypeData}
                                selected={launchType}
                                setSelected={setLaunchType}
                                handleSelect={_selectLaunchType} />
                        </div>
                    }
                </div> */}
            </div>
        </div>
    )
}

export default Step2;
