import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    MainConatiner: {
        padding: "0.5rem 0rem"
    },
    OptionsContainer: {
        display: "flex",
        columnGap: '1rem',
        marginTop: '1.5rem',
        rowGap: '1rem',
        flexWrap: 'wrap'
    },
    Heading: {
        display: "block",
        // fontFamily: "Montserrat",
        color: "#484848",
        fontSize: "0.8rem",
        fontWeight: "Bold",
        letterSpacing: "0",
    },
    Options: {
        padding: "0.5rem 2rem",
        borderRadius: "2rem",
        border: "1px solid #F4511E",
        fontSize: "0.8rem",
        cursor: "pointer",
        textAlign: "center",
        background: "transparent",
        fontWeight: 600,
        color: "#707070"
    },
    SelectedOption: {
        background: "#F4511E",
        color: "#FFF"
    }
}));

export default useStyles;