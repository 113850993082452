import { authFetch } from "../utils/fetch";
import { authFetchFormData } from "../utils/fetch";

const fetchBatches = async (companyId, { trainerIds = null }) => {
    let url = `/batch?startIndex=0&limit=3000&sort=createdOn&sortDirection=desc&companyIds=${companyId}`
    if (trainerIds !== null) url += `&trainerIds=${trainerIds}`
    try {
        const resData = await authFetch.get(`${url}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}


const createBatch = async (payload) => {

    try {
        const resData = await authFetch.post(`/batch`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateBatch = async (payload) => {

    try {
        const resData = await authFetch.put(`/batch`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const deleteBatch = async (data) => {
    try {
        const resData = await authFetch.delete(`/batch`, { data });
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchAllLearners = async (companyId) => {
    try {
        const resData = await authFetch.get(`/batch/get-all-learners?startIndex=0&limit=1000&sort=createdOn&sortDirection=desc&companyIds=${companyId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchBatchLearners = async (companyId, batchId) => {
    try {
        const resData = await authFetch.get(`/batch/get-learners?startIndex=0&limit=1000&sort=createdOn&sortDirection=asc&companyIds=${companyId}&batchIds=${batchId}`);
        return resData;
    } catch (err) {
        return err.response;
    }
}

//TO UPLOAD LEARNERS USING EXCEL FILE
const uploadLearnersToBatch = async (payload) => {

    try {
        const resData = await authFetchFormData.post(`/batch/add-learners`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

// TO UPLOAD LEARNER USING EXCEL FILE WHICH CONTAIN SAP IDS
const uploadLearnersToBatchUsingSAPIDs = async (payload) => {

    try {
        const resData = await authFetchFormData.post(`/batch/add-learners-using-sap-ids`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

//TO REMOVE LEARNERS USING EXCEL FILE
const removeLearnersFromBatch = async (payload) => {

    try {
        const resData = await authFetch.post(`/batch/remove-learners`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}


const addLearnerToBatch = async (payload) => {

    try {
        const resData = await authFetch.post(`/batch/add-learner`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}


const removeLearner = async (payload) => {

    try {
        const resData = await authFetch.post(`/batch/remove-learner`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}




export default {
    fetchBatches,
    createBatch,
    updateBatch,
    deleteBatch,
    fetchAllLearners,
    fetchBatchLearners,
    uploadLearnersToBatch,
    removeLearnersFromBatch,
    addLearnerToBatch,
    removeLearner,
    uploadLearnersToBatchUsingSAPIDs
}