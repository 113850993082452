import React from 'react'

//mui
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

//components
import Label from '../../../label';
import FileuploadInput from '../fileupload-input';

//styles
import { makeStyles } from "@mui/styles";
import { useEffect } from 'react';




const CustomContext = ({
    id,
    defaultValue = '',
    isDisabled = false,
    maxLength = 500000,
    limitText = '',
    isExceeded = false,
    isEmpty = false,
    designType,
    handleContextChange
}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event) => {
        let value = parseInt(event.target.value)
        setValue(value);
    };

    useEffect(() => {
        if (designType === 'TF_SoundRight') {
            setValue(2)
        }
    }, [])


    // const contextTypes = ["Text", "Image", "Audio", "Video"]//hiding while going live in phase 1
    const contextTypes = []
    return (
        <div className={classes.mainCustomContext}>
            <div className={classes.row1}>
                <Label labelText={`Context:`} fs={'0.7rem'} />
                <div>
                    {contextTypes.map((ele, index) => (
                        <FormControl key={index}>
                            <RadioGroup
                                className={classes.radioGroup}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                            >
                                <FormControlLabel value=""
                                    // disabled={ele !== "Text" ? true : false}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 15,
                                            color: ele === "Text" ? '#f4511e' : "#f4511e"
                                        },
                                    }}
                                    control={<Radio
                                        checked={value === index}
                                        // onChange={handleChange}
                                        // value={index.toString()}
                                        name="radio-buttons"
                                    // inputProps={{ 'aria-label': 'A' }}
                                    />} label={ele} />
                            </RadioGroup>
                        </FormControl>
                    ))}

                </div>
            </div>
            <div className={`${classes.customTextareaBox} ${isExceeded || isEmpty ? classes.error : ''} ${isDisabled ? classes.disabledStyle : ''}`}>
                <textarea disabled={isDisabled} defaultValue={defaultValue} maxLength={maxLength} type='text' onChange={handleContextChange} style={{ resize: 'none' }} />
                <span style={{ backgroundColor: isDisabled ? 'rgb(239,239,239,0.3)' : '#fff' }}>
                    {limitText}
                </span>
            </div>

        </div>
    )
}

export default CustomContext;


const useStyles = makeStyles(() => ({
    mainCustomContext: {
        borderBottom: '1px solid rgb(184, 184, 184, 0.4)',
        paddingBottom: '1.5rem'
    },
    row1: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.3rem',
        '& label': {
            width: '15%'
        },

    },
    radioGroup: {
        '& .MuiFormControlLabel-root': {
            margin: '0 0.8rem',
            '& .MuiTypography-root': {
                color: 'rgb(112, 112, 112, 0.8)',
                fontFamily: "Montserrat",
                fontSize: "0.69rem",
                fontWeight: "600",
            },
            '& .MuiButtonBase-root': {
                padding: '0.4rem',
                margin: '0 0.3rem'
            }
        }
    },
    customTextareaBox: {
        border: '2px solid rgb(184, 184, 184, 0.3)',
        borderRadius: '3px',
        display: 'flex',
        fontSize: "0.7rem",
        fontWeight: "500",
        '& textarea': {
            color: 'rgb(112, 112, 112, 0.9)',
            padding: '0.5rem',
            fontSize: "0.8rem",
            width: '86%',
            border: 'none',
            overflowY: 'hidden',
            '&:focus': {
                outline: 'none',
            },
        },

        '& span': {
            backgroundColor: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.28rem 0.5rem',
            width: '14%',
            minWidth: 'fit-content',
            fontWeight: 600,
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            color: '#EB3917',
        },

        '&:focus-within': {
            // outline: '2px solid #4F0060',
            border: '2px solid #4F0060',
        },
        '&:hover': {
            // outline: '2px solid #4F0060',
            border: '2px solid #4F0060',
        },
    },
    error: {
        // outline: '2px solid #EB3917 !important',
        border: '2px solid #EB3917 !important',
        '&:hover': {
            // outline: '2px solid #EB3917 !important',
            border: '2px solid #EB3917 !important',

        },
    },
    disabledStyle: {
        '&:hover': {
            outline: 'none !important',
        },
    }
}));