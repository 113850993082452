import { authFetchFormData } from "../utils/fetch";
import { authFetch } from "../utils/fetch";

const createNotification = async (data) => {
    try {
        const resData = await authFetch.post(`/notification`, data);
        return resData
    } catch (err) {
        return err.response
    }
}

const updateNotification = async (payload) => {

    try {
        const resData = await authFetch.put(`/notification`, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const deleteNotification = async (data) => {
    try {
        const resData = await authFetch.delete(`/notification`, { data });
        return resData
    } catch (err) {
        return err.response
    }
}

const getNotificationJobId = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/notification/launch`, data);
        return resData
    } catch (err) {
        return err.response
    }
}
const disableUsersNotification = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/notification/disable`, data);
        return resData
    } catch (err) {
        return err.response
    }
}

const launchUsingDemographics = async (payload) => {
    try {
        const resData = await authFetch.post(`/notification/launch-using-demographics`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const createNotificationByStatus = async (data) => {
    try {
        const resData = await authFetch.post(`/notification/launch-using-content-status`, data);
        return resData
    } catch (err) {
        return err.response
    }
}


export default {
    createNotification,
    updateNotification,
    deleteNotification,
    getNotificationJobId,
    disableUsersNotification,
    launchUsingDemographics,
    createNotificationByStatus,
}