import React from 'react';
import FileSelect from '../../components/tms-components/FileSelect';
import TextInput from '../../components/tms-components/TextInput';
import TextArea from '../../components/tms-components/TextArea';
import SimpleFormSelect from '../../components/tms-components/SimpleFormSelect';
import FlatPrimaryButton from '../../components/tms-components/FlatPrimaryButton';
import FormControl from '../../components/tms-components/FormControl';
import Label from '../../components/tms-components/Label';
import StyledDownloadLink from '../../components/tms-components/StyledDownloadLink';
// import { Dialog } from '@material-ui/core';
import Loader from '../../components/tms-components/Loader';
import Button2 from '../../components/tms-components/Button2';
import TopBarWrapper from '../../components/tms-components/TopBarWrapper';
import styled from 'styled-components';

//utils
import useQuery from '../../utils/useQuery';
import commonUtil from '../../utils/commonUtil';

//constants
import constants from '../../constants';

//services
import audienceServices from '../../services/audience';

//new
import Dialog from '@mui/material/Dialog';


export default function BatchLearnerUpload(props) {
  const query = useQuery();
  const companyId = query.get('c_id');
  const [loading, setLoading] = React.useState(false);
  const [progressText, setProgressText] = React.useState('Please wait...');
  const [errorText, setErrorText] = React.useState(
    'The CSV file is invalid or contains no learners, please try again!',
  );
  const [uploadFile, setUploadFile] = React.useState(null);
  const [batchName, setBatchName] = React.useState('');
  const [batchId, setBatchId] = React.useState(0);
  const [batchDescription, setBatchDescription] = React.useState('Batch description');
  const [progressDialog, setProgressDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);

  const isSammanCapital = companyId === process.env.REACT_APP_SAMMAN_CAPTIAL_COMPANY_ID;

  const uploadLearnerFileLink = isSammanCapital ? constants.SAMMAN_CAPITALS_BATCH_UPLOAD_FILE_FORMAT : constants.BATCH_UPLOAD_FILE_FORMAT;

  // effect to fetch training batches

  function onFileSelect(file) {
    setUploadFile(file[0]);
  }

  function runValidation() {
    if (uploadFile === null) {
      setErrorText('Please select the upload File');
      setFailureDialog(true);
      return false;
    }

    // commenting this code, because some browsers on Windows do not give the MIME type correctly
    // if(uploadFile.type.toLowerCase() !== "text/csv") {
    //   setErrorText("Please select a valid CSV file!");
    //   setFailureDialog(true);
    //   return false;
    // }

    if (batchId === 0 && batchName === '') {
      // user has selected both batch name and an existing batch
      setErrorText(
        'Please enter the Batch Name or select an existing Batch.',
      );
      setFailureDialog(true);
      return false;
    }

    //To check if both are changed - Only one should be chosen either new batch name or select existing batch
    if (batchId !== 0 && batchName !== '') {
      // user has selected both batch name and an existing batch
      setErrorText(
        'Please either type Batch Name or select an existing Batch.',
      );
      setFailureDialog(true);
      return false;
    }

    return true;
  }

  let batchOptions = props.batches.map((batch) => {
    let batch_name = batch.code;
    if (batch.name !== null) {
      batch_name += ' ' + batch.name;
    }
    return {
      id: batch.id,
      display_text: batch_name,
    };
  });


  let selectedBatchName = '';

  if (batchId !== 0) {
    let the_batch = props.batches.filter((b) => b.id === batchId);
    if (the_batch.length > 0) {
      let batch_name = the_batch[0].code;
      if (the_batch[0].name !== null) {
        batch_name += ' ' + the_batch[0].name;
      }
      selectedBatchName = batch_name;
    }
  } else {
    selectedBatchName = '';
  }


  const handleBatchUpload = async () => {
    let valid = runValidation();
    if (!valid) return;

    setProgressDialog(true);

    // determine if the CSV file is valid

    // let action_check_csv = props.MOClient.csv_batch_upload_learners_preflight(
    //   {
    //     token: props.jwtToken,
    //     file: uploadFile,
    //   },
    // );

    // action_check_csv.then((outcome) => {
    //   if (outcome.status !== 'success') {
    //     setProgressDialog(false);
    //     setErrorText(outcome.description);
    //     setFailureDialog(true);
    //     return;
    //   }

    // determine if we need to create a new batch and then create it
    let batch_id = batchId;

    if (batch_id === 0) {
      // new batch needs to be created

      const createBatchPayload = {
        companyId: companyId,
        name: batchName,
        description: batchDescription
      }


      const res = await audienceServices.createBatch(createBatchPayload);
      if (res.status === 200 || res.status === 201) {
        //here we have to upload batch learners file
        const batchId = res.data.data.id;
        const json = {
          companyId: companyId,
          batchId: batchId,
          addBatchLearnerFile: uploadFile
        }
        const addLearnersPayload = await commonUtil.jsonToFormData(json);
        let addLearnersResponse;

        if (isSammanCapital) {
          addLearnersResponse = await audienceServices.uploadLearnersToBatchUsingSAPIDs(addLearnersPayload);
        } else {
          addLearnersResponse = await audienceServices.uploadLearnersToBatch(addLearnersPayload);
        }

        if (addLearnersResponse.status === 200 || addLearnersResponse.status === 201) {
          setProgressDialog(false);
          setSuccessDialog(true);
        } else if (addLearnersResponse.status === 400) {
          //here batch creation api failed
          setProgressDialog(false);
          if (addLearnersResponse?.data?.errors[0]?.message) {
            setErrorText(addLearnersResponse.data.errors[0].message);
            setFailureDialog(true);
          }

        } else {
          console.log('failed to upload learners');
          setProgressDialog(false);
          setErrorText('Internal server error');
          setFailureDialog(true);
        }
      } else if (res.status === 400) {
        //here batch creation api failed
        setProgressDialog(false);
        if (res?.data?.errors[0]?.message) {
          setErrorText(res.data.errors[0].message);
          setFailureDialog(true);
        }

      } else {
        setProgressDialog(false);
        setErrorText('Internal server error');
        setFailureDialog(true);
        return;
      }
      return

      // OLD
      // let action_create_new_batch = props.MOClient.create_training_batch(
      //   {
      //     project_id: props.activeProject,
      //     token: props.jwtToken,
      //     data: {
      //       batch_name: batchName,
      //       batch_description: batchDescription,
      //     },
      //   },
      // );

      // action_create_new_batch.then((create_batch_outcome) => {
      //   if (create_batch_outcome.status !== 'success') {
      //     setProgressDialog(false);
      //     setFailureDialog(true);
      //     return;
      //   }

      //   batch_id = create_batch_outcome.data.batch_id;

      //   let o = {
      //     token: props.jwtToken,
      //     batch_id: batch_id,
      //     file: uploadFile,
      //   };

      //   let action = props.MOClient.csv_batch_upload_learners(o);

      //   action.then((outcome) => {
      //     setProgressDialog(false);

      //     if (outcome.status === 'success') {
      //       setSuccessDialog(true);
      //     } else {
      //       setFailureDialog(true);
      //     }
      //   });
      // });
    } else {
      //here we have to add learners to existing batch

      const json = {
        companyId: companyId,
        batchId: batchId,
        addBatchLearnerFile: uploadFile
      }
      const addLearnersPayload = await commonUtil.jsonToFormData(json);

      let addLearnersResponse;
      if (isSammanCapital) {
        addLearnersResponse = await audienceServices.uploadLearnersToBatchUsingSAPIDs(addLearnersPayload);
      }
      else {
        addLearnersResponse = await audienceServices.uploadLearnersToBatch(addLearnersPayload);
      }
      if (addLearnersResponse.status === 200 || addLearnersResponse.status === 201) {
        setProgressDialog(false);
        setSuccessDialog(true);
      } else if (addLearnersResponse.status === 400) {
        //here batch creation api failed
        setProgressDialog(false);
        if (addLearnersResponse?.data?.errors[0]?.message) {
          setErrorText(addLearnersResponse.data.errors[0].message);
          setFailureDialog(true);
        }

      } else {
        console.log('failed to upload learners');
        setProgressDialog(false);
        setErrorText('Internal server error');
        setFailureDialog(true);
      }

      //OLD
      // let o = {
      //   token: props.jwtToken,
      //   batch_id: batch_id,
      //   file: uploadFile,
      // };

      // let action = props.MOClient.csv_batch_upload_learners(o);

      // action.then((outcome) => {
      //   setProgressDialog(false);

      //   if (outcome.status === 'success') {
      //     setSuccessDialog(true);
      //   } else {
      //     setErrorText(outcome.description);
      //     setFailureDialog(true);
      //   }
      // });
      return
    }
  }

  const handleCreateTrainingSession = () => {
    props.history.push(`/drive/training/create-new-training-session?c_id=${companyId}`);
  }


  return (
    <div style={{ textAlign: 'left' }}>
      <FormControl>
        <Label>Upload File <span style={{ color: 'red' }}>*</span></Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FileSelect onFileSelect={onFileSelect} />
          <StyledDownloadLink
            href={uploadLearnerFileLink}
            style={{ marginLeft: 10 }}>
            Download File Format
          </StyledDownloadLink>
        </div>

        <p style={{ fontSize: '0.85rem', color: '#707070', margin: '1rem 0' }}>
          Mandatory Fields:
        </p>
        <ul style={{ fontSize: '0.85rem', color: '#707070', marginLeft: '2rem' }}>
          {/* <li>First Name</li>
          <li>Last Name</li> */}
          {isSammanCapital ? <li>SAP ID</li> : <li>Username</li>}
        </ul>
      </FormControl>

      <FormControl>
        <Label>New Batch Name <span style={{ color: 'red' }}>*</span></Label>
        <TextInput value={batchName} onChangeText={(t) => setBatchName(t)} />
      </FormControl>

      {/* Commented for now - 19-04-2023 */}

      <FormControl>
        <Label>OR</Label>
        <Label>Select Existing Batch</Label>
        <SimpleFormSelect
          autoClose={true}
          options={[{ id: 0, display_text: 'None' }, ...batchOptions]}
          onSelect={(id) => {
            setBatchId(id);
          }}
          text={selectedBatchName}
        />
      </FormControl>

      {/*<FormControl>*/}
      {/*  <Label>Batch Description (optional)</Label>*/}
      {/*  <TextArea onChange={(text)=>{*/}
      {/*    setBatchDescription(text);*/}
      {/*  }}></TextArea>*/}
      {/*</FormControl>*/}

      <FormControl style={{ marginTop: '15px' }}>
        <FlatPrimaryButton
          onClick={handleBatchUpload}>
          Batch Upload
        </FlatPrimaryButton>
      </FormControl>

      {/* Progress Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialog}
        onClose={() => {
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />

          <ProgressText>{progressText}</ProgressText>
        </ProgressContainer>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={successDialog}
        onClose={() => {
          setSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/tick.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Learners Uploaded Successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={handleCreateTrainingSession}>
              Create a Training Session
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Failure Dialog */}
      <Dialog
        open={failureDialog}
        onClose={() => {
          setFailureDialog(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorText}</ProgressText>
          </div>

          <Button2 onClick={() => setFailureDialog(false)}>Close</Button2>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
