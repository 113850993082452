import animationData from '../lotties/index'
import { TEXT_TO_SPEECH_VOICES, TEXT_TO_SPEECH_LANGUAGES } from './text-to-speech.constants';

const HEADER_HEIGHT = '3.4rem';
const DRAWER_WIDTH = '65px';
const X_API_KEY = "rxXOaUzpRCVd7blaUTBLFh6ziGrb14goBn7XdJa5Z80ZNMQxnS7Mdhejgl2Jw77u469cYKSkvX14M0TXX6ICZANAwswgNPhOeWApDULyI3Yj9MTTuNCClKQapa6z5MOo";
const USER_LAUNCH_FILE_FORMAT = "https://masterov4-excel-uploads.s3.ap-south-1.amazonaws.com/user-upload-template/addNewUserMasterO.xlsx";
const BATCH_UPLOAD_FILE_FORMAT = "https://masterov4-excel-uploads.s3.ap-south-1.amazonaws.com/user-upload-template/addNewUserInBatch.xlsx";
const SAMMAN_CAPITALS_BATCH_UPLOAD_FILE_FORMAT = "https://masterov4-excel-uploads.s3.ap-south-1.amazonaws.com/user-upload-template/addUserInBatchUsingSapIds.xlsx";

const GBA_GAMETYPE = {
    MCQ_TikiMonkey: "MCQ",
    MCQ_MasterBlaster: "MCQ",
    MCQ_Football: "MCQ",
    MCQ_StarArcadia: "MCQ",
    MTRR_PopcornMatch: "MTRR",
    MTRR_TrashTrivia: "MTRR",
    MTRR_CrackerMania: "MTRR",
    MTRR_LearnoCasino: "MTRR",
    MTRR_BucketBlast: "MTRR",
    MA_SpaceRocks: "MA",
    MA_BalloonPop: "MA",
    MA_WordSearch: "MA",
    MA_RevealThePicture: "MA",
    TF_CrazyCars: "TF",
    TF_BuildingBlocks: "TF",
    TF_TastyTruth: "TF",
    TF_SoundRight: "TF",
}

const GBA_SCREENS_OLD = {
    MCQ_TikiMonkey: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Tiki-monkeys.png',
    MCQ_MasterBlaster: '/images/gba-assets/MCQ/master-blaster-screen.png',
    MCQ_StarArcadia: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Star-arcadia.png',
    MTRR_PopcornMatch: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Popcorn-match.png',
    MTRR_TrashTrivia: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Trash-trivia.png',
    MTRR_CrackerMania: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Cracker-mania.png',
    MTRR_LearnoCasino: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Learn-o-casino.png',
    MTRR_BucketBlast: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/bucket-blast-studio.png',
    MA_SpaceRocks: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Space-rocks.png',
    MA_BalloonPop: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Baloon-pop.png',
    MA_WordSearch: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Wordsearch.png',
    MA_RevealThePicture: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Reveal-the-picture.png',
    TF_CrazyCars: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Crazy-cars.png',
    TF_BuildingBlocks: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Building-blocks.png',
    TF_TastyTruth: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Building-blocks.png',
    TF_SoundRight: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Sounds-right.png',
}

const GBA_SCREENS = {
    MCQ_TikiMonkey: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/TikiMonkeys.png',
    MCQ_MasterBlaster: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/MasterBlaster.png',
    MCQ_Football: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/Football.png',
    MCQ_StarArcadia: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/StarArcadia.png',
    MTRR_PopcornMatch: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/PopcornMatch.png',
    MTRR_TrashTrivia: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/TrashTrivia.png',
    MTRR_CrackerMania: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/CrackerMania.png',
    MTRR_LearnoCasino: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/LearnOCasino.png',
    MTRR_BucketBlast: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/bucket-blast-studio.png',
    MA_SpaceRocks: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/SpaceRocks.png',
    MA_BalloonPop: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/BalloonPop.png',
    MA_WordSearch: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/GalacticWordsearch.png',
    MA_RevealThePicture: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/RevealThePicture.png',
    TF_CrazyCars: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/CrazyCars.png',
    TF_BuildingBlocks: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/BuildingBlocks.png',
    TF_TastyTruth: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/TastyTruth.png',
    TF_SoundRight: 'https://masterov4-media-files.s3.ap-south-1.amazonaws.com/studiohelperimages/SoundsRight.png',
}

const GBA_GAMETYPE_DATA = [
    {
        type: "MCQ",
        challenges: [
            {
                name: 'tiki-monkeys',
                designType: "MCQ_TikiMonkey",
                aboutTitle: 'Tiki Monkeys',
                bgImgUrl: '/images/gba-assets/MCQ/tiki-monkeys.png',
                screen: GBA_SCREENS["MCQ_TikiMonkey"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MCQ",
                    "designType": "MCQ_TikiMonkey",
                    "maxScore": 120,
                    "skills": [],
                    "questions": []
                }
            },
            {
                name: 'master-blaster',
                designType: "MCQ_MasterBlaster",
                aboutTitle: 'Master Blaster',
                bgImgUrl: '/images/gba-assets/MCQ/master-blaster.png',
                screen: GBA_SCREENS["MCQ_MasterBlaster"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MCQ",
                    "designType": "MCQ_MasterBlaster",
                    "maxScore": 120,
                    "skills": [],
                    "questions": []
                }
            },
            {
                name: 'football',
                designType: "MCQ_Football",
                aboutTitle: 'Goal Rush',
                bgImgUrl: '/images/gba-assets/MCQ/football.png',
                screen: GBA_SCREENS["MCQ_Football"],
                banner: '/images/icons/beta-launch.png',
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MCQ",
                    "designType": "MCQ_Football",
                    "maxScore": 120,
                    "skills": [],
                    "questions": []
                }
            },
            //HIDDEN FOR FIRST PHASE
            // {
            //     name: 'star-arcadia',
            //     designType: "MCQ_StarArcadia",
            //     aboutTitle: 'Star Arcadia',
            //     bgImgUrl: '/images/gba-assets/MCQ/star-arcadia.png',
            //     screen: GBA_SCREENS["MCQ_StarArcadia"],
            //     banner: null,
            //     json: {
            //         "companyId": "",
            //         "microskillId": "",
            //         "type": "GBA",
            //         "gameType": "MCQ",
            //         "designType": "MCQ_StarArcadia",
            //         "image": "",
            //         "maxScore": 120,
            //         "skills": [],
            //         "questions": []
            //     }
            // }

        ]
    },
    {
        type: "MTRR",
        challenges: [
            {
                name: 'popcorn-match',
                designType: "MTRR_PopcornMatch",
                aboutTitle: 'Popcorn Match',
                bgImgUrl: '/images/gba-assets/MTRR/popcorn-match.png',
                screen: GBA_SCREENS["MTRR_PopcornMatch"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MTRR",
                    "designType": "MTRR_PopcornMatch",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }

            },
            {
                name: 'trash-trivia',
                designType: "MTRR_TrashTrivia",
                aboutTitle: 'Trash Trivia',
                bgImgUrl: '/images/gba-assets/MTRR/trash-trivia.png',
                screen: GBA_SCREENS["MTRR_TrashTrivia"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MTRR",
                    "designType": "MTRR_TrashTrivia",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            },
            {
                name: 'cracker-mania',
                designType: "MTRR_CrackerMania",
                aboutTitle: 'Cracker Mania',
                bgImgUrl: '/images/gba-assets/MTRR/cracker-mania.png',
                screen: GBA_SCREENS["MTRR_CrackerMania"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MTRR",
                    "designType": "MTRR_CrackerMania",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            },
            {
                name: 'learn-o-casino',
                designType: "MTRR_LearnoCasino",
                aboutTitle: 'Learn-O Casino',
                bgImgUrl: '/images/gba-assets/MTRR/learn-o-casino.png',
                screen: GBA_SCREENS["MTRR_LearnoCasino"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MTRR",
                    "designType": "MTRR_LearnoCasino",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            },
            {
                name: 'Bucket Blast',
                designType: "MTRR_BucketBlast",
                aboutTitle: 'Bucket Blast',
                bgImgUrl: '/images/gba-assets/MTRR/bucket-blast.png',
                screen: GBA_SCREENS["MTRR_BucketBlast"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MTRR",
                    "designType": "MTRR_BucketBlast",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            }
        ]
    },
    {
        type: "MA",
        challenges: [
            {
                name: 'space-rocks',
                designType: "MA_SpaceRocks",
                aboutTitle: 'Space Rocks',
                bgImgUrl: '/images/gba-assets/MA/space-rocks.png',
                screen: GBA_SCREENS["MA_SpaceRocks"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MA",
                    "designType": "MA_SpaceRocks",
                    "maxScore": 120,
                    "skills": [],
                    "questions": []
                }
            },
            {
                name: 'balloon-pop',
                designType: "MA_BalloonPop",
                aboutTitle: 'Balloon Pop',
                bgImgUrl: '/images/gba-assets/MA/balloon-pop.png',
                screen: GBA_SCREENS["MA_BalloonPop"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MA",
                    "designType": "MA_BalloonPop",
                    "maxScore": 120,
                    "skills": [],
                    "questions": []
                }
            },
            //HIDDEN FOR FIRST PHASE
            // {
            //     name: 'galactic-word-search',
            //     designType: "MA_WordSearch",
            //     aboutTitle: 'Galactic Wordsearch',
            //     bgImgUrl: '/images/gba-assets/MA/galactic-word-search.png',
            //     screen: GBA_SCREENS["MA_WordSearch"],
            //     banner: null,
            //     json: {
            //         "companyId": "",
            //         "microskillId": "",
            //         "type": "GBA",
            //         "gameType": "MA",
            //         "designType": "MA_WordSearch",
            //         "maxScore": 120,
            //         "skills": [],
            //         "questions": []
            //     }
            // },
            {
                name: 'reveal-the-picture',
                designType: "MA_RevealThePicture",
                aboutTitle: 'Reveal The Picture',
                bgImgUrl: '/images/gba-assets/MA/reveal-the-picture.png',
                screen: GBA_SCREENS["MA_RevealThePicture"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "MA",
                    "designType": "MA_RevealThePicture",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            }
        ]
    },
    {
        type: "TF",
        challenges: [
            {
                name: 'crazy-cars',
                designType: "TF_CrazyCars",
                aboutTitle: 'Crazy Cars',
                bgImgUrl: '/images/gba-assets/TF/crazy-cars.png',
                screen: GBA_SCREENS["TF_CrazyCars"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "TF",
                    "designType": "TF_CrazyCars",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            },
            {
                name: 'building-blocks',
                designType: "TF_BuildingBlocks",
                aboutTitle: 'Building Blocks',
                bgImgUrl: '/images/gba-assets/TF/building-blocks.png',
                screen: GBA_SCREENS["TF_BuildingBlocks"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "TF",
                    "designType": "TF_BuildingBlocks",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            },
            {
                name: 'tasty-truth',
                designType: "TF_TastyTruth",
                aboutTitle: 'Tasty Truth',
                bgImgUrl: '/images/gba-assets/TF/tasty-truth.png',
                screen: GBA_SCREENS["TF_TastyTruth"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "TF",
                    "designType": "TF_TastyTruth",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            },
            {
                name: 'sounds-right',
                designType: "TF_SoundRight",
                aboutTitle: 'Sounds Right',
                bgImgUrl: '/images/gba-assets/TF/sounds-right.png',
                screen: GBA_SCREENS["TF_SoundRight"],
                banner: null,
                json: {
                    "companyId": "",
                    "microskillId": "",
                    "type": "GBA",
                    "gameType": "TF",
                    "designType": "TF_SoundRight",
                    "maxScore": 120,
                    "skills": [],
                    "contexts": [],
                    "questions": []
                }
            }
        ]
    },
]

const MCQ_MIN_QUESTIONS = 5;
const TF_MIN_QUESTIONS = 5;
const MTRR_MIN_QUESTIONS = 2;
const MA_MIN_QUESTIONS = 2;

const MCQ_MAX_QUESTIONS = 200; //ORG = 10, NEW = 200
const TF_MAX_QUESTIONS = 200; //ORG = 10, NEW = 200
const MTRR_MAX_QUESTIONS = 200;//ORG = 3, NEW = 200
const MA_MAX_QUESTIONS = 200;//ORG = 3, NEW = 200

const MCQ_MAX_QUESTIONS_FOR_APP = 10;
const TF_MAX_QUESTIONS_FOR_APP = 10;
const MTRR_MAX_QUESTIONS_FOR_APP = 3;
const MA_MAX_QUESTIONS_FOR_APP = 3;

const GBA_VALIDATIONS = {
    MCQ_TikiMonkey: {
        questionMaxLength: 110,
        responseMaxLength: 60,
        minQuestionsRequired: MCQ_MIN_QUESTIONS, //10
        maxQuestions: MCQ_MAX_QUESTIONS,
        appMaxQuestions: MCQ_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 1
    },
    MCQ_MasterBlaster: {
        questionMaxLength: 110,
        responseMaxLength: 60,
        minQuestionsRequired: MCQ_MIN_QUESTIONS, //10
        maxQuestions: MCQ_MAX_QUESTIONS,
        appMaxQuestions: MCQ_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 1
    },
    MCQ_Football: {
        questionMaxLength: 110,
        responseMaxLength: 60,
        minQuestionsRequired: MCQ_MIN_QUESTIONS, //10
        maxQuestions: MCQ_MAX_QUESTIONS,
        appMaxQuestions: MCQ_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 1
    },
    MCQ_StarArcadia: {
        questionMaxLength: 110,
        responseMaxLength: 50,
        minQuestionsRequired: MCQ_MIN_QUESTIONS, //10
        maxQuestions: MCQ_MAX_QUESTIONS,
        appMaxQuestions: MCQ_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 1
    },
    TF_SoundRight: {
        contextMaxLength: 85,
        questionMaxLength: 85,
        responseMaxLength: 30,
        minQuestionsRequired: TF_MIN_QUESTIONS, //10
        maxQuestions: TF_MAX_QUESTIONS,
        appMaxQuestions: TF_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 1
    },
    TF_CrazyCars: {
        contextMaxLength: 500,
        questionMaxLength: 500,
        // responseMaxLength : 60,
        minQuestionsRequired: TF_MIN_QUESTIONS, //10,
        maxQuestions: TF_MAX_QUESTIONS,
        appMaxQuestions: TF_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 1
    },
    TF_BuildingBlocks: {
        contextMaxLength: 500,
        questionMaxLength: 500,
        // responseMaxLength : 60,
        minQuestionsRequired: 10,
        maxQuestions: TF_MAX_QUESTIONS, //10
        appMaxQuestions: TF_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 1
    },
    TF_TastyTruth: {
        contextMaxLength: 500,
        questionMaxLength: 500,
        // responseMaxLength : 60,
        minQuestionsRequired: TF_MIN_QUESTIONS, //10
        appMaxQuestions: TF_MAX_QUESTIONS_FOR_APP,
        maxQuestions: TF_MAX_QUESTIONS,
        minCheckedRequired: 1
    },
    MTRR_PopcornMatch: {
        contextMaxLength: 85,
        questionMaxLength: 66,
        responseMaxLength: 45,
        minQuestionsRequired: MTRR_MIN_QUESTIONS, //15
        appMaxQuestions: MTRR_MAX_QUESTIONS_FOR_APP,
        maxQuestions: MTRR_MAX_QUESTIONS,
    },
    MTRR_TrashTrivia: {
        contextMaxLength: 85,
        questionMaxLength: 66,
        responseMaxLength: 45,
        minQuestionsRequired: MTRR_MIN_QUESTIONS, //15
        appMaxQuestions: MTRR_MAX_QUESTIONS_FOR_APP,
        maxQuestions: MTRR_MAX_QUESTIONS,
    },
    MTRR_CrackerMania: {
        contextMaxLength: 85,
        questionMaxLength: 66,
        responseMaxLength: 45,
        minQuestionsRequired: MTRR_MIN_QUESTIONS, //15
        appMaxQuestions: MTRR_MAX_QUESTIONS_FOR_APP,
        maxQuestions: MTRR_MAX_QUESTIONS
    },
    MTRR_LearnoCasino: {
        contextMaxLength: 85,
        questionMaxLength: 66,
        responseMaxLength: 45,
        minQuestionsRequired: MTRR_MIN_QUESTIONS, //15
        appMaxQuestions: MTRR_MAX_QUESTIONS_FOR_APP,
        maxQuestions: MTRR_MAX_QUESTIONS
    },
    MTRR_BucketBlast: {
        contextMaxLength: 85,
        questionMaxLength: 66,
        responseMaxLength: 45,
        minQuestionsRequired: MTRR_MIN_QUESTIONS, //15
        appMaxQuestions: MTRR_MAX_QUESTIONS_FOR_APP,
        maxQuestions: MTRR_MAX_QUESTIONS
    },
    MA_SpaceRocks: {
        questionMaxLength: 85,
        responseMaxLength: 63,
        minQuestionsRequired: MA_MIN_QUESTIONS, //3
        maxQuestions: MA_MAX_QUESTIONS,
        appMaxQuestions: MA_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 4
    },
    MA_BalloonPop: {
        questionMaxLength: 85,
        responseMaxLength: 63,
        minQuestionsRequired: MA_MIN_QUESTIONS, //3
        maxQuestions: MA_MAX_QUESTIONS,
        appMaxQuestions: MA_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 4
    },
    MA_RevealThePicture: {
        questionMaxLength: 85,//here context is equal to question
        responseMaxLength: 63,
        minQuestionsRequired: MA_MIN_QUESTIONS, //3
        maxQuestions: MA_MAX_QUESTIONS,
        appMaxQuestions: MA_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 4
    },
    MA_WordSearch: {
        questionMaxLength: 85,
        responseMaxLength: 63,
        minQuestionsRequired: MA_MIN_QUESTIONS, //3
        maxQuestions: MA_MAX_QUESTIONS,
        appMaxQuestions: MA_MAX_QUESTIONS_FOR_APP,
        minCheckedRequired: 4
    }
}

const GBA_CSV_FORMATS = {
    MCQ: "/files/gba-csv-formats/multiple-choice-questions-csv-format.csv",
    MA: "/files/gba-csv-formats/multiple-answers-csv-format.csv",
    MTRR: "/files/gba-csv-formats/match-the-right-response-csv-format.csv",
    TF: "/files/gba-csv-formats/true-false-csv-format.csv",
    TF_SoundRight: "/files/gba-csv-formats/sounds-right-csv-format.csv",
    MTRR_LearnoCasino: "/files/gba-csv-formats/learnocasino-csv-format.csv",
}

const GAME_DESIGN_TYPE_GBA = {
    "Crazy Cars": "TF_CrazyCars",
    "Building Blocks": "TF_BuildingBlocks",
    "Tasty Truth": "TF_TastyTruth",
    "Sounds Right": "TF_SoundRight",
    "Tiki Monkeys": "MCQ_TikiMonkey",
    "Star Arcadia": "MCQ_StarArcadia",
    "Master Blaster": "MCQ_MasterBlaster",
    "Popcorn Match": "MTRR_PopcornMatch",
    "Trash Trivia": "MTRR_TrashTrivia",
    "Cracker Mania": "MTRR_CrackerMania",
    "Learn-O Casino": "MTRR_LearnoCasino",
    "Bucket Blast": "MTRR_BucketBlast",
    "Space Rocks": "MA_SpaceRocks",
    "Balloon Pop": "MA_BalloonPop",
    "Reveal The Picture": "MA_RevealThePicture",
    "Galactic Wordsearch": "MA_WordSearch",
}

export const DEMO_COMP_ID = "compy_TKJDlfhBJohJMXDnICr";
export const MAHINDRA_ID = "compy_1fXD9imeeDyQxfdHOhq";
const QA_DEMO_COMP_ID = "compy_AIMVgdypvL62dTcghkr";
const ABSLI_ID = "compy_20oO4UKabHzmKobN3Zp";
const IFL_ID = "compy_hWp5qK4Sdwvj7U90A3B"
const ABCDL_ID = "compy_hoU0tU12kzMo56wfLmB"
const ABHI_ID = "compy_lRnSXrifKiXdQso58lS"

const HAS_TMS_ACCESS = [DEMO_COMP_ID, IFL_ID];
const HAS_SPOTLIGHT_ACCESS = [DEMO_COMP_ID, ABSLI_ID, QA_DEMO_COMP_ID];
const SPOTLIGHT_CONTEXTUAL_MATCH_ACCESS = [DEMO_COMP_ID, ABSLI_ID, QA_DEMO_COMP_ID];
const RESTRICT_GBA_FOR = [ABSLI_ID, ABCDL_ID, ABHI_ID];
const RESTRICT_LA_FOR = [ABSLI_ID, ABCDL_ID, ABHI_ID];

const OTHER_REPORTS_TABS_OBJECT = {
    MS_ATTEMPT_LEVEL: 9,//MS_ATTEMPT_LEVEL
    MS_FEEDBACK: 10,//MS_FEEDBACK
    ACTIVE_INACTIVE_USER: 11,//ACTIVE_INACTIVE_USER
    COMPLETION_WITH_TIMESPENT: 12,//COMPLETION_WITH_TIMESPENT
    USER_ACTIVITY: 13,//USER_ACTIVITY
    PRE_POST_COMPLETION_ATTEMPTS: 14,//PRE_POST_COMPLETION_ATTEMPTS
    FIRST_TIME_COMPLETION: 15,//FIRST_TIME_COMPLETION
    LICENCE_REPORT: 16,//LICENCE_REPORT
    SPOTLIGHT_ATTEMPT_REPORT: 17,//SPOTLIGHT_ATTEMPT_REPORT
    DISABLED_MICROSKILL_REPORT: 18,//DISABLED_MICROSKILL_REPORT
    LEARNING_JOURNEY_COMPLETION_REPORT: 19,//LEARNING_JOURNEY_COMPLETION_REPORT
}

const CUSTOM_REPORTS_TAB_VALUE = 20;

const OTHER_REPORTS_TABS = Object.values(OTHER_REPORTS_TABS_OBJECT);
const OTHER_REPORTS_ROLES = Object.keys(OTHER_REPORTS_TABS_OBJECT);

const DRAWER_MENU = [
    {
        id: '1',
        url: '/projects',
        value: 'home',
        title: 'Home',
        restricted: true,
        allowedRoles: ['PROJECTS_MANAGER'],
        icon: '/images/side-menu-icons/Home-w.svg',
        collapseMenu: []
    },
    {
        id: '2',
        url: '',
        value: 'content',
        title: 'Content',
        restricted: true,
        allowedRoles: ['CONTENT_CREATOR'],
        icon: '/images/side-menu-icons/Studio-w.svg',
        collapseMenu: [
            {
                title: 'Microskills',
                name: 'content',
                url: '/manage-microskills',
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Library',
                name: 'content',
                url: '/documents',
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Content Launch',
                name: 'content',
                url: '/user-launch',
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Groups',
                name: 'content',
                url: '/groups',
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Copy Microskill',
                name: 'content',
                url: '/copy-microskill',
                hasAccess: ['superAdmin']
            },
        ]
    },
    {
        id: '3',
        url: '',
        value: 'user-management',
        title: 'User Management',
        restricted: true,
        allowedRoles: ['USERS_MANAGER'],
        icon: '/images/side-menu-icons/Learner Management-w.svg',
        collapseMenu: [
            {
                title: 'Add User',
                name: 'user-management',
                url: '/add-user',
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Manage Users',
                name: 'user-management',
                url: '/manage-user',
                restricted: true,
                allowedRoles: ['MANAGE_USERS'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'User Details',
                name: 'user-management',
                url: '/user-details',
                restricted: true,
                allowedRoles: ['ALL_USER_LIST'],
                hasAccess: ['superAdmin', 'customerSupport', 'companyAdmin']
            }
        ]
    },
    {
        id: '4',
        url: '',
        value: 'training',
        title: 'Training',
        restricted: true,
        allowedRoles: ['TMS_TRAINER'],
        icon: '/images/side-menu-icons/Training Management System-w.svg',
        collapseMenu: [
            {
                title: 'Batch',
                name: 'training',
                url: '/drive/audience/manage-learners',
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Course',
                name: 'training',
                url: '/drive/courses-and-training/course-log',
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Training',
                name: 'training',
                url: '/drive/training/training-log',
                hasAccess: ['superAdmin', 'companyAdmin']
            },

        ]
    },
    {
        id: '5',
        url: '',
        value: 'engagement',
        title: 'Engagement',
        restricted: true,
        allowedRoles: ['FORM_MANAGER'],
        icon: '/images/side-menu-icons/Engagement-w.svg',
        collapseMenu: [
            {
                title: 'Forms',
                name: 'engagement',
                url: '/add-forms',
                restricted: true,
                allowedRoles: ['FORM_MANAGER'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Bulletin Board',
                name: 'engagement',
                url: '/manage-bulletin',
                restricted: false,
                allowedRoles: [],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Notification',
                name: 'engagement',
                // url: '/coming-soon',
                url: '/push-notifications',
                restricted: true,
                allowedRoles: ['POST_NOTIFICATION'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
        ]
    },

    {
        id: '6',
        url: '',
        value: 'analytics',
        title: 'Analytics',
        restricted: true,
        allowedRoles: ['ANALYTICS_MANAGER'],
        icon: '/images/side-menu-icons/Analytics-w.svg',
        collapseMenu: [
            {
                title: 'Login and Completion',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: 1,
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            // {
            //     title: 'Learning Engagement',
            //     name: 'analytics',
            //     url: '/coming-soon',
            //     tabValue: 3,
            //     hasAccess: ['superAdmin', 'companyAdmin']
            // },
            // {
            //     title: 'Learning Effectiveness',
            //     name: 'analytics',
            //     url: '/coming-soon',
            //     tabValue: 4,
            //     hasAccess: ['superAdmin', 'companyAdmin']
            // },
            {
                title: 'Feedback',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: 5,
                restricted: true,
                allowedRoles: ['TMS_ANALYTICS'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Skill Gap',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: 6,
                restricted: true,
                allowedRoles: ['SKILLGAP_REPORT'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Tough Question Analysis',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: 7,
                restricted: true,
                allowedRoles: ['TQA_REPORT'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Leaderboard',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: 8,
                restricted: true,
                allowedRoles: ['LEADERBOARD_REPORT'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Other Reports',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: 9,
                restricted: true,
                allowedRoles: ['OTHER_REPORTS'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Custom Reports',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: CUSTOM_REPORTS_TAB_VALUE,
                restricted: true,
                allowedRoles: ['CUSTOM_REPORTS'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'All Report Templates',
                name: 'analytics',
                // url: '/coming-soon',
                url: '/analytics',
                tabValue: 21,
                restricted: true,
                allowedRoles: ['CUSTOM_REPORTS'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
            {
                title: 'Custom Report History',
                name: 'analytics',
                url: '/analytics',
                tabValue: 22,
                restricted: true,
                allowedRoles: ['CUSTOM_REPORTS'],
                hasAccess: ['superAdmin', 'companyAdmin']
            },
        ]
    },
    {
        id: '7',
        url: '/login',
        value: 'logout',
        title: 'Logout',
        restricted: false,
        icon: '/images/side-menu-icons/Logout.svg',
        collapseMenu: []
    },
];

const DRAWER_STYLE = {
    display: {
        sm: 'none',
        md: 'block'
    }
};

const CHALLENGE_DATA = [
    {
        url: '/microskill-studio/learning-aid',
        imgUrl: '/images/icons/businessman.svg',
        displayName: 'Learning Aid',
        challengeName: 'LA',
        detail: "A collection of advanced, dynamic and extremely flexible learning aids, for the best retention possibilities and deeper learning possibilities."
    },
    {
        url: '/microskill-studio/game-based-assessment',
        imgUrl: '/images/icons/joystick.svg',
        displayName: 'Game-based Assessment',
        challengeName: 'GBA',
        detail: "Gamified Assessments to test the knowledge of the learners in a fun and engaging manner which they can take in their downtime"
    },
    {
        url: '/microskill-studio/spotlight',
        imgUrl: '/images/icons/spotlight.png',
        displayName: 'Spotlight',
        challengeName: 'SL',
        detail: "Video based self coaching with deep AI capabilities to provide conversational analytics and deeper insights into client meetings in realtime"
    },
    {
        url: '/microskill-studio/scorm',
        imgUrl: '/images/icons/scorm.svg',
        displayName: 'SCORM',
        challengeName: 'SCORM',
        detail: "Add any SCORM package and showcase it on the mobile app, web platform or both. SCORM version 1.2 is currently supported"
    },
    {
        url: '/microskill-studio/form-based-challenge',
        imgUrl: '/images/icons/forms-studio.svg',
        displayName: 'FORMS',
        challengeName: 'FORM',
        detail: "Traditional Assesments that can contain a mix of different question and response type. Suitable when flexibility is required"
    },
    {
        url: '/microskill-studio/qr-based-challenge',
        imgUrl: '/images/icons/qr-based.png',
        displayName: 'QR Code for feedback',
        challengeName: 'QR_CODE',
        detail: "Add any external form link and mark submission automatically on learner app"
    }
];

const GBA_SETTINGS = [
    {
        titleText: 'Maximum Score:',
        type: 'input',
        defaultValue: '120',
        isEdit: false,
        key: 1,
    },
    {
        titleText: 'Score Per Question:',
        type: 'input',
        defaultValue: '12',
        isEdit: false,
        key: 2,
    },
    {
        titleText: 'Gameplay Speed:',
        type: 'input',
        defaultValue: 'Normal',
        isEdit: false,
        key: 3,
    },
    {
        titleText: 'Total Time:',
        type: 'input',
        defaultValue: '120 Seconds',
        isEdit: false,
        key: 4,
    },
    {
        titleText: 'Maximum Lives:',
        type: 'input',
        defaultValue: '5',
        isEdit: false,
        key: 5,
    },
    {
        titleText: 'Show Answers After Incorrect Attempts:',
        type: 'input',
        defaultValue: 'null',
        isEdit: true,
        key: 6,
    },
    // {
    //     titleText: 'Question Sequence:',
    //     type: 'radio',
    //     options: ['Random', 'Sequence'],
    //     defaultValue: 'Sequence'
    // }

]

const LA_DESIGN_CODES = {
    imageTitleBody: 'ITB',
    videoTitleBody: 'VTB',
    videoOnly: 'V',
    imageOnly: 'I',
    cover: 'COVER',
    objective: 'OBJECTIVE',
    titleBody: 'TB',
    body: 'B',
}

const LA_VALIDATIONS = {
    COVER: {
        title: 45,
    },
    OBJECTIVE: {
        title: 45,
        body: 200
    },
    TB: {
        title: 50,
        body: 240
    },
    B: {
        body: 320
    },
    ITB: {
        title: 60,
        body: 200
    },
    VTB: {
        title: 50,
        body: 240
    },

}

//INCLUDES HIDDEN CARDS FOR LA
const LA_CARD_TYPE = [
    {
        title: 'COVER & OBJECTIVE',
        data: [
            {
                cardType: 'cover',
                designCode: 'COVER',
                animation: animationData.AimAndShoot,
                image: ''
            },
            {
                cardType: 'objective',
                designCode: 'OBJECTIVE',
                animation: animationData.Comp,
                image: ''
            }
        ]

    },
    //HIDDEN FOR FIRST PHASE
    // {
    //     title: 'SECTION BREAK',
    //     data: [
    //         {
    //             name: 'sectionBreak 1',
    //         },
    //         {
    //             name: 'sectionBreak 2',
    //         },
    //         {
    //             name: 'sectionBreak 3',
    //         },
    //         {
    //             name: 'sectionBreak 4',
    //         },
    //     ]

    // },
    {
        title: 'ONLY TEXT',
        data: [
            {
                cardType: 'titleBody',
                designCode: 'TB',
                animation: animationData.Comp,
                image: '/images/learning-aid-assets/card-type/title-body.svg'

            },
            {
                cardType: 'body',
                designCode: 'B',
                animation: animationData.Comp,
                image: '/images/learning-aid-assets/card-type/body-only.svg'
            }
        ]

    },
    {
        title: 'IMAGE LAYOUTS',
        data: [
            {
                cardType: 'imageOnly',
                designCode: 'I',
                animation: animationData.Comp,
                image: '/images/learning-aid-assets/card-type/image-only.svg'
            },
            {
                cardType: 'imageTitleBody',
                designCode: 'ITB',
                animation: animationData.MtrrRotate,
                image: '/images/learning-aid-assets/card-type/image-title-body.svg'
            }

        ]

    },
    {
        title: 'VIDEO LAYOUTS',
        data: [
            {
                cardType: 'videoOnly',
                designCode: 'V',
                animation: animationData.FillInTheBlank,
                image: '/images/learning-aid-assets/card-type/video-only-la.svg'
            },
            {
                cardType: 'videoTitleBody',
                designCode: 'VTB',
                animation: animationData.FillInTheBlank,
                image: '/images/learning-aid-assets/card-type/video-title-body.svg'
            },
        ]
    },
    //HIDDEN FOR FIRST PHASE
    // {
    //     title: 'STATEMENTS & QUOTES',
    //     data: [
    //         {
    //             cardType: 'statements',
    //             designCode: '',
    //             animation: animationData.AimAndShoot,
    //             image : ''
    //         },
    //         {
    //             cardType: 'quotes',
    //             designCode: '',
    //             animation: animationData.Comp,
    //             image : ''
    // }
    //     ]

    // },
    // {
    //     title: 'BULLET AND NUMBERED LISTS',
    //     data: [
    //         {
    //             cardType: 'bulletList',
    //             designCode: '',
    //             animation: animationData.AimAndShoot,
    //             image : ''
    //         },
    //         {
    //             cardType: 'numberedList',
    //             designCode: '',
    //             animation: animationData.Comp,
    //             image : ''
    //         }
    //     ]

    // },
    // {
    //     title: 'TABLE AND GRAPHS',
    //     data: [
    //         {
    //             cardType: 'table',
    //             designCode: '',
    //             animation: animationData.AimAndShoot,
    //             image : ''
    //         },
    //         {
    //             cardType: 'graph',
    //             designCode: '',
    //             animation: animationData.MtrrRotate,
    //             image : ''
    //         }
    //     ]

    // },
    // {
    //     title: 'LOAN CALCULATOR',
    //     data: [
    //         {
    //             cardType: 'loanCalculator',
    //             designCode: '',
    //             animation: animationData.AimAndShoot,
    //             image : ''
    //         }
    //     ]

    // },

]

const LA_DEFAULT_IMAGE = '/images/learning-aid-assets/la-default.png';


const LA_INTERACTIONS = [
    {
        title: 'MULTIPLE CHOICE QUESTION',
        data: [
            {
                gameType: 'MCQ',
                interactionType: 'ILA_MCQ_1', //interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/mcq-v1.png',
                animation: animationData.mcq1
            },
            {
                gameType: 'MCQ',
                interactionType: 'ILA_MCQ_2',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/mcq-v2.png',
                animation: animationData.mcq2
            }
        ]

    },
    //HIDING IN PHASE 1 LAUNCH
    {
        title: 'MATCH THE RIGHT RESPONSE',
        data: [
            {
                gameType: 'MTRR',
                interactionType: 'ILA_MTRR_1',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/mtrr-v1.png',
                animation: animationData.mtrr1
            },
            {
                gameType: 'MTRR',
                interactionType: 'ILA_MTRR_2',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/mtrr-v2.png',
                animation: animationData.mtrr2
            }
        ]

    },
    {
        title: 'FILL IN THE BLANKS',
        data: [
            {
                gameType: 'FITB',
                interactionType: 'ILA_FITB_1',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/fill-in-the-blank-v1.png',
                animation: animationData.fitb1
            },
            {
                gameType: 'FITB',
                interactionType: 'ILA_FITB_2',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/fill-in-the-blank-v2.png',
                animation: animationData.fitb2
            }
        ]

    },
    {
        title: 'TRUE OR FALSE',
        data: [
            {
                gameType: 'TF',
                interactionType: 'ILA_TF_1',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/true-false-v1.png',
                animation: animationData.truefalse
            },
            {
                gameType: 'TF',
                interactionType: 'ILA_TF_2',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/true-false-v2.png',
                animation: animationData.truefalse
            },

        ]

    },
    //HIDING IN PHASE 1 LAUNCH
    {
        title: 'SEQUENCE',
        data: [
            {
                gameType: 'ILA',
                interactionType: 'ILA_SEQUENTIAL',//interactionType = designType
                previewUrl: '/images/learning-aid-assets/screens/sequence.png',
                animation: animationData.sequence
            },
        ]

    }

]

const ILA_VALIDATIONS = {
    ILA_MCQ_1: {
        question: 120,
        response: 50
    },
    ILA_MCQ_2: {
        question: 120,
        response: 50
    },
    ILA_MTRR_1: {
        context: 120,
        question: 50,
        response: 50
    },
    ILA_MTRR_2: {
        context: 120,
        question: 50,
        response: 50
    },
    ILA_FITB_1: {
        question: 120,
        response: 50
    },
    ILA_FITB_2: {
        question: 120,
        response: 24
    },
    ILA_TF_1: {
        question: 120,
        response: 50
    },
    ILA_TF_2: {
        question: 120,
        response: 50
    },
    ILA_SEQUENTIAL: {
        question: 120,
        response: 50
    },

    //to be moved in LA validations
    objective: {
        context: 120,
    }
}

const ILA_NUMBER_OF_RESPONSES = {
    ILA_MCQ_1: 4,
    ILA_MCQ_2: 4,
    ILA_MTRR_1: 4,
    ILA_MTRR_2: 4,
    ILA_FITB_1: 3,
    ILA_FITB_2: 1,
    ILA_TF_1: 2,
    ILA_TF_2: 2,
    ILA_SEQUENTIAL: 3
}

const LA_QUESTION_ANIMATION = {
    ILA_MCQ_1: animationData.mcq1,
    ILA_MCQ_2: animationData.mcq2,
    ILA_MTRR_1: animationData.mtrr1,
    ILA_MTRR_2: animationData.mtrr2,
    ILA_TF_1: animationData.truefalse,
    ILA_TF_2: animationData.truefalse,
    ILA_FITB_1: animationData.fitb1,
    ILA_FITB_2: animationData.fitb2,
    ILA_SEQUENTIAL: animationData.sequence,

}

const ILA_QUESTION_JSON = {
    ILA_MCQ_1: {
        id: 0,
        sequence: 1,
        question: '',
        isQuestionEmpty: false,
        isQuestionRepeated: false,
        designType: "ILA_MCQ_1",
        status: 'inProgress',
        validations: [],
        mandatory: true,
        score: 10,
        answers: [
            {
                id: 0,
                answer: '',
                isCorrect: true,
                sequence: 1
            },
            {
                id: 1,
                answer: '',
                isCorrect: false,
                sequence: 2
            },
            {
                id: 2,
                answer: '',
                isCorrect: false,
                sequence: 3
            },
            {
                id: 3,
                answer: '',
                isCorrect: false,
                sequence: 4
            }
        ],
        emptyAnswers: [
            {
                isEmpty: false
            },
            {
                isEmpty: false
            },
            {
                isEmpty: false
            },
            {
                isEmpty: false
            },
        ],
        repeatedAnswers: [
            {
                isRepeated: false
            },
            {
                isRepeated: false
            },
            {
                isRepeated: false
            },
            {
                isRepeated: false
            },
        ]
    },
    ILA_MCQ_2: {
        id: 0,
        sequence: 1,
        question: '',
        isQuestionEmpty: false,
        isQuestionRepeated: false,
        designType: "ILA_MCQ_2",
        status: 'inProgress',
        validations: [],
        mandatory: true,
        score: 10,
        answers: [
            {
                id: 0,
                answer: '',
                isCorrect: true,
                sequence: 1
            },
            {
                id: 1,
                answer: '',
                isCorrect: false,
                sequence: 2
            },
            {
                id: 2,
                answer: '',
                isCorrect: false,
                sequence: 3
            },
            {
                id: 3,
                answer: '',
                isCorrect: false,
                sequence: 4
            }
        ],
        emptyAnswers: [
            {
                isEmpty: false
            },
            {
                isEmpty: false
            },
            {
                isEmpty: false
            },
            {
                isEmpty: false
            },
        ],
        repeatedAnswers: [
            {
                isRepeated: false
            },
            {
                isRepeated: false
            },
            {
                isRepeated: false
            },
            {
                isRepeated: false
            },
        ]
    },
    ILA_TF_1: {
        id: 0,
        sequence: 1,
        designType: "ILA_TF_1",
        status: 'inProgress',
        validations: [],
        question: "",
        isQuestionEmpty: false,
        isQuestionRepeated: false,
        mandatory: true,
        score: 10,
        answers: [
            {
                answer: "True",
                isCorrect: true,
                sequence: 1,
            },
            {
                answer: "False",
                isCorrect: false,
                sequence: 2
            }
        ],
        emptyAnswers: [
            {
                isEmpty: false
            },
            {
                isEmpty: false
            }
        ],
        repeatedAnswers: [
            {
                isRepeated: false
            },
            {
                isRepeated: false
            }
        ]
    },
    ILA_TF_2: {
        id: 0,
        sequence: 1,
        designType: "ILA_TF_2",
        status: 'inProgress',
        validations: [],
        question: "",
        isQuestionEmpty: false,
        isQuestionRepeated: false,
        sequence: 0,
        mandatory: true,
        score: 10,
        answers: [
            {
                answer: "",
                isCorrect: true,
                sequence: 1
            },
            {
                answer: "",
                isCorrect: false,
                sequence: 2
            }
        ],
        emptyAnswers: [
            {
                isEmpty: false
            },
            {
                isEmpty: false
            }
        ],
        repeatedAnswers: [
            {
                isRepeated: false
            },
            {
                isRepeated: false
            }
        ]
    },
    ILA_MTRR_1: {
        id: 0,
        sequence: 1,
        designType: "ILA_MTRR_1",
        status: 'inProgress',
        validations: [],
        // contextId: 0,
        contextObj: { id: 0, type: 'TEXT', context: '' },
        mandatory: true,
        score: 50,
        isContextEmpty: false,
        questionArr: [
            {
                id: 0,
                sequence: 1,
                // contextId: 0,
                designType: "ILA_MTRR_1",
                question: "",
                score: 10,
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                repeatedAnswers: [
                    {
                        isRepeated: false
                    }
                ],
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            },
            {
                id: 0,
                sequence: 2,
                // contextId: 0,
                score: 10,
                designType: "ILA_MTRR_1",
                question: "",
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            },
            {
                id: 0,
                sequence: 3,
                // contextId: 0,
                score: 10,
                designType: "ILA_MTRR_1",
                question: "",
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                repeatedAnswers: [
                    {
                        isRepeated: false
                    }
                ],
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            },
            {
                id: 0,
                sequence: 4,
                // contextId: 0,
                score: 10,
                designType: "ILA_MTRR_1",
                question: "",
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                repeatedAnswers: [
                    {
                        isRepeated: false
                    }
                ],
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            },
            {
                id: 0,
                sequence: 5,
                // contextId: 0,
                score: 10,
                designType: "ILA_MTRR_1",
                question: "",
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                repeatedAnswers: [
                    {
                        isRepeated: false
                    }
                ],
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            }
        ]
    },
    ILA_MTRR_2: {
        id: 0,
        sequence: 1,
        designType: "ILA_MTRR_2",
        status: 'inProgress',
        validations: [],
        // contextId: 0,
        contextObj: { id: 0, type: 'TEXT', context: '' },
        mandatory: true,
        score: 40,
        isContextEmpty: false,
        questionArr: [
            {
                id: 0,
                sequence: 1,
                // contextId: 0,
                designType: "ILA_MTRR_2",
                question: "",
                score: 10,
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                repeatedAnswers: [
                    {
                        isRepeated: false
                    }
                ],
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            },
            {
                id: 0,
                sequence: 2,
                // contextId: 0,
                score: 10,
                designType: "ILA_MTRR_2",
                question: "",
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            },
            {
                id: 0,
                sequence: 3,
                // contextId: 0,
                score: 10,
                designType: "ILA_MTRR_2",
                question: "",
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                repeatedAnswers: [
                    {
                        isRepeated: false
                    }
                ],
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            },
            {
                id: 0,
                sequence: 4,
                // contextId: 0,
                score: 10,
                designType: "ILA_MTRR_2",
                question: "",
                isQuestionEmpty: false,
                isQuestionRepeated: false,
                repeatedAnswers: [
                    {
                        isRepeated: false
                    }
                ],
                emptyAnswers: [
                    {
                        isEmpty: false
                    }
                ],
                answers: [
                    {
                        answer: "",
                        isCorrect: true,
                        sequence: 1
                    }
                ]
            }
        ]
    },
    ILA_FITB_1: {
        id: 0,
        sequence: 1,
        designType: "ILA_FITB_1",
        status: 'inProgress',
        validations: [],
        question: "",
        isQuestionEmpty: false,
        isQuestionRepeated: false,
        mandatory: true,
        score: 10,
        answers: [
            {
                answer: "",
                isCorrect: true,
                sequence: 1
            },
            {
                answer: "",
                isCorrect: false,
                sequence: 2
            },
            {
                answer: "",
                isCorrect: false,
                sequence: 3
            }
        ],
        emptyAnswers: [
            {
                isEmpty: false
            },
            {
                isEmpty: false
            },
            {
                isEmpty: false
            }
        ],
        repeatedAnswers: [
            {
                isRepeated: false
            },
            {
                isRepeated: false
            },
            {
                isRepeated: false
            }
        ]
    },
    ILA_FITB_2: {
        id: 0,
        sequence: 1,
        designType: "ILA_FITB_2",
        status: 'inProgress',
        validations: [],
        question: "",
        isQuestionEmpty: false,
        isQuestionRepeated: false,
        mandatory: true,
        score: 10,
        answers: [
            {
                answer: "",
                isCorrect: true,
                sequence: 1,
            }
        ],
        emptyAnswers: [
            {
                isEmpty: false
            }
        ],
        repeatedAnswers: [
            {
                isRepeated: false
            }
        ]
    },
    ILA_SEQUENTIAL: {
        id: 0,
        sequence: 1,
        designType: "ILA_SEQUENTIAL",
        status: 'inProgress',
        validations: [],
        question: "",
        isQuestionEmpty: false,
        isQuestionRepeated: false,
        mandatory: true,
        score: 10,
        responsePerQuestion: {
            minimum: 2,
            maximum: 10
        },
        answers: [
            // change number 2 to minimum response per question
            ...new Array(2).fill({}).map((e, i) => ({
                answer: "",
                isCorrect: true,
                sequence: i + 1,
            }))
        ],
        emptyAnswers: [
            // change number 10 to maximum response per question
            ...new Array(10).fill({ isEmpty: false })
        ],
        repeatedAnswers: [
            // change number 10 to maximum response per question
            ...new Array(10).fill({ isRepeated: false })
        ]
    },
}

const ILA_PREVIEWS = {
    ILA_MCQ_1: '/images/learning-aid-assets/screens/mcq-v1.png',
    ILA_MCQ_2: '/images/learning-aid-assets/screens/mcq-v2.png',
    ILA_MTRR_1: '/images/learning-aid-assets/screens/mtrr-v1.png',
    ILA_MTRR_2: '/images/learning-aid-assets/screens/mtrr-v2.png',
    ILA_TF_1: '/images/learning-aid-assets/screens/true-false-v1.png',
    ILA_TF_2: '/images/learning-aid-assets/screens/true-false-v2.png',
    ILA_FITB_1: '/images/learning-aid-assets/screens/fill-in-the-blank-v1.png',
    ILA_FITB_2: '/images/learning-aid-assets/screens/fill-in-the-blank-v2.png',
    ILA_SEQUENTIAL: '/images/learning-aid-assets/screens/sequence.png'
}

const ILA_GAMETYPES = {
    ILA_MCQ_1: 'MCQ',
    ILA_MCQ_2: 'MCQ',
    ILA_MTRR_1: 'MTRR',
    ILA_MTRR_2: 'MTRR',
    ILA_TF_1: 'TF',
    ILA_TF_2: 'TF',
    ILA_FITB_1: 'FITB',
    ILA_FITB_2: 'FITB',
    ILA_SEQUENTIAL: 'SEQUENCE'
}

// const MS_DEFAULT_COVER_IMAGE = "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/compy_TKJDlfhBJohJMXDnICr/coverImage-1688654752504.png";
const MS_DEFAULT_COVER_IMAGE = "https://d27yeeml4scius.cloudfront.net/apiv3media/Images/larg/large9.png";
const MS_DEFAULT_BRAND_ICON = "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/compy_TKJDlfhBJohJMXDnICr/brandImage-1691649784467.png";


const MO_LOGO_PATH = "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/clewehsn2000tqnvrh52gda46/LAMediaFiles-1678343988728.png";
const VTB_DEFAULT_THUMBNAIL = "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/compy_TKJDlfhBJohJMXDnICr/LAMediaFiles-1688384292247.png";

//CHANGING TO EMPTY WHILE GOING LIVE IN PHASE 1

// const SUGGESTED_IMAGES = [
//     { url: "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/clecejs58000turjkvnnqeua8/LAMediaFiles-1678439909470.jpg" },
//     { url: "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/clecejs58000turjkvnnqeua8/LAMediaFiles-1678439963167.jpg" },
//     { url: "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/clecejs58000turjkvnnqeua8/LAMediaFiles-1678439909470.jpg" },
//     { url: "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/clecejs58000turjkvnnqeua8/LAMediaFiles-1678439963167.jpg" },
// ]

const SUGGESTED_IMAGES = []

const LANGUAGES = [
    {
        id: 'en-US',
        value: 'English',
        name: 'English',
    },
    {
        id: 'hi-IN',
        value: 'Hindi',
        name: 'Hindi',
    },
    {
        id: 'bn-IN',
        value: 'Bengali',
        name: 'Bengali',
    },
    {
        id: 'ta-IN',
        value: 'Tamil',
        name: 'Tamil',
    },
    {
        id: 'te-IN',
        value: 'Telugu',
        name: 'Telugu',
    },
    {
        id: 'gu-IN',
        value: 'Gujarati',
        name: 'Gujarati',
    },
    {
        id: 'kn-IN',
        value: 'Kannada',
        name: 'Kannada',
    },
    {
        id: 'ml-IN',
        value: 'Malayalam',
        name: 'Malayalam',
    },
    {
        id: 'mr-IN',
        value: 'Marathi',
        name: 'Marathi',
    },
    {
        id: 'tr',
        value: 'Turkish',
        name: 'Turkish',
    },
    {
        id: 'or-IN',
        value: 'Oriya',
        name: 'Oriya',
    },
    {
        id: 'es-ES',
        value: 'Spanish',
        name: 'Spanish',//(Spain)
    }
];

const STATUS_CODE_MESSAGES = {
    500: "Internal server error"
}

// const SUPPORTED_IMAGE = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/bmp', 'image/svg'];
// const SUPPORTED_AUDIO = ['audio/mpeg', 'audio/mp3', 'audio/ogg', 'audio/wav'];
// const SUPPORTED_VIDEO = ['video/mp4', 'video/quicktime', 'video/webm', 'video/ogg'];
// const SUPPORTED_DOCX = ['.xlsx', '.xls', '.docx', '.doc', '.pptx', '.ppt'];

const SUPPORTED_IMAGE = ['.jpg', '.jpeg', '.png', '.webp', '.gif', '.bmp', '.svg'];
const SUPPORTED_AUDIO = ['.mpeg', '.mp3', '.ogg', '.wav'];
const SUPPORTED_VIDEO = ['.mp4', '.mov', '.webm', '.ogg'];
const SUPPORTED_DOCX = ['.xlsx', '.xls', '.docx', '.doc', '.pptx', '.ppt', '.pdf', '.xlsm'];


const LIBRARY_SUPPORTED_EXTENSIONS = [...SUPPORTED_IMAGE, ...SUPPORTED_AUDIO, ...SUPPORTED_VIDEO, ...SUPPORTED_DOCX];
const SPOTLIGHT_SUPPORTED_EXTENSIONS = ['.mpeg', '.ogg', '.wav', '.mp4', '.mov', '.webm'];

const EDITOR_MODULES = {
    toolbar: [
        [{ font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline'],
        [{ indent: '-1' }, { indent: '+1' }, { indent: '0' }],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

const EDITOR_FORMATS = ['font', 'size', 'bold', 'italic', 'underline', 'indent'];
const LA_MINTIMESPENT_LIMIT = 999;
const REPOSITORY_MAX_FILE_SIZE_MB = 50;

const SUPER_ADMIN_COMPANY_ID = 'xx-sa';

const SPOTLIGHT_KEYWORD_CSV_FORMAT = "/files/spotlight/keyword-csv-format.csv"
const MIME_TYPES = {
    PDF: 'application/pdf',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}

const GEN_AI_THUMB_IMG = "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/compy_AIMVgdypvL62dTcghkr/LAMediaFiles-1711624770340.png";

// response limit for Sequence ILA
const MIN_RESPONSE_PER_QUESTION_SEQUENCE_ILA = {
    MINIMUM: 3,
    MAXIMUM: 10
}


export default {
    HEADER_HEIGHT,
    DRAWER_WIDTH,
    X_API_KEY,
    DRAWER_MENU,
    DRAWER_STYLE,
    CHALLENGE_DATA,
    GBA_GAMETYPE_DATA,
    GBA_VALIDATIONS,
    GAME_DESIGN_TYPE_GBA,
    GBA_SETTINGS,
    LA_CARD_TYPE,
    LA_DEFAULT_IMAGE,
    LA_INTERACTIONS,
    LA_QUESTION_ANIMATION,
    ILA_VALIDATIONS,
    ILA_NUMBER_OF_RESPONSES,
    ILA_QUESTION_JSON,
    ILA_PREVIEWS,
    ILA_GAMETYPES,
    MO_LOGO_PATH,
    SUGGESTED_IMAGES,
    USER_LAUNCH_FILE_FORMAT,
    BATCH_UPLOAD_FILE_FORMAT,
    SAMMAN_CAPITALS_BATCH_UPLOAD_FILE_FORMAT,
    HAS_TMS_ACCESS,
    HAS_SPOTLIGHT_ACCESS,
    RESTRICT_GBA_FOR,
    RESTRICT_LA_FOR,
    VTB_DEFAULT_THUMBNAIL,
    IFL_ID,
    GBA_GAMETYPE,
    GBA_CSV_FORMATS,
    MS_DEFAULT_COVER_IMAGE,
    MS_DEFAULT_BRAND_ICON,
    LANGUAGES,
    STATUS_CODE_MESSAGES,
    LA_VALIDATIONS,
    LA_DESIGN_CODES,
    LIBRARY_SUPPORTED_EXTENSIONS,
    SPOTLIGHT_SUPPORTED_EXTENSIONS,
    EDITOR_MODULES,
    EDITOR_FORMATS,
    OTHER_REPORTS_TABS_OBJECT,
    OTHER_REPORTS_TABS,
    GBA_SCREENS,
    LA_MINTIMESPENT_LIMIT,
    REPOSITORY_MAX_FILE_SIZE_MB,
    OTHER_REPORTS_ROLES,
    SUPER_ADMIN_COMPANY_ID,
    SPOTLIGHT_KEYWORD_CSV_FORMAT,
    TEXT_TO_SPEECH_VOICES,
    TEXT_TO_SPEECH_LANGUAGES,
    MIME_TYPES,
    GEN_AI_THUMB_IMG,
    SPOTLIGHT_CONTEXTUAL_MATCH_ACCESS,
    CUSTOM_REPORTS_TAB_VALUE
}