import React, { useContext } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import reportsServices from '../../../../services/reports';
import { GlobalStates } from '../../../../App';
import useQuery from '../../../../utils/useQuery';




const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: theme.spacing(2),
        boxShadow: theme.shadows[3],
    },
    header: {
        color: theme.palette.common.black,
        fontWeight: "bold",
    },
    downloadLink: {
        color: theme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    tableRow: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
    },
}));

const ReportsHistory = ({ setBackdrop }) => {
    const classes = useStyles();
    const [globalStates] = useContext(GlobalStates);
    const query = useQuery();

    const companyId = query.get("c_id");



    const reportsData = [
        { email: "dineshb@masteroapp.com", report: "Login", file: "Download", downloaded: "14-4-2024", expires: "15-7-2024; 3:20 PM" },
        { email: "akiba@masteroapp.com", report: "Attempt-wise", file: "Download", downloaded: "14-4-2024", expires: "14-4-2024; 3:20 PM" },
    ];

    const handleDownloadCustomReport = async (id) => {
        // setBackdrop(true);
        // const downloadReportPayload = {
        //     templateId: id,
        //     flag: "excel",
        //     companyId: companyId,
        // }
        // const response = await reportsServices.sendReportActions(downloadReportPayload);
        // console.log('downloadAsExcel response = ', response);
        // if (response.status === 200) {
        //     let fileUrl = response.data.data.file.Location;
        //     console.log('downloadAsExcel fileUrl = ', fileUrl);
        //     if (fileUrl) {
        //         const link = document.createElement('a');
        //         link.href = fileUrl;
        //         link.download = 'Login reports'; // Set the desired filename for the downloaded file
        //         link.click();
        //         setBackdrop(false);
        //     }

        // } else {
        //     globalStates.handleToast(true, "Internal server error", "error");
        //     setBackdrop(false);
        // }
        console.log('I am called download')
    }

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                    <TableRow>
                        <TableCell className={classes.header}>Email ID</TableCell>
                        <TableCell className={classes.header}>Report</TableCell>
                        <TableCell className={classes.header}>File</TableCell>
                        <TableCell className={classes.header}>Downloaded On</TableCell>
                        <TableCell className={classes.header}>Expires On</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reportsData.map((row, index) => (
                        <TableRow key={index} className={classes.tableRow}>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.report}</TableCell>
                            <TableCell>
                                <span style={{ color: '#ff5722', borderBottom: '1px solid #ff5722', cursor: 'pointer' }} onClick={() => handleDownloadCustomReport(row.id)}> Download</span>
                            </TableCell>
                            <TableCell>{row.downloaded}</TableCell>
                            <TableCell>{row.expires}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ReportsHistory;