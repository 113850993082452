import React, { useEffect, useState, useContext } from "react";

// components
import MasteroTable from "../../components/mastero-table";
import LoadingContainer from "../../components/loading-container";
import SuccessDialog from '../../components/success-dialog';
import FailureDialog from '../../components/failure-dialog';
import CreateNewCompany from "../../components/create-new-company";
import SearchBar from "../../components/custom-styled/search-bar";

//services
import companiesServices from "../../services/companies"

// utils
import useRouter from "../../utils/useRouter";
import isEmpty from "../../utils/isEmpty";

// styles
import useStyles from "./index.styles";

//global-states
import { GlobalStates } from "../../App";

let descriptionTitle = "How to use:"
let descriptionText = "Here you can create new companies that will include admins, learners, and content based on the limits and access levels specified for the respective organizations."
let defaultPagination = {
  limit: 0,
  pageCount: 0,
  startIndex: -1,
  totalCount: 0
}

const CompaniesPage = ({ isMobile }) => {
  // console.log("isMobile:", isMobile);
  const [globalStates] = useContext(GlobalStates);
  const classes = useStyles();
  const router = useRouter();
  const [tableData, setTableData] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: "",
    validity: "",
  });

  const [editCompany, setEditCompany] = useState({ edit: false, key: null });
  const [dialogContent, setDialogContent] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState(defaultPagination);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [enableScreenshots, setEnableScreenshots] = useState(true);

  // refs
  const debounceSearch = React.useRef(null);

  useEffect(() => {
    fetchCompanies(startIndex);
  }, [startIndex])

  const fetchCompanies = async (startIndex = 0, search = '') => {
    setTableLoader(true);
    try {
      let res = await companiesServices.fetchCompaniesPagination(startIndex, search);
      if (res.status === 200 || res.status === 201) {
        let activeCompanies = res.data.data.filter(element => element.active === true);
        let tempData = [];
        activeCompanies.forEach(element => {
          let obj = {
            ...element,
            totalAdmins: element.Details.totalAdmins,
            totalProjects: element.Details.totalProjects,
            totalUsers: element.Details.totalUsers,
          }
          tempData.push(obj);
        });
        setPagination(res.data.pagination);
        setPageCount(res.data.pagination.pageCount);
        setTotalCount(res.data.pagination.totalCount);
        setTableData(tempData);
        setTableLoader(false);
      }
      if (res.status === 400 || res.status === 401) {
        if (res.data?.errors[0]?.message === "Admin not registered or not active") {
          // setLoading(false);
          setTableLoader(false)
          localStorage.removeItem('session');
          router.history.push('/login');
        }
        //
      }

    } catch (e) {
      // router.history.push('/login');
    }
  }

  const handleBackClick = () => {
    if (startIndex >= limit) {
      setStartIndex(startIndex - limit)
    }
  }

  const handleForwardClick = () => {
    if (startIndex + limit < totalCount) {
      setStartIndex(startIndex + limit);
    }
  }

  const handleSubmit = async (data) => {
    globalStates.handleProgressDialog(true, 'Please wait...');
    let payload = {
      name: data.name,
      description: "Master-O App Company",
      dbName: "Shared DB",
      dbUrl: "mysql://root:master@123@localhost:4406/sharedDB",
      projectsLimit: 10,
      usersLimit: 1000,
      adminsLimit: 5,
      microskillsLimit: 50,
      validity: data.validity,
      enableScreenShot: enableScreenshots
      // name, description, databaseId, dbName, dbUrl, projects, users, admins, microskills, challenges
    }
    if (editCompany.edit) {
      // tableData[editCompany.key] = payload;
      let updatePayload = {
        id: tableData[editCompany.key].id,
        name: data.name,
        validity: data.validity,
        description: "Temp description",
        enableScreenShot: enableScreenshots,
      }
      let res = await companiesServices.updateCompany(updatePayload);
      globalStates.handleProgressDialog(false);
      console.log('update company res = ', res);
      if (res.status === 200 || res.status === 201) {
        setDialogContent("Company updated successfully!");
        setSuccessDialogOpen(true);
        setInputValues({ name: "", validity: "" })
        setEditCompany({ edit: false, key: null });
        fetchCompanies();
        return
      } else {
        globalStates.handleToast(true, 'Internal server error', 'error')
      }

      // setEditCompany({ edit: false, key: null });

    } else {
      let res = await companiesServices.createCompany(payload);
      globalStates.handleProgressDialog(false);
      if (res.status === 400) {
        if (res.data.errors) {
          setDialogContent(res?.data?.errors[0]?.message);
          setErrorDialogOpen(true);
          // alert(res.data.errors[0].message)
        }
        return
      }
      if (res.status === 200 || res.status === 201) {
        setDialogContent("Company created successfully!");
        setSuccessDialogOpen(true);
        fetchCompanies();
      }
    }
    return
  }

  const handleEdit = (element, key) => {
    setInputValues({
      name: element.name,
      validity: element.expiresOn.split('T')[0],
    });
    if ("enableScreenShot" in element.Details){
      setEnableScreenshots(element.Details.enableScreenShot)
    }
    setEditCompany({ edit: true, key: key, element: element });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleDelete = async (key) => {
    let confirm = window.confirm("Do you really want to delete this company?")
    if (confirm) {
      globalStates.handleProgressDialog(true, 'Please wait...');
      const payload = { id: tableData[key].id }
      try {
        let res = await companiesServices.deleteCompany(payload);
        globalStates.handleProgressDialog(false);
        if (res.status === 200 || res.status === 201) {
          setDialogContent("Company deleted sucessfully!");
          setSuccessDialogOpen(true);
          fetchCompanies();
        }
        return
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }

  };

  const handleDuplicate = (element, key) => {
    return
  };

  const handleNameClick = (element) => {
    if (!('companyId' in element)) {
      router.history.push(`/projects/${element.id}`)
    }
    return
  }

  //TABLE DETAILS
  const TITLE = "Create New Company";
  const PLACEHOLDER = "Company name"
  const LABELS = {
    companyName: "Company Name:",
    validity: "Company Validity:",
  };
  const SAVE_BTN_TEXT = editCompany.edit ? "Save Changes" : "Save Company";
  const TABLE_TITLE = "All Companies"

  const HEADINGS = [
    { title: "Company Name", key: "name", link: true },//key : name
    { title: "Projects", key: "totalProjects", link: false },
    { title: "Admins", key: "totalAdmins", link: false },
    { title: "Users", key: "totalUsers", link: false },
    { title: "Validity", key: "expiresOn", link: false },
    { title: "Last Updated", key: "updatedOn", link: false },
    { title: "Quick Actions", key: "quickActions", link: false },
  ];


  const handleSearchCompany = (e) => {
    e.preventDefault();
    clearTimeout(debounceSearch.current);
    let value = e.target.value;
    setSearchValue(value);
    let delay = 1000;
    if (!value.length) delay = 0;
    debounceSearch.current = setTimeout(() => {
      fetchCompanies(0, value);
      clearTimeout(debounceSearch.current);
    }, delay)
  }

  const handleClearSearch = () => {
    setSearchValue('');
    if (!isEmpty(searchValue)) fetchCompanies(startIndex);
    else setStartIndex(0);
  }

  const handleScreenshotEnable = (event) => {
      let isChecked = event.target.checked;
      setEnableScreenshots(isChecked);
  }


  if (loading) {
    return (
      <LoadingContainer />
    )
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.row1}>
          <CreateNewCompany
            title={TITLE}
            labels={LABELS}
            placeholder={PLACEHOLDER}
            btnTxt={SAVE_BTN_TEXT}
            handleSubmit={handleSubmit}
            inputValues={inputValues}
            setInputValues={setInputValues}
            descriptionTitle={descriptionTitle}
            descriptionText={descriptionText}
            withScreenShotCheckBox={true}
            handleScreenshotEnable={handleScreenshotEnable}
            isScreenShotEnabled={enableScreenshots}
          />
        </div>

        <div className={classes.row2}>

          <SearchBar
            handleChange={handleSearchCompany}
            value={searchValue}
            handleClear={handleClearSearch}
            placeholder="Search company"
          />

          <MasteroTable
            tableTitle={TABLE_TITLE}
            tableHeadings={HEADINGS}
            tableData={tableData}
            quickActions={true}
            deleteKey={true}
            copyKey={false}
            handleNameClick={handleNameClick}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleForwardClick={handleForwardClick}
            handleBackClick={handleBackClick}
            pageCount={pageCount}
            pageNo={pageNo}
            pagination={pagination}
            loader={tableLoader}
          />
        </div>

        {
          successDialogOpen &&
          <SuccessDialog
            successDialogOpen={successDialogOpen}
            setSuccessDialogOpen={setSuccessDialogOpen}
            dialogText={dialogContent}
            handleClick={() => setSuccessDialogOpen(false)}
            buttonText='Okay'
          />
        }
        {
          errorDialogOpen &&
          <FailureDialog
            errorDialogOpen={errorDialogOpen}
            setErrorDialogOpen={setErrorDialogOpen}
            dialogText={dialogContent}
            handleClick={() => setErrorDialogOpen(false)}
          />
        }
      </div>
    );
  }

};

export default CompaniesPage;
