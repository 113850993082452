import React, { useState, useEffect, useRef, useContext } from 'react'
import useRouter from '../../utils/useRouter'
import Papa from 'papaparse';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

//  components
import CustomInput from "./components/custom-input";
import TypeQuestion from './components/typeQuestion';
import ResponseQuestions from './components/ResponseQuestions';
import RatingResponses from './components/ratingQuestionsResponses';
import Label from './components/label';

//  mui
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

//  styles
import useStyles from './index.styles';

//  services
import formServices from "../../services/form";
import FailureDialog from '../failure-dialog';
import SuccessDialog from '../success-dialog';
import useQuery from '../../utils/useQuery';
import LoadingContainer from '../loading-container';
import { Divider } from '@mui/material';

//validations
import formsValidations from '../../validations/forms-validations';
import DeleteDialog from '../delete-dialog';

import { GlobalStates } from '../../App';
import form from '../../services/form';
import { isEmpty } from 'lodash';
import LinearWithValueLabel from '../game-based-assessment/components/lenear-progress-bar';

const FORM_CSV_HEADERS = ['Question', 'Question Type', 'Score Per Correct Response', 'Answer 1', 'Answer 2', 'Answer 3', 'Answer 4', 'Answer 5', 'Answer 6', 'Answer 7', 'Answer 8', 'Answer 9', 'Answer 10', 'Correct Answers (1,2,3,4) NO SPACES'];
const FORM_CSV_KEYS = ['question', 'question_type', 'score_per_correct_response', 'answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6', 'answer7', 'answer8', 'answer9', 'answer10', 'correct_answers']
const CSV_QUESTION_TYPES = {
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE'
};
const QUESTION_TYPES = {
    SINGLE_CHOICE: 'SINGLE_CHOICE_QUESTION',
    MULTIPLE_CHOICE: 'MULTI_CHOICE_QUESTION',
    OPEN_ENDED: 'OPEN_ENDED',
    STAR_RATING: 'STAR_RATING',
    NUMBER_RATING: 'NUMBER_RATING',
    DATE: 'DATE',
    CUSTOM: 'CUSTOM'
};

const FormsQuestion = () => {
    const router = useRouter();
    const query = useQuery();
    const formId = query.get('formId');
    const companyId = query.get('c_id');
    const microSkillId = query.get('ms_id')
    const sequence = query.get('seq')
    const styles = useStyles();
    const [globalStates] = useContext(GlobalStates);
    const [maxScore, setMaxScore] = useState(0);
    const questionCharLimit = 110;
    const responseCharLimit = 85;
    const formNameCharLimit = 60;
    const formDescCharLimit = 250;

    let finalData = {
        formId: "",
        companyId: "",
        questions: []
    };

    let QuestionData = {
        id: 0,
        questionId: 0,
        question: "",
        isQuestionEmpty: false,
        status: 'error',
        validations: [],
        options: {},
        type: "SINGLE_CHOICE_QUESTION",
        mandatory: false,
        sequence: 1,
        active: true,
        scorePerResponse: "0",
        isScore: false,
        answers: []
    };
    let answersData = {
        id: 0,
        answerId: 0,
        option: "",
        value: false,
        type: "",
        sequence: 1,
        isAnswerEmpty: false,
        active: true
    };

    const [formQuestion, setFormQuestion] = useState([]);

    let responses = Array(2).fill(null).map((item, index) => ({ exceeded: false, limitText: `${responseCharLimit} Characters` }))
    let questions = Array(1).fill(null).map((item, index) => ({ exceeded: false, limitText: `${questionCharLimit} Characters`, answers: [...responses] }))
    const ansValidation = { exceeded: false, limitText: `${responseCharLimit} Characters`, isEmpty: true }
    const quesValidation = { exceeded: false, limitText: `${questionCharLimit} Characters`, isEmpty: true, answers: [] }

    const [questionValidations, setQuestionValidations] = useState(questions);
    const [failureAddQuestion, setFailureAddQuestion] = useState(false);
    const [successAddQuestion, setSuccessAddQuestion] = useState(false);
    const [successResponseText, setSuccessResponseText] = useState('');
    const [failedResponseText, setFailedResponseText] = useState('');
    // const [deleteDialogText, setDeleteDialogText] = useState('Question');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteParameters, setDeleteParameters] = useState({});
    const [collapseList, setCollapseList] = useState([0]);
    const [minScore, setMinScore] = useState(0);
    const [minQuestionAttempt, setMinQuestionAttempt] = useState(0);

    const [formName, setFormName] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [isAssessMent, setIsAssesment] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [timeLimit, setTimeLimit] = useState(60);
    const [randomizeQuestions, setRandomizeQuestions] = useState(false);
    const [randomizeAnswers, setRandomizeAnswers] = useState(false);
    const [formNameValidation, setFormNameValidation] = useState({ exceeded: false, limitText: `${formNameCharLimit} Characters`, isEmpty: true });
    const [formDescValidation, setFormDescValidation] = useState({ exceeded: false, limitText: `${formDescCharLimit} Characters`, isEmpty: true });
    const [progress, setProgress] = useState(0);
    const [fileName, setFileName] = useState('');
    const [csvData, setCsvData] = useState(null)

    const [intervalCleared, setIntervalCleared] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const maxScoreRef = useRef(0);
    const csvUploadInputRef = useRef();
    let timer = useRef(null);

    useEffect(async () => {
        await fetchSpecificForm();
    }, []);

    const newFormValidations = (questionValidation) => {
        let tempQuestionValidation = [...questionValidation];
        formQuestion.forEach((element, idx) => {
            let value = element.question;
            if (value === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, answers: [...responses] };
            }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                }
            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })

        setQuestionValidations(tempQuestionValidation);
        setLoadingData(false);
    }

    const fetchSpecificForm = async () => {
        const respData = await formServices.fetchSpecificForm({ formId });
        if (respData.status === 200 || respData.status === 201) {
            const data = respData.data.data;
            const Questions = data.Questions
            // setFormName(data.name);
            // setFormDescription(data.description);
            handleChangeFormName(data.name);
            handleFormDescription(data.description);

            setShowScore(data.showScore);
            setIsAssesment(data.assessment);
            setTimeLimit(data.timeLimit)

            if (data.settings !== null && data.settings !== undefined && data.settings !== '') {
                setRandomizeQuestions(data.settings.randomizeQuestions);
                setRandomizeAnswers(data.settings.randomizeAnswers);
                setMinScore(data.settings?.minScore ?? 0);
            }

            if (Questions.length === 0) {
                setFormQuestion([QuestionData]);
                // setQuestionValidations(questions);
                // newFormValidations(questions); // uncomment this to enable validations 
                setSuccessResponseText('Question Added in Form Successfully');
                setFailedResponseText('Error in adding Question in Form, Please Try again');
                setLoadingData(false); // comment this when enable validations 
            }
            else {
                const formData = [...Questions];
                const tempFormData = [];
                setSuccessResponseText('Form Updated Successfully');
                setFailedResponseText('Error in updating the form, Please Try again');

                formData.forEach((data, idx) => {
                    const tempData = {};
                    tempData.id = idx;
                    tempData.question = data.question;
                    tempData.isQuestionEmpty = false;
                    tempData.status = 'done';
                    tempData.validations = [];
                    tempData.options = data.options === null ? [] : data.options;
                    tempData.type = data.type;
                    tempData.mandatory = data.mandatory;
                    tempData.sequence = data.sequence;
                    tempData.active = data.active;
                    tempData.questionId = data.id;
                    tempData.scorePerResponse = data.scorePerResponse.toString() || "0";
                    tempData.isScore = data.scorePerResponse > 0 ? true : false;
                    const tempDataAnswers = [];
                    data.answers.forEach((ans, idx) => {
                        const tempAns = {};
                        tempAns.option = ans.answer;
                        tempAns.value = ans.isCorrect;
                        tempAns.type = ans.answerType;
                        tempAns.id = idx;
                        tempAns.answerId = ans.id;
                        tempAns.active = ans.active;
                        tempAns.sequence = ans.sequence;
                        tempDataAnswers.push(tempAns);
                    })
                    tempData.answers = tempDataAnswers;
                    tempFormData.push(tempData);
                })
                // handleInitTextValidation(tempFormData); // uncomment this to enable validations 
                setFormQuestion([...tempFormData]);
                setMinQuestionAttempt(tempFormData.length)   // For Setting the MinQuestion attempt intially
                setLoadingData(false);
            }
        }
    }

    const addQuestion = async (payload) => {
        const resp = await formServices.updateForm(payload);

        globalStates.handleProgressDialog(false);
        if (resp.status === 200 || resp.status === 201) {
            setFormQuestion([QuestionData]);
            // setQuestionValidations(prev => ({ ...prev, quesValidation })); // uncomment this to enable validations 
            setSuccessAddQuestion(!successAddQuestion);
        }
        else {
            setFailureAddQuestion(!failureAddQuestion)
        }
    }

    const handleQuestionValidation = (value, idx) => {
        let tempQuestionValidation = [...questionValidations];
        let responses = tempQuestionValidation[idx].answers;
        let queValue = value;
        if (queValue.length > questionCharLimit) {
            tempQuestionValidation[idx] = { exceeded: true, limitText: `${queValue.length - questionCharLimit} Characters Exceeded`, answers: [...responses] }
        }
        else {
            tempQuestionValidation[idx] = { exceeded: false, limitText: `${questionCharLimit - queValue.length} Characters`, answers: [...responses] }
        }
        setQuestionValidations(tempQuestionValidation);
    }

    const handleAnswerValidation = (value, questionIdx, answerIdx) => {
        let tempQuestionValidation = [...questionValidations];
        let tempResponseValidation = [...tempQuestionValidation[questionIdx].answers];
        let ansValue = value;
        if (ansValue.length > responseCharLimit) {
            tempResponseValidation[answerIdx] = { exceeded: true, limitText: `${ansValue.length - responseCharLimit} Characters Exceeded` }
        }
        else {
            tempResponseValidation[answerIdx] = { exceeded: false, limitText: `${responseCharLimit - ansValue.length} Characters` }
        }
        tempQuestionValidation[questionIdx].answers = [...tempResponseValidation]
        setQuestionValidations(tempQuestionValidation);
    }

    const handleInputQuestion = (value, id) => {
        const tempObj = [...formQuestion];
        tempObj[id].question = value;
        tempObj[id].isQuestionEmpty = false;
        setFormQuestion(tempObj);

        // handleQuestionValidation(value, id) // uncomment this to enable validations 

    }

    const handleInitTextValidation = (questionArray) => {

        //  Validations
        let tempQuestionValidation = Array(questionArray.length).fill(null)
        questionArray.forEach((element, idx) => {
            let queValue = element.question;
            if (queValue === '') {
                tempQuestionValidation[idx] = { exceeded: false, limitText: `*Required`, isEmpty: true, answers: [...responses] };
            } else if (queValue.length > questionCharLimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN questionCharlimit CHARACTERS.
                tempQuestionValidation[idx] = { exceeded: true, limitText: `${queValue.length - questionCharLimit} Characters Exceeded`, isEmpty: false, answers: [...responses] };
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
                tempQuestionValidation[idx] = { exceeded: false, limitText: `${questionCharLimit - queValue.length} Characters`, isEmpty: false, answers: [...responses] };
            }

            let tempResponseValidation = [...element.answers];
            element.answers.forEach((ele, index) => {
                let ansValue = ele.option;
                if (ansValue === '') {
                    tempResponseValidation[index] = { exceeded: false, limitText: "*Required" }
                } else if (ansValue.length > responseCharLimit) {
                    //HERE LENGTH OF INPUT IS GREATER THAN questionCharlimit CHARACTERS.
                    tempResponseValidation[index] = { exceeded: true, limitText: `${ansValue.length - responseCharLimit} Characters Exceeded`, isEmpty: false };
                } else {
                    //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
                    tempResponseValidation[index] = { exceeded: false, limitText: `${responseCharLimit - ansValue.length} Characters`, isEmpty: false };
                }

            })

            tempQuestionValidation[idx].answers = [...tempResponseValidation]
        })

        setQuestionValidations(tempQuestionValidation);
    }

    const handleAddQuestion = () => {
        // const tempObj = [...formQuestion];

        // make a deep copy of formQuestion
        const strigifyObj = JSON.stringify(formQuestion);
        const tempObj = JSON.parse(strigifyObj);
        tempObj.push({ ...QuestionData, id: tempObj.length });

        tempObj.map((obj, idx) => {
            obj.id = idx;
            obj.sequence = idx + 1;
        })

        // setQuestionValidations(prev => ([ ...prev, quesValidation ])); // uncomment this to enable validations 
        setFormQuestion(tempObj);
        setMinQuestionAttempt(tempObj.length)  // For Setting the min Question attempt by the length of added questions 

        handleCollapse(tempObj.length - 1)
    }

    const handleDeleteQuestion = async (idx) => {

        let tempObj = [...formQuestion];

        let deleteQuestion = tempObj.find((item, i) => idx === i);
        if (typeof (deleteQuestion.questionId) === 'string') {
            const questionId = deleteQuestion.questionId;
            const resp = await formServices.deleteQuestion({ formId, companyId, questionId });
            if (resp.status !== 200 && resp.status !== 201) {
                console.log("It is Not able to delete");
                return;
            }
        }

        let deleteQuestionArr = tempObj.filter((item, i) => idx !== i);

        deleteQuestionArr.map((item, idx) => {
            item.id = idx;
            item.sequence = idx + 1;
            var answerObj = item.answers;
            answerObj.map((answer, i) => {
                answer.id = i;
                answer.sequence = i + 1;
            })
        })

        setFormQuestion(deleteQuestionArr);
        setMinQuestionAttempt(deleteQuestionArr.length)

        // // <===uncomment this to enable validations ===>
        // let tempQuestionValidation = [...questionValidations];
        // tempQuestionValidation.splice(idx, 1);

        // setQuestionValidations(tempQuestionValidation);
        // e.stopPropagation();
        // // <===uncomment this to enable validations ===>
    }

    const deleteAnswerFunc = (ansIndex, quesIndex) => {
        // let tempObj = [...formQuestion];
        // console.log({ ansId, ansIndex, quesId, quesIndex });
        const strigifyObj = JSON.stringify(formQuestion);
        const tempObj = JSON.parse(strigifyObj);

        const tempQuestion = tempObj[quesIndex];
        const tempAnswers = [...tempQuestion.answers];

        tempAnswers.splice(ansIndex, 1);
        tempQuestion.answers = [...tempAnswers]

        setFormQuestion(tempObj);

        // delete validation for answer
        // // <=== uncomment this to enable validations ====>
        //     const tempQuestionValidation = [...questionValidations];
        // const tempResponseValidation = [...tempQuestionValidation[quesIndex].answers];
        // tempResponseValidation.splice(ansIndex, 1);
        // tempQuestionValidation[quesIndex].answers = [...tempResponseValidation];
        // setQuestionValidations(tempQuestionValidation);
        // // <=== uncomment this to enable validations ====>
    }

    const handleDeleteAnswer = async (questionIdx, idx) => {
        // console.log(questionIdx, idx);
        let tempObj = [...formQuestion];

        let deleteQuestion = tempObj[questionIdx];
        if (typeof (deleteQuestion.questionId) === 'string') {
            const questionId = deleteQuestion.questionId;
            const answerId = deleteQuestion.answers[idx].answerId;

            if (typeof answerId === 'string') {
                const resp = await formServices.deleteAnswer({ formId, companyId, questionId, answerId });
                if (resp.status !== 200 && resp.status !== 201) {
                    console.log("It is Not able to delete");
                    // return;
                }
                deleteAnswerFunc(idx, questionIdx);
                return
            }
            deleteAnswerFunc(idx, questionIdx);
            return
        }
        deleteAnswerFunc(idx, questionIdx);
    }

    const handleDelete = async (i) => {
        setShowDeleteDialog(false);
        if (i.type === 'CLEAR') {
            handleClearAll();
            return;
        }
        if (i.type === 'Question') {
            handleDeleteQuestion(i.questionIdx);
        }
        else if (i.type === 'Answer') {
            handleDeleteAnswer(i.questionIdx, i.answerIdx);
        }
    }

    const handleQuestionType = (e, id) => {
        let value = e.target.value;
        let tempObj = [...formQuestion];
        if (value === "Single") {
            tempObj[id].type = "SINGLE_CHOICE_QUESTION";

            tempObj[id].answers.map((element, idx) => {
                tempObj[id].answers[idx].value = false;
            })
        }
        else if (value === "Multiple") {
            tempObj[id].type = "MULTI_CHOICE_QUESTION";
        }
        else if (value === "Open ended") {
            tempObj[id].type = "OPEN_ENDED";
            tempObj[id].answers = [];
        }
        else if (value === "Rating") {
            // By Defalut in Rating, Star is Selected
            tempObj[id].type = "STAR_RATING";
            tempObj[id].answers = [];
            tempObj.options = {
                maxLimit: 5,
                minLimit: 0
            }
        }
        else if (value === "Date") {
            tempObj[id].type = "DATE";
        }
        else if (value === "Custom") {
            tempObj[id].type = "CUSTOM";
        }

        setFormQuestion(tempObj)
    }

    const handleInputAnswer = (e, questionIdx, answerIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].answers[answerIdx].option = e.target.value;
        tempObj[questionIdx].answers[answerIdx].isAnswerEmpty = false;
        // console.log(tempObj);
        setFormQuestion(tempObj)

        //  Validations
        // handleAnswerValidation(e.target.value, questionIdx, answerIdx) // uncomment this to enable validations 
    }

    const handleCorrectIncorrectOption = (questionIdx, answerIdx) => {
        let tempObj = [...formQuestion];
        if (tempObj[questionIdx].type === "SINGLE_CHOICE_QUESTION") {
            tempObj[questionIdx].answers.map((element, idx) => {
                if (tempObj[questionIdx].answers[idx].value) {
                    tempObj[questionIdx].answers[idx].value = false;
                    setMaxScore(maxScore - 10);
                    maxScoreRef.current -= 10;
                }
            })
        }

        if (tempObj[questionIdx].answers[answerIdx].value === false) {
            maxScoreRef.current += 10;
            setMaxScore(maxScore + 10);
            tempObj[questionIdx].answers[answerIdx].value = true;
        }
        else {
            maxScoreRef.current -= 10;
            setMaxScore(maxScore - 10)
            tempObj[questionIdx].answers[answerIdx].value = false;
        }
        setFormQuestion(tempObj);
    }

    const handleAddAnswer = (questionIdx, typeAddAnswer = "") => {
        let tempObj = [...formQuestion];
        let tempAnsObj = [...tempObj[questionIdx].answers];

        let answerTemp = answersData;
        answerTemp.type = "FIXED"
        tempAnsObj = [...tempObj[questionIdx].answers, answersData];
        tempObj[questionIdx].answers = tempAnsObj;

        tempObj.map((obj) => {
            var tempAns = obj.answers;
            tempAns.map((ans, idx) => {
                ans.id = idx;
                ans.sequence = idx + 1;
            })
            obj.answers = tempAns;
        })

        setFormQuestion(tempObj);

        // handle validation for answer
        // // <=== uncomment this to enable validations ====>
        // let tempQuestionValidation = [...questionValidations]
        // let tempResponseValidation = [...tempQuestionValidation[questionIdx].answers];
        // tempResponseValidation.push(ansValidation);
        // tempQuestionValidation[questionIdx].answers = [...tempResponseValidation]

        // setQuestionValidations(tempQuestionValidation);
        // // <=== uncomment this to enable validations ====>
    }

    const handleMandatory = (e, questionIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].mandatory = !tempObj[questionIdx].mandatory;
        setFormQuestion(tempObj)
    }

    const handleScoreToBeAddedInQuestion = (e, questionIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].isScore = !tempObj[questionIdx].isScore;
        setFormQuestion(tempObj);
    }

    const handleScorePerResponse = (e, questionIdx) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].scorePerResponse = (e.target.value);
        setFormQuestion(tempObj);
    }

    const updateFormButton = () => {
        if (isEmpty(formName) || isEmpty(formDescription)) {
            setFailedResponseText('Please fill the form name or description');
            setFailureAddQuestion(true);
            return
        }
        if (formName.length > formNameCharLimit || formDescription.length > formDescCharLimit) {
            setFailedResponseText('Form Name or Description is exceeding the limit');
            setFailureAddQuestion(true);
            return
        }
        let tempObj = [...formQuestion];
        let validatedList = formsValidations.validateList(formQuestion, isAssessMent);
        let { isValid, idx } = formsValidations.isEachListitemValid(validatedList);
        let tempCollapse = []
        setFormQuestion(validatedList);
        setCollapseList(tempCollapse)
        if (!isValid) {
            setFailedResponseText('Invalid Question');
            setFailureAddQuestion(true);
            return
        }

        if (tempObj.length === 0) {
            setFailedResponseText("Please add questions")
            setFailureAddQuestion(true)
            return
        }

        if (minQuestionAttempt < 1) {
            setFailedResponseText("Minimum question attempt should be greater than 0");
            setFailureAddQuestion(true)
            return
        }
        globalStates.handleProgressDialog(true, 'Please wait...');

        tempObj.map((obj) => {
            if (obj.isScore === false) obj.scorePerResponse = 0;
            obj.scorePerResponse = parseInt(obj.scorePerResponse);
        });

        var score = 0;
        for (const question of tempObj) {
            if (question.type === 'SINGLE_CHOICE_QUESTION') {
                let tempAnswer = question.answers;
                tempAnswer = tempAnswer.filter((ques) => ques.value === true);
                score += tempAnswer.length * question.scorePerResponse;
            } else if (question.type === 'MULTI_CHOICE_QUESTION') {
                let tempAnswer = question.answers;
                tempAnswer = tempAnswer.filter((ques) => ques.value === true);
                score += tempAnswer.length * question.scorePerResponse;
            } else if (question.type === 'OPEN_ENDED') {
                score += question.scorePerResponse;
            } else if (question.type === 'STAR_RATING') {
                score += question.scorePerResponse;
            } else if (question.type === 'NUMBER_RATING') {
                score += question.scorePerResponse;
            } else if (question.type === 'DATE') {
                score += question.scorePerResponse;
            } else if (question.type === 'CUSTOM') {
                score += question.scorePerResponse;
            }
        }

        if (isAssessMent && score < minScore) {
            globalStates.handleProgressDialog(false);
            setFailedResponseText('Minimum Score should be less than Total Score');
            setFailureAddQuestion(true);
            return
        }

        let submitData = finalData;
        submitData.formId = formId;
        submitData.companyId = companyId;
        submitData.name = formName;
        submitData.assessment = isAssessMent;
        submitData.timeLimit = parseInt(timeLimit);
        submitData.showScore = showScore;
        submitData.description = formDescription;
        submitData.maxScore = score;
        submitData.questions = [...tempObj];
        submitData.settings = { randomizeQuestions, randomizeAnswers, minScore, minQuestionAttempt };
        addQuestion(submitData)
    }

    const handleMinScore = (value) => {
        setMinScore(value);
    }

    const handleMinQuestionAttempt = (e) => {
        setMinQuestionAttempt(e.target.value);
    }

    const handleFailureClose = () => {
        setFailureAddQuestion(!failureAddQuestion);
    }

    const handleSuccessClose = () => {
        setSuccessAddQuestion(!successAddQuestion);
        if (microSkillId) {
            router.history.push(`/microskill-studio/form-based-challenge?c_id=${companyId}&&ms_id=${microSkillId}&&seq=${sequence}&&formId=${formId}`)
        }
        else
            router.history.push(`/add-forms?c_id=${companyId}`);
    }

    const handleChangeFormName = (value) => {
        setFormName(value);
        if (value.length > formNameCharLimit) {
            setFormNameValidation({ exceeded: true, limitText: `${value.length - formNameCharLimit} Characters Exceeded`, isEmpty: false });
        }
        else {
            setFormNameValidation({ exceeded: false, limitText: `${formNameCharLimit - value.length} Characters`, isEmpty: false });
        }

    }

    const handleFormDescription = (value) => {
        setFormDescription(value);
        if (value.length > formDescCharLimit) {
            setFormDescValidation({ exceeded: true, limitText: `${value.length - formDescCharLimit} Characters Exceeded`, isEmpty: false });
        }
        else {
            setFormDescValidation({ exceeded: false, limitText: `${formDescCharLimit - value.length} Characters`, isEmpty: false });
        }
    }

    const handleAssementChange = () => {
        if (isAssessMent === false) {
            const tempObj = [...formQuestion];

            tempObj.map((ques) => {
                if (ques.type !== 'SINGLE_CHOICE_QUESTION' && ques.type !== 'MULTI_CHOICE_QUESTION') {
                    ques.type = 'SINGLE_CHOICE_QUESTION';
                    ques.options = [];
                }
            })
        }

        setIsAssesment(!isAssessMent);
    }

    const handleShowScore = () => {
        setShowScore(!showScore);
    }

    const handleRandomizeQuestions = () => {
        setRandomizeQuestions(!randomizeQuestions);
    }

    const handleRandomizeAnswers = () => {
        setRandomizeAnswers(!randomizeAnswers);
    }

    const handleTimeLimit = (e) => {
        setTimeLimit(e.target.value);
    }

    const handleIncrementTime = () => {
        setTimeLimit(timeLimit + 1);
    }

    const handleDecrementTime = () => {
        if (timeLimit > 0) {
            setTimeLimit(timeLimit - 1);
        }
    }

    const handleCollapse = (idx) => {
        let tempCollapseList = [...collapseList];
        if (tempCollapseList.includes(idx)) {
            let filtered = tempCollapseList.filter(ele => ele !== idx);
            setCollapseList(filtered);
            return;
        }
        tempCollapseList.push(idx);
        setCollapseList(tempCollapseList);
    }

    const FORM_SETTINGS = [
        {
            index: 1,
            label: 'Assessment',
            checked: isAssessMent,
            handleChange: handleAssementChange
        },
        {
            index: 2,
            label: 'Show Score to Learners',
            checked: showScore,
            handleChange: handleShowScore
        },
        {
            index: 3,
            label: 'Randomize Questions',
            checked: randomizeQuestions,
            handleChange: handleRandomizeQuestions
        },
        {
            index: 4,
            label: 'Randomize Answers',
            checked: randomizeAnswers,
            handleChange: handleRandomizeAnswers
        }
    ]

    const isCsvFormatValid = (fields = []) => {
        let formCsvHeaders = FORM_CSV_HEADERS
        let isValid = false;

        if (fields.length !== formCsvHeaders.length) return isValid;
        for (let i = 0; i < formCsvHeaders.length; i++) {
            if (!fields.includes(formCsvHeaders[i])) {
                isValid = false;
                break;
            } else {
                isValid = true;
            }
        }
        return isValid;

    }

    const updateProgress = () => {
        timer = setInterval(() => {
            setProgress((prevProgress) => {
                let random = prevProgress + Math.floor(Math.random() * 20);
                if (prevProgress >= 100 || random >= 100) {
                    clearInterval(timer);
                    setIntervalCleared(true);
                    return 100
                } else {
                    return random
                }
            });
        }, 300);
    };

    const handleParseQuestionsFromCsv = (parsedCsvData) => {
        const filteredData = parsedCsvData.filter(row => {
            if (!isEmpty(row[FORM_CSV_HEADERS[0]])) return row
        })
        let tempFormQstnArr = []

        filteredData.forEach((data, qIndex) => {
            let tempQstndata = { ...QuestionData };
            tempQstndata.question = data[FORM_CSV_HEADERS[0]];
            tempQstndata.type = QUESTION_TYPES[data[FORM_CSV_HEADERS[1]]];
            if (!isEmpty(data[FORM_CSV_HEADERS[2]])) {
                tempQstndata.scorePerResponse = data[FORM_CSV_HEADERS[2]]
                tempQstndata.isScore = true;
            }
            tempQstndata.validations = [];

            let tempAnswerList = []
            FORM_CSV_HEADERS.slice(3, -1).forEach((ans, index) => {
                let tempAnsData = { ...answersData };
                if (!isEmpty(data[ans])) {
                    // tempAnsData.idx = tempAnswerList.length;
                    // tempAnsData.sequence = tempAnswerList.length + 1;
                    tempAnsData.option = data[ans];
                    tempAnsData.type = 'FIXED'

                    let correctOptionsArr = data[FORM_CSV_HEADERS.at(-1)].split(",").map(opt => parseInt(opt));
                    let correctOptions = data[FORM_CSV_HEADERS[1]] === CSV_QUESTION_TYPES.SINGLE_CHOICE ? correctOptionsArr.slice(0, 1) : correctOptionsArr
                    if (correctOptions.includes(index + 1)) {
                        tempAnsData.value = true
                    }
                    tempAnswerList.push(tempAnsData);
                }
            })
            tempQstndata.answers = tempAnswerList.map((e, i) => ({ ...e, id: i, sequence: i + 1 })); // to insert id and sequence acc to elements
            // console.log(tempQstndata);
            tempFormQstnArr.push(tempQstndata);
        })

        setFormQuestion(prev => {
            let prevArr = [...prev]
            if (prevArr.length <= 1 && isEmpty(prevArr[0].question)) {
                prevArr = [];
            }

            // to insert id and sequence acc to previous questions
            tempFormQstnArr = tempFormQstnArr.map((e, i) => ({ ...e, id: prevArr.length + i, sequence: prevArr.length + i + 1 }))

            let returningObj = [...prevArr, ...tempFormQstnArr]
            setMinQuestionAttempt(returningObj.length)
            return returningObj
        })
    }

    const handleCsvUpload = (file) => {
        if (!file) return

        setLoadingCsv(true)
        setProgress(0);
        setCollapseList([]);
        // setAddContextBtn(false);
        // setIsRestricted(true);
        setFileName(file.name);

        Papa.parse(file, {
            header: true, // Assumes the first row contains column headers
            dynamicTyping: false, // Automatically parse numbers and booleans
            complete: (result) => {
                // The parsed data is available in result.data
                let fields = result.meta.fields || [];
                if (isCsvFormatValid(fields)) {
                    setCsvData(result.data);
                    handleParseQuestionsFromCsv(result.data)
                    updateProgress();
                } else {
                    globalStates.handleToast(true, 'Incorrect CSV uploaded, please retry!', 'error');
                    return
                }
            },
            error: (error) => {
                // console.error('CSV Parsing Error:', error.message);
                globalStates.handleToast(true, 'Failed to upload csv', 'error');
                setLoadingCsv(false);
            },
        });
        csvUploadInputRef.current.value = null
        setLoadingCsv(false)
    }

    const handleClearAll = async () => {
        const payload = {
            companyId,
            formId
        }
        const response = await formServices.deleteAllContent(payload);
        if ([200, 201].includes(response.status)) {
            window.location.reload();
        } else {
            console.log(response.data)
        }
    }

    /**
     * @download form download feature
     * this function is to download existing form data into CSV file
    */
    const handleParseQuestionToCsv = async () => {
        if (isEmpty(formQuestion)) {
            globalStates.handleToast(true, 'No data found to download', 'error')
            return
        }
        if (formQuestion.length <= 1 && isEmpty(formQuestion[0].question)) {
            globalStates.handleToast(true, 'No data found to download', 'error')
            return
        }

        let isFormDownloadable = [...formQuestion].filter(e => {
            if (e.type !== QUESTION_TYPES.SINGLE_CHOICE && e.type !== QUESTION_TYPES.MULTIPLE_CHOICE) {
                return e
            }
        })

        if (isFormDownloadable.length > 0) {
            globalStates.handleToast(true, 'This form can not be downloadable, as it contains mix type of questions', 'error')
            return
        }

        let workbook = new Excel.Workbook();

        let sheet = workbook.addWorksheet('form-csv-format');
        const cell_formats = FORM_CSV_HEADERS.map((e, i) => ({ header: e, key: FORM_CSV_KEYS[i] }))
        sheet.columns = cell_formats

        formQuestion.forEach((elem, index) => {
            let rowData = {};

            rowData[FORM_CSV_KEYS[0]] = elem.question
            rowData[FORM_CSV_KEYS[1]] = elem.type === QUESTION_TYPES.SINGLE_CHOICE ? CSV_QUESTION_TYPES.SINGLE_CHOICE : CSV_QUESTION_TYPES.MULTIPLE_CHOICE
            rowData[FORM_CSV_KEYS[2]] = elem.isScore ? elem.scorePerResponse : '';

            let correctAnswers = ''
            elem.answers.slice(0, 10).sort((a, b) => a.sequence - b.sequence).forEach((e, i) => {
                rowData[`answer${i + 1}`] = e.option
                if (e.value) {
                    correctAnswers += correctAnswers.length <= 0 ? `${i + 1}` : `,${i + 1}`
                }
            })
            rowData[FORM_CSV_KEYS.at(-1)] = correctAnswers

            sheet.addRow(rowData);
        })


        let array_buff = await workbook.csv.writeBuffer();

        let buff = new Blob([array_buff]);
        //console.log(new Blob(buff));

        saveAs(buff, 'form-csv-format.csv', { autoBOM: true });
    }

    if (loadingData) {
        return <LoadingContainer />
    }

    return (
        <div className={styles.MainContainer}>
            <div className={`${styles.settingsContainer} ${styles.orangeScrollbar}`}>
                <div className={styles.csvUploadContainer}>
                    <Label labelText={"CSV Upload:"} fs='0.8rem' p='0.5rem 0' />
                    {loadingCsv ? <div className={`${styles.uploadProgressBox}`}>
                        <i className="fa-solid fa-file-csv"></i>
                        <div>
                            <h6>{fileName}</h6>
                            <LinearWithValueLabel progress={progress} />
                        </div>
                    </div> : null}
                    <div className={`${styles.csvUploadBoxContainer} ${styles.centerFlex}`}>
                        <div className={styles.csvUploadBox}>
                            <label className={styles.uploadIcon}>
                                <i className="fa-solid fa-cloud-arrow-up"></i>
                                <input
                                    ref={csvUploadInputRef}
                                    style={{ display: 'none' }}
                                    type='file'
                                    accept='.csv'
                                    onChange={(event) => handleCsvUpload(event.target.files[0])}
                                />
                            </label>
                            <h4>Upload CSV file here</h4>
                            <h5>or</h5>
                            <a
                                href={"/files/form-csv-format/form-csv-format.csv"}
                                target="_blank"
                                download
                            >
                                Download CSV format
                            </a>
                        </div>
                    </div>
                </div>
                {/* <<=== FOR FUTURE USE ===>> */}
                {/* <Button
                    variant='contained'
                    className={styles.addQuesBtn}
                    disableElevation
                    onClick={handleParseQuestionToCsv}
                >
                    Download Questions
                </Button> */}
                <div className={styles.labelContainer}>
                    <CustomInput
                        id="FormName"
                        defaultValue={formName}
                        handleChange={(e) => handleChangeFormName(e.target.value)}
                        withLabel={true}
                        label="Name:"
                        exceeded={formNameValidation.exceeded}
                        isEmpty={formNameValidation.isEmpty}
                        limitText={formNameValidation.limitText}
                    />
                </div>
                <div className={`${styles.labelContainer}`} style={{ position: 'relative', paddingBottom: '1rem' }}>
                    <label htmlFor="FormDescription" className={`${styles.Label} py-0_5`}>
                        Description:
                    </label>
                    <textarea
                        className={`${styles.Input_Box} ${styles.TextArea}`}
                        id="FormDescription"
                        name="FormDescription"
                        // style={{ outlineColor: formDescValidation.exceeded ? 'red': "" }}
                        value={formDescription}
                        onChange={(e) => handleFormDescription(e.target.value)}
                    />
                    <span className={styles.descLimitText}>{formDescValidation.limitText}</span>
                </div>

                <div className={`${styles.centerFlex} ${styles.formOptionContainer}`}>
                    {
                        FORM_SETTINGS.map((setting, idx) => (
                            <div key={idx} className={`${styles.centerFlex} cursor-p`} onClick={setting.handleChange}>
                                <Checkbox
                                    checked={setting.checked}
                                    size='small'
                                    className="text-orange"
                                    sx={{
                                        paddingLeft: '0.5rem',
                                        marginLeft: '-0.5rem',
                                    }}
                                />
                                <Typography className={styles.Label}>{setting.label}</Typography>
                            </div>
                        ))
                    }
                </div>

                <div className={styles.labelContainer}>
                    <label htmlFor='TimeLimit' className={styles.Label}>
                        Time Limit:
                    </label>
                    <div className={`${styles.centerFlex} ${styles.timeLimitContainer}`}>
                        <div style={{ width: "10rem" }}>
                            <CustomInput
                                defaultValue={timeLimit}
                                handleChange={(e) => handleTimeLimit(e)}
                                withLabel={false}
                            />
                        </div>
                        <div className={`${styles.centerFlex} ${styles.timeLimitArrowContainer}`}>
                            <div className={`${styles.centerFlex} ${styles.timeLimitArrows}`}>
                                <KeyboardArrowUpIcon onClick={handleIncrementTime} style={{ fontSize: "1rem" }} />
                                <KeyboardArrowDownIcon onClick={handleDecrementTime} style={{ fontSize: "1rem" }} />
                            </div>
                            <div>Seconds</div>
                        </div>
                    </div>
                </div>

                {isAssessMent && (
                    <div className={styles.labelContainer}>
                        <label htmlFor='TimeLimit' className={styles.Label}>
                            Min Score:
                        </label>
                        <div className={`${styles.centerFlex} ${styles.timeLimitContainer}`}>
                            <CustomInput
                                wrapperStyling={{ width: '10rem' }}
                                defaultValue={minScore}
                                handleChange={(e) => handleMinScore(e.target.value)}
                                withLabel={false}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.labelContainer}>
                    <label htmlFor='TimeLimit' className={styles.Label}>
                        Questions to Attempt:
                    </label>
                    <div className={`${styles.centerFlex} ${styles.timeLimitContainer}`}>
                        <CustomInput
                            wrapperStyling={{ width: '10rem' }}
                            defaultValue={minQuestionAttempt}
                            handleChange={(e) => handleMinQuestionAttempt(e)}
                            withLabel={false}
                        />
                    </div>
                </div>

                <div className={styles.btnBox}>
                    <Button
                        key={"next"}
                        variant="contained"
                        className={styles.saveBtn}
                        onClick={updateFormButton}
                    >
                        NEXT
                    </Button>
                    <Button
                        key={"clear"}
                        title='Clear all'
                        className={`${styles.btn} ${styles.clearBtn}`}
                        onClick={() => {
                            setDeleteParameters({ type: 'CLEAR' })
                            setShowDeleteDialog(true);
                        }}
                    >
                        CLEAR & DELETE
                    </Button>
                </div>

            </div>
            <div className={`${styles.formQuestionContainer} ${styles.orangeScrollbar}`}>
                {formQuestion.map((item, i) => (
                    <React.Fragment key={i}>
                        <div key={item.id} style={{ width: '95%', marginBottom: '1rem' }}>
                            <div className={styles.FormQuestionHeading} onClick={() => handleCollapse(i)}>
                                <span>
                                    <i className={`fa-solid ${collapseList.includes(i) ? 'fa-angle-up' : 'fa-angle-down'} ${styles.dropDownIcon}`} />
                                    {!collapseList.includes(i) && <i className={`fa-solid ${item.validations.length > 0 ? "fa-circle-exclamation" : "fa-circle-check"} ${item.validations.length ? styles.unValidatedQuestionIcon : styles.validatedQuestionIcon}`} />}
                                    Question {i + 1}:
                                </span>
                                <div className={`${styles.centerFlex} ${styles.errorContainer}`}>
                                    {item.validations.length !== 0 && (
                                        <div className={`${styles.errorText}`} dataHover={`${item.validations}`}>
                                            <i className={`fa-solid fa-circle-exclamation ${styles.unValidatedQuestionIcon}`} />
                                            {item.validations.length === 1 ? item.validations[0] : `${item.validations.length} error occured`}
                                        </div>
                                    )}
                                    <DeleteIcon
                                        key={i}
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            setShowDeleteDialog(true);
                                            setDeleteParameters({ questionIdx: i, answerIdx: undefined, type: 'Question' })
                                        }} />
                                </div>
                            </div>
                            {collapseList.includes(i) && (
                                <>
                                    <CustomInput
                                        id={i.toString()}
                                        defaultValue={item.question}
                                        handleChange={(e) => handleInputQuestion(e.target.value, i)}
                                        isDisabledTextLimitContainer={true}
                                    // isEmpty={item.isQuestionEmpty}
                                    // maxLength={questionCharLimit}
                                    // isExceeded={questionValidations[i]?.exceeded}
                                    // limitText={questionValidations[i]?.limitText}
                                    />
                                    <div style={{ display: "flex", width: "100%", flexDirection: 'column', rowGap: '0.5rem' }}>
                                        <div style={{ display: "flex", justifyContent: 'flex-start' }}>
                                            <FormControlLabel
                                                sx={{ marginLeft: 0 }}
                                                onChange={(e) => handleMandatory(e, i)}
                                                control={
                                                    <Checkbox
                                                        size='small'
                                                        checked={item.mandatory}
                                                        style={{ padding: "0.5rem", color: "#F4511E", marginLeft: '-0.5rem' }}
                                                    />
                                                }
                                                label={<Typography className={styles.Label}>Mandatory</Typography>}
                                            />
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            <Checkbox size='small' checked={item.isScore} onClick={(e) => handleScoreToBeAddedInQuestion(e, i)} style={{ padding: "0.5rem", color: "#F4511E", marginLeft: '-0.5rem' }} />
                                            <Typography className={styles.Label} sx={{ paddingRight: '0.5rem' }}>Score Per Response:</Typography>
                                            <CustomInput
                                                wrapperStyling={{ width: "calc(5rem - 4px)" }}
                                                defaultValue={item.scorePerResponse}
                                                handleChange={(e) => handleScorePerResponse(e, i)}
                                                isDisabledTextLimitContainer={true}
                                                isDisabled={item.isScore ? false : true}
                                                withLabel={false}
                                            />
                                        </div>
                                    </div>

                                    <TypeQuestion
                                        id={i}
                                        handleQuestionType={handleQuestionType}
                                        QuestionType={item.type}
                                        isAssessment={isAssessMent}
                                    />

                                    {
                                        (item.type === 'SINGLE_CHOICE_QUESTION' || item.type === 'MULTI_CHOICE_QUESTION') &&
                                        <ResponseQuestions
                                            questionIdx={i}
                                            answers={item.answers}
                                            typeQuestion={item.type}
                                            handleInputAnswer={handleInputAnswer}
                                            validation={questionValidations[i]?.answers}
                                            handleCorrectIncorrectOption={handleCorrectIncorrectOption}
                                            handleAddAnswer={handleAddAnswer}
                                            setShowDeleteDialog={setShowDeleteDialog}
                                            setDeleteParameters={setDeleteParameters}

                                        />
                                    }

                                    {
                                        (item.type === 'STAR_RATING' || item.type === 'NUMBER_RATING') &&
                                        <RatingResponses
                                            questionIdx={i}
                                            formQuestion={formQuestion}
                                            setFormQuestion={setFormQuestion}
                                        />
                                    }
                                </>
                            )}
                        </div>

                        <Divider style={{ margin: '1rem 0', width: '95%' }} />
                    </React.Fragment>
                ))}

                <Button
                    variant='contained'
                    startIcon={<AddIcon />}
                    className={styles.addQuesBtn}
                    disableElevation
                    onClick={handleAddQuestion}
                >
                    Add Questions
                </Button>

                {
                    failureAddQuestion === true &&
                    <FailureDialog
                        setErrorDialogOpen={setFailureAddQuestion}
                        errorDialogOpen={failureAddQuestion}
                        dialogText={failedResponseText}
                        handleClick={handleFailureClose}
                    />
                }

                {
                    successAddQuestion === true &&
                    <SuccessDialog
                        successDialogOpen={successAddQuestion}
                        setSuccessDialogOpen={setSuccessAddQuestion}
                        dialogText={successResponseText}
                        handleClick={handleSuccessClose}
                    />
                }

                {
                    showDeleteDialog === true &&
                    <DeleteDialog
                        id={deleteParameters}
                        dialogText={deleteParameters.type}
                        confirmDeleteDialog={showDeleteDialog}
                        setConfirmDeleteDialog={setShowDeleteDialog}
                        handleDelete={handleDelete}
                    />
                }
            </div>
            <div className={styles.SecondConatiner}>
                <div className={`${styles.centerFlex}`} style={{ height: '100%' }}>
                    <img src="/images/form-assests/form-mobile.png" alt="Mobile-Preview-Form" />
                </div>
            </div>
        </div>
    )
}

export default FormsQuestion;