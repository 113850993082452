import React, { useState, useContext, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SetReportScheduleModal from '../report-schedule-modal';
import reportsServices from '../../../../services/reports';
import { GlobalStates } from "../../../../App";
import isEmpty from "../../../../utils/isEmpty";
import useQuery from '../../../../utils/useQuery';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: theme.spacing(2),
        boxShadow: theme.shadows[3],
    },
    header: {
        color: theme.palette.common.black,
        fontWeight: "bold",
    },
    actionIcons: {
        display: "flex",
        gap: theme.spacing(1),
    },
    tableRow: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
    },
}));

const SchedulesHistory = () => {
    const classes = useStyles();
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [emailArray, setEmailArray] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleTime, setScheduleTime] = useState(null);
    const [frequency, setFrequency] = useState("Daily");
    const [globalStates] = useContext(GlobalStates);
    const [schedulesData, setSchedulesData] = useState([]);
    const [reportPeriod, setReportPeriod] = useState("Current Month");
    const [scheduleId, setScheduleId] = useState(null);
    const [templateIds, setTemplateIds] = useState([]);

    let query = useQuery();
    const companyId = query.get("c_id");

    useEffect(() => {
        getScheduleList()
    }, [])

    const getScheduleList = async () => {
        const response = await reportsServices.customReportScheduleList(companyId);
        if (response.status === 200) {
            setSchedulesData(response.data.data);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    }

    const handleSchedule = async () => {
        const schedulePayload = {
            emailArray: emailArray.split(','),
            startDate: startDate,
            endDate: endDate,
            scheduleTime: scheduleTime,
            frequency,
            report_duration: reportPeriod,
            id: scheduleId,
            companyId: companyId,
            templateIds: templateIds,
        }
        const response = await reportsServices.customReportScheduleUpdate(schedulePayload);
        if (response.status === 200) {
            let message = `Report Schedule Updated Successfully`
            globalStates.handleToast(true, message, "success");
            setIsReportModalOpen(prev => !prev);
            getScheduleList();
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    }

    const handleDeleteTemplate = async (id) => {
        let confirm = window.confirm("Do you really want to delete this template?");
        if (!confirm) return
        globalStates.handleProgressDialog(true, 'Please wait...')
        const response = await reportsServices.deleteScheduleReport({ id });
        globalStates.handleProgressDialog(false);

        if (isEmpty(response)) {
            globalStates.handleToast(true, "Request timeout", "error");
            return
        }
        if (response.status === 200 || response.status === 201) {
            globalStates.handleToast(true, 'Deleted succesfully!', "success");
            const filterData = schedulesData.filter((ele) => ele.id !== id);
            setSchedulesData(filterData);
        } else {
            globalStates.handleToast(true, "Failed to delete, please try again!", "error");
        }
    }

    const editSchedule = (emailArray, startDate, endDate, scheduleTime, reportPeriod, frequency, id, temIds) => {
        setEmailArray(emailArray.join(','));
        setStartDate(startDate);
        setEndDate(endDate);
        setScheduleTime(scheduleTime);
        setReportPeriod(reportPeriod);
        setFrequency(frequency);
        setIsReportModalOpen(prev => !prev);
        setScheduleId(id);
        setTemplateIds([...temIds])
    }

    return (
        <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
                <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                    <TableRow>
                        <TableCell className={classes.header}>Email ID</TableCell>
                        <TableCell className={classes.header}>Report</TableCell>
                        <TableCell className={classes.header}>Frequency</TableCell>
                        <TableCell className={classes.header}>End Date</TableCell>
                        <TableCell className={classes.header}>Report Period</TableCell>
                        <TableCell className={classes.header}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {schedulesData.map((row, index) => (
                        <TableRow key={index} className={classes.tableRow}>
                            <TableCell>{(row.settings.emailArray).join(',')}</TableCell>
                            {/* <TableCell>{row.template.title}</TableCell> */}
                            <TableCell>{(row?.settings?.titles)?.join(', ')}</TableCell>
                            <TableCell>{row.settings.frequency}</TableCell>
                            <TableCell>{row.settings.endDate}</TableCell>
                            <TableCell>{row.settings.report_duration}</TableCell>
                            <TableCell>
                                <div className={classes.actionIcons}>
                                    <IconButton>
                                        <EditIcon style={{ color: '#4d4d4d' }} onClick={() => editSchedule(row.settings.emailArray, row.settings.startDate, row.settings.endDate, row.settings.scheduleTime, row.settings.report_duration, row.settings.frequency, row.id, row.settings?.templateIds)} />
                                    </IconButton>
                                    <IconButton>
                                        <DeleteIcon style={{ color: '#4d4d4d' }} onClick={() => handleDeleteTemplate(row.id)} />
                                    </IconButton>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <SetReportScheduleModal
                open={isReportModalOpen}
                onClose={() => setIsReportModalOpen(prev => !prev)}
                emailArray={emailArray}
                setEmailArray={setEmailArray}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                scheduleTime={scheduleTime}
                setScheduleTime={setScheduleTime}
                frequency={frequency}
                setFrequency={setFrequency}
                handleSchedule={handleSchedule}
                isReportHistory={true}
                reportPeriod={reportPeriod}
                setReportPeriod={setReportPeriod}
                isScheduleUpdate={true}
            />
        </TableContainer>
    );
};

export default SchedulesHistory;