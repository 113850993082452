import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    main: {
        // border : '1px solid black',
        display: 'flex',
        position: 'relative',
        backgroundColor: 'rgb(149, 152, 154, 0.1)',
        height: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        '& nav': {
            height: '3.8rem',
            // background: '#fff',
            borderBottom: '1px solid rgb(112,112,112,0.2)'
            // borderBottom: "1px solid rgb(221, 224, 226, 0.5)",
        },
        '& > button':{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
        }
    },
    header: {
        // border: '1px solid',
        backgroundColor: '#F9F9F9',
        height: '3.4rem',
        display: 'flex',
        width: '50%',
        alignItems: 'center',
        paddingLeft: '1rem',
        gap: '1rem',
        position: 'absolute',
        top: '-3.4rem',
        left: 0,
        zIndex: 9999,
        '& > img': {
            cursor: 'pointer',
            transition: 'width 2s',
            '&:hover': {
                width: '1.2rem',
                marginRight: '-0.11rem'
            }
        },
        '& h1': {
            color: "rgb(0,0,0,0.9)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "700",
            '& img': {
                height: '0.8rem',
                width: '0.8rem',
                marginLeft: '2rem',
                '&:hover': {
                    cursor: 'pointer',
                    filter: 'invert(42%) sepia(69%) saturate(4247%) hue-rotate(352deg) brightness(100%) contrast(92%)'
                }
            }
        },

    },
    editName: {
        display: 'flex',
        alignItems: 'center',
        '& > input': {
            border: 'none',
            backgroundColor: '#F9F9F9',
            padding: '0.2rem 0.5rem',
            color: "rgb(0,0,0,0.9)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "700",
            borderBottom: '1px solid #f4511e',
            '&:focus': {
                outline: 'none',
                borderBottom: '2px solid #f4511e',
            }
        },
        '& .MuiButtonBase-root': {
            marginLeft: '2rem',
            height: '1.5rem',
            width: '2rem',
            borderRadius: '4px',
            backgroundColor: '#f4511e',
            color: '#fff',
            border: '2px solid #f4511e',
            fontWeight: 700,
            letterSpacing: '1px',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
            }
        }
    },
    minTimeSpent: {
        // border: '1px solid red',
        position: 'absolute',
        minWidth: 'max-content',
        left: '33rem',
        display: 'flex',
        gap: '0.5rem',
        alignItems: 'center',
        '& > input': {
            border: '2px solid #70707080',
            width: ({ minTimeSpent }) => minTimeSpent && minTimeSpent.toString().length > 1 ? `calc(${minTimeSpent.toString().length} * 15px)` : '30px',
            // width: '30px',
            borderRadius: 2,
            padding: '0.2rem 0.3rem',
            color: "rgba(112, 112, 112,0.9)",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "600",
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
        },
        '& > i': {
            color: '#f4511e',
            fontSize: '1rem',
            scale: '1.2',
            transition: 'scale 0.5s',
            marginLeft: '1rem',
            '&:hover': {
                scale: '1.4',
                cursor: 'pointer'
            }
        },
    },
    column1: {
        // border : '1px solid black',
        width: '28%',
        height: '100%',
        borderRight: "1.5px solid rgb(221, 224, 226, 0.5)",
        // padding : '1.2rem',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '3px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px',
        }
    },
    column2: {
        backgroundColor: 'rgb(149, 152, 154, 0.1)',
        // width: '100%',
        // width: '72%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        // justifyContent: 'center',
        // padding: '0.5rem'
    },
    disableEvents: {
        pointerEvents: 'none'
    },

    importBtnBox: {
        padding: '2rem 2.5rem',
        paddingTop: '0.5rem'
    },


    // images Loader css for ppt import
    imagesLoadingWrapper: {
        display: 'grid',
        gridTemplateColumns: '25% 75%',
        gap: '1rem',
        padding: '1rem',
        // height: '6rem',
        // maxHeight: '6rem',
    },
    loadingLottieContainer: {
        width: '100%',
        aspectRatio: '1 / 1',
        // height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '5px',
        border: '1px solid rgb(112, 112, 112, 0.2)',
    },

    linearProgressContainer: {
        paddingTop: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },

    linearProgressWrapper: {
        display: 'flex',
    },

    linearProgress : {
        backgroundColor: '#F2F2F2',
        '& span':{
            backgroundColor: '#f3c50e'
        },
        '& .MuiLinearProgress-bar1':{
            backgroundColor: '#f3c50e'
        }
    },
    typography : {
        color : '#f3c50e',
        fontSize : '0.7rem'
    },

    minTimeApplyToAllSection: {
        marginLeft: '1rem',
    },

}));

export default useStyles;