import React, { useContext, useState, useEffect, useRef } from 'react';


//mui
import { Box, Button, Typography, Modal, TextField, Stack, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

//styles
import useStyles from './index.style';

//components
import DemographicData from '../demographic-data';
import CustomDataPoint from '../custom-data-point';
import Label from '../../../custom-styled/label';
import CustomReportTable from '../custom-report-table';
import CustomSelect from "../../../custom-styled/custom-select";
import ControlledSwitches from '../../../custom-styled/switch-button';
import DatePicker from '../date-picker';
import MultipleFilter from '../multiple-filter';

//services
import reportServices from '../../../../services/reports';

//utils
import commonUtil from '../../../../utils/commonUtil';
import useQuery from '../../../../utils/useQuery';
import isEmpty from '../../../../utils/isEmpty';

//context
import { GlobalStates } from '../../../../App';
// constant
import analyticsConstant from '../../constant/analytics.constant';
import { v4 as uuid } from 'uuid';
import dayjs from 'dayjs';
import useRouter from "../../../../utils/useRouter";

import SetReportScheduleModal from '../report-schedule-modal';

import reportsServices from '../../../../services/reports';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const dateRangeTypeData = [
    {
        id: "LAUNCHESON",
        name: "Completion wise date range",
    },
    {
        id: "COMPLETION",
        name: "Launch wise date range",
    }

]

const ReportBuilder = ({ setBackdrop, contentFilter, setContentFilter, _applyContentFilter, _resetContentFilter, _handleContentSearch, _selectAllSubmenuCF, _handleSubmenuItemCF }) => {
    const classes = useStyles();
    let query = useQuery();
    const companyId = query.get("c_id");
    const template_Id = query.get("template_Id");
    const router = useRouter();
    const [globalStates] = useContext(GlobalStates);
    const [templateName, setTemplateName] = useState('');
    const [demographicFilter, setDemographicFilter] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [selectedDataPoint, setSelectedDataPoint] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({});
    let mappingObject = commonUtil.objectify(mapping, "name");
    const [open, setOpen] = useState(false);
    const [showGeneratedTable, setShowGeneratedTable] = useState(false);
    const [savedTemplates, setSavedTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState([]);
    const [allDemographicData, setAllDemographicData] = useState([]);
    const [selectedDemographicColumns, setSelectedDemographicColumns] = useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [selectedShortcut, setSelectedShortcut] = useState(null);
    const [launchWise, setLaunchWise] = useState(true);
    const [dateRangeType, setDateRangeType] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isTemplateEdited, setIsTemplateEdited] = useState(false);
    const [microskillALignmentSelected, setMicroskillALignmentSelected] = useState(null);
    const [defaultDemographicData, setDefaultDemographicData] = useState([]);
    const [isRedirectViaReports, setIsRedirectViaReports] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [reportType, setReportType] = useState('CUSTOM');
    const [headerFields, setHeaderFields] = useState([]);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [emailArray, setEmailArray] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [scheduleTime, setScheduleTime] = useState(null);
    const [frequency, setFrequency] = useState("Daily");
    const [reportPeriod, setReportPeriod] = useState("Current Month");
    const [isReplaceTemplate, setIsReplaceTemplate] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterPayload, setFilterPayload] = useState({ companyId, flag: "filter" });

    const templateIdRef = useRef(null);


    const today = dayjs();
    let minDate = dayjs(new Date('2022-01-01'));

    useEffect(() => {
        fetchSavedTemplated();
        if (!isEmpty(template_Id)) {
            setIsRedirectViaReports(true);
        }
    }, [])

    useEffect(() => {
        if (isRedirectViaReports && !isEmpty(allDemographicData)) {
            handleSelectTemplate(template_Id);
        }
    }, [allDemographicData, isRedirectViaReports])

    const fetchSavedTemplated = async () => {
        const response = await reportServices.fetchSavedCustomTemplates(companyId);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data.map(ele => ({ ...ele, name: ele.title }));
            setSavedTemplates(data);
        }
    }

    const validate = () => {
        if (!selectedDataPoint.length && reportType === 'CUSTOM') {
            globalStates.handleToast(true, 'Please select the data point', 'error');
            return false;
        }

        let isMicroskillSelected = false;
        let isDataPointsSelected = false;
        let isMasterOIdsSelected = false;
        let isNameNumberSelected = false;
        const dataPointIds = [
            'attempts', 'score', 'completion-status', 'content-launch-date',
            'challenges', 'completion-date', 'completed-in-days', 'max-score', 'time-spent'
        ];
        const microskillIds = ['microskill-id-columns', 'microskill-id-rows'];
        const nameNumberIds = ['name', 'number'];

        for (const { id, selectedValue, name } of selectedDataPoint) {
            if (!selectedValue.length) {
                globalStates.handleToast(true, `Please select the value for ${name}`, 'error');
                return false;
            }
            if (dataPointIds.includes(id)) isDataPointsSelected = true;
            if (microskillIds.includes(id)) isMicroskillSelected = true;
            if (nameNumberIds.includes(id)) isNameNumberSelected = true;
            if (id === 'mastero-id') isMasterOIdsSelected = true;
        }

        if (isDataPointsSelected && !isMicroskillSelected) {
            globalStates.handleToast(true, 'The specified data points require the selection of a microskill/s', 'error');
            return false;
        }
        if (isNameNumberSelected && !isMasterOIdsSelected) {
            globalStates.handleToast(true, 'The specified data points require the selection of a Master-O ID', 'error');
            return false;
        }

        return true;
    };

    const handleOpenModal = () => {
        const valid = validate()
        if (!valid) return
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleSaveTemplate = async (isSchedule) => {
        if (!templateName.length) {
            globalStates.handleToast(true, 'Please enter the template name', 'error')
            return
        }
        let payload = getPayload()
        globalStates.handleProgressDialog(true, 'Please wait...');
        let response;
        if (!isEmpty(selectedTemplate) && isReplaceTemplate) {
            payload = { ...payload, id: selectedTemplate[0] }
            response = await reportServices.customReportUpdateTemplate(payload);
        } else {
            response = await reportServices.customReportSaveTemplate(payload);
        }
        globalStates.handleProgressDialog(false);
        if (response && (response.status === 201 || response.status === 200)) {
            fetchSavedTemplated();
            setTemplateName('');
            if (isSchedule) {
                templateIdRef.current = response.data.data.id;
                setIsReportModalOpen(prev => !prev);
            }
            globalStates.handleToast(true, 'Template saved successfully!', 'success')
            if (isTemplateEdited) {
                setIsTemplateEdited(false);
            }
            handleClose();
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    };

    const getMappingDetails = (value) => mappingObject[value];

    const getSelectedDemographics = () => {
        let selectedFilters = [];
        for (let i = 0; i < selectedDemographicColumns.length; i++) {
            const selectedfilterIndex = demographicFilter.findIndex((data) => data.id === selectedDemographicColumns[i])
            const selectedfilterData = demographicFilter[selectedfilterIndex]
            const columnName = getMappingDetails(selectedfilterData.id)["table_column"];
            const excelColumn = getMappingDetails(selectedfilterData.id)["excel_column"];
            let filterObj = { columnName: columnName, values: selectedfilterData.selected, excelColumn: excelColumn }
            selectedFilters.push(filterObj);
        }
        return selectedFilters;
    }

    const handleGenerateReport = () => {
        const valid = validate()
        if (!valid) return
        if (isTemplateEdited) {
            globalStates.handleToast(true, 'Please save template first', 'error')
            return
        }
        setShowGeneratedTable(true);
    };

    const getCustomDataPoints = () => {
        if (!Array.isArray(selectedDataPoint) || selectedDataPoint.length === 0) {
            return [];
        }

        return selectedDataPoint.map(ele => {
            const {
                id,
                name,
                selectedValue = [],
                uniqueId,
                group,
                isFilter,
                isValue
            } = ele;

            const key = uniqueId;
            const filter = appliedFilters[key];
            const value = isEmpty(selectedValue[0]) ? null : selectedValue[0];
            const resolvedName =
                id === "microskill-id-rows" ? "Microskill Name" : name;

            return {
                name: resolvedName,
                value,
                filter,
                group,
                isFilter,
                isValue,
            };
        });
    };

    const handleSelectTemplate = async (templateId) => {
        if ((!isEmpty(selectedDataPoint) && isEmpty(selectedTemplate)) || isTemplateEdited) {
            let confirm = window.confirm("All unsaved changes will be lost, do you wish to continue?");
            if (!confirm) return
        }
        (isTemplateEdited) && setIsTemplateEdited(false);
        setSelectedTemplate([templateId]);
        isReplaceTemplate && setIsReplaceTemplate(false);
        handleResetDateRange();
        const response = await reportServices.getSavedCustomTemplate(companyId, templateId);
        if (response.status === 200 || response.status === 201) {
            if (response.data.data.type === "MULTILLINGUALMASTER" || response.data.data.type === "MULTILINGUALCUSTOM") {
                setHeaderFields(response.data.data.request.fields);
            } else {
                handleSetSelectedDataPoint(response.data.data.request.customPointData);
            }
            setReportType(response.data.data.type);
            handleSetSelectedDemographics(response.data.data.request.demographicData)
            if (!isEmpty(templateId)) {
                setTimeout(() => {
                    router.history.replace(`/analytics?c_id=${companyId}&&tab=20`)
                }, 1000);
            }

        }
    }

    const getId = (name) => {
        return analyticsConstant.customDataPointConstant.find(ele => ele.name === name).id;
    }

    const getFilterValue = (elementId, value) => {
        let filterValue;
        let selectedFilter;
        analyticsConstant.customDataPointConstant.forEach((ele) => {
            if (ele.id === elementId) {
                filterValue = ele.values;
                selectedFilter = ele.values.find((data) => data.id === value).filter
            }
        }
        )
        return { filterValue, selectedFilter }

    }

    const handleSetSelectedDataPoint = (customPoint) => {
        let tempFilters = {}
        const data = customPoint.map((ele) => {
            const id = getId(ele.name === "Microskill Name" ? "Microskill Name-rows" : ele.name);
            if (id === "microskill-id-columns" || id === "microskill-id-rows") {
                setMicroskillALignmentSelected(id)
            }
            const { filterValue, selectedFilter } = getFilterValue(id, ele.value);
            let uniqueId = uuid();
            tempFilters[uniqueId] = ele.filter;
            return (
                {
                    name: ele.name,
                    selectedValue: [ele.value],
                    group: ele.group,
                    currentFilter: selectedFilter,
                    id: id,
                    uniqueId,
                    values: filterValue,
                    isFilter: ele.isFilter,
                    isValue: ele.isValue,
                })
        })
        setSelectedDataPoint(() => {
            setAppliedFilters(tempFilters);
            return data;
        });
    }

    const handleSetSelectedDemographics = (demographicDataValue) => {
        const tempSelectedDemographicCols = demographicDataValue.map((ele) => ele.excelColumn)

        const tempSelectedDemographics = allDemographicData.map(ele => {
            let tempSubmenu = [];

            ele && ele.values.forEach(item => {
                if (item) tempSubmenu.push({ id: item, name: item })
            });
            return {
                id: ele.columnName,
                name: ele.columnName,
                subMenu: tempSubmenu,
                selected: !isEmpty(demographicDataValue.find((item => item.excelColumn === ele.columnName))) ? demographicDataValue.find((item => item.excelColumn === ele.columnName)).values : [],
                total: tempSubmenu.length,
            }
        })
        setDemographicFilter((prev) => {
            setSelectedDemographicColumns(tempSelectedDemographicCols);
            return tempSelectedDemographics;
        })
    }

    const handleDateChange = (newValue) => {
        if (newValue[1] !== null) {
            setFromDate(dayjs(newValue[0]).format('YYYY-MM-DD HH:mm:ss.SSS'));
            setToDate(dayjs(newValue[1]).format('YYYY-MM-DD HH:mm:ss.SSS'));
        }
        setDateRange(newValue);

    }
    const handleResetDateRange = () => {
        setDateRange([null, null]);
    }

    const getPayload = () => {
        const isCustom = reportType === "CUSTOM";

        return {
            companyId,
            title: templateName,
            settings: 'string',
            type: isCustom ? 'CUSTOM' : 'MULTILINGUALCUSTOM',
            request: {
                demographicData: getSelectedDemographics(),
                ...(isCustom
                    ? { customPointData: getCustomDataPoints() }
                    : { fields: headerFields }),
                dateFilter: {
                    reportFetchType: launchWise ? 'LAUNCHESON' : 'COMPLETION',
                    fromDate,
                    toDate,
                },
                microskillIds: isCustom ? [] : filterPayload.microskillIds,
            },
        };
    };

    const handleClearSelection = () => {
        setSelectedDataPoint([]);
        setAppliedFilters({});
        setDemographicFilter(defaultDemographicData);
        setSelectedDemographicColumns([]);
        setSelectedTemplate([]);
        setIsTemplateEdited(false);
        setMicroskillALignmentSelected(null);
        isReplaceTemplate(false);
    }

    const handleDateRangeType = (item) => {
        setDateRangeType([item.id]);
        if (item.id === "LAUNCHESON") {
            setLaunchWise(true)
        } else {
            setLaunchWise(false)
        }
    }

    const handleBackBtn = () => {
        setShowGeneratedTable(prev => !prev);
    }

    const handleSchedule = async () => {
        const schedulePayload = {
            emailArray: emailArray.split(','),
            startDate: startDate,
            endDate: endDate,
            scheduleTime: scheduleTime,
            frequency,
            report_duration: reportPeriod,
            templateId: [`${templateIdRef.current}`],
            companyId: companyId
        }

        const response = await reportsServices.customReportSchedule(schedulePayload);
        if (response.status === 200) {
            let message = `Report Scheduled Successfully`
            globalStates.handleToast(true, message, "success");
            setIsReportModalOpen(prev => !prev);
            resetModalInput();
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
        }
    }

    const resetModalInput = () => {
        setStartDate(null);
        setEndDate(null);
        setEmailArray('');
        setScheduleTime(null);
        setFrequency("Daily");
        setReportPeriod("Current Month");
    }

    const handleApplyContentFilter = (filterList) => {
        const { newPayload } = _applyContentFilter(filterPayload, contentFilter);
        setFilterPayload(newPayload);
    }

    const handleResetContentFilter = () => {
        let newPayload = {
            ...filterPayload,
            companyId: companyId,
            flag: "filter"
        };
        if (newPayload.hasOwnProperty('microskillIds')) {
            delete newPayload['microskillIds'];
        }
        if (newPayload.hasOwnProperty('languages')) {
            delete newPayload['languages'];
        }
        setFilterPayload(newPayload);
        _resetContentFilter();
    }


    return (
        <>
            {!showGeneratedTable
                ?
                <div className={classes.reportBuilder}>
                    <div>
                        <h1 className={classes.heading}>Report Builder</h1>
                        <div className={classes.flexStyle}>
                            <div>
                                <div style={{ display: "flex" }}>
                                    <CustomSelect
                                        hideBaseBtn={true}
                                        defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Templates', selected: selectedTemplate, dataObject: commonUtil.objectify(savedTemplates, "id") })}
                                        withSelectAllOption={false}
                                        listData={savedTemplates}
                                        selected={selectedTemplate}
                                        handleSelect={(item) => handleSelectTemplate(item.id)}
                                        radio={true}
                                        autoClose={true}
                                        width={'250px'}
                                    />
                                    <div className={classes.dateRangeWrapper}>
                                        <DatePicker
                                            value={dateRange}
                                            maxDate={today}
                                            minDate={minDate}
                                            handleDateChange={handleDateChange}
                                            handleReset={handleResetDateRange}
                                            selectedShortcut={selectedShortcut}
                                            setSelectedShortcut={setSelectedShortcut}
                                        />
                                    </div>
                                    {
                                        (dateRange[0] && dateRange[1]) &&
                                        <CustomSelect
                                            hideBaseBtn={true}
                                            defaultText={commonUtil.getSelectLabel({
                                                type: 'single',
                                                defaultText: 'Select Date Range Type',
                                                selected: dateRangeType,
                                                dataObject: commonUtil.objectify(dateRangeTypeData, "id"),
                                            })}
                                            withSelectAllOption={false}
                                            listData={dateRangeTypeData}
                                            selected={dateRangeType}
                                            handleSelect={(item) => handleDateRangeType(item)}
                                            autoClose={true}
                                            width="230px"
                                            radio={true}
                                        />
                                    }
                                    {
                                        (reportType === 'MULTILLINGUALMASTER' || reportType === 'MULTILINGUALCUSTOM') &&
                                        <div>
                                            <MultipleFilter
                                                label={"Content Filters"}
                                                title={"Select Content"}
                                                filterList={contentFilter}
                                                setFilterList={setContentFilter}
                                                handleSaveFilter={handleApplyContentFilter}
                                                handleResetFilter={handleResetContentFilter}
                                                handleSearch={_handleContentSearch}
                                                isFilterOpen={isFilterOpen}
                                                setIsFilterOpen={setIsFilterOpen}
                                                customSelectAll={_selectAllSubmenuCF}
                                                customhandleClick={_handleSubmenuItemCF}
                                            />
                                        </div>
                                    }
                                </div>

                            </div>
                            <div className={classes.btnBox}>

                                <Button title='Generate report' onClick={handleGenerateReport} className={`${classes.btn} ${classes.saveBtn}`} disabled={reportType === 'MULTILLINGUALMASTER' || reportType === 'MULTILINGUALCUSTOM'}>
                                    Generate Report
                                </Button>

                                <Button
                                    title='Save Template'
                                    onClick={handleOpenModal}
                                    className={`${classes.btn} ${classes.saveBtn}`}>
                                    Save Template
                                </Button>
                                <Button
                                    title='Save Template'
                                    onClick={handleClearSelection}
                                    className={`${classes.btn} ${classes.clearBtn}`}>
                                    Clear Selection
                                </Button>
                            </div>
                        </div>
                    </div>


                    <div className={classes.headerContainer}>
                        <div className={classes.headerBox} style={{ width: '20%' }}>
                            <Label labelText={'Select Demographics'} fs='1rem' />
                            <DemographicData
                                demographicFilter={demographicFilter}
                                setDemographicFilter={setDemographicFilter}
                                mapping={mapping}
                                setMapping={setMapping}
                                setAllDemographicData={setAllDemographicData}
                                selectedDemographicColumns={selectedDemographicColumns}
                                setSelectedDemographicColumns={setSelectedDemographicColumns}
                                selectedTemplate={selectedTemplate}
                                isTemplateEdited={isTemplateEdited}
                                setIsTemplateEdited={setIsTemplateEdited}
                                setBackdrop={setBackdrop}
                                allDemographicData={allDemographicData}
                                setDefaultDemographicData={setDefaultDemographicData}
                            />
                        </div>

                        <div className={classes.headerBox} style={{ width: "80%" }}>
                            <Label labelText={'Select Data Points'} fs='1rem' />
                            <CustomDataPoint
                                appliedFilters={appliedFilters}
                                setAppliedFilters={setAppliedFilters}
                                selectedDataPoint={selectedDataPoint}
                                setSelectedDataPoint={setSelectedDataPoint}
                                selectedTemplate={selectedTemplate}
                                isTemplateEdited={isTemplateEdited}
                                setIsTemplateEdited={setIsTemplateEdited}
                                setMicroskillALignmentSelected={setMicroskillALignmentSelected}
                                microskillALignmentSelected={microskillALignmentSelected}
                                reportType={reportType}
                                headerFields={headerFields}
                                setHeaderFields={setHeaderFields}
                            />
                        </div>
                    </div>
                    <SetReportScheduleModal
                        open={isReportModalOpen}
                        onClose={() => setIsReportModalOpen(prev => !prev)}
                        emailArray={emailArray}
                        setEmailArray={setEmailArray}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        scheduleTime={scheduleTime}
                        setScheduleTime={setScheduleTime}
                        frequency={frequency}
                        setFrequency={setFrequency}
                        handleSchedule={handleSchedule}
                        isReportHistory={true}
                        reportPeriod={reportPeriod}
                        setReportPeriod={setReportPeriod}
                    />
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box sx={style}>
                            <div className={classes.headerDiv}>
                                <Label labelText={'Save Template'} fs='1rem' style={{ marginBottom: '10px' }} />
                                <IconButton className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon style={{ color: '#ff5722', fontSize: "20px" }} />
                                </IconButton>
                            </div>

                            <Stack spacing={2} sx={{ margin: '1rem 0' }}>
                                <TextField
                                    label="Template Name"
                                    variant="outlined"
                                    fullWidth
                                    value={templateName}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setTemplateName(value);
                                        const regex = /^[a-zA-Z0-9_ ]*$/;
                                        if (!regex.test(value)) {
                                            setHasError(true);
                                        } else {
                                            setHasError(false);
                                        }
                                    }}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            '&.Mui-focused': {
                                                color: '#f4511e',
                                            },
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#f4511e',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#f4511e',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#f4511e',
                                            },
                                        },
                                    }}
                                />

                                {hasError && (
                                    <Typography
                                        variant="body2"
                                        sx={{ color: 'red', fontStyle: 'italic', fontSize: '0.8rem' }}
                                    >
                                        Special characters are not allowed. Please remove them to proceed.
                                    </Typography>
                                )}
                                {/* button Logic*/}
                                {(reportType !== "MULTILLINGUALMASTER" && !isEmpty(selectedTemplate)) && (
                                    <div className={classes.toggleDiv}>
                                        <ControlledSwitches
                                            checked={isReplaceTemplate}
                                            handleChange={() => setIsReplaceTemplate(prev => !prev)}
                                        />
                                        <Typography variant="h4" sx={{ color: '#707070', fontSize: '0.7rem', fontWeight: 700, marginLeft: '1rem' }}>Replace Existing Template</Typography>
                                    </div>
                                )}

                                <div className={classes.buttonContainerDiv}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSaveTemplate()}
                                        style={{
                                            backgroundColor: hasError ? 'grey' : '#f4511e',
                                            cursor: hasError ? 'not-allowed' : 'pointer',
                                            cursor: hasError ? 'not-allowed' : 'pointer',
                                            border: hasError ? 'none' : '1px solid #f4511e',
                                            textTransform: 'capitalize',
                                            width: "44%"
                                        }}
                                        disabled={hasError}
                                    >
                                        Save Only
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSaveTemplate(true)}
                                        style={{
                                            backgroundColor: hasError ? 'grey' : '#fff',
                                            color: hasError ? 'rgba(0, 0, 0, 0.26)' : '#f4511e',
                                            cursor: hasError ? 'not-allowed' : 'pointer',
                                            border: hasError ? 'none' : '1px solid #f4511e',
                                            textTransform: 'capitalize',
                                            width: "50%"
                                        }}
                                        disabled={hasError}
                                    >
                                        Save & Schedule
                                    </Button>
                                </div>
                            </Stack>
                        </Box>
                    </Modal>
                </div>
                :
                <CustomReportTable
                    payload={getPayload()}
                    selectedTemplate={selectedTemplate}
                    handleBackBtn={handleBackBtn}
                />
            }
        </>
    );
};

export default ReportBuilder;