import React, { useContext, useEffect, useState } from 'react'
import usestyles from './index.styles'
import BulletinUploadCard from '../manage-bulletin/components/search-bar/upload-card'
import VideoCard from './components/video-card/VideoCard'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import ImageCard from './components/image-card/ImageCard'
import FormControl from './components/FormControl'
// import TextInput from './components/Textinput';
import TextInput from '../../components/tms-components/TextInput';
import TextArea from './components/TextArea'
// import Label from './components/Label'
import styles from './style'
import useQuery from '../../utils/useQuery'
import bulletinServices from '../../services/bulletin'
import AudioCard from './components/audio-card';
import FlatPrimaryButton from './components/FlatPrimaryButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import commonUtil from '../../utils/commonUtil';
import FailureDialog from '../failure-dialog';
import SuccessDialog from '../success-dialog';
import { GlobalStates } from '../../App';
import { get, transform } from 'lodash';
import constants, { MAHINDRA_ID } from '../../constants';
import Axios from 'axios';
import isEmpty from '../../utils/isEmpty';
import LinkCard from './components/link-card';
import LinkTitleEditor from './components/link-card/LinkTitleEditor';
import MuiCheckbox from "@mui/material/Checkbox";
// import Label from '../../custom-styled/label';
import Label from '../custom-styled/label';


const Bulletin = () => {

  const style = usestyles()
  const classes = styles()

  const query = useQuery();
  const history = useHistory();

  const [globalStates] = useContext(GlobalStates);

  const company_id = query.get("c_id");
  const isEdit = query.get("isEdit");
  const bulletinId = query.get("id");

  // states : 
  const [titleBody, setTitleBody] = useState({ title: "", body: "" });
  const [titleText, setTitleText] = useState("");
  const [bodyText, setBodyText] = useState("");
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [link, setLink] = useState('');
  const [isYoutube, setIsYoutube] = useState(false);
  const [audio, setAudio] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [audioThumbnail, setAudioThumbnail] = useState(null);
  const [audioThumbnailFile, setAudioThumbnailFile] = useState(null);
  const [payload, setPayload] = useState(null)
  const [mediaPayload, setMediaPayload] = useState({
    companyId: company_id,
    BulletInMediaFile: null,
    bulletInId: ""
  })
  const [mediaFile, setMediaFile] = useState(null);
  const [fileType, setFileType] = useState("image");
  const [cardType, setCardType] = useState("");
  const [titleLimit, setTitleLimit] = useState(false);
  const [bodyLimit, setBodyLimit] = useState(200);
  const [buttonFlag, setButtonFlag] = useState(false);
  const [updateButtonFlag, setUpdateButtonFlag] = useState(false);
  const [fetchedBulletinId, setFetchedBulltinId] = useState("");
  const [editFlag, setEditFlag] = useState(false);
  const [externalLinkThumbnail, setExternalLinkThumbnail] = useState(null);
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failedText, setFailedText] = useState('');
  const [successText, setSuccessText] = useState('');
  const [isAnnouncementChecked, setIsAnnouncementChecked] = useState(false);

  const handleRadio = (e) => {
    setFileType(e.target.value)
    setEditFlag(true); // for cehcking the editing in editmode 
  }
  // console.log(fileType)

  const handeleTitleChange = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    return tempDiv.innerText || tempDiv.textContent;
  }

  const handleBodyChange = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    return tempDiv.innerText || tempDiv.textContent;
  }

  // ----------------------Image Functions ----------------------
  const handleImageUpload = (event) => {
    // console.log("Image Uplaod", event);
    if (event.target.files && event.target.files[0]) {
      // setImageFile(event.target.files[0]);
      let img = URL.createObjectURL(event.target.files[0]);
      // setIsSuggested(false);
      setImageFile(event.target.files[0]);
      setImage(img);
    }
  }

  // ---------------------- External Link Functions ----------------------
  const handleExternalLinkThumbnailUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      setExternalLinkThumbnail(event.target.files[0])
    }
  }


  //  --------------- Video Functions -----------------------
  const handleVideoUpload = () => {
    setVideo(mediaFile)
  }

  useEffect(() => {
    if (fileType === "video")
      setVideo(mediaFile);
    if (fileType === "youtube" || fileType === "link")
      setLink(mediaFile);
    if (fileType === "image")
      setImage(mediaFile);
    if (fileType === "audio")
      setAudio(mediaFile);
  }, [mediaFile])

  // console.log(mediaFile,audio,audioFile);

  const handleRemoveVideo = () => {
    setVideoFile(null);
    setIsYoutube(false);
    setVideo(null);
    setLink('')
  }

  const hanldeInputFileUpload = (event) => {
    handleRemoveVideo()
    let file = event.target.files[0];
    let fileUrl = URL.createObjectURL(file);
    // setVideoFile(file)
    // setVideo(URL.createObjectURL(file));
    setVideo(fileUrl);
    setVideoFile(file)
    setFileType("video")
    setEditFlag(true)
    // console.log("vide0", file, fileUrl);
  }

  // ------------------------ Video & External Link Functions -------------------------

  const handleExternalLink = (e) => {
    let value = e.target.value;

    if (fileType === "link") {
      setLink(value);
    }
    else {
      let embedUrl = commonUtil.convertToEmbedUrl(value);
      setLink(embedUrl);
      setIsYoutube(true)
    }
    // console.log('embedUrl = ', embedUrl);
  }

  useEffect(() => {
    if (link.length > 0) {
      if (isYoutube) setFileType("youtube");
      setEditFlag(true);
    }
  }, [link])



  // ------------------------ Audio Functions -------------------------

  const onAudioChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setAudioFile(file);
      setAudio(URL.createObjectURL(file));
      let urlFile = URL.createObjectURL(file);
      // console.log(file,urlFile);
    }
  };



  const handleChangeLaBgImg = (e) => {
    // console.log(e.target.value);
    setAudioThumbnailFile(e.target.files[0])
    let fileUrl = URL.createObjectURL(e.target.files[0])
    setAudioThumbnail(fileUrl)
    setEditFlag(true)
  }
  // ------------------- Bulletin Functions ---------------------------------

  const handleBrandingIcon = () => {
    switch (fileType) {
      case "image":
        return (<div className={style.LabelInputBox}>
          <label htmlFor="brandingIcon" className={style.Label}>
            Upload Media
          </label>
          <div className={style.Custom_File_Input}>
            <h1 className={style.FilePath}>Image</h1>
            <label className={style.Btn}>
              <img className={style.Folder_Icon} src="/images/add-microskill/folder.svg" alt="" /> BROWSE
              <input
                style={{ display: "none" }}
                id="brandingIcon"
                name="brandingIcon"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </label>
          </div>
        </div>)
        break;
      case "video":
        return (<div className={style.LabelInputBox}>
          <label htmlFor="brandingVideo" className={style.Label}>
            Upload Media
          </label>
          <div className={style.Custom_File_Input}>
            <h1 className={style.FilePath}>Video</h1>
            <label className={style.Btn}>
              <img className={style.Folder_Icon} src="/images/add-microskill/folder.svg" alt="" /> BROWSE
              <input
                style={{ display: "none" }}
                id="brandingVideo"
                name="brandingVideo"
                type="file"
                accept="video/*"
                onChange={hanldeInputFileUpload}
              />
            </label>
          </div>
          <span style={{ textAlign: 'center', transform: 'translateX(-15%)', fontWeight: "700", color: "gray" }}>OR</span>
          <input
            placeholder='Enter Your Video URL (Youtube/Vimeo)'
            className={`${style.externalLinkInput}`}
            onChange={handleExternalLink}
            type='text' />
        </div>)
        break;
      case "audio":
        return (<div className={style.LabelInputBox}>
          <label htmlFor="brandingAudio" className={style.Label}>
            Upload Media
          </label>
          <div className={style.Custom_File_Input}>
            <h1 className={style.FilePath}>Audio</h1>
            <label className={style.Btn}>
              <img className={style.Folder_Icon} src="/images/add-microskill/folder.svg" alt="" /> BROWSE
              <input
                style={{ display: "none" }}
                id="brandingAudio"
                name="brandingAudio"
                type="file"
                accept="audio/*"
                onChange={onAudioChange}
              />
            </label>
          </div>
        </div>

        )
        break;
      case "youtube":
        return (<div className={style.LabelInputBox}>
          <label htmlFor="brandingVideo" className={style.Label}>
            Upload Media
          </label>
          <div className={style.Custom_File_Input}>
            <h1 className={style.FilePath}>Video</h1>
            <label className={style.Btn}>
              <img className={style.Folder_Icon} src="/images/add-microskill/folder.svg" alt="" /> BROWSE
              <input
                style={{ display: "none" }}
                id="brandingVideo"
                name="brandingVideo"
                type="file"
                accept="video/*"
                onChange={hanldeInputFileUpload}
              />
            </label>
          </div>
          <span style={{ textAlign: 'center', transform: 'translateX(-15%)', fontWeight: "700", color: "gray" }}>OR</span>
          <input
            placeholder='Enter Your Video URL (Youtube/Vimeo)'
            className={`${style.externalLinkInput} `}
            onChange={handleExternalLink}
            value={link}
            type='text' />
        </div>)
        break;
      case "link":
        return (<div className={style.labelInputContainer}>
          <div className={`${style.LabelInputBox} ${style.externalLinkBox}`}>
            <label htmlFor="brandingLinkTitle" className={style.Label}>
              Enter Title
            </label>
            <LinkTitleEditor
              setTitleBody={setTitleBody}
              setTitleText={setTitleText}
              titleBody={titleBody}
            />
          </div>

          <div className={`${style.LabelInputBox} ${style.externalLinkBox}`}>
            <label htmlFor="brandingLink" className={style.Label}>
              Enter Link
            </label>
            <input
              placeholder='Enter Your Link'
              className={`${style.externalLinkInput}`}
              onChange={handleExternalLink}
              value={link}
              type='text' />
          </div>

          <div className={`${style.LabelInputBox} ${style.externalLinkBox}`}>
            <label htmlFor="brandingLinkThumbnail" className={style.Label}>
              Upload Thumbnail
            </label>
            <div className={style.Custom_File_Input}>
              <h1 className={style.FilePath}>Thumbnail</h1>
              <label className={style.Btn}>
                <img className={style.Folder_Icon} src="/images/add-microskill/folder.svg" alt="" /> BROWSE
                <input
                  style={{ display: "none" }}
                  id="brandingIcon"
                  name="brandingIcon"
                  type="file"
                  accept="image/*"
                  onChange={handleExternalLinkThumbnailUpload}
                />
              </label>
            </div>
          </div>

        </div>)
        break;
      default: return null;
        break;
    }
  }

  const handleCardView = () => {
    switch (fileType) {
      case "image":
        return (<ImageCard
          titleBody={titleBody}
          titleText={titleText}
          titleLimit={titleLimit}
          setTitleBody={setTitleBody}
          setTitleText={setTitleText}
          setBodyText={setBodyText}
          image={image}
          setImage={setImage}
          imageFile={imageFile}
          setImageFile={setImageFile}
          hanldeFileUpload={handleImageUpload}
        />)
        break;
      case "video":
        return (
          <VideoCard
            titleBody={titleBody}
            titleText={titleText}
            setTitleBody={setTitleBody}
            setTitleText={setTitleText}
            setBodyText={setBodyText}
            video={video}
            setVideo={setVideo}
            videoFile={videoFile}
            setVideoFile={setVideoFile}
            handleVideoUpload={handleVideoUpload}
            setIsYoutube={setIsYoutube}
            isYoutube={isYoutube}
            setLink={setLink}
            link={link}
            setFileType={setFileType}
            handleRemoveVideo={handleRemoveVideo}
            handleExternalLink={handleExternalLink}
            titleLimit={titleLimit}
          />
        )
        break;
      case "audio":
        return (<AudioCard
          titleBody={titleBody}
          setTitleBody={setTitleBody}
          setTitleText={setTitleText}
          setBodyText={setBodyText}
          audio={audio}
          setAudio={setAudio}
          audioFile={audioFile}
          setAudioFile={setAudioFile}
          onAudioChange={onAudioChange}
          handleChangeLaBgImg={handleChangeLaBgImg}
          audioThumbnail={audioThumbnail}
          setBodyLimit={setBodyLimit}
          setTitleLimit={setTitleLimit}
          titleLimit={titleLimit}
          titleText={titleText}
          bodyLimit={bodyLimit}
        />)
        break;
      case "youtube":
        return (
          <VideoCard
            titleBody={titleBody}
            titleText={titleText}
            titleLimit={titleLimit}
            setTitleBody={setTitleBody}
            setTitleText={setTitleText}
            setBodyText={setBodyText}
            video={video}
            setVideo={setVideo}
            videoFile={videoFile}
            setVideoFile={setVideoFile}
            handleVideoUpload={handleVideoUpload}
            setIsYoutube={setIsYoutube}
            isYoutube={isYoutube}
            setLink={setLink}
            link={link}
            handleRemoveVideo={handleRemoveVideo}
          />
        )
        break;
      case "link":
        return (
          <LinkCard
            link={link}
            setLink={setLink}
          />
        )
        break;
      default: return null
        break;
    }
  }

  const getBulletinType = (type, bulletinCard) => {
    switch (type) {
      case "IMAGE":
        setImage(bulletinCard)
        setCardType("ITB")
        return "image"
        break;
      case "YOUTUBE":
        setLink(bulletinCard)
        setCardType("VTB")
        return "youtube"
        break;
      case "VIDEO":
        setCardType("VTB")
        setVideoFile(bulletinCard)
        return "video"
        break;
      case "AUDIO":
        setAudioFile(bulletinCard)
        setCardType("ATB")
        return "audio"
        break;
      case "LINK":
        setLink(bulletinCard);
        setCardType("LTB");
        return "link";
        break;
      default: return null;
        break;
    }
  }

  const getAllBulletins = async () => {
    const response = await bulletinServices.fetchBulletins(company_id)
    let bulletinData = response.data.data;
    let filteredBulletin = bulletinData.filter(e => e.id === bulletinId)[0];

    console.log("filteredBulletins", filteredBulletin);

    let bulletin = filteredBulletin.content.contents;
    let bulletinCard = bulletin[0].content
    let bulletinTitle = bulletin[1].content
    let bulletinBody = bulletin[2].content
    let announcement = filteredBulletin.announcement;

    const bulletinType = getBulletinType(bulletin[0].name, bulletinCard)
    setFileType(bulletinType);
    setTitleBody(prev => ({ ...prev, title: bulletinTitle, body: bulletinBody }))
    setMediaFile(bulletinCard);
    let titleValue = handeleTitleChange(bulletinTitle)
    setTitleText(titleValue);
    setFetchedBulltinId(filteredBulletin.id)
    setCardType(filteredBulletin);
    setIsAnnouncementChecked(announcement);
  }


  const handleMediaPayload = async (element) => {
    setMediaPayload(element)
  }

  const getContent = () => {

    switch (fileType) {
      case "image":
        // return "https://d27yeeml4scius.cloudfront.net/apiv3media/Images/larg/large9.png"
        handleMediaPayload({
          companyId: company_id,
          BulletInMediaFile: imageFile,
          bulletInId: ""
        })
        return;
        break;

      case "audio":
        handleMediaPayload({
          companyId: company_id,
          BulletInMediaFile: audioFile,
          bulletInId: ""
        })
        return
        //  return "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/compy_TKJDlfhBJohJMXDnICr/GBAMediaFile-1694601181124.mp3"
        break;

      case "video":
        handleMediaPayload({
          companyId: company_id,
          BulletInMediaFile: videoFile,
          bulletInId: ""
        })
        return
        // return "https://www.youtube.com/embed/-LVRh1kb1zM?si=MQlVKQOuU-HjQ5fC"
        break;

      case "youtube":
        handleMediaPayload({
          companyId: company_id,
          BulletInMediaFile: link,
          bulletInId: ""
        })
        return
        // return "https://www.youtube.com/embed/-LVRh1kb1zM?si=MQlVKQOuU-HjQ5fC"
        break;

      case "link":
        handleMediaPayload({
          companyId: company_id,
          BulletInMediaFile: link,
          bulletinId: ""
        })
        return
        break;

      default: return null
        break;
    }

  }

  const getYoutubeThumbnail = async () => {
    try {

      const url = link;
      const match = url.match(/(?:v=|\/embed\/|youtu\.be\/|\/v\/|\/videos\/|\/video\/)([a-zA-Z0-9_-]{11})/);
      const videoId = match ? match[1] : null;
      return `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`;

    } catch (error) {
      console.log("ERROR", JSON.parse(error));
    }
  };

  const generateVideoThumbnail = async (videoFile) => {
    // console.log(videoFile);
    // Ensure videoFile is not null or undefined
    // if (!videoFile || !(videoFile instanceof Blob)) {
    //   throw new Error("Invalid video file provided");
    // }

    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      const videoURL = videoFile

      // Set the video source to the Blob URL
      video.src = videoURL;
      video.crossOrigin = "anonymous"; // Add this for cross-origin videos if necessary

      video.onloadedmetadata = () => {
        // Seek to a frame to capture
        video.currentTime = 1; // Capture a frame after 1 second (adjust as needed)
      };

      video.onseeked = () => {
        // Create a canvas to capture the frame
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a thumbnail image
        const thumbnailUrl = canvas.toDataURL("image/jpeg");
        resolve(thumbnailUrl);

        // Clean up
        URL.revokeObjectURL(videoURL);
        video.remove();
        canvas.remove();
      };

      video.onerror = (error) => {
        reject("Error loading video file: " + error.message);
      };
    });
  };

  const getAudioThumbnail = async (bulletinId) => {
    // console.log(audioThumbnailFile);
    const formDataPayload = await commonUtil.jsonToFormData({
      companyId: company_id,
      BulletInMediaFile: audioThumbnailFile,
      bulletInId: bulletinId.length < 1 ? fetchedBulletinId : bulletinId
    }, false)
    if (isEmpty(audioThumbnailFile))
      return null;
    const uploadResponse = await bulletinServices.bulletinMediaUpload(formDataPayload);
    return uploadResponse.data.data.mediaResources[0].location;
  }

  const getExternalLinkThumbnail = async (bulletinId) => {
    const formDataPayload = await commonUtil.jsonToFormData({
      companyId: company_id,
      BulletInMediaFile: externalLinkThumbnail,
      bulletInId: bulletinId.length < 1 ? fetchedBulletinId : bulletinId
    }, false)

    if (!externalLinkThumbnail || externalLinkThumbnail?.size === 0)
      return null;

    const uploadResponse = await bulletinServices.bulletinMediaUpload(formDataPayload);

    return uploadResponse.data.data.mediaResources[0].location;
  }

  const getThumbnail = async (fileURL, bulletinId = "") => {
    console.log(fileType, constants.VTB_DEFAULT_THUMBNAIL);
    switch (fileType) {
      case "video":
        // return constants.VTB_DEFAULT_THUMBNAIL;
        let thumbnail = await generateVideoThumbnail(video);
        return thumbnail;
      case "image":
        return fileURL;
      case "youtube":
        // return "https://i3.ytimg.com/vi/JkhvoJ77cN4/maxresdefault.jpg";
        return await getYoutubeThumbnail(link, bulletinId);
      case "audio":
        return await getAudioThumbnail(bulletinId);
      case "link":
        return await getExternalLinkThumbnail(bulletinId);
      default:
        return null;
    }

  }

  const getCardType = async () => {
    switch (fileType) {
      case "image":
        return "ITB"
        break;
      case "video":
        return "VTB"
        break;
      case "youtube":
        return "VTB"
        break;
      case "audio":
        return "ATB"
        break;
      case "link":
        return "LTB"

      default: return null
        break;
    }
  }

  useEffect(() => {
    setPayload({
      companyId: company_id,
      title: titleText,
      content: {
        ratio: "4:3",
        cardType: "VTB",
        contents: [
          {
            name: fileType.toUpperCase(),
            type: "URL",
            content: mediaFile,
            sequence: 0,
            thumbnail: "https://learning-aid.s3-accelerate.amazonaws.com/thumb/67a941c0ObjectionHandlingIntro(online-video-cutter.com).png"
          },
          {
            name: "TITLE",
            type: "TEXT",
            content: titleBody.title,
            sequence: 2
          },
          {
            name: "BODY",
            type: "TEXT",
            content: titleBody.body,
            sequence: 3
          }
        ],
        zoomable: false
      }
    })
    getContent();
  }, [image, titleBody, fileType, video, audio, mediaFile, link, titleText])


  useEffect(() => {
    if (isEdit) {
      getAllBulletins();

    }
  }, [])



  // ----------------------------- Bulletin Creation ------------------------



  const getPayload = async (fileURL, bulletinId = "") => {
    return {
      companyId: company_id,
      title: titleText,
      announcement: isAnnouncementChecked,
      content: {
        ratio: "4:3",
        cardType: await getCardType(),
        contents: [
          {
            name: fileType.toUpperCase(),
            type: "URL",
            content: fileURL,
            sequence: 0,
            thumbnail: await getThumbnail(fileURL, bulletinId)
          },
          {
            name: "TITLE",
            type: "TEXT",
            content: titleBody.title,
            sequence: 2
          },
          {
            name: "BODY",
            type: "TEXT",
            content: titleBody.body,
            sequence: 3
          }
        ],
        zoomable: false
      }
    }
  }


  const handleMediaUpload = async (formDataPayload, bulletinId) => {

    const uploadResponse = await bulletinServices.bulletinMediaUpload(formDataPayload);
    if (uploadResponse.status === 200 || uploadResponse.status === 201) {
      let payloadWithURL = await getPayload(uploadResponse.data.data.mediaResources[0].location, bulletinId)
      let updatePayload = { ...payloadWithURL, bulletInId: bulletinId }
      setPayload(updatePayload)
      const updateResponse = await bulletinServices.bulletinUpdate(updatePayload);
      if (updateResponse.status === 200 || updateResponse.status === 201) {
        globalStates.handleProgressDialog(false, 'Please wait...');
        setSuccess(true);
        setSuccessText("Bulletin created successfully!");
      }
      else {
        globalStates.handleToast(true, 'Internal Server Error.', 'error');
        let message = `Something went wrong, please try again!`
        setFailedText(message);
        setFailed(true);
      }
    }
  }


  const handleLinkUpload = async (bulletinId) => {
    let payloadWithURL = await getPayload(link, bulletinId)

    let updatePayload = { ...payloadWithURL, bulletInId: bulletinId }
    setPayload(updatePayload)
    console.log("After apyload", updatePayload);
    const updateResponse = await bulletinServices.bulletinUpdate(updatePayload);
    if (updateResponse.status === 200 || updateResponse.status === 201) {
      globalStates.handleProgressDialog(false, 'Please wait...');
      setSuccess(true);
      setSuccessText("Bulletin created successfully!");
    }
    else {
      globalStates.handleToast(true, 'Internal Server Error.', 'error');
      let message = `Something went wrong, please try again!`
      setFailedText(message);
      setFailed(true);
    }
  }

  const validateCreateBulletinPayload = () => {

    if (titleText.length < 1
      || (fileType !== "link" && bodyText.length < 1)
      || (mediaPayload.BulletInMediaFile === null && link.length < 1)) {
      globalStates.handleToast(true, 'Please Fill All Fields', 'error');
      return false;
    }

    if (titleText.length > 180) {
      setTitleLimit(true);
      setTimeout(() => {
        setTitleLimit(false)
      }, 5000);
      globalStates.handleToast(true, 'Title character limit exceeded (limit 180 chars.)', 'error');
      return false;
    }

    return true;
  }

  const handleBulletinCreate = async () => {
    let bulletinId = "";

    if (!validateCreateBulletinPayload()) return;

    globalStates.handleProgressDialog(true, 'Please wait...');

    // console.log(mediaPayload,link);
    const createResponse = await bulletinServices.createBulletin({ "companyId": company_id });
    if (createResponse.status === 200 || createResponse.status === 201) {
      bulletinId = createResponse.data.data.id


      let temp = { ...mediaPayload, bulletInId: bulletinId }

      const formDataPayload = await commonUtil.jsonToFormData(temp)
      setMediaPayload(temp)

      link.length > 0 && (fileType === "youtube" || fileType === "link")
        ? handleLinkUpload(bulletinId)
        : handleMediaUpload(formDataPayload, bulletinId);

    }
    else {
      globalStates.handleToast(true, 'Internal Server Error.', 'error');
      let message = `Something went wrong, please try again!`
      setFailedText(message);
      setFailed(true);
    }
  }

  const handleFailClick = () => {
    setFailed(false);
  }

  const handleSuccessClick = () => {
    setSuccess(false);
    history.push(`/manage-bulletin?c_id=${company_id}`)
  }


  // ----------------------------- Bulletin Update -----------------------
  const getupdatePayload = async () => {
    return {
      companyId: company_id,
      title: titleText,
      bulletInId: fetchedBulletinId,
      announcement: isAnnouncementChecked,
      content: {
        ratio: "4:3",
        cardType: await getCardType(),
        contents: [
          {
            name: fileType.toUpperCase(),
            type: "URL",
            content: mediaFile,
            sequence: 0,
            thumbnail: await getThumbnail(mediaFile)
          },
          {
            name: "TITLE",
            type: "TEXT",
            content: titleBody.title,
            sequence: 2
          },
          {
            name: "BODY",
            type: "TEXT",
            content: titleBody.body,
            sequence: 3
          }
        ],
        zoomable: false
      }
    }
  }


  const validateEditBulletinPayload = () => {

    if (titleText.length < 1 || (mediaFile === null && link.length < 1)) {
      globalStates.handleToast(true, 'Please Fill All Fields', 'error');
      return false;
    }

    if (titleText.length > 180) {
      setTitleLimit(true);
      setTimeout(() => {
        setTitleLimit(false)
      }, 5000);
      globalStates.handleToast(true, 'Title character limit exceeded (limit 180 chars.)', 'error');
      return false;
    }
    return true;
  };


  const handleUpdateLinkUpload = async () => {

    let payloadWithURL = await getPayload(link)

    let updatePayload = { ...payloadWithURL, bulletInId: fetchedBulletinId }
    console.log("The payload", updatePayload);
    setPayload(updatePayload)
    const updateResponse = await bulletinServices.bulletinUpdate(updatePayload);
    if (updateResponse.status === 200 || updateResponse.status === 201) {
      globalStates.handleProgressDialog(false, 'Please wait...');
      setSuccess(true);
      setSuccessText("Bulletin updates successfully!");
    }
    else {
      globalStates.handleToast(true, 'Internal Server Error.', 'error');
      let message = `Something went wrong, please try again!`
      setFailedText(message);
      setFailed(true);
    }
  }


  const handleEditedUpload = async (updatedPayload) => {
    // console.log(updatedPayload);
    const formDataPayload = await commonUtil.jsonToFormData(updatedPayload)

    if (fileType === "youtube" || fileType === "link")
      handleUpdateLinkUpload()
    // console.log("youtube");
    else {
      globalStates.handleProgressDialog(true, 'Please wait...');
      const uploadResponse = await bulletinServices.bulletinMediaUpload(formDataPayload);
      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        setMediaFile(uploadResponse.data.data.mediaResources[0].location)
        let payloadWithURL = await getPayload(uploadResponse.data.data.mediaResources[0].location)

        let updatePayload = { ...payloadWithURL, bulletInId: fetchedBulletinId }
        // console.log("update Payload",updatePayload);
        setPayload(updatePayload)
        const updateResponse = await bulletinServices.bulletinUpdate(updatePayload);
        if (updateResponse.status === 200 || updateResponse.status === 201) {
          globalStates.handleProgressDialog(false, 'Updated');
          setSuccess(true);
          setSuccessText("Bulletin updated successfully!");
        }
        else {
          globalStates.handleToast(true, 'Internal Server Error.', 'error');
          let message = `Something went wrong, please try again!`
          setFailedText(message);
          setFailed(true);
        }
      }
      console.log("media");
    }
  }

  // console.log("edit",editFlag);
  const handleBulletinUpdate = async () => {
    // console.log(titleText.length, mediaPayload.BulletInMediaFile, link.length, fetchedBulletinId);

    if (!validateEditBulletinPayload()) return;

    let updatedPayload = { ...mediaPayload, bulletInId: fetchedBulletinId }

    if (editFlag) {
      console.log("edited update");
      handleEditedUpload(updatedPayload);
    } else {
      console.log("simple update");
      globalStates.handleProgressDialog(true, 'Please wait...');
      const updateResponse = await bulletinServices.bulletinUpdate(await getupdatePayload());
      if (updateResponse.status === 200 || updateResponse.status === 201) {
        globalStates.handleProgressDialog(false, 'Please wait...');
        setSuccess(true);
        setSuccessText("Bulletin updated successfully!");
      } else {
        globalStates.handleToast(true, 'Internal Server Error.', 'error');
        const message = "Something went wrong, please try again!";
        setFailedText(message);
        setFailed(true);
      }
    }

  }

  const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
      <MuiCheckbox
        id={id}
        name={name}
        type={type}
        onChange={handleClick}
        checked={isChecked}
        sx={{
          "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
        }}
      />
    );
  };

  return (
    <>

      <div className={style.mainContainer}>

        <div className={style.leftContainer}>
          <h3 style={{ padding: '1rem 0.5rem' }}>Select Media Type:</h3>


          <FormControl>
            <RadioGroup
              className={classes.radioGroup}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            >
              <div className={style.radioContainer}>
                <FormControlLabel value="image"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#f4511e'
                    },
                  }}
                  control={<Radio
                    name="radio-buttons"
                    checked={fileType === "image"}
                    onChange={handleRadio}
                  />}
                  label={"Image"}
                />
                <FormControlLabel value={link.length > 0 ? "youtube" : "video"}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#f4511e'
                    },
                  }}
                  control={<Radio
                    name="radio-buttons"
                    checked={fileType === "video" || fileType === "youtube"}
                    onChange={handleRadio}
                  />}
                  label={"Video"}
                />
                <FormControlLabel value="audio"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#f4511e'
                    },
                  }}
                  control={<Radio
                    name="radio-buttons"
                    checked={fileType === "audio"}
                    onChange={handleRadio}
                  />}
                  label={"Audio"}
                />
                {company_id === MAHINDRA_ID && <FormControlLabel value="link"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                      color: '#f4511e'
                    },
                  }}
                  control={<Radio
                    name="radio-buttons"
                    checked={fileType === "link"}
                    onChange={handleRadio}
                  />}
                  label={"Link"}
                />}
              </div>
            </RadioGroup>
          </FormControl>


          {/* Brandin icon  */}
          {handleBrandingIcon()}

          {/* Announcement Checkbox :  */}
          <div style={{ margin: "1rem 0" }}>
            <Checkbox
              id="announcement"
              type="checkbox"
              handleClick={() => setIsAnnouncementChecked(!isAnnouncementChecked)}
              isChecked={isAnnouncementChecked}
            />
            <Label labelText={"Announcement"} htmlFor='announcement' fs='0.8rem' />
          </div>

          {/* Announcement Checkbox :  */}

          {/* title*/}
          {/* <FormControl
            className={classes.full_Width}
            style={{ textAlign: 'left' }}>
            <Label>Title:</Label>
            <div
              style={{
                border: 'solid 0.5px #dde0e2',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}>
              <TextInput
                placeholder={'New Launch!'}
                style={{ width: '78%' }}
                border={'transparent'}
                width={classes.full_Width}
                value={titleText}
                onChangeText={(text) => {
                  if (text.length > 60) return; // Exit if text exceeds the character limit
                  setTitleLimit(60 - text.length); // Update remaining character count
                  setTitleBody((prev) => ({ ...prev, title: text })); // Update titleBody with new text

                }}
              />
              <label
                style={{
                  marginLeft: '5px',
                  marginRight: '10px',
                  font: 'normal normal bold 6px/8px Montserrat',
                  color: '#707070',
                }}>
                {`Character limit: ${titleLimit}`}
              </label>
            </div>
            <label
              style={{
                marginLeft: '5px',
                font: 'normal normal bold 10px Montserrat',
                color: '#f63b34',
              }}>
            </label>
          </FormControl> */}

          {/* description */}
          {/* <FormControl
            className={classes.full_Width}
            style={{ textAlign: 'left' }}>
            <Label>Description:</Label>
            <div
              style={{
                border: 'solid 0.5px #dde0e2',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '100%',
              }}>
              <TextArea
                placeholder={'Enter Scenario Description Here'}
                resize={'none'}
                border={'transparent'}
                width={'100%'}
                text={bodyText}
                onChangeText={(text) => {
                  if (text.length > 200) return
                  setBodyLimit(200 - text.length);
                  setTitleBody(prev => ({ ...prev, body: text }))
                }}
              ></TextArea>
              <label
                style={{
                  marginRight: 10,
                  marginBottom: 5,
                  font: 'normal normal bold 6px/8px Montserrat',
                  color: '#707070',
                }}>
                {`Character limit: ${bodyLimit}`}
              </label>
            </div>
            <label
              style={{
                marginLeft: '5px',
                font: 'normal normal bold 10px Montserrat',
                color: '#f63b34',
              }}>
            </label>
          </FormControl>  */}

          {!isEdit ?
            <FlatPrimaryButton
              style={{
                minWidth: '130px',
                width: '140px',
                padding: '15px 30px',
                transform: 'translateY(60%)'
              }}
              onClick={handleBulletinCreate}
              disabled={buttonFlag}
            >
              Create
            </FlatPrimaryButton>
            :
            <FlatPrimaryButton
              style={{
                minWidth: '130px',
                width: '140px',
                padding: '15px 30px',
                transform: 'translateY(60%)'
              }}
              onClick={handleBulletinUpdate}
            // disabled={buttonFlag}
            >
              Update
            </FlatPrimaryButton>
          }


        </div>
        <div className={style.rightContainer}>
          {handleCardView()}
        </div>

      </div>

      {failed && (
        <FailureDialog
          setErrorDialogOpen={setFailed}
          errorDialogOpen={failed}
          dialogText={failedText}
          handleClick={handleFailClick}
        />
      )}

      {
        success && (
          <SuccessDialog
            successDialogOpen={success}
            setSuccessDialogOpen={setSuccess}
            dialogText={successText}
            handleClick={handleSuccessClick}
            bgOpacity={50}
            buttonText={'OK'}
          />
        )
      }

    </>
  )
}

export default Bulletin