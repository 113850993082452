import React, { useState, useEffect } from 'react';

//mui
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//components
import LoginReport from './components/login-report'
import CompletionReport from './components/completion-report'
import Feedback from './components/feedback';
import Loader from "../custom-styled/loader";
import SkillGapReport from './components/skillgap-report';
import ToughQuestionAnalysisReport from './components/tqa-reports';
import LeaderboardReport from './components/leaderboard-report';
import OtherReports from './components/other-reports';

//utils
import useQuery from '../../utils/useQuery';
import commonUtil from '../../utils/commonUtil';

//services
import reportsServices from '../../services/reports';
import groupServices from '../../services/group';
import categoryServices from '../../services/categories';
import microskillServices from '../../services/microskill';

//styles
import useStyles from './index.styles';
import constants from '../../constants';
import isEmpty from '../../utils/isEmpty';
import { GlobalStates } from '../../App';
import ReportBuilder from './components/custom-report';
import CustomTemplates from './components/custom-templates';
import CustomReportHistoryData from './components/custom-report-history-data';

import { useContext } from 'react';

let staticDemographicFilters = [
  {
    id: "Username", name: 'Username', subMenu: [], selected: []
  }
]

let staticLanguages = [
  {
    id: 'English',
    name: 'English',
  },
  {
    id: 'Hindi',
    name: 'Hindi',
  },
  {
    id: 'Bengali',
    name: 'Bengali',
  },
  {
    id: 'Tamil',
    name: 'Tamil',
  },
  {
    id: 'Telugu',
    name: 'Telugu',
  },
  {
    id: 'Gujarati',
    name: 'Gujarati',
  },
  {
    id: 'Kannada',
    name: 'Kannada',
  },
  {
    id: 'Malayalam',
    name: 'Malayalam',
  },
  {
    id: 'Marathi',
    name: 'Marathi',
  },
  {
    id: 'Turkish',
    name: 'Turkish',
  },
  {
    id: 'Oriya',
    name: 'Oriya',
  },
];

let staticContentFilters = [
  { id: "groups", name: 'Groups', subMenu: [], selected: [] },
  {
    id: "microskills", name: 'Microskills', subMenu: [], selected: []
  },
  {
    id: "category", name: 'Category', subMenu: [], selected: []
  },
  {
    id: "language", name: 'Language', subMenu: staticLanguages, selected: []
  },
]



const Analytics = () => {
  let tabsWithContentFilters = [2, 6, 7, 8, 9, 10, 12, 14, 15, 17, 20];
  let loginCompletionTabs = [1, 2];
  let otherReportTabs = constants.OTHER_REPORTS_TABS || [9, 10, 11, 12, 13, 14, 15, 16, 17];
  const customReportTabValue = constants.CUSTOM_REPORTS_TAB_VALUE;
  const query = useQuery();
  const tab = query.get("tab");
  const companyId = query.get("c_id");
  const [globalStates, setGlobalStates] = useContext(GlobalStates);
  const [backdrop, setBackdrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);//have to change this to null
  const classes = useStyles();
  const [allDemographicObjects, setAllDemographicObjects] = useState(null);
  const [mapping, setMapping] = useState([]);
  let mappingObject = commonUtil.objectify(mapping, "name");
  let stringifiedDemographicFilter = JSON.stringify(staticDemographicFilters);
  let parsedDemographicFilter = JSON.parse(stringifiedDemographicFilter);
  const [demographicFilter, setDemographicFilter] = useState(parsedDemographicFilter);
  let stringifiedContentFilter = JSON.stringify(staticContentFilters);
  let parsedContentFilter = JSON.parse(stringifiedContentFilter);
  const [contentFilter, setContentFilter] = useState(parsedContentFilter);
  const [microskills, setMicroskills] = useState([]);
  const [groups, setGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredMicroskills, setFilteredMicroskills] = useState([]);


  useEffect(() => {
    handleTabChange();
  }, [tab])

  const handleCloseBackdrop = () => {
    return
  }

  const handleTabChange = () => {
    setSelectedTab(parseInt(tab));
  }

  const handleSelectTab = (event, newValue) => {
    if (selectedTab === newValue) return
    setSelectedTab(newValue);
  };

  useEffect(() => {
    fetchDemographics(companyId, { withLoader: true });
    if (tabsWithContentFilters.includes(selectedTab)) {
      fetchGroups(companyId);
      fetchCategories(companyId);
      fetchMicroskills(companyId, {})
    }
  }, [selectedTab])

  useEffect(() => {
    if (groups.length || categories.length || microskills.length) {
      updateToDefaultCF(groups, categories, microskills);
    }
  }, [groups, categories, microskills])


  const updateToDefaultCF = (groups, categories, microskills) => {
    let tempContentFilters = [...contentFilter];
    // if (!roles.includes("MULTILINGUAL")) tempContentFilters = tempContentFilters.filter(item => item.id !== "language");
    for (let i = 0; i < tempContentFilters.length; i++) {
      if (tempContentFilters[i].id === "groups") {
        tempContentFilters[i] = { ...tempContentFilters[i], subMenu: groups, selected: [], total: groups.length };
        setSelectedGroups([]);
      }
      if (tempContentFilters[i].id === "category") {
        tempContentFilters[i] = { ...tempContentFilters[i], subMenu: categories, selected: [], total: categories.length };
        setSelectedCategories([]);
      }
      if (tempContentFilters[i].id === "microskills") {
        tempContentFilters[i] = { ...tempContentFilters[i], subMenu: microskills, selected: [], total: microskills.length };
        setFilteredMicroskills([]);
      }
      if (tempContentFilters[i].id === "language") {
        tempContentFilters[i] = { ...tempContentFilters[i], subMenu: staticLanguages, selected: [], total: staticLanguages.length };
      }
    }
    setContentFilter(tempContentFilters);
  }

  const fetchGroups = async (companyId) => {
    const response = await groupServices.fetchGroupsSearch(companyId);
    if (response && response.status === 200 || response.status === 201) {
      let activeGroups = response.data.data.filter(ele => ele.active === true);
      activeGroups.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else return 0
      })
      setGroups(activeGroups);
    }
  }

  const fetchCategories = async (companyId) => {
    // return
    const response = await categoryServices.fetchCategories(companyId);
    if (response && response.status === 200 || response.status === 201) {
      let activeCategories = response.data.data.categories.filter(ele => ele.active === true);
      activeCategories.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else return 0
      })
      setCategories(activeCategories);
    }
  }

  const fetchMicroskills = async (companyId, { search = null, groupIds = null, limit = 3000, categoryIds = null, withBackDrop = false }) => {
    if (withBackDrop) setBackdrop(true);
    const response = await microskillServices.fetchMicroskillsSearch(companyId, search, groupIds, limit, categoryIds);
    if (response && response.status === 200 || response.status === 201) {
      let activeMicroskills = response.data.data.filter(ele => ele.active === true);
      activeMicroskills.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else return 0
      })
      setMicroskills(activeMicroskills);
      if (withBackDrop) setBackdrop(false);
    }
  }

  const fetchFilteredMicroskills = async (companyId, { search = null, groupIds = null, limit = 3000, categoryIds = null, withBackDrop = false }) => {
    if (withBackDrop) setBackdrop(true);
    const response = await microskillServices.fetchMicroskillsSearch(companyId, search, groupIds, limit, categoryIds);
    if (response && response.status === 200 || response.status === 201) {
      let filteredMicroskills = response.data.data.filter(ele => ele.active === true);
      filteredMicroskills.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        } else return 0
      })
      // setMicroskills(filteredMicroskills);

      // let tempContentFilters = [...contentFilter];
      // for (let i = 0; i < tempContentFilters.length; i++) {
      //   if (tempContentFilters[i].id === "microskills") {
      //     tempContentFilters[i] = { ...tempContentFilters[i], subMenu: filteredMicroskills };
      //     setContentFilter(tempContentFilters);
      //     break;
      //   }
      // }

      if (withBackDrop) setBackdrop(false);
      return filteredMicroskills
    }
    return [];
  }

  const getSelectedData = (key, filterData) => {
    let selected = [];
    for (let i = 0; i < filterData.length; i++) {
      if (filterData[i].id === key) {
        selected = filterData[i].selected;
        break;
      }
    }
    return selected;
  }

  const _handleSubmenuItemCF = async (item, selectedfilter, filterList) => {
    let templist = [...filterList];

    if (selectedfilter.id === "groups") {
      let selectedGroups = selectedfilter.selected;
      let filteredMicroskills = microskills;
      if (selectedGroups.length) filteredMicroskills = await fetchFilteredMicroskills(companyId, { groupIds: selectedGroups.length ? selectedGroups : null, withBackDrop: true });
      // let prevSelectedGroups = [];
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === selectedfilter.id) {
          // prevSelectedGroups = templist[i].selected;
          let newObj = { ...templist[i], selected: selectedfilter.selected };
          templist[i] = newObj;
        }
        if (templist[i].id === "microskills") {
          let newObj = { ...templist[i], subMenu: filteredMicroskills, total: filteredMicroskills.length, selected: [] };
          templist[i] = newObj;
        }
        if (templist[i].id === "category") {
          let newObj = { ...templist[i], selected: [] };
          templist[i] = newObj;
        }
      }
      setSelectedGroups(selectedGroups);
      setFilteredMicroskills(filteredMicroskills);
    }

    if (selectedfilter.id === "microskills") {
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === selectedfilter.id) {
          let newObj = { ...templist[i], selected: selectedfilter.selected };
          templist[i] = newObj;
        }
      }
    }

    if (selectedfilter.id === "category") {
      let selectedCategories = selectedfilter.selected;
      let filteredMicroskills = microskills;
      if (selectedCategories.length) filteredMicroskills = await fetchFilteredMicroskills(companyId, { categoryIds: selectedCategories.length ? selectedCategories : null, withBackDrop: true });
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === selectedfilter.id) {
          let newObj = { ...templist[i], selected: selectedfilter.selected };
          templist[i] = newObj;
        }
        if (templist[i].id === "microskills") {
          let newObj = { ...templist[i], subMenu: filteredMicroskills, total: filteredMicroskills.length, selected: [] };
          templist[i] = newObj;
        }
        if (templist[i].id === "groups") {
          let newObj = { ...templist[i], selected: [] };
          templist[i] = newObj;
        }
      }
      setSelectedCategories(selectedGroups);
      setFilteredMicroskills(filteredMicroskills);
    }

    if (selectedfilter.id === "language") {
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === selectedfilter.id) {
          let newObj = { ...templist[i], selected: selectedfilter.selected };
          templist[i] = newObj;
        }
      }
    }

    setContentFilter(templist);
  }

  const _applyContentFilter = (payload) => {
    let newPayload = {
      ...payload,
      microskillIds: [],
      languages: []
    }
    contentFilter.forEach(ele => {
      if (ele.selected.length) {
        if (ele["id"] === "groups") {
          let isAnyMsSelected = false;
          let msFilter = contentFilter.filter(item => item.id === "microskills");
          if (msFilter.length && msFilter[0].selected.length) isAnyMsSelected = true;
          if (!isAnyMsSelected) {
            let groupMsIds = filteredMicroskills.map(item => (item.id));
            newPayload = { ...newPayload, microskillIds: groupMsIds };
          }
        }
        if (ele["id"] === "category") {
          let isAnyMsSelected = false;
          let msFilter = contentFilter.filter(item => item.id === "microskills");
          if (msFilter.length && msFilter[0].selected.length) isAnyMsSelected = true;
          if (!isAnyMsSelected) {
            let categoryMsIds = filteredMicroskills.map(item => (item.id));
            newPayload = { ...newPayload, microskillIds: categoryMsIds };
          }
        }
        if (ele["id"] === "microskills") newPayload = { ...newPayload, microskillIds: ele.selected };
        if (ele["id"] === "language") newPayload = { ...newPayload, languages: ele.selected };

      }
    })

    let templist = [...contentFilter];
    for (let i = 0; i < templist.length; i++) {
      let newObj = { ...templist[i] };
      if (templist[i].id === "microskills") {
        newObj.subMenu = selectedGroups.length || selectedCategories.length ? filteredMicroskills : microskills;
        templist[i] = newObj;
        // templist[i].selected = filterList[i].selected;
      }
      if (templist[i].id === "groups") {
        newObj.subMenu = groups;
        templist[i] = newObj;
      }
      if (templist[i].id === "category") {
        newObj.subMenu = categories;
        templist[i] = newObj;
      }
      if (templist[i].id === "language") {
        newObj.subMenu = staticLanguages;
        templist[i] = newObj;
      }
    }
    setContentFilter(templist);
    return { newPayload }
  }

  const _resetContentFilter = async () => {
    updateToDefaultCF(groups, categories, microskills);
  }

  const _handleContentSearch = (value, selectedFilter) => {
    let templist = [...contentFilter];
    if (selectedFilter.id === "microskills") {
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === "microskills") {
          if (value === "") {
            templist[i].subMenu = selectedGroups.length || selectedCategories.length ? filteredMicroskills : microskills;;
            break
          } else {
            let result;
            if (selectedGroups.length || selectedCategories.length) {
              result = filteredMicroskills.filter(li => li.name.toLowerCase().includes(value.toLowerCase()));
            } else {
              result = microskills.filter(li => li.name.toLowerCase().includes(value.toLowerCase()));
            }
            templist[i].subMenu = result;
            break
          }
        }
      }
      setContentFilter(templist);
      return
    }
    if (selectedFilter.id === "groups") {
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === "groups") {
          if (value === "") {
            templist[i].subMenu = groups;
            break
          } else {
            let result = groups.filter(li => li.name.toLowerCase().includes(value.toLowerCase()));
            templist[i].subMenu = result;
            break
          }
        }
      }
      setContentFilter(templist);
      return
    }
    if (selectedFilter.id === "category") {
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === "category") {
          if (value === "") {
            templist[i].subMenu = categories;
            break
          } else {
            let result = categories.filter(li => li.name.toLowerCase().includes(value.toLowerCase()));
            templist[i].subMenu = result;
            break
          }
        }
      }
      setContentFilter(templist);
      return
    }

    if (selectedFilter.id === "language") {
      for (let i = 0; i < templist.length; i++) {
        if (templist[i].id === "language") {
          if (value === "") {
            templist[i].subMenu = staticLanguages;
            break
          } else {
            let result = staticLanguages.filter(li => li.name.toLowerCase().includes(value.toLowerCase()));
            templist[i].subMenu = result;
            break
          }
        }
      }
      setContentFilter(templist);
      return
    }


  }

  const _selectAllSubmenuCF = async (newSelectedFilter) => {
    await _handleSubmenuItemCF(null, newSelectedFilter, contentFilter);
  }

  //------------------------------DF-----------------------------------





  const fetchFilteredDemographics = async (companyId, { withBackdrop = false, selectedDemographics = [], newDemographicFilter = [] }) => {
    let parsedSelectedFilterCopy;
    if (globalStates?.selectedFilterCopy !== undefined) {
      parsedSelectedFilterCopy = JSON.parse(globalStates.selectedFilterCopy);
    }
    if (!selectedTab) return;

    if (withBackdrop) setBackdrop(true);

    if (!selectedDemographics.length) {
      await fetchDemographics(companyId, { withLoader: false });
      if (withBackdrop) setBackdrop(false);
      return
    } else {
      let payload = {
        companyId: companyId,
        demographics: selectedDemographics
      }
      const response = await reportsServices.getFilteredDemographics(payload);
      if (response && response.status === 200) {
        let distinctColumnValues = response.data.data.distinctColumnValues;
        let tempDemographicFilters = [];
        distinctColumnValues.forEach(ele => {
          let tempSubmenu = [];
          ele.values.forEach(item => {
            if (item) tempSubmenu.push({ id: item, name: item })
          });
          let tempObj = { id: ele.columnName, name: ele.columnName, subMenu: tempSubmenu, selected: [], total: tempSubmenu.length };
          tempDemographicFilters.push(tempObj);
        })
        for (let i = 0; i < tempDemographicFilters.length; i++) {
          let ele = { ...tempDemographicFilters[i] };
          let selectedOnes = newDemographicFilter.filter(item => item.name === ele.name && item.selected.length);
          if (selectedOnes.length) {
            tempDemographicFilters[i] = { ...ele, selected: selectedOnes[0].selected, subMenu: selectedOnes[0].subMenu, total: selectedOnes[0].total };
          }
        }

        setDemographicFilter(tempDemographicFilters);
        let demographicObjects = commonUtil.objectify(tempDemographicFilters, "id");
        if (!isEmpty(parsedSelectedFilterCopy) && demographicObjects.hasOwnProperty(parsedSelectedFilterCopy?.id)) {
          demographicObjects[parsedSelectedFilterCopy.id] = { ...demographicObjects[parsedSelectedFilterCopy.id], subMenu: parsedSelectedFilterCopy.subMenu }
        }
        setAllDemographicObjects(JSON.stringify(demographicObjects));
      }

    }
    if (withBackdrop) setBackdrop(false);
  }

  const fetchDemographics = async (companyId, { withLoader = false, withBackdrop = false }) => {
    if (withLoader) setLoading(true);
    if (!selectedTab) return;
    if (withBackdrop) setBackdrop(true);
    const response = await reportsServices.getDemographics(companyId);
    if (response && response.status === 200) {
      let distinctColumnValues = response.data.data.distinctColumnValues;
      updateToDefaultDF(distinctColumnValues);
      let resData = response.data.data;
      if (resData?.mapping && !mapping.length) {
        let mapping = resData?.mapping;
        mapping.forEach(element => {
          element.id = element.table_column;
          element.name = element.excel_column;
        });
        setMapping(mapping);
      }
      if (withLoader) setLoading(false);
      if (withBackdrop) setBackdrop(false);
    }
  }

  const getSelectedDemographics = (demographicFilter) => {
    let selectedFilters = [];
    for (let i = 0; i < demographicFilter.length; i++) {
      if (demographicFilter[i].selected.length) {
        const columnName = getMappingDetails(demographicFilter[i].id)["table_column"];
        const excelColumn = getMappingDetails(demographicFilter[i].id)["excel_column"];
        let filterObj = { columnName: columnName, values: demographicFilter[i].selected, excelColumn: excelColumn }
        selectedFilters.push(filterObj);
      }
    }
    return selectedFilters;
  }

  const updateToDefaultDF = (distinctColumnValues) => {
    if (distinctColumnValues.length) {
      let tempDemographicFilters = [];
      distinctColumnValues.forEach(ele => {
        let tempSubmenu = [];
        ele.values.forEach(item => {
          if (item) tempSubmenu.push({ id: item, name: item })
        });
        let tempObj = { id: ele.columnName, name: ele.columnName, subMenu: tempSubmenu, selected: [], total: tempSubmenu.length };
        tempDemographicFilters.push(tempObj);
      })
      setDemographicFilter(tempDemographicFilters);
      let demographicObjects = commonUtil.objectify(tempDemographicFilters, "id");
      setAllDemographicObjects(JSON.stringify(demographicObjects))

    }
  }

  const getMappingDetails = (value) => mappingObject[value];

  const _applyDemographicFilter = (demographicFilter, payload) => {
    let allDemographicData = JSON.parse(allDemographicObjects);
    let selectedFilters = [];
    let selectedOrders = [];
    let newDemographicFilterData = [];

    for (let i = 0; i < demographicFilter.length; i++) {
      if (demographicFilter[i].selected.length) {
        const mappingObj = getMappingDetails(demographicFilter[i].id);
        let filterObj = {
          columnName: mappingObj["table_column"],
          excelColumn: mappingObj["excel_column"],
          values: demographicFilter[i].selected,
          isFilter: mappingObj["isFilter"],
          order: mappingObj["order"]
        }

        selectedOrders.push(mappingObj["order"]);
        selectedFilters.push(filterObj);
      }
      let tempObj = { ...demographicFilter[i], subMenu: allDemographicData[demographicFilter[i].id].subMenu };
      newDemographicFilterData.push(tempObj);
    }

    //NEXT DEMOGRAPHIC ORDER
    let sortedMapping = [...mapping];
    sortedMapping.sort((a, b) => {
      if (a.order < b.order) return -1
      else return 1
    })
    selectedOrders.sort((a, b) => b - a);

    let current = selectedOrders[0];
    if (!isEmpty(current)) {
      for (let j = 0; j < sortedMapping.length; j++) {
        let isFilter = sortedMapping[j].isFilter;
        let order = sortedMapping[j].order;
        if (isFilter === 1 && order > current && sortedMapping[j].table_column !== "passwordHash") {
          if (!selectedOrders.includes(order)) {
            let missingFilterObj = {
              columnName: sortedMapping[j].table_column,
              excelColumn: sortedMapping[j].excel_column,
              isFilter: sortedMapping[j].isFilter,
              order: sortedMapping[j].order,
              values: [],
            }
            selectedFilters.push(missingFilterObj);
            break;
          }
        }
      }
    }

    let newPayload = { ...payload, flag: "filter", demographics: selectedFilters };

    return { newDemographicFilterData, newPayload }

  }

  const _resetDemographicFilter = async () => {
    await fetchDemographics(companyId, { withBackdrop: true });
  }

  const _handleSubmenuItemDF = (item, selectedfilter, filterList) => {
    let templist = [...filterList];
    for (let i = 0; i < templist.length; i++) {
      if (templist[i].id === selectedfilter.id) {
        let newObj = { ...templist[i] };
        let selected = templist[i].selected;
        if (selected.includes(item.id)) {
          let filtered = selected.filter(ele => ele !== item.id);
          newObj.selected = filtered;
          templist[i] = newObj;
          break;
        } else {
          newObj.selected = [...selected, item.id];
          templist[i] = newObj;
          break;
        }
      }

    }
    let selectedDemographics = getSelectedDemographics(templist);
    fetchFilteredDemographics(companyId, { withBackdrop: true, selectedDemographics: selectedDemographics, newDemographicFilter: templist });
  }

  const _selectAllSubmenuDF = (newSelectedFilter, filterList) => {
    let templist = [...filterList];
    for (let i = 0; i < templist.length; i++) {
      if (templist[i].id === newSelectedFilter.id) {
        let newObj = { ...templist[i], selected: newSelectedFilter.selected };
        templist[i] = newObj;
        break;
      }

    }
    let selectedDemographics = getSelectedDemographics(templist);
    fetchFilteredDemographics(companyId, { withBackdrop: true, selectedDemographics: selectedDemographics, newDemographicFilter: templist });

  }


  const handleDemographicSearch = (value, selectedFilter) => {
    let parsedSelectedFilterCopy;
    if (globalStates?.selectedFilterCopy !== undefined) {
      parsedSelectedFilterCopy = JSON.parse(globalStates.selectedFilterCopy);
    }
    let allDemographics = JSON.parse(allDemographicObjects);
    if (isEmpty(globalStates?.selectedFilterCopy) || parsedSelectedFilterCopy?.id !== selectedFilter?.id) {
      setGlobalStates({ ...globalStates, selectedFilterCopy: JSON.stringify(allDemographics[selectedFilter.id]) });
    }
    let templist = [...demographicFilter];
    for (let i = 0; i < templist.length; i++) {
      if (templist[i].id === selectedFilter.id) {
        const selectedDemographicSubmenu = allDemographics[selectedFilter.id].subMenu;
        if (value === "") {
          templist[i].subMenu = selectedDemographicSubmenu;
          break
        } else {
          let result = selectedDemographicSubmenu.filter(li => li.name.toLowerCase().includes(value.toLowerCase()));
          templist[i].subMenu = result;
          break
        }
      }
    }

    setDemographicFilter(templist);
  }

  const _resetDateRange = (payload) => {
    let newPayload = { ...payload }
    if ("fromDate" in newPayload) delete newPayload["fromDate"];
    if ("toDate" in newPayload) delete newPayload["toDate"];
    if ("startDate" in newPayload) delete newPayload["startDate"];
    if ("endDate" in newPayload) delete newPayload["endDate"];
    if ("reportFetchType" in newPayload) delete newPayload["reportFetchType"];
    return { newPayload }
  }

  const tabStyle = {
    minWidth: '20% !important'
  }

  return (
    <div className={classes.main}>
      {!otherReportTabs.includes(selectedTab) ? <Tabs
        sx={{ display: selectedTab === customReportTabValue && 'none' }}
        className={classes.tabs}
        value={selectedTab}
        onChange={handleSelectTab}
        textColor="secondary"
        indicatorColor="secondary"
        classes={{
          indicator: classes.tabIndicator,
        }}
        aria-label="secondary tabs example"
      >
        {loginCompletionTabs.includes(selectedTab) && <Tab sx={tabStyle} value={1} label="Login Report" icon={<img src='/images/analytics/check.svg' alt='' />} />}
        {loginCompletionTabs.includes(selectedTab) && <Tab sx={tabStyle} value={2} label="Completion Report" icon={<img src='/images/analytics/compliance.svg' alt='' />} />}
        {selectedTab === 3 && <Tab sx={tabStyle} value={3} label="Learner Engagement" icon={<img src='/images/analytics/engage.svg' alt='' />} />}
        {selectedTab === 4 && <Tab sx={tabStyle} value={4} label="Learning Effectiveness" icon={<img src='/images/analytics/compliance.svg' alt='' />} />}
        {selectedTab === 5 && <Tab sx={tabStyle} value={5} label="Feedback" icon={<img src='/images/analytics/star.svg' alt='' />} />}
        {selectedTab === 6 && <Tab sx={tabStyle} value={6} label="Skill Gap Report" icon={<img src='/images/analytics/star.svg' alt='' />} />}
        {selectedTab === 7 && <Tab sx={tabStyle} value={7} label="Tough Question Analysis" icon={<img src='/images/analytics/star.svg' alt='' />} />}
        {selectedTab === 8 && <Tab sx={tabStyle} value={8} label="Leaderboard Report" icon={<img src='/images/analytics/star.svg' alt='' />} />}

      </Tabs> : null}
      {
        loading ?
          <div className={classes.loaderBox}>
            <Loader />
            {/* <h1>Please wait . . .</h1> */}
          </div> :
          <>
            {
              selectedTab === 1 &&
              <LoginReport
                setBackdrop={setBackdrop}
                mapping={mapping}
                demographicFilter={demographicFilter}
                setDemographicFilter={setDemographicFilter}
                _applyDemographicFilter={_applyDemographicFilter}
                _selectAllSubmenuDF={_selectAllSubmenuDF}
                _handleSubmenuItemDF={_handleSubmenuItemDF}
                _resetDemographicFilter={_resetDemographicFilter}
                handleDemographicSearch={handleDemographicSearch}
                _resetDateRange={_resetDateRange}
              />
            }
            {
              selectedTab === 2 &&
              <CompletionReport
                setBackdrop={setBackdrop}
                mapping={mapping}
                demographicFilter={demographicFilter}
                setDemographicFilter={setDemographicFilter}
                _applyDemographicFilter={_applyDemographicFilter}
                _selectAllSubmenuDF={_selectAllSubmenuDF}
                _handleSubmenuItemDF={_handleSubmenuItemDF}
                _resetDemographicFilter={_resetDemographicFilter}
                handleDemographicSearch={handleDemographicSearch}
                contentFilter={contentFilter}
                setContentFilter={setContentFilter}
                _selectAllSubmenuCF={_selectAllSubmenuCF}
                _handleSubmenuItemCF={_handleSubmenuItemCF}
                _applyContentFilter={_applyContentFilter}
                _resetContentFilter={_resetContentFilter}
                _handleContentSearch={_handleContentSearch}
                _resetDateRange={_resetDateRange}
              />
            }
            {
              selectedTab === 3 && <h1>Learner Engagement</h1>
            }
            {
              selectedTab === 4 && <h1>Learning Effectiveness</h1>
            }
            {
              selectedTab === 5 &&
              <Feedback
                backdrop={backdrop}
                setBackdrop={setBackdrop}
                _resetDateRange={_resetDateRange}
              />
            }
            {
              selectedTab === 6 &&
              <SkillGapReport
                setBackdrop={setBackdrop}
                mapping={mapping}
                demographicFilter={demographicFilter}
                setDemographicFilter={setDemographicFilter}
                _applyDemographicFilter={_applyDemographicFilter}
                _selectAllSubmenuDF={_selectAllSubmenuDF}
                _handleSubmenuItemDF={_handleSubmenuItemDF}
                _resetDemographicFilter={_resetDemographicFilter}
                handleDemographicSearch={handleDemographicSearch}
                contentFilter={contentFilter}
                setContentFilter={setContentFilter}
                _selectAllSubmenuCF={_selectAllSubmenuCF}
                _handleSubmenuItemCF={_handleSubmenuItemCF}
                _applyContentFilter={_applyContentFilter}
                _resetContentFilter={_resetContentFilter}
                _handleContentSearch={_handleContentSearch}
              />
            }
            {
              selectedTab === 7 &&
              <ToughQuestionAnalysisReport
                setBackdrop={setBackdrop}
                mapping={mapping}
                demographicFilter={demographicFilter}
                setDemographicFilter={setDemographicFilter}
                _applyDemographicFilter={_applyDemographicFilter}
                _selectAllSubmenuDF={_selectAllSubmenuDF}
                _handleSubmenuItemDF={_handleSubmenuItemDF}
                _resetDemographicFilter={_resetDemographicFilter}
                handleDemographicSearch={handleDemographicSearch}

                contentFilter={contentFilter}
                setContentFilter={setContentFilter}
                _selectAllSubmenuCF={_selectAllSubmenuCF}
                _handleSubmenuItemCF={_handleSubmenuItemCF}
                _applyContentFilter={_applyContentFilter}
                _resetContentFilter={_resetContentFilter}
                _handleContentSearch={_handleContentSearch}
              />
            }
            {
              selectedTab === 8 &&
              <LeaderboardReport
                setBackdrop={setBackdrop}
                mapping={mapping}
                demographicFilter={demographicFilter}
                setDemographicFilter={setDemographicFilter}
                _applyDemographicFilter={_applyDemographicFilter}
                _selectAllSubmenuDF={_selectAllSubmenuDF}
                _handleSubmenuItemDF={_handleSubmenuItemDF}
                _resetDemographicFilter={_resetDemographicFilter}
                handleDemographicSearch={handleDemographicSearch}
                contentFilter={contentFilter}
                setContentFilter={setContentFilter}
                _selectAllSubmenuCF={_selectAllSubmenuCF}
                _handleSubmenuItemCF={_handleSubmenuItemCF}
                _applyContentFilter={_applyContentFilter}
                _resetContentFilter={_resetContentFilter}
                _handleContentSearch={_handleContentSearch}
              />
            }

            {
              otherReportTabs.includes(selectedTab) &&
              <OtherReports
                setBackdrop={setBackdrop}
                mapping={mapping}
                demographicFilter={demographicFilter}
                setDemographicFilter={setDemographicFilter}
                _applyDemographicFilter={_applyDemographicFilter}
                _selectAllSubmenuDF={_selectAllSubmenuDF}
                _handleSubmenuItemDF={_handleSubmenuItemDF}
                _resetDemographicFilter={_resetDemographicFilter}
                handleDemographicSearch={handleDemographicSearch}
                contentFilter={contentFilter}
                setContentFilter={setContentFilter}
                _selectAllSubmenuCF={_selectAllSubmenuCF}
                _handleSubmenuItemCF={_handleSubmenuItemCF}
                _applyContentFilter={_applyContentFilter}
                _resetContentFilter={_resetContentFilter}
                _handleContentSearch={_handleContentSearch}
                _resetDateRange={_resetDateRange}
                selectedTab={selectedTab}
                handleSelectTab={handleSelectTab}
              />
            }
            {
              selectedTab === customReportTabValue && <ReportBuilder
                setBackdrop={setBackdrop}
                contentFilter={contentFilter}
                setContentFilter={setContentFilter}
                _applyContentFilter={_applyContentFilter}
                _resetContentFilter={_resetContentFilter}
                _handleContentSearch={_handleContentSearch}
                _selectAllSubmenuCF={_selectAllSubmenuCF}
                _handleSubmenuItemCF={_handleSubmenuItemCF} />
            }
            {
              selectedTab === 21 &&
              <CustomTemplates
                setBackdrop={setBackdrop}
                mapping={mapping}
              />
            }
            {
              selectedTab === 22 &&
              <CustomReportHistoryData
                setBackdrop={setBackdrop}
                mapping={mapping}
              />
            }

          </>
      }
      <Backdrop
        sx={{
          backgroundColor: 'rgb(0 0 0 / 75%)',
          backdropFilter: 'blur(4px)',
          color: '#fff',
          zIndex: 99999
        }}
        open={backdrop}
        onClick={handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>

  );

}


export default Analytics;