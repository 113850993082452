import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';

//utils
import isEmpty from '../../../../utils/isEmpty';

//constants
import constants from '../../../../constants';

//components
// import Title from '../../../title';
import CustomTextArea from '../../../custom-styled/custom-text-area';
import CustomInput from '../../../custom-styled/custom-input'
// import ControlledSwitches from '../../../custom-styled/switch-button';
import CustomButton from '../../../custom-styled/button';

// const MIN_RESPONSES = 3;
// const MAX_RESPONSES = 5;

const Sequence = ({
  type,
  activeQuestion = { designType: '' },
  handleQuestion,
  handleResponse,
  list,
  setList,
  ilaCardId,
  selectedQuestion
}) => {
  const classes = useStyles();
  const { minimum, maximum } = constants.ILA_QUESTION_JSON['ILA_SEQUENTIAL']?.responsePerQuestion
  const MIN_RESPONSES = minimum, MAX_RESPONSES= maximum;

  const answerObj = { answer: "", isCorrect: true, sequence: activeQuestion?.answers?.length + 1 };
  const createValidation = (iteration) => new Array(iteration).fill({ exceeded: false, limitText: `${responseValidation} CHARACTERS` })

  const questionValidation = constants.ILA_VALIDATIONS[type]['question'];
  const responseValidation = constants.ILA_VALIDATIONS[type]['response'];
  const [questionLimit, setQuestionLimit] = useState({ exceeded: false, limitText: `${questionValidation} CHARACTERS` })
  const [responseLimit, setResponseLimit] = useState(createValidation(MIN_RESPONSES));
  const [showAddResponse, setShowAddResponse] = useState(false);
  if (activeQuestion.designType !== 'ILA_SEQUENTIAL') {
    activeQuestion = {}
  }

  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let questionTempLimit = {};
      let value = activeQuestion.question;
      if (value.length > questionValidation) {
        //HERE LENGTH OF INPUT IS GREATER THAN questionCharlimit CHARACTERS.
        questionTempLimit = { exceeded: true, limitText: `${value.length - questionValidation} CHARACTERS EXCEEDED!` };
        setQuestionLimit(questionTempLimit);
      } else {
        //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
        if (activeQuestion.isQuestionEmpty) {
          questionTempLimit = { exceeded: false, limitText: `*Required` };
        } else {
          questionTempLimit = { exceeded: false, limitText: `${questionValidation - value.length} CHARACTERS` };
        }
        setQuestionLimit(questionTempLimit);
      }
    }
  }, [activeQuestion])

  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let temp = [...responseLimit];
      if (activeQuestion.answers.length < MAX_RESPONSES) {
        setShowAddResponse(true);
      } else {
        setShowAddResponse(false);
      }
      activeQuestion.answers.forEach((element, id) => {
        let value = element.answer;
        if (value.length > responseValidation) {
          //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
          temp[id] = { exceeded: true, limitText: `${value.length - responseValidation} CHARACTERS EXCEEDED!` };
          // setResponsesValidations(temp);
        } else {
          //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
          if (activeQuestion.emptyAnswers[id].isEmpty && id < MIN_RESPONSES) {
            temp[id] = { exceeded: false, limitText: `*Required` };
          } else {
            temp[id] = { exceeded: false, limitText: `${responseValidation - value.length} CHARACTERS` };
          }
        }
      });
      // console.log('McqResponse temp = ', temp);
      setResponseLimit(() => {
        if (temp.length > activeQuestion.answers.length) {
          let slicedArr = temp.slice(0, activeQuestion.answers.length)
          return slicedArr
        }
        return temp
      });
    }


  }, [activeQuestion])


  const handleQuestionChange = (e) => {
    let value = e.target.value;
    handleQuestion(value);
    console.log(value.length);
    if (value.length > questionValidation) {
      setQuestionLimit({ exceeded: true, limitText: `${value.length - questionValidation} CHARACTERS EXCEEDED!` })
    } else {
      setQuestionLimit({ exceeded: false, limitText: `${questionValidation - value.length} CHARACTERS` })
    }
  }

  const handleResponseChange = (e, index) => {
    console.log('handleResponseChange index = ', index);
    let value = e.target.value;
    handleResponse(value, index);
    let temp = [...responseLimit];
    if (value.length > responseValidation) {
      temp[index] = { exceeded: true, limitText: `${value.length - responseValidation} CHARACTERS EXCEEDED!` }
      setResponseLimit(temp)
    } else {
      temp[index] = { exceeded: false, limitText: `${responseValidation - value.length} CHARACTERS` }
      setResponseLimit(temp)
    }
  }

  const handleAddResponse = () => {
    let listCopy = [...list];
    for (let i = 0; i < listCopy.length; i++) {
      if (listCopy[i].id === ilaCardId) {
        listCopy[i].games.questions[selectedQuestion].answers.push(answerObj);
        listCopy[i].games.questions[selectedQuestion].emptyAnswers.push({isEmpty: false});
        // console.log('listCopy[i] = ', listCopy[i]);
        break;
      }
    }
    setList(listCopy);

    let temp = [...responseLimit];
    temp.push({ exceeded: false, limitText: `${responseValidation} CHARACTERS` });
    setResponseLimit(temp);

    if (temp.length >= MAX_RESPONSES) {
      setShowAddResponse(false);
    }
  }

  const handleDeleteResponse = (element, index) => {
    let listCopy = [...list];
    for (let i = 0; i < listCopy.length; i++) {
      if (listCopy[i].id === ilaCardId) {
        let answersArr = [...listCopy[i].games.questions[selectedQuestion].answers];
        answersArr.splice(index, 1);
        let seqAnswersArr = answersArr.sort((a, b) => a.sequence - b.sequence).map((e, i) => ({ ...e, sequence: i + 1 }))
        if (seqAnswersArr.length < MAX_RESPONSES) {
          setShowAddResponse(true);
        }
        listCopy[i].games.questions[selectedQuestion].answers = seqAnswersArr
        break;
      }
    }
    setList(listCopy);
    let temp = [...responseLimit];
    temp.splice(index, 1);
    setResponseLimit(temp);
  }

  useEffect(() => {
    if (responseLimit.length > MAX_RESPONSES) {
      setShowAddResponse(false)
    }
  }, [responseLimit])

  return (
    <div className={`${classes.main} scrollbar`} style={{overflowX: 'hidden', overflowY: 'auto'}}>
      {/* <div className={classes.row2}> */}
        <div className={classes.card}>
          {/* <Title titleText={'Sequence'} /> */}
          <div className={classes.contextBox}>
            <CustomTextArea
              value={isEmpty(activeQuestion) ? '' : activeQuestion.question}
              handleChange={handleQuestionChange}
              exceedText={questionLimit.limitText}
              isExceeded={questionLimit.exceeded}
              isEmpty={isEmpty(activeQuestion) ? false : activeQuestion.isQuestionEmpty}
            />
          </div>
          <div className={classes.responsesBox}>
            {
              responseLimit.map((ele, i, arr) => (
                <div className={classes.response} key={i}>
                  <div className={classes.column1}>
                    {i + 1}
                  </div>
                  <div className={classes.column2}>
                    <CustomInput
                      wrapperWidth={"100%"}
                      value={isEmpty(activeQuestion) ? '' : activeQuestion?.answers[i]?.answer}
                      handleChange={(e) => handleResponseChange(e, i)}
                      exceedText={responseLimit[i].limitText}
                      isExceeded={responseLimit[i].exceeded}
                      isEmpty={isEmpty(activeQuestion) ? false : activeQuestion.emptyAnswers[i].isEmpty}
                    />

                  </div>
                  {
                    arr.length > MIN_RESPONSES && (
                      <div className={classes.column3}>
                        <DeleteIcon
                          key={i}
                          style={{ cursor: "pointer", color: 'rgba(100, 100, 100, 1' }}
                          onClick={(e) => {
                            handleDeleteResponse(ele, i)
                            console.log(ele)
                            // setShowDeleteDialog(true);
                            // setDeleteParameters({ questionIdx: i, answerIdx: undefined, type: 'Question' })
                          }} />
                      </div>
                    )
                  }
                </div>
              ))
            }
          </div>
          {
            showAddResponse ?
              <div className={classes.addResponse}>
                <CustomButton btnText={'Add New Response +'} handleClick={handleAddResponse} />
              </div> : null
          }
        </div>
      {/* </div> */}
    </div>
  )
}

export default Sequence;

const useStyles = makeStyles(() => ({
  main: {
    // backgroundColor : 'red',
    width: '100%',
    minHeight: '60%',
    maxHeight: '98%',
    display: 'flex',
    flexDirection: 'column',
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: '#f4511e'
    }

  },
  row1: {
    // border : '1px solid black',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '6%',
    width: '100%',
  },
  checked: {
    '& span': {
      '& .Mui-checked ': {
        color: '#f4511e',

      },
      '& .MuiSwitch-track ': {
        backgroundColor: 'rgb(244, 81, 30) !important'
      }
    }
  },
  scoreBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '27%',
    '& input': {
      width: '26%',
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
  },
  mandatoryBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '25%'
  },
  row2: {
    // border : '1px solid black',
    height: '94%',
    width: '100%',
  },
  card: {
    // aspectRatio: 0.87,
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '1rem 2rem',
    height: '100%',
    '& > h2': {
      marginTop: '1rem',
      fontSize: '1.6rem',
      color: 'rgb(0,0,0, 0.8)'
    }
  },
  contextBox: {
    // border : '1px solid black',
    width: '100%',
    height: '33%',
    marginTop: '1.5rem',
  },
  responsesBox: {
    // border: '1px solid',
    height: '51%',
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.7rem'
  },
  response: {
    display: 'flex',
    height: '2.5rem',
    columnGap: '3%'
  },
  column1: {
    border: '1px solid #ccc',
    color: 'rgb(112, 112, 112, 0.7)',
    width: '10%',
    aspectRatio: 1 / 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  column2: {
    // border : '1px solid',
    width: '85%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center'
  },

  column3: {
    // border : '1px solid',
    width: '5%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  addResponse: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '1rem',
    '& .MuiButtonBase-root': {
      width: '30%',
      height: '2rem',
      padding: 0,
      fontSize: '0.5rem',
      fontWeight: 700,
      borderRadius: '4px'
    }
  }
}));