import React, { useState, useEffect, useRef, useContext } from 'react';
import TopBarWrapper from '../../components/tms-components/TopBarWrapper';
import MOTopBar from '../../components/tms-components/MOTopBar';
import TopBarHeading from '../../components/tms-components/TopBarHeading';
import FormControl from '../../components/tms-components/FormControl';
import Label from '../../components/tms-components/Label';
import TextInput from '../../components/tms-components/TextInput';
import SimpleFormSelect from '../../components/tms-components/SimpleFormSelect';
import CoursePathwayElement from '../../components/tms-components/CoursePathwayElement';
import FlatPrimaryButton from '../../components/tms-components/FlatPrimaryButton';
import FlatClearButton from '../../components/tms-components/FlatClearButton';
// import { Dialog } from '@material-ui/core';
import AddCourseEvent from './AddCourseEvent';
import styled from 'styled-components';
import ClearButton from '../../components/tms-components/ClearButton';
import PlusIcon from '../../components/tms-components/MDLIcon/icons/Plus';
import Loader from '../Loader';
import Button2 from '../../components/tms-components/Button2';
import SuccessTick from '../../assets/success.svg';
import FailureTick from '../../assets/failure.svg';

//new
import Dialog from '@mui/material/Dialog';

//utils
import useQuery from '../../utils/useQuery';

//services
import courseServices from '../../services/course';

//global-states
import { GlobalStates } from '../../App';

const courseElementBGConstants = ['#1a56bd', '#f63b34', '#56dab8', '#ffdd00'];

const FormWrapper = styled.div`
  padding: 30px;
  text-align: left;
`;

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

function NewCourse(props) {
  const query = useQuery();
  const companyId = query.get('c_id');
  const [globalStates] = useContext(GlobalStates);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category_object, setCategoryObject] = useState({});
  const [course_types, setCourseTypes] = useState([
    {
      id: 0,
      display_text: '',
    },
  ]);

  const newEventModuleOptions = [];

  if (
    props.hasOwnProperty('companyInfo') &&
    props.companyInfo.hasOwnProperty('modules')
  ) {
    for (let microskill of props.companyInfo.modules) {
      newEventModuleOptions.push({
        id: microskill.id,
        display_text: microskill.name,
      });
    }
  }

  let trainingHoursOptions = [];

  for (let i = 0; i <= 8; i++) {
    trainingHoursOptions.push({
      id: i,
      display_text: i.toString(),
    });
  }

  const [progressText, setProgressText] = useState('Please wait...');
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogText, setErrorDialogText] = useState(
    'There was an unknown error, please try again?',
  );

  const [courseName, setCourseName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [categoryNameErrorText, setCategoryNameErrorText] = useState(null);

  const courseNameRef = useRef(null);

  const [courseNameErrorText, setCourseNameErrorText] = useState(null);
  const [categoryErrorText, setCategoryErrorText] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(0);

  const [addCourseEventDialogOpen, setAddCourseEventDialogOpen] = useState(
    false,
  );
  const [newEventName, setNewEventName] = useState(null);
  const [newEventSelectedModules, setNewEventSelectedModules] = useState([]);
  const [newEventTrainingHours, setNewEventTrainingHours] = useState(8);

  const [editCourseEventDialogOpen, setEditCourseEventDialogOpen] = useState(
    false,
  );
  const [editCourseEventIndex, setEditCourseEventIndex] = useState(0);
  const [editEventName, setEditEventName] = useState(null);
  const [editEventSelectedModules, setEditEventSelectedModules] = useState([]);
  const [editEventTrainingHours, setEditEventTrainingHours] = useState(8);

  // Teams, Google Meet links
  const [eventInviteLink, setEventInviteLink] = useState(null);
  const [eventInviteLinkPassword, setEventInviteLinkPassword] = useState(null);

  const newEventModuleOptionsObject = {};
  const editEventModuleOptionsObject = {};

  useEffect(() => {
    fetchCourseCategories(companyId);
    fetchCourseTypes();
  }, [])

  const fetchCourseCategories = async (companyId) => {
    const res = await courseServices.fetchCourseCategories(companyId);
    console.log('fetchCourseCategories res = ', res);
    if (res.status === 200 || res.status === 201) {
      let data = res.data.data;
      let tempCategoryOptions = [];
      let tempCategoryObject = {};
      data.forEach(element => {
        tempCategoryOptions.push(
          {
            id: element.id,
            display_text: element.name,
          },
        );
        tempCategoryObject[element.id] = { name: element.name };
      })
      console.log('tempCategoryOptions = ', tempCategoryOptions);
      console.log('tempCategoryObject = ', tempCategoryObject);
      setCategoryOptions(tempCategoryOptions);
      setCategoryObject(tempCategoryObject);
    }
  }

  const fetchCourseTypes = async () => {
    const res = await courseServices.fetchCourseTypes();
    console.log('fetchCourseTypes res = ', res);
    if (res.status === 200 || res.status === 201) {
      let data = res.data.data;
      let tempCourseTypes = [];
      data.forEach(element => {
        tempCourseTypes.push(
          {
            id: element.id,
            display_text: element.name,
          },
        );
        if (element.name === 'Blended Learning Journey') setSelectedCourse(element.id)
      })
      console.log('tempCourseTypes = ', tempCourseTypes);
      setCourseTypes(tempCourseTypes);
    }
  }

  const defaultCoursePathwayElements = [
    {
      event_name: 'Pre-Module Assessment',
      modules: [],
      training_hours: 8,
      meetingURL: '',
      meetingURLPassword: ''
    },
    {
      event_name: 'Training Workshop',
      modules: [],
      training_hours: 8,
      meetingURL: '',
      meetingURLPassword: ''
    },
    {
      event_name: 'Post-Workshop Module',
      modules: [],
      training_hours: 8,
      meetingURL: '',
      meetingURLPassword: ''
    }
  ];

  let [coursePathwayElements, setCoursePathwayElements] = useState(
    defaultCoursePathwayElements,
  );



  for (let m of newEventModuleOptions) {
    editEventModuleOptionsObject[m.id] = m;
  }

  for (let m of newEventModuleOptions) {
    newEventModuleOptionsObject[m.id] = m;
  }

  let newEventModulesSelectedText = '';

  for (let selected of newEventSelectedModules) {
    if (newEventModuleOptionsObject.hasOwnProperty(selected)) {
      newEventModulesSelectedText +=
        newEventModuleOptionsObject[selected].display_text + ', ';
    }
  }

  let editEventModulesSelectedText = '';

  for (let selected of editEventSelectedModules) {
    if (editEventModuleOptionsObject.hasOwnProperty(selected)) {
      editEventModulesSelectedText +=
        editEventModuleOptionsObject[selected].display_text + ', ';
    }
  }

  function runFormValidation() {
    if (!categoryId) {
      setCategoryErrorText("Please select the category.")
      return false
    } else if (courseName === '' || courseName === null) {
      setCourseNameErrorText('Course Name is required');
      courseNameRef.current.focus();
      return false;
    } else {
      setCourseNameErrorText(null);
      setCategoryErrorText(null);
    }

    return true;
  }

  const handleAddEventMicroskill = async (payload) => {
    const res = await courseServices.addEventMicroskills(payload);
    console.log('handleAddEventMicroskill res = ', res);
  }

  const handleCreateEvent = async (eventPayload, isLast = false) => {
    console.log('handleCreateEvent eventPayload = ', eventPayload);
    const eventResponse = await courseServices.createCourseEvent(eventPayload);
    console.log('handleCreateEvent = ', isLast, ' ', eventResponse);
    if (eventResponse.status === 200 || eventResponse.status === 201) {
      const courseEventId = eventResponse.data.data.id;
      if (eventPayload.microskillIds.length) {
        let addMsToEventPayload = {
          companyId: eventPayload.companyId,
          microskillIds: eventPayload.microskillIds,
          courseEventId: courseEventId
        }
        console.log('handleCreateEvent addMsToEventPayload = ', addMsToEventPayload);
        const addMsToEventResponse = await courseServices.addEventMicroskills(addMsToEventPayload);
        console.log(' addMsToEventResponse = ', addMsToEventResponse);
      }

      // eventMicroskills.forEach(ele => {
      //   const addEventMicroskillPayload = {
      //     companyId: companyId,
      //     microskillId: ele,
      //     courseEventId: courseEventId,

      //   }
      //   handleAddEventMicroskill(addEventMicroskillPayload)
      // })


      if (isLast) {
        setProgressDialogOpen(false);
        setSuccessDialogOpen(true);
        props.onCourseAdded();
      }

    }
  }

  const handleCreateCourse = async () => {
    let isFormValid = runFormValidation();
    if (isFormValid !== true) {
      return;
    }
    setProgressDialogOpen(true);

    let networkRequestBodyObject = {
      companyId: companyId,
      name: courseName,
      description: "test description",
      courseTypeId: selectedCourse,
      categoryId: categoryId,
      // course_events: coursePathwayElements,
      // project_id: props.activeProject,
    };
    const courseResponse = await courseServices.createCourse(networkRequestBodyObject)
    setProgressDialogOpen(false);
    if (courseResponse.status === 200 || courseResponse.status === 201) {
      //after creating course here we have to create course event
      const courseId = courseResponse.data.data.id;

      console.log(' loop started');
      coursePathwayElements.forEach((element, index) => {
        let eventPayload = {
          companyId: companyId,
          courseId: courseId,
          name: element.event_name,
          trainingHours: element.training_hours,
          microskillIds: element.modules,
          sequence: index + 1,
          meetingURL: element.meetingURL,
          meetingURLPassword: element.meetingURLPassword
        }

        let isLast = false;
        if (index === coursePathwayElements.length - 1) {
          isLast = true;
        }
        handleCreateEvent(eventPayload, isLast);

      })
      console.log(' loop end ');

      // setProgressDialogOpen(false);
      // setSuccessDialogOpen(true);
      // props.onCourseAdded();



    } else if (courseResponse.status === 400 && courseResponse.data.errors[0].message) {
      setErrorDialogText(courseResponse.data.errors[0].message)
      setErrorDialogOpen(true);
    } else {
      setProgressDialogOpen(false);
      setErrorDialogText("Internal Server Error.")
      setErrorDialogOpen(true);
    }

    //OLD API REQUEST
    // let action = props.MOClient.create_training_course({
    //   token: props.jwtToken,
    //   data: networkRequestBodyObject,
    // });
    // action.then((response) => {
    //   setProgressDialogOpen(false);

    //   if (response.status === 'success') {
    //     setSuccessDialogOpen(true);
    //     props.onCourseAdded();
    //   } else {
    //     if (response.hasOwnProperty('description')) {
    //       setErrorDialogText(response.description);
    //     }
    //     setErrorDialogOpen(true);
    //   }
    // });
  }

  React.useEffect(() => {
    if (courseName === '') {
      setCourseNameErrorText('Course Name is required');
      courseNameRef.current.focus();
    } else {
      setCourseNameErrorText('');
    }
  }, [courseName]);


  return (
    <div style={{ backgroundColor: '#fff', minHeight: 'calc(100vh - 3.4rem)' }}>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Create new Training Course</TopBarHeading>
        </MOTopBar>
        <div>
          <FormWrapper>
            {/* {props.companyInfo.company_id == 90 && ( */}
            <FormControl>
              <Label>Category Name</Label>

              <SimpleFormSelect
                autoClose={true}
                options={categoryOptions}
                text={categoryId === null ? "Select category" : category_object[categoryId].name}
                onSelect={setCategoryId}
                setErrorText={setCategoryErrorText}
                errorText={categoryErrorText}
              />
            </FormControl>
            {/* )} */}
            {console.log(categoryId)}
            <FormControl>
              <Label>
                Course Name <span style={{ color: 'red' }}>*</span>
              </Label>
              <TextInput
                ref={courseNameRef}
                value={courseName}
                onChangeText={(text) => {
                  setCourseName(text);
                }}
                errorText={courseNameErrorText}
              />
            </FormControl>

            <FormControl>
              <Label>Course Type</Label>

              <SimpleFormSelect
                autoClose={true}
                options={course_types}
                text={
                  course_types.filter((v) => v.id === selectedCourse)[0]?.display_text
                }

                onSelect={setSelectedCourse}
              />
            </FormControl>

            <FormControl>
              <Label>Course Pathway</Label>
              {coursePathwayElements.map((e, k) => {
                console.log(coursePathwayElements[k])
                return (
                  <CoursePathwayElement
                    key={k}
                    disableClone={true}
                    backgroundColor={courseElementBGConstants[k % 4]}
                    text={e.event_name}
                    onEdit={() => {
                      setEditCourseEventIndex(k);
                      setEditEventName(coursePathwayElements[k].event_name);
                      setEditEventTrainingHours(
                        coursePathwayElements[k].training_hours,
                      );
                      setEditEventSelectedModules(
                        coursePathwayElements[k].modules,
                      );
                      setEventInviteLink(coursePathwayElements[k].meetingURL);
                      setEventInviteLinkPassword(coursePathwayElements[k].meetingURLPassword);
                      setEditCourseEventDialogOpen(true);
                    }}
                    onCopy={() => {
                      let copy = [...coursePathwayElements];
                      copy.push(coursePathwayElements[k]);
                      setCoursePathwayElements(copy);
                    }}
                    onDelete={() => {
                      if (coursePathwayElements.length === 1) {
                        globalStates.handleToast(true, 'Minimun 1 event is required', 'error')
                        return
                      }
                      let copy = [...coursePathwayElements];
                      copy.splice(k, 1);
                      setCoursePathwayElements(copy);
                    }}
                  />
                );
              })}

              <StyledGenericButton
                onClick={() => {
                  setAddCourseEventDialogOpen(true);
                }}>
                <div style={{ marginRight: 5, display: 'flex' }}>
                  <StyledPlusIcon />
                </div>
                Add Course Event
              </StyledGenericButton>
            </FormControl>

            <FormControl>
              <FlatPrimaryButton
                onClick={handleCreateCourse}>
                Schedule Course
              </FlatPrimaryButton>

              <div style={{ marginLeft: 10, display: 'inline-block' }}>
                <FlatClearButton
                  onClick={() => {
                    setCoursePathwayElements([]);
                    setCourseName('');
                  }}>
                  Clear and Delete
                </FlatClearButton>
              </div>
            </FormControl>
          </FormWrapper>
        </div>

        {/* Add Course Event Dialog */}
        <div id="create-course-event-dialog">
          <Dialog
            open={addCourseEventDialogOpen}
            onClose={() => {
              setAddCourseEventDialogOpen(false);
            }}>
            <AddCourseEvent
              eventName={newEventName}
              onChangeEventName={(text) => {
                setNewEventName(text);
              }}
              moduleOptions={newEventModuleOptions}
              eventInviteLink={eventInviteLink}
              onChangeEventInviteLink={(text) => {
                setEventInviteLink(text);
              }}
              eventInviteLinkPassword={eventInviteLinkPassword}
              onChangeEventInviteLinkPassword={(text) => {
                setEventInviteLinkPassword(text);
              }}
              onModuleSelect={(id) => {
                let copy = [...newEventSelectedModules];
                if (copy.indexOf(id) === -1) {
                  copy.push(id);
                  setNewEventSelectedModules(copy);
                } else {
                  copy = copy.filter((e) => e !== id);
                  setNewEventSelectedModules(copy);
                }
              }}
              modulesSelected={newEventSelectedModules}
              selectedModulesText={newEventModulesSelectedText}
              trainingHoursOptions={trainingHoursOptions}
              trainingHoursSelected={newEventTrainingHours}
              onTrainingHoursSelect={(id) => {
                setNewEventTrainingHours(id);
              }}
              trainingHoursText={
                trainingHoursOptions.filter(
                  (i) => i.id == newEventTrainingHours,
                )[0].display_text
              }
              onAddEvent={() => {
                if (newEventName === '' || newEventName === null) {
                  setErrorDialogOpen(true);
                  setErrorDialogText('Error: Event Name cannot be empty');
                  return;
                }
                let alreadyExist = false;
                coursePathwayElements.forEach(element => {
                  if (element.event_name.toLowerCase() === newEventName.toLowerCase()) {
                    alreadyExist = true;
                  }
                })
                if (alreadyExist) {
                  setErrorDialogOpen(true);
                  setErrorDialogText(`${newEventName} already exists`);
                  return;
                }
                let o = {
                  event_name: newEventName,
                  modules: newEventSelectedModules,
                  training_hours: newEventTrainingHours,
                  meetingURL: eventInviteLink,
                  meetingURLPassword: eventInviteLinkPassword
                };

                let copy = [...coursePathwayElements];
                copy.push(o);
                setCoursePathwayElements(copy);
                setEventInviteLink('');
                setEventInviteLinkPassword('');
                setNewEventName('');
                setNewEventSelectedModules([]);
                setNewEventTrainingHours(8);
                setAddCourseEventDialogOpen(false);

              }}
              onDelete={() => {
                setAddCourseEventDialogOpen(false);
              }}
            />
          </Dialog>
        </div>

        {/* Edit Course Event Dialog */}
        <div id="edit-course-event-dialog">
          <Dialog
            open={editCourseEventDialogOpen}
            onClose={() => {
              setEditCourseEventDialogOpen(false);
            }}>
            <AddCourseEvent
              eventName={editEventName}
              onChangeEventName={(text) => {
                setEditEventName(text);
              }}
              eventInviteLink={eventInviteLink}
              onChangeEventInviteLink={(text) => {
                setEventInviteLink(text);
              }}
              eventInviteLinkPassword={eventInviteLinkPassword}
              onChangeEventInviteLinkPassword={(text) => {
                setEventInviteLinkPassword(text);
              }}
              moduleOptions={newEventModuleOptions}
              onModuleSelect={(id) => {
                let copy = [...editEventSelectedModules];
                if (copy.indexOf(id) === -1) {
                  copy.push(id);
                  setEditEventSelectedModules(copy);
                } else {
                  copy = copy.filter((e) => e !== id);
                  setEditEventSelectedModules(copy);
                }
              }}
              modulesSelected={editEventSelectedModules}
              selectedModulesText={editEventModulesSelectedText}
              trainingHoursOptions={trainingHoursOptions}
              trainingHoursSelected={editEventTrainingHours}
              onTrainingHoursSelect={(id) => {
                setEditEventTrainingHours(id);
              }}
              trainingHoursText={
                trainingHoursOptions.filter(
                  (i) => i.id == editEventTrainingHours,
                )[0].display_text
              }
              onAddEvent={() => {
                if (editEventName === '' || editEventName === null) {
                  setErrorDialogOpen(true);
                  setErrorDialogText('Error: Event Name cannot be empty');
                  return;
                }


                let o = {
                  event_name: editEventName,
                  modules: editEventSelectedModules,
                  training_hours: editEventTrainingHours,
                  meetingURL: eventInviteLink,
                  meetingURLPassword: eventInviteLinkPassword
                };

                let copy = [...coursePathwayElements];

                copy[editCourseEventIndex] = o;

                setCoursePathwayElements(copy);

                setEditEventName('');
                setEditEventSelectedModules([]);
                setEditCourseEventDialogOpen(false);
              }}
              onDelete={() => {
                setEditCourseEventDialogOpen(false);
              }}
            />
          </Dialog>
        </div>

        {/* Progress Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={progressDialogOpen}
          onClose={() => {
            setProgressDialogOpen(false);
          }}>
          <ProgressContainer>
            <Loader fill="#f4511e" />
            <ProgressText>Please wait...</ProgressText>
          </ProgressContainer>
        </Dialog>

        {/* Success Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={successDialogOpen}
          onClose={() => {
            setSuccessDialogOpen(false);
          }}>
          <ProgressContainer>
            <img src={SuccessTick} style={{ width: '100px' }} alt="Success" />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>Course Created Successfully!</ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  props.history.push(`/drive/courses-and-training/course-log?c_id=${companyId}`);
                }}>
                Go to Course Log
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>

        {/* Failure Dialog */}
        <Dialog
          open={errorDialogOpen}
          onClose={() => {
            setErrorDialogOpen(false);
          }}>
          <ProgressContainer>
            <img src={FailureTick} style={{ width: '80px' }} />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>{errorDialogText}</ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  setErrorDialogOpen(false);
                }}>
                Close
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>
      </TopBarWrapper>
    </div>
  );
}

export default NewCourse;
