import React, { useState } from 'react';

// components
import CustomInput from '../custom-input';

// mui-icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// mui
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

// styles
import useStyles from './index.styles';
import { useEffect } from 'react';
import isEmpty from '../../../../utils/isEmpty';

const RATING_METHODS = {
    STAR_RATING: 'STAR_RATING',
    NUMBER_RATING: 'NUMBER_RATING'
}

const RatingResponses = (props) => {
    const { questionIdx, formQuestion, setFormQuestion } = props;
    const Question = formQuestion[questionIdx];
    const Options = Question.options;
    const Type = Question.type;
    const CharLimit = 10;

    const [upperLimitValidation, setUpperLimitValidation] = useState({ exceeded: false, limitText: `${CharLimit} Characters`, isEmpty: true });
    const [lowerLimitValidation, setLowerLimitValidation] = useState({ exceeded: false, limitText: `${CharLimit} Characters`, isEmpty: true });

    const styles = useStyles();

    const valueSetter = (field, value) => {
        setFormQuestion(prev => {
            let tempObj = [...prev];
            tempObj[questionIdx].options[field] = value;
            return tempObj;
        })
    }

    const handleChangeUpperLimitText = (e) => {
        let value = e.target.value;
        valueSetter('maxLabel', value)
    }

    const handleChangeLowerLimitText = (e) => {
        let value = e.target.value;
        valueSetter('minLabel', value)
    }

    const handleArrowUpClick = (text) => {
        let upperLimit = Options?.maxLimit ?? 5
        let lowerLimit = Options?.minLimit ?? 1
        if (text === 'UPPER_LIMIT' && upperLimit < 10) {
            valueSetter('maxLimit', upperLimit + 1)
        }
        else if (text === 'LOWER_LIMIT' && lowerLimit < 10 && lowerLimit < upperLimit - 1) {
            valueSetter('minLimit', lowerLimit + 1)
        }
    }

    const handleArrowDownClick = (text) => {
        let upperLimit = Options?.maxLimit ?? 5
        let lowerLimit = Options?.minLimit ?? 1;

        if (text === 'UPPER_LIMIT' && upperLimit > 2) {
            if (lowerLimit + 1 === upperLimit) {
                valueSetter('minLimit', lowerLimit - 1)
            }
            valueSetter('maxLimit', upperLimit - 1)
        }
        else if (text === 'LOWER_LIMIT' && lowerLimit >= 2) {
            valueSetter('minLimit', lowerLimit - 1)
        }
    }

    const handleRatingMethod = (e, value) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].type = value;
        setFormQuestion(tempObj);
    }

    const validationHandler = (value, setter) => {
        let lengthVal = value?.length
        if (lengthVal > CharLimit) {
            setter({ exceeded: true, limitText: `${lengthVal - CharLimit} characters exceeded`, isEmpty: false })
        } else {
            setter({ exceeded: false, limitText: `${CharLimit - lengthVal} characters`, isEmpty: isEmpty(value) })
        }

    }

    // uncomment this to enable validations 
    // useEffect(() => {
    //     const options = formQuestion[questionIdx]?.options
    //     const maxLabel = options?.maxLabel ?? ''
    //     const minLabel = options?.minLabel ?? ''
    //     validationHandler(maxLabel, setUpperLimitValidation)
    //     validationHandler(minLabel, setLowerLimitValidation)
    // }, [formQuestion, questionIdx])

    return (
        <div style={{ margin: '1rem 0' }}>
            {formQuestion[questionIdx].type === 'NUMBER_RATING' && (
                <>
                    <div className={styles.Heading}>Responses:</div>
                    <div className={`${styles.centerFlex} ${styles.labelContainer}`}>
                        <div className={styles.AnswerLabel}>
                            <CustomInput
                                handleChange={(e) => handleChangeUpperLimitText(e)}
                                defaultValue={Options?.maxLabel}
                                placeholder='Upper Limit Label'
                                maxLength={10}
                                isDisabledTextLimitContainer={true}
                                // exceeded={upperLimitValidation.exceeded}
                                // limitText={upperLimitValidation.limitText}
                                // isEmpty={upperLimitValidation.isEmpty}

                            />
                        </div>

                        <div className={`${styles.centerFlex} ${styles.limitSetContainer}`}>
                            <div className={`${styles.limitValue}`}>{Options?.maxLimit ?? 5}</div>
                            <div className={`${styles.centerFlex} ${styles.textContainer}`}>
                                <div className={`${styles.centerFlex} ${styles.arrowRating}`}>
                                    <KeyboardArrowUpIcon className={styles.arrow} onClick={() => handleArrowUpClick('UPPER_LIMIT')} />
                                    <KeyboardArrowDownIcon className={styles.arrow} onClick={() => handleArrowDownClick('UPPER_LIMIT')} />
                                </div>
                                <div>Upper Limit</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.centerFlex} ${styles.labelContainer}`}>
                        <div className={styles.AnswerLabel}>
                            <CustomInput
                                handleChange={(e) => handleChangeLowerLimitText(e)}
                                defaultValue={Options?.minLabel}
                                placeholder='Lower Limit Label'
                                maxLength={10}
                                isDisabledTextLimitContainer={true}
                                // exceeded={lowerLimitValidation.exceeded}
                                // limitText={lowerLimitValidation.limitText}
                                // isEmpty={lowerLimitValidation.isEmpty}
                            />
                        </div>

                        <div className={`${styles.centerFlex} ${styles.limitSetContainer}`}>
                            <div className={`${styles.limitValue}`}>{Options?.minLimit ?? 1}</div>
                            <div className={`${styles.centerFlex} ${styles.textContainer}`}>
                                <div className={`${styles.centerFlex} ${styles.arrowRating}`}>
                                    <KeyboardArrowUpIcon className={styles.arrow} onClick={() => handleArrowUpClick('LOWER_LIMIT')} />
                                    <KeyboardArrowDownIcon className={styles.arrow} onClick={() => handleArrowDownClick('LOWER_LIMIT')} />
                                </div>
                                <div>Lower Limit</div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className={`${styles.centerFlex} ${styles.ratingMethodContainer}`}>
                <div className={styles.Heading}>Rating Method:</div>
                <FormControl>
                    <RadioGroup defaultValue='STAR_RATING' className={`${styles.centerFlex} ${styles.ratingMethod}`} onChange={(e, value) => handleRatingMethod(e, value)}>
                        <FormControlLabel value={RATING_METHODS.STAR_RATING} label={'Star Rating'} control={<Radio className={styles.arrow} checked={Type === RATING_METHODS.STAR_RATING} />} />
                        <FormControlLabel value={RATING_METHODS.NUMBER_RATING} label={'Number Rating'} control={<Radio className={styles.arrow} checked={Type === RATING_METHODS.NUMBER_RATING} />} />
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    )
}

export default RatingResponses;