import React, { useState, useEffect } from 'react'


//components
import Question from '../custom-input';
import SkillTags from '../skill-tags';
import Label from '../../../label';
import Context from '../context-input';
import ResponseTF from './tf-response';
import FileuploadInput from '../fileupload-input';

//styles
import { makeStyles } from "@mui/styles";
import { useContext } from 'react';

//global-states
import { GlobalStates } from '../../../../../../App';

const useStyles = makeStyles(() => ({
  labelBox: {
    margin: '1rem 0rem',
  },
}));

let answers = [
  {
    id: 0,
    option: "TRUE",
    value: true
  },
  {
    id: 1,
    option: "FALSE",
    value: false
  }
]

const TrueFalse = ({
  listitem,
  isUnChecked,
  isDisableAll,
  tags,
  selectedTag,
  handleTagClick,
  itemId,
  designType,
  list,
  setList,
  context,
  contextValidation,
  handleContextChange,
  question,
  questionValidation,
  handleQuestionChange,
  getQuestionNumber,
  responsesCharLimit,
  setSettings }) => {
  const classes = useStyles();
  const [tfResponse, setTfResponse] = useState(listitem.answers);
  const [emptyAnswers, setEmptyAnswers] = useState(listitem.emptyAnswers);
  const [unChecked, setUnChecked] = useState(listitem.unCheckedOptions);
  const [fileName, setFileName] = useState('No File Chosen');
  const [audioFile, setAudioFile] = useState(null);
  const [globalStates] = useContext(GlobalStates);



  useEffect(() => {
    setTfResponse(listitem.answers)
    setFileName(listitem.fileName);
    setAudioFile(listitem.audioFile);
  }, [list])


  const handleResponseChange = (e, index) => {
    let temp = [...tfResponse];
    temp[index].option = e.target.value;
    setTfResponse(temp);
    let tempEmpty = [...emptyAnswers]
    tempEmpty[index].isEmpty = false;
    setEmptyAnswers(tempEmpty);
  };

  const handleRadioChangeTF = (event) => {
    let value = parseInt(event.target.value)

    let tempUnChecked = [...unChecked];
    for (let i = 0; i < tempUnChecked.length; i++) {
      tempUnChecked[i].unChecked = false;
    }
    setUnChecked(tempUnChecked);

    let temp = [...tfResponse];

    for (let i = 0; i < temp.length; i++) {
      temp[i].value = false;
    }
    temp[value].value = true;
    setTfResponse(temp);

  };

  const handleFileChange = (e) => {
    let file = e.target.files[0]
    if (file.size > 5000000) {
      globalStates.handleToast(true, 'File Size Greater than 5 MB', 'error');
      return
    }
    setFileName(e.target.files[0].name);
    setAudioFile(e.target.files[0]);
    let tempList = [...list];
    for (let i = 0; i < tempList.length; i++) {
      tempList[i].audioFile = e.target.files[0];
      tempList[i].fileName = e.target.files[0].name;
      tempList[i].isAudioEmpty = false;
    }
    setList(tempList);
    setSettings(prev => ({ ...prev, textToSpeechContext: '', textToSpeechS3Url: null, textToSpeechVoice: '' }));

  }

  // defaultValues[index].option
  const audioNoteText = 'Max file size: 5 MB | Formats supported: MP3, WAV, OGG'
  return (
    <>

      {
        (designType === 'TF_SoundRight') ?
          <>
            <Label labelText={`Audio:`} fs={'0.7rem'} />
            <FileuploadInput
              isDisabled={isDisableAll}
              fileName={fileName}
              accept={'audio/*'}
              withWarning={true}
              warningText={audioNoteText}
              handleChange={handleFileChange}
            />
          </> :
          <Context
            defaultValue={context}
            isDisabled={isDisableAll}
            designType={designType}
            handleContextChange={handleContextChange}
            limitText={contextValidation.limitText}
            isExceeded={contextValidation.exceeded}
            isEmpty={listitem.isContextEmpty}
          // maxLength={designType === 'TF_CrazyCars' ? 5000 : 500}
          />
      }
      <div className={classes.labelBox} >
        <Label labelText={`Question ${getQuestionNumber(itemId)}:`} fs={'0.7rem'} />
      </div>
      <Question
        isDisabled={isDisableAll}
        defaultValue={question}
        handleChange={handleQuestionChange}
        limitText={questionValidation.limitText}
        isExceeded={questionValidation.exceeded}
        isEmpty={listitem.isQuestionEmpty}
      // maxLength={designType === 'TF_CrazyCars' ? 5000 : 500}
      />
      <SkillTags tags={tags} selectedTag={selectedTag} handleTagClick={handleTagClick} />
      <ResponseTF
        designType={designType}
        listitem={listitem}
        isUnChecked={isUnChecked}
        isDisableAll={isDisableAll}
        tfResponse={tfResponse}
        handleResponseChange={handleResponseChange}
        handleRadioChangeTF={handleRadioChangeTF}
        responsesCharLimit={responsesCharLimit}
      />
    </>
  )
}

export default TrueFalse;
