import React, { useState, useEffect } from "react";
import { v4 as uuid } from 'uuid';

//mui
import AddIcon from '@mui/icons-material/Add';
import Checkbox from "@mui/material/Checkbox";

//components
import CustomSelect from "../../../custom-styled/custom-select";
import CustomInput from "../../../custom-styled/custom-input";
import ControlledSwitches from "../../../custom-styled/switch-button";

//utils
import commonUtil from '../../../../utils/commonUtil';
import useQuery from "../../../../utils/useQuery";

//constants
import analyticsConstants from "../../constant/analytics.constant";

//services
import microskillServices from "../../../../services/microskill";
import userServices from "../../../../services/user";

//styles
import useStyles from "./index.styles";
import { isEmpty } from "lodash";

const CustomDataPoint = ({ appliedFilters, setAppliedFilters, selectedDataPoint, setSelectedDataPoint, selectedTemplate, isTemplateEdited, setIsTemplateEdited, microskillALignmentSelected, setMicroskillALignmentSelected, reportType, headerFields, setHeaderFields }) => {
    const currentDate = new Date().toISOString().split('T')[0];
    const classes = useStyles();
    let query = useQuery();
    const companyId = query.get("c_id");
    const [masteroIds, setMasteroIds] = useState([]);
    const [microskills, setMicroskills] = useState([]);


    const fetchMicroskills = async (companyId) => {
        const response = await microskillServices.fetchMicroskills(companyId, null, 1000);
        if (response.status === 200 || response.status === 201) {
            let activeMicroskills = response.data.data.filter(ele => ele.active === true);
            setMicroskills(activeMicroskills);
        }
    }

    const fetchMasteroIds = async (companyId) => {
        let response;
        //need to uncomment here
        // if (selectedMicroskillName.length !== 0) {
        //     let payload = {
        //         companyId,
        //         microskillIds: selectedMicroskillName
        //     }
        //     response = await microskillServices.getMicroskillAndDemographicWiseUsers(payload);
        //     if (response.status === 200 || response.status === 201) {
        //         const activeUsers = response.data.data.map(ele => ({ ...ele, name: ele.Username }));
        //         setMasteroIds(activeUsers);
        //     }
        // } else {
        response = await userServices.fetchUsers(companyId);
        if (response.status === 200 || response.status === 201) {
            let activeUsers = response.data.data.filter(ele => ele.active === true)
            activeUsers = activeUsers.map(ele => ({ ...ele, name: ele.username }));
            setMasteroIds(activeUsers);
        }
        // }
    }

    useEffect(() => {
        const microskillDataPoint = selectedDataPoint.find(ele => (ele.id === "microskill-id-rows" || ele.id === 'microskill-id-columns'));
        const masteroIdsDataPoint = selectedDataPoint.find(ele => ele.id === "mastero-id");
        if (!microskills.length && microskillDataPoint) fetchMicroskills(companyId);
        if (!masteroIds.length && masteroIdsDataPoint) fetchMasteroIds(companyId);
        // if (masteroIdsDataPoint) fetchMasteroIds(companyId)  //need to uncomment here
    }, [selectedDataPoint])
    // }, [selectedDataPoint, selectedMicroskillName])  //need to uncomment here



    const getInitialFilterValue = (item) => {
        const { id, filter } = item;

        switch (filter) {
            case null:
                return null
            case 'DROP_DOWN':
                return []
            case 'DATE_RANGE':
                return { id, startDate: '', endDate: '' }
            case 'DATE':
                return { id, startDate: '' }
            case 'INPUT_FIELD':
                return { id, value: 0 }
            case 'INPUT_FIELD_RANGE':
                return { id, from: 0, to: 0 }
            default:
                return null
        }

    }

    const handleInputChange = (e, element) => {
        const { name, value, type } = e.target;
        if (type === 'number' && (parseInt(value) < 0 || parseInt(value) > 100)) return;
        let key = element.uniqueId;
        const newAppliedFilters = { ...appliedFilters };
        newAppliedFilters[key][name] = value;
        setAppliedFilters(newAppliedFilters);

    }

    const getInputValue = (element) => {
        const key = element.uniqueId;
        return appliedFilters[key];
    }

    const getFilterDataComponent = (element) => {
        const filterData = element.currentFilter;
        let valueObj;
        switch (filterData) {
            case null:
                return <h5>No filter Applicable</h5>
            case "DROP_DOWN":
                const key = element.uniqueId;
                const listData = getListData(element);
                const selected = appliedFilters[key];
                const idsWithSelectAll = ['mastero-id', 'microskill-id-rows', 'microskill-id-columns'];
                return <CustomSelect
                    hideBaseBtn={true}
                    defaultText={commonUtil.getSelectLabel({ type: 'multiple', defaultText: 'Select Value', selected: selected, dataObject: commonUtil.objectify(listData, "id"), labelLength: 18 })}
                    withSelectAllOption={idsWithSelectAll.includes(element.id)}
                    customSelectAll={true}
                    _selectAll={() => _selectAllFunction(element)}
                    listData={listData}
                    selected={selected}
                    handleSelect={(item) => _selectFunction(item, element)}
                    width="200px"
                />
            case "DATE":
                valueObj = getInputValue(element)
                return <input name="value" type="date" value={valueObj.value} max={currentDate} onChange={(e) => handleInputChange(e, element)} />
            case "DATE_RANGE":
                valueObj = getInputValue(element)
                return <><input name="startDate" type="date" value={valueObj.startDate} max={currentDate} onChange={(e) => handleInputChange(e, element)} /> <input name="endDate" min={valueObj.startDate} max={currentDate} type="date" value={valueObj.endDate} onChange={(e) => handleInputChange(e, element)} /></>
            case "INPUT_FIELD":
                valueObj = getInputValue(element)
                return <input name="value" min={0} max={100} value={valueObj.value} style={{ width: 70 }} type="number" onChange={(e) => handleInputChange(e, element)} />
            case "INPUT_FIELD_RANGE":
                valueObj = getInputValue(element)
                return <> <input name="from" min={0} max={100} value={valueObj.from} style={{ width: 70 }} type="number" onChange={(e) => handleInputChange(e, element)} /> <input name="to" min={0} max={100} value={valueObj.to} style={{ width: 70 }} type="number" onChange={(e) => handleInputChange(e, element)} /></>
        }

    }

    const getListData = (element) => {
        switch (element.id) {
            case "mastero-id":
                return masteroIds;
            case "microskill-id-rows":
                return microskills;
            case "microskill-id-columns":
                return microskills;
            default:
                let selectedFilterType = element.values.find(ele => ele.id === element.selectedValue[0])
                if (selectedFilterType) return selectedFilterType.data ?? [];
                return []
        }
    }


    const _selectAllFunction = (element) => {
        let key = element.uniqueId;
        const newAppliedFilters = { ...appliedFilters };
        const list = getListData(element);
        let selected = newAppliedFilters[key]
        let isSelectAll = list.length === selected.length;
        if (isSelectAll) {
            newAppliedFilters[key] = []
        } else {
            let all = list.map(ele => ele.id);
            newAppliedFilters[key] = all;
        }

        setAppliedFilters(newAppliedFilters);
    }

    const _selectFunction = (item, element) => {
        let key = element.uniqueId;
        const newAppliedFilters = { ...appliedFilters };
        const selected = newAppliedFilters[key]
        if (selected.includes(item.id)) {
            let filtered = selected.filter(ele => ele !== item.id);
            // if (element.id === 'microskill-id-rows' || element.id === 'microskill-id-columns') {
            //     setSelectedMicroskillName(filtered);
            // }
            newAppliedFilters[key] = filtered;
        } else {
            newAppliedFilters[key] = [...selected, item.id];
            // if (element.id === 'microskill-id-rows' || element.id === 'microskill-id-columns') {
            //     setSelectedMicroskillName([...selected, item.id]);
            // }
        }

        setAppliedFilters(newAppliedFilters);
    }


    const handleSelectFilterType = (item, element) => {
        const newAppliedFilters = { ...appliedFilters };
        const withFilter = selectedDataPoint.map((ele) => {
            if (ele.uniqueId === element.uniqueId) {
                newAppliedFilters[element.uniqueId] = getInitialFilterValue(item, element);
                return { ...ele, currentFilter: item.filter, selectedValue: [item.id] }
            }
            return ele
        })

        setSelectedDataPoint(withFilter);
        setAppliedFilters(newAppliedFilters);
    }

    const selectDataPointColumns = (item) => {
        const newDataPoints = [...selectedDataPoint, { ...item, uniqueId: uuid() }];
        if (item.id === 'microskill-id-rows' || item.id === 'microskill-id-columns') {
            setMicroskillALignmentSelected(item.id);
        }
        if (!isTemplateEdited && !isEmpty(selectedTemplate)) {
            setIsTemplateEdited(true)
        }
        setSelectedDataPoint(newDataPoints);
    }

    const handleToggleGroup = (item) => {
        const newSelectedDataPoint = selectedDataPoint.map(ele => {
            if (item.uniqueId === ele.uniqueId) {
                return { ...ele, group: !ele.group }
            }
            return ele
        })
        setSelectedDataPoint(newSelectedDataPoint);
    }

    const handleFieldStatus = (item) => {
        setHeaderFields((prev) => {
            const updatedArr = prev.map((ele) => {
                if (ele.name === item.name) {
                    return { ...ele, status: !ele.status }
                }
                return ele
            })
            return updatedArr;
        })
    }

    const handleFilterCheckBox = (item) => {
        const newSelectedDataPoint = selectedDataPoint.map(ele => {
            if (item.uniqueId === ele.uniqueId) {
                return { ...ele, isFilter: !ele.isFilter }
            }
            return ele
        })
        setSelectedDataPoint(newSelectedDataPoint);
    }
    const handleValueCheckBox = (item) => {
        const newSelectedDataPoint = selectedDataPoint.map(ele => {
            if (item.uniqueId === ele.uniqueId) {
                return { ...ele, isValue: !ele.isValue }
            }
            return ele
        })
        setSelectedDataPoint(newSelectedDataPoint);
    }


    const handleDelete = (item) => {
        const filtered = selectedDataPoint.filter(ele => ele.uniqueId !== item.uniqueId);
        setSelectedDataPoint(filtered)
    }

    const handleUpdateDataPoint = (item, ele) => {
        const dataPointIndex = selectedDataPoint.findIndex(data => data.uniqueId === ele.uniqueId);
        const constantDataIndex = analyticsConstants.customDataPointConstant.findIndex(ele => ele.id === item.id);

        // Validate indices before proceeding
        if (dataPointIndex === -1 || constantDataIndex === -1) {
            console.error("Invalid item or constant reference");
            return; // Exit if indices are invalid
        }

        setSelectedDataPoint((prev) =>
            prev.map((ele, index) => {
                if (index === dataPointIndex) {
                    if (!isTemplateEdited && !isEmpty(selectedTemplate)) {
                        setIsTemplateEdited(true);
                    }
                    return {
                        ...analyticsConstants.customDataPointConstant[constantDataIndex],
                        uniqueId: ele.uniqueId,
                    };
                }
                return ele;
            })
        );
        const updatedObj = Object.fromEntries(
            Object.entries(appliedFilters).filter(([key]) => key !== ele.uniqueId)
        );
        setAppliedFilters(updatedObj);
    };

    const getInitialData = () => {
        const { customDataPointConstant } = analyticsConstants;

        if (isEmpty(microskillALignmentSelected)) {
            return customDataPointConstant.sort((a, b) => a.name.localeCompare(b.name));
        }

        const filterItem = microskillALignmentSelected === "microskill-id-columns"
            ? "microskill-id-rows"
            : "microskill-id-columns";

        return customDataPointConstant
            .filter((ele) => ele.id !== filterItem)
            .sort((a, b) => a.name.localeCompare(b.name));
    };

    return (
        <>
            {
                (reportType === "MULTILLINGUALMASTER" || reportType === "MULTILINGUALCUSTOM") ?
                    <table className={classes.wrapperTableField}>
                        <thead>
                            <tr>
                                <th>Values</th>
                                <th>Enable</th>
                                <th>Values</th>
                                <th>Enable</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: Math.ceil(headerFields.length / 2) }, (_, rowIndex) => {
                                const leftIndex = rowIndex;
                                const rightIndex = rowIndex + Math.ceil(headerFields.length / 2);

                                return (
                                    <tr key={rowIndex} className={classes.customFieldRow}>
                                        {/* Left Column */}
                                        {headerFields[leftIndex] ? (
                                            <>
                                                <td className={classes.tableTdCell}>
                                                    <CustomInput
                                                        value={headerFields[leftIndex].name}
                                                        isDisabled={true}
                                                        wrapperWidth="15rem"
                                                        withLabel={false}
                                                        withLimitWarning={false}
                                                    />
                                                </td>
                                                <td>
                                                    <div className={classes.toggleWrapper}>
                                                        <ControlledSwitches
                                                            checked={headerFields[leftIndex].status}
                                                            handleChange={() => handleFieldStatus(headerFields[leftIndex])}
                                                        />
                                                    </div>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td></td>
                                                <td></td>
                                            </>
                                        )}

                                        {/* Right Column */}
                                        {headerFields[rightIndex] ? (
                                            <>
                                                <td className={classes.tableTdCell}>
                                                    <CustomInput
                                                        value={headerFields[rightIndex].name}
                                                        isDisabled={true}
                                                        wrapperWidth="15rem"
                                                        withLabel={false}
                                                        withLimitWarning={false}
                                                    />
                                                </td>
                                                <td>
                                                    <div className={classes.toggleWrapper}>
                                                        <ControlledSwitches
                                                            checked={headerFields[rightIndex].status}
                                                            handleChange={() => handleFieldStatus(headerFields[rightIndex])}
                                                        />
                                                    </div>
                                                </td>
                                            </>
                                        ) : (
                                            <>
                                                <td></td>
                                                <td></td>
                                            </>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    :
                    <>
                        {selectedDataPoint.length ? (
                            <table className={classes.wrapperTable}>
                                <thead>
                                    <tr>
                                        <th>Data Point</th>
                                        <th>Function</th>
                                        <th>Parameter</th>
                                        <th>Group</th>
                                        <th>Filter</th>
                                        <th>Value </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedDataPoint.map((ele, index) => (
                                        <tr key={index} className={classes.customFieldRow}>
                                            <td>

                                                <CustomSelect
                                                    hideBaseBtn={true}
                                                    defaultText={commonUtil.getSelectLabel({
                                                        type: 'single',
                                                        defaultText: 'Select Value',
                                                        selected: [`${ele.id}`],
                                                        dataObject: commonUtil.objectify(analyticsConstants.customDataPointConstant, "id"),
                                                    })}
                                                    withSelectAllOption={false}
                                                    listData={analyticsConstants.customDataPointConstant.sort((a, b) => (a.name > b.name) ? 1 : -1)}
                                                    selected={[`${ele.id}`]}
                                                    handleSelect={(item) => handleUpdateDataPoint(item, ele)}
                                                    autoClose={true}
                                                    width="200px"
                                                    radio={true}
                                                />
                                            </td>
                                            <td>
                                                <CustomSelect
                                                    hideBaseBtn={true}
                                                    defaultText={commonUtil.getSelectLabel({
                                                        type: 'single',
                                                        defaultText: 'Select Value',
                                                        selected: ele.selectedValue,
                                                        dataObject: commonUtil.objectify(ele.values, "id"),
                                                    })}
                                                    withSelectAllOption={false}
                                                    listData={ele.values}
                                                    selected={ele.selectedValue}
                                                    handleSelect={(item) => handleSelectFilterType(item, ele)}
                                                    autoClose={true}
                                                    width="200px"
                                                    radio={true}
                                                />
                                            </td>
                                            <td>
                                                {ele.currentFilter ? (
                                                    getFilterDataComponent(ele)
                                                ) : (
                                                    <h5>No filter Applicable</h5>
                                                )}
                                            </td>
                                            <td>
                                                {ele.id !== "content-launch-date" && (
                                                    <div className={classes.toggleWrapper}>
                                                        <ControlledSwitches
                                                            checked={ele.group}
                                                            handleChange={() => handleToggleGroup(ele)}
                                                        />
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <div className={classes.checkBoxDiv}>
                                                    <Checkbox
                                                        checked={ele.isFilter}
                                                        onChange={() => handleFilterCheckBox(ele)}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: "#ff5722",
                                                            },
                                                            '& .MuiSvgIcon-root': { fontSize: 20 },
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className={classes.checkBoxDiv}>
                                                    <Checkbox
                                                        checked={ele.isValue}
                                                        onChange={() => handleValueCheckBox(ele)}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                                color: "#ff5722",
                                                            },
                                                            '& .MuiSvgIcon-root': { fontSize: 20 },
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                <img
                                                    onClick={() => handleDelete(ele)}
                                                    src="/images/icons/delete-photo.svg"
                                                    alt="Delete Button"
                                                    title="Delete"
                                                    style={{ height: "15px", width: "auto" }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}

                        <div className={classes.addContentBtn}>
                            <AddIcon />  Add Headers
                            <CustomSelect
                                hideBaseBtn={true}
                                defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Headers', selected: [], dataObject: {} })}
                                withSelectAllOption={false}
                                listData={getInitialData()}
                                selected={[]}
                                handleSelect={selectDataPointColumns}
                                // withCheckBox={false}
                                autoClose={true}
                                width="230px"
                                radio={true}
                            />
                        </div>
                    </>
            } </>
    );
};

export default CustomDataPoint;