import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    Heading: {
        color: "#484848",
        fontFamily: "Montserrat",
        fontWeight: "bold"
    },
    labelContainer: {
        justifyContent: 'space-between',
        margin: '1rem 0'
    },
    AnswerLabel: {
        width: '70%'
    },
    limitSetContainer: {
        width: '25%',
        height: '100%',
        border: '1px solid #70707050'
    },
    limitValue: {
        width: '25%',
        textAlign: 'center',
        background: '#FFF',
        color: 'rgba(112, 112, 112, 0.9)'
    },

    textContainer: {
        flexDirection: 'row',
        columnGap: '0.5rem',
        padding: '0 0.5rem',
        width: '75%',
        background: 'rgb(245, 245, 245);'
    },

    arrowRating: {
        flexDirection: 'column'
    },

    arrow: {
        fontSize: '1rem',
        color: '#F4511E !important',
        cursor: 'pointer'
    },

    ratingMethodContainer: {
        flexDirection: 'row',
        columnGap: '1rem',
        justifyContent: 'flex-start'
    },

    ratingMethod: {
        flexDirection: 'row'
    }
}));

export default useStyles;