import React, { useEffect, useState } from 'react'

// mui
import { FormControl, FormControlLabel, RadioGroup, Typography, Radio } from '@mui/material'
import DragDropFiles from '../../../drag-drop-files-uploader/index';
import UploadedFiles from '../../../drag-drop-files-uploader/uploaded-files';

//  constants
import constants from '../../../../constants';

//styles
import useStyles from '../../index.styles';

// components
import ViaDemographic from '../via-demographic';
import CustomSelect from '../../../custom-styled/custom-select';

//utils
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';

const byStatus = [{
    name: 'Not Started',
    id: 'NotStarted'
}, {
    name: 'In Progress',
    id: 'Inprogress'
}, {
    name: 'Completed',
    id: 'Completed'
}]

const Files = (props) => {

    const classes = useStyles();
    const fileLink = constants.USER_LAUNCH_FILE_FORMAT;
    const { setLearnerOption,
        learnerOption,
        fileUpload,
        setFileUpload,
        showDwModal,
        setShowDwModal,
        selectedUsers,
        setSelectedUsers,
        headings,
        setHeadings,
        userData,
        setUserData,
        isDwVisible,
        setIsDwVisible,
        setSelectedLaunchType,
        setLaunchType,
        setIsLaunchVisible,
        demographicFilter,
        setDemographicFilter,
        getSelectedDemographics,
        getMappingDetails,
        mappingObject,
        mapping,
        setMapping,
        linkedContent,
        selectedMicroskills,
    } = props;

    const handleChange = (e, value) => {

        if (e.target.value === 'DEMOGRAPHIC') {
            setIsDwVisible(true);
            setSelectedLaunchType('DEMOGRAPHIC');
            setLearnerOption(value);
        } else if (e.target.value === 'CSV') {
            setIsDwVisible(false);
            setSelectedLaunchType('CSV');
            setLearnerOption(value);
        }
        else {
            setIsDwVisible(false);
            setSelectedLaunchType('BYSTATUS');
            setLearnerOption(value);
        }
    }

    const handleFileUpload = (files) => {
        setFileUpload(files[0])
    }

    const handleSelectStatus = (id) => {
        props.setSelectedStatus([id]);
        setIsLaunchVisible(true)
    }

    return (
        <>
            <FormControl className={classes.notificationLaunchType}>

                <RadioGroup
                    onChange={handleChange}>
                    <FormControlLabel value="CSV" control={<Radio checked={learnerOption === "CSV"} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>Via  Excel File</Typography>} />
                </RadioGroup>

                <RadioGroup
                    onChange={handleChange}>
                    <FormControlLabel value="DEMOGRAPHIC" control={<Radio checked={learnerOption === "DEMOGRAPHIC"} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>Demographic Wise</Typography>} />
                </RadioGroup>
                {
                    (!isEmpty(linkedContent) && linkedContent[0] === "Microskills" && !isEmpty(selectedMicroskills)) &&
                    <RadioGroup
                        onChange={handleChange}>
                        <FormControlLabel value="BYSTATUS" control={<Radio checked={learnerOption === "BYSTATUS"} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>By Status</Typography>} />
                    </RadioGroup>
                }


            </FormControl>
            {learnerOption === "CSV" && (<div style={{ padding: "1rem 0rem" }}>
                <a style={{
                    textDecoration: "none",
                    color: "#F4511E",
                    fontWeight: "bolder",
                    fontSize: "1rem",
                    fontFamily: 'Montserrat',
                }} href={fileLink} className={classes.anchor} title='Download File Format'>Download File Format</a>
            </div>)}
            {learnerOption === "CSV" && (
                <div style={{ width: "40%", height: "15rem", marginBottom: "5rem" }}>
                    {fileUpload === null ?
                        <DragDropFiles title={'Drag & Drop your file here to import'} handleChange={handleFileUpload} typesAccepted={["XLS", "XLSX"]} />
                        :
                        <UploadedFiles fileName={fileUpload.name} setFileUpload={setFileUpload} />
                    }
                </div>
            )}

            {/* Demographic Wise  */}

            {
                (learnerOption === "DEMOGRAPHIC" && isDwVisible) && (
                    <div className={classes.notificationDemographic}>
                        <ViaDemographic
                            showDwModal={showDwModal}
                            setShowDwModal={setShowDwModal}
                            isDwVisible={isDwVisible}
                            setIsDwVisible={setIsDwVisible}
                            headings={headings}
                            setHeadings={setHeadings}
                            selectedUsers={selectedUsers}
                            setSelectedUsers={setSelectedUsers}
                            userData={userData}
                            setUserData={setUserData}
                            setLaunchType={setLaunchType}
                            setIsLaunchVisible={setIsLaunchVisible}
                            demographicFilter={demographicFilter}
                            setDemographicFilter={setDemographicFilter}
                            getSelectedDemographics={getSelectedDemographics}
                            getMappingDetails={getMappingDetails}
                            mappingObject={mappingObject}
                            mapping={mapping}
                            setMapping={setMapping}
                        />
                    </div>
                )
            }
            {(learnerOption === "BYSTATUS") &&
                <div style={{ padding: "1rem 0rem" }}>
                    <CustomSelect
                        hideBaseBtn={true}
                        defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Status', selected: props.selectedStatus, dataObject: commonUtil.objectify(byStatus, "id") })}
                        withSelectAllOption={false}
                        listData={byStatus}
                        selected={props.selectedStatus}
                        handleSelect={(item) => { handleSelectStatus(item.id); }}
                        radio={true}
                        autoClose={true}
                        width={'250px'}
                    />
                </div>
            }

            {/* Demographic Wise  */}

        </>
    )
}

export default Files